import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ConfiguracionMain from ".";
import LayoutConfiguracion from "../../components/layout-configuracion";
import AsignarGeocercaUsuario from "./asignar-geocerca-a-usuario";
import GestionarTiempoCarga from "./gestionar_tiempo_carga";
import GestionarTiempoDescarga from "./gestionar_tiempo_descarga";
import SeleccionarGrupoGeocercasIncidente from "./seleccionar-grupo-geocerca-ciudad";
import ListarCategoria from "./categoria/listar-categoria";
import Categoria from "./categoria/categoria";
import ListarBitacora from "./categoria-bitacora/listar-bitacora";
import Bitacora from "./categoria-bitacora/bitacora";
import ListarTipoIncidente from "./listar-tipo-incidente";
import TipoIncidente from "./tipo-incidente";
import ListarEstadoCamion from "./listar-estado-camion";
import EstadoCamion from "./estado-camion";
import ListarSubTipoUsuarioWeb from "./subtipo-usuario-web/listar-subtipo-usuario-web";
import SubTipoUsuarioWeb from "./subtipo-usuario-web/subtipo-usuario-web";

const RouterConfiguracion = () => {
  const { path } = useRouteMatch();
  return (
    <LayoutConfiguracion>
      <Switch>

        <Route exact path={`${path}/gestionar_tiempo_carga`}>
          <GestionarTiempoCarga />
        </Route>
        <Route exact path={`${path}/gestionar_tiempo_descarga`}>
          <GestionarTiempoDescarga />
        </Route>
        <Route exact path={`${path}/asignar-geocerca-usuario`}>
          <AsignarGeocercaUsuario />
        </Route>
        <Route exact path={`${path}/seleccionar-geocerca-ciudad-incidente`}>
          <SeleccionarGrupoGeocercasIncidente />
        </Route>
        <Route exact path={`${path}/categoria/listar-categoria`}>
          <ListarCategoria />
        </Route>
        <Route exact path={`${path}/categoria/categoria`}>
          <Categoria />
        </Route>
        <Route exact path={`${path}/subtipo-usuario-web/listar-subtipo-usuario-web`}>
          <ListarSubTipoUsuarioWeb />
        </Route>
        <Route exact path={`${path}/subtipo-usuario-web/subtipo-usuario-web`}>
          <SubTipoUsuarioWeb />
        </Route>

        <Route exact path={`${path}/listar-tipo-incidente`}>
          <ListarTipoIncidente />
        </Route>
        <Route exact path={`${path}/tipo-incidente`}>
          <TipoIncidente />
        </Route>

        <Route exact path={`${path}/listar-estado-camion`}>
          <ListarEstadoCamion />
        </Route>
        <Route exact path={`${path}/estado-camion`}>
          <EstadoCamion />
        </Route>


        <Route exact path={`${path}/categoria-bitacora/listar-bitacora`}>
          <ListarBitacora />
        </Route>
        <Route exact path={`${path}/categoria-bitacora/bitacora`}>
          <Bitacora />
        </Route>

        <Route exact path={`${path}`}>
          <ConfiguracionMain />
        </Route>


      </Switch>
    </LayoutConfiguracion>
  );
};

export default RouterConfiguracion;

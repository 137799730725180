import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import readXlsxFile from "read-excel-file";
import Grid from "../../components/grid";
import { obtenerGeocerca } from "../../api/reportes";
//import { obtenerListadoGeocercas } from "../../api/gestion-dts/gestion";
import moment from "moment";
import "moment-timezone";

export default function EntradasGeocercas() {
  const history = useHistory();
  const location = useLocation();
  const [nombreExcel, setNombreExcel] = useState("");
  const [excelValue, setExcelValue] = useState(null);
  const [dataEntradasGeocerca, setDataEntradasGeocerca] = useState([]);
  const [dataExcel, setDataExcel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [porcentajeAvance, setPorcentajeAvance] = useState(0);

  const headersEntradasGeocerca = [
    { key: "codGeocerca", title: "Cod. Destino", filter: true },
    { key: "nombreGeocerca", title: "Destino", filter: true },
    { key: "placa", title: "Placa", filter: true },
    { key: "fecha", title: "Fecha", filter: true },
    { key: "hora", title: "Hora", filter: true },
    { key: "diferencia", title: "Diferencia", filter: true },
  ];
  const headersDataExcel = [
    { key: "codigo", title: "Cod. Destino", filter: true },
    { key: "placa", title: "Placa", filter: true },
    { key: "fechaInicioVisualizada", title: "Fecha Inicio", filter: true },
    { key: "horaInicio", title: "Hora Inicio", filter: true },
    { key: "fechaCierreVisualizada", title: "Fecha Fin", filter: true },
    { key: "status", title: "Status", filter: true },
  ];

  const importar = () => {
    try {
      document.getElementById("fileUpload").click();
    } catch (err) {
      console.log("importarExcel err");
      console.log(err);
    }
  };
  const procesarExcel = (rows) => {
    try {
      console.log(rows);
      if (rows.length == 0) {
        return;
      }
      let dataFormat = [];

      for (let i = 1; i < rows.length; i++) {
        if (rows[i][6] === "CERRADA") {
          let row = {
            codigo: rows[i][0] ? rows[i][0].toString() : "",
            placa: rows[i][1] ? rows[i][1].toString() : "",
            fechaInicioVisualizada: rows[i][2]
              ? moment.utc(rows[i][2]).format("YYYY-MM-DD")
              : "",
            horaInicio: rows[i][3]
              ? moment.utc(rows[i][3]).format("HH:mm")
              : "",
            fechaCierreVisualizada: rows[i][4]
              ? moment.utc(rows[i][4]).format("YYYY-MM-DD")
              : "",
            fechaInicio: rows[i][2]
              ? `${moment.utc(rows[i][2]).format("YYYY-MM-DD")} 00:00:00`
              : "",
            fechaCierre: rows[i][4]
              ? `${moment.utc(rows[i][4]).format("YYYY-MM-DD")} 23:59:59`
              : "",
            status: rows[i][6] ? rows[i][6].toString() : "",
          };
          dataFormat.push(row);
        }
      }

      setDataExcel(dataFormat);

      if (dataFormat.length > 0) {
        alert("Se cargaron los datos correctamente");
      }
    } catch (err) {
      console.log("procesarExcel err");
      console.log(err);
    }
  };
  const obtainReportGeocerca = async () => {
    setLoading(true);
    let data = [];
    const batchSize = 10;
    for (let i = 0; i < dataExcel.length; i += batchSize) {
      const batch = dataExcel.slice(i, i + batchSize);
      setPorcentajeAvance(i / dataExcel.length);
      let resp = await obtenerGeocerca(batch);
      console.log(batch);
      for (let x = 0; x < resp.length; x++) {
        if (resp[x].fechaHora !== null) {
          let fechaHora = new Date(resp[x].fechaHora);
          let fecha = fechaHora.toLocaleDateString("es-BO", {
            timeZone: "America/La_Paz",
          });
          let hora = fechaHora.toLocaleTimeString("es-BO", {
            timeZone: "America/La_Paz",
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          });
          resp[x].fecha = fecha;
          resp[x].hora = hora;
          console.log(fecha + ":" + hora);
          console.log(fechaHora);
          const fechaHoraResp = moment(
            `${moment(moment.tz(fechaHora, "America/La_Paz")).format(
              "YYYY-MM-DD"
            )} ${moment(moment.tz(fechaHora, "America/La_Paz")).format(
              "HH:mm"
            )}`
          );
          console.log(fechaHoraResp);
          const fechaHoraBatch = moment(
            `${batch[x].fechaInicioVisualizada} ${batch[x].horaInicio}`
          );
          console.log(fechaHoraBatch);
          const diff = fechaHoraResp.diff(fechaHoraBatch);

          console.log(diff);
          console.log(moment.duration(diff).asHours());
          resp[x].diferencia = `${Math.trunc(moment.duration(diff).asHours())
            .toString()
            .padStart(2, "0")}:${Math.abs(moment.duration(diff).minutes())
            .toString()
            .padStart(2, "0")}`;
        } else {
          resp[x].fecha = "-";
          resp[x].hora = "-";
          resp[x].diferencia = "00:00";
        }
      }
      data.push(...resp);
    }
    setDataEntradasGeocerca(data);
    setLoading(false);
    console.log(data);
  };
  const reset = () => {
    try {
      setDataExcel([]);
    } catch (err) {
      console.log("reset err");
      console.log(err);
    }
  };
  useEffect(() => {
    if (excelValue !== null) {
      reset();
      procesarExcel(excelValue);
    }
  }, [excelValue]);
  /*useEffect(() => {
    obtainReportGeocerca();
  }, [dataExcel]);*/
  useEffect(() => {
    setNombreExcel("");
    const fileUpload = document.getElementById("fileUpload");
    fileUpload.addEventListener("change", async () => {
      const resp = await readXlsxFile(fileUpload.files[0]);
      setNombreExcel(fileUpload.files[0].name);
      setExcelValue(resp);
      fileUpload.value = null;
    });
  }, []);

  return (
    <div className="p-[2rem]">
      <input
        id="fileUpload"
        type="file"
        className="my-auto text-white p-[5px] px-[10px] mr-[10px] hidden"
      />
      <div className="flex justify-between">
        <div>{`Entradas a geocercas (Excel)`}</div>
        <div>
          <button
            className="my-auto bg-[#84B044] rounded text-white p-[5px] px-[10px] mr-[10px]"
            onClick={() => {
              importar();
            }}
          >
            CARGAR
          </button>
          <button
            className="my-auto bg-[#84B044] rounded text-white p-[5px] px-[10px] mr-[10px]"
            onClick={() => {
              obtainReportGeocerca();
            }}
          >
            GENERAR
          </button>
        </div>
      </div>
      <div>
        <Grid
          rows={dataExcel}
          headers={headersDataExcel}
          //filaClick={filaClick}
          center={true}
        />
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 30,
          }}
        >
          <div
            className="loader"
            style={{ marginTop: "15px", height: "50px", width: "50px" }}
          ></div>
          <div style={{ marginTop: 10 }}>
            <p>{`Cargando... ${Math.trunc(porcentajeAvance * 100)}%`}</p>
          </div>
        </div>
      ) : (
        <div className="mt-[15px]">
          <Grid
            rows={dataEntradasGeocerca}
            headers={headersEntradasGeocerca}
            exportar={true}
            //filaClick={filaClick}
            center={true}
          />
        </div>
      )}
    </div>
  );
}

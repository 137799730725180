import carIcon from "../assets/iconos/car.png";

export const MODULOS_ADMINISTRACION_USUARIO = [
  {
    id: 1,
    titulo: "Usuario Web",
    descripcion: "Administradores de la flota",
    //iconHero: true,
    icono: carIcon,
    color: "rgba(0, 128, 0, 0.5)",
    href: "usuario/listar-usuarios-web",
  },
  {
    id: 2,
    titulo: "Usuario Movil",
    descripcion: "Choferes",
    //iconHero: true,
    icono: carIcon,
    color: "rgba(0, 128, 0, 0.5)",
    href: "usuario/listar-usuarios-movil",
  },
];

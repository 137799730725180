import { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { isTokenExpire } from "../utils/token";
import {
  Bars3BottomLeftIcon,
  Squares2X2Icon,
  XMarkIcon,
  MapIcon,
  ArrowLeftOnRectangleIcon,
  UserCircleIcon,
  BellAlertIcon,
  UserGroupIcon,
  Cog8ToothIcon,
  ClipboardDocumentCheckIcon,
  ChartBarSquareIcon,
  CubeTransparentIcon,
  DocumentTextIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";
import { Dialog, Menu, Transition } from "@headlessui/react";

import Logo from "../img/logo.svg";

function Layout({ children }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [expireToken, setExpireToken] = useState(true);
  const history = useHistory();

  const sidebarNavigation = [
    { name: "Dashboard", href: "/", icon: ChartBarSquareIcon },
    { name: "Checklist", href: "/checklist", icon: ClipboardDocumentCheckIcon },
    { name: "Seguimiento", href: "/seguimiento", icon: MapIcon },
    { name: "Eventos", href: "/eventos", icon: Squares2X2Icon },
    { name: "Descargas", href: "/descargas", icon: ArrowRightOnRectangleIcon },
    { name: "Cargas", href: "/cargas", icon: ArrowLeftOnRectangleIcon },
    { name: "Usuarios", href: "/usuario", icon: UserGroupIcon },
    { name: "Configuracion", href: "/configuracion", icon: Cog8ToothIcon },
    { name: "Reportes", href: "/reportes", icon: DocumentTextIcon },
    { name: "Georuta", href: "/georuta", icon: CubeTransparentIcon },
    { name: "Alertas", href: "/alertas", icon: BellAlertIcon },
    { name: "Salir", href: "/salir", icon: ArrowLeftOnRectangleIcon },
  ].map((menuItem) => ({
    ...menuItem,
    getHref: () => (menuItem.primary ? `/` : `/${menuItem.href}`),
    current: function getCurrent(currentPath) {
      return currentPath === this.getHref();
    },
  }));
  const userNavigation = [
    { name: "Your Profile", href: "#" },
    { name: "Sign out", href: "#" },
  ];
  const nextPage = (page) => {
    try {
      history.push(`${page}`);
    } catch (err) {
      console.log("nextPage err");
      console.log(err);
    }
  };
  useEffect(() => {
    const resp = isTokenExpire();
    if (resp) {
      nextPage("/login");
    } else {
      setExpireToken(false);
    }
  }, []);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  if (expireToken) {
    return <div></div>;
  }
  return (
    <>
      <div className="flex h-[100vh]">
        {/* Narrow sidebar */}
        <div className="hidden w-28 overflow-y-auto bg-[#113A5F] md:block">
          <div className="flex w-full flex-col items-center py-6">
            <div className="flex flex-shrink-0 items-center px-[10px]">
              <img className="h-8 w-auto " alt="Boltrack" src={Logo} />
            </div>
            <div className="mt-6 w-full flex-1 space-y-1 px-2">
              {sidebarNavigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    /*item.current(router.asPath)
                      ? "bg-[#1b5993] text-white"
                      : */ "text-indigo-100 hover:bg-[#1b5993] hover:text-white",
                    "group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
                  )}
                  aria-current={
                    /*item.current(router.asPath) ? "page" :*/ undefined
                  }
                >
                  <item.icon
                    className={classNames(
                      /*item.current(router.asPath)
                        ? "text-white"
                        : */ "text-indigo-300 group-hover:text-white",
                      "h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  <span className="mt-2">{item.name}</span>
                </a>
              ))}
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-20 md:hidden"
            onClose={setMobileMenuOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-[#113A5F] pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-1 right-0 -mr-14 p-1">
                      <button
                        type="button"
                        className="flex h-12 w-12 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Close sidebar</span>
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img className="h-8 w-auto " alt="Boltrack" src={Logo} />
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto px-2">
                    <nav className="flex h-full flex-col">
                      <div className="space-y-1">
                        {sidebarNavigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              /*item.current(router.asPath)
                                ? "bg-[#1b5993] text-white"
                                : */ "text-indigo-100 hover:bg-[#1b5993] hover:text-white",
                              "group py-2 px-3 rounded-md flex items-center text-sm font-medium"
                            )}
                            aria-current={
                              /*item.current(router.asPath) ? "page" :*/ undefined
                            }
                          >
                            <item.icon
                              className={classNames(
                                /*item.current(router.asPath)
                                  ? "text-white"
                                  : */ "text-indigo-300 group-hover:text-white",
                                "mr-3 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            <span>{item.name}</span>
                          </Link>
                        ))}
                      </div>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Content area */}
        <div className="flex flex-1 flex-col overflow-hidden ">
          <header className="w-full md:hidden">
            <div className="relative z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white shadow-sm">
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex flex-1 justify-between px-4 sm:px-6">
                <div className="flex flex-1"></div>
                <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative flex-shrink-0">
                    <div>
                      <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span className="sr-only">Open user menu</span>
                        <UserCircleIcon
                          className="h-8 w-8 rounded-full"
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <Link
                                to={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                {item.name}
                              </Link>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </header>
          <div className="flex flex-1 items-stretch overflow-hidden">
            <aside className=" w-full overflow-y-auto  border-gray-200 bg-white lg:block">
              {children}
            </aside>
          </div>
        </div>
      </div>
    </>
  );
}
export default Layout;

import moment from "moment/moment";
import { SUCCESS } from "../page/const/http-const";
import { getHeaders } from "./utils";

export async function obtenerGrupoGeoruta(obj) {
  try {
    let info = null;
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/georuta/obtener-grupo-georuta/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      if (body[0] !== null && body[0] !== undefined) {
        info = body[0];
      }
    }
    return info;
  } catch (err) {
    console.log("obtenerGrupoGeoruta err");
    console.log(err);
    return null;
  }
}

export async function guardarGrupoGeoruta(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/georuta/guardar-grupo-georuta/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("guardarGrupoGeoruta err");
    console.log(err);
    return false;
  }
}

export async function modificarGrupoGeoruta(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/georuta/modificar-grupo-georuta/",
      {
        headers: getHeaders(),
        method: "PUT",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("modificarGrupoGeoruta err");
    console.log(err);
    return false;
  }
}

export async function obtenerGrupoPuntosControl(obj) {
  try {
    let info = null;
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/georuta/obtener-grupo-puntos-control/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      if (body[0] !== null && body[0] !== undefined) {
        info = body[0];
      }
    }
    return info;
  } catch (err) {
    console.log("obtenerGrupoPuntosControl err");
    console.log(err);
    return null;
  }
}

export async function guardarGrupoPuntosControl(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/georuta/guardar-grupo-puntos-control/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("guardarGrupoPuntosControl err");
    console.log(err);
    return false;
  }
}

export async function modificarGrupoPuntosControl(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/georuta/modificar-grupo-puntos-control/",
      {
        headers: getHeaders(),
        method: "PUT",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("modificarGrupoPuntosControl err");
    console.log(err);
    return false;
  }
}

export async function obtenerGeoruta(obj) {
  try {
    let rows = [];
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/georuta/obtener-georuta/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      rows = body;
      for (let i = 0; i < rows.length; i++) {
        const element = rows[i];
        rows[i].fechaproceso = moment(element.fechaProceso).format(
          "DD/MM/YYYY HH:mm"
        );
      }
    }
    return rows;
  } catch (err) {
    console.log("obtenerGeoruta err");
    console.log(err);
    return [];
  }
}

export async function guardarGeoruta(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/georuta/guardar-georuta/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("guardarViaje err");
    console.log(err);
    return false;
  }
}

export async function modificarGeoruta(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/georuta/modificar-georuta/",
      {
        headers: getHeaders(),
        method: "PUT",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("modificarGeoruta err");
    console.log(err);
    return false;
  }
}

export async function deshabilitarGeoruta(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/georuta/deshabilitar-georuta/",
      {
        headers: getHeaders(),
        method: "PUT",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("deshabilitarGeoruta err");
    console.log(err);
    return false;
  }
}

export async function obtenerCantidadCodigoGeoruta(obj) {
  try {
    let esUnico = false;
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/georuta/obtener-cantidad-codigo-georuta/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS && body[0] !== undefined) {
      esUnico = body[0].total === 0;
    }
    return esUnico;
  } catch (err) {
    console.log("obtenerCantidadCodigoGeoruta err");
    console.log(err);
    return false;
  }
}

export async function obtenerItinerarioGeorutaDescargaXEmpresa(obj) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/georuta/obtener-itinerario-georuta-descarga-empresa/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());

    return body;
  } catch (err) {
    console.log("obtenerItinerarioGeorutaDescargaXEmpresa err");
    console.log(err);
    return null;
  }
}

export async function obtenerItinerarioGeorutaDescarga(obj) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/georuta/obtener-itinerario-georuta-descarga/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());

    return body;
  } catch (err) {
    console.log("obtenerItinerarioGeorutaDescarga err");
    console.log(err);
    return null;
  }
}

export async function finalizarGeorutaAsignadad(obj) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/georuta/finalizar-georuta-asignada/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());

    return body;
  } catch (err) {
    console.log("obtenerItinerarioGeorutaDescarga err");
    console.log(err);
    return null;
  }
}

export async function obtenerEstadoItinerarioDescarga(obj) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/georuta/obtener-estado-itinerario-descarga/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());

    return body;
  } catch (err) {
    console.log("obtenerEstadoItinerarioDescarga err");
    console.log(err);
    return null;
  }
}

export async function obtenerItinerarioGeoruta(obj) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/georuta/obtener-itinerario-georuta/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());

    return body;
  } catch (err) {
    console.log("obtenerItinerarioGeoruta err");
    console.log(err);
    return null;
  }
}

export async function asignarGeorutaDescarga(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/georuta/asignar-georuta-descarga/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("asignarGeorutaDescarga err");
    console.log(err);
    return false;
  }
}

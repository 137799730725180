import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { parseJwt } from "../../utils/token";
import {
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
  INT_ID_MODULO_DASHBOARD,
} from "../../utils/const-int";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
import { TRUE } from "../../utils/const-bool";
import { CUMPLIMIENTO_DESCARGA } from "./const_dashboard_card";
import "../../style/style-cargando.css";
import {
  ESTILO_BOTON_LISTAR_ATRAS,
  ESTILO_BOTON_LISTAR_NUEVO,
} from "../../utils/const-estilos";
import ModalDashboardOptions from "../../components/modal-dashboard-options";
import {
  guardarDashboardCard,
  obtenerDashboardCardCombo,
  obtenerDashboardCards,
} from "../../api/dashboard";
import {
  obtenerDescargaPorFechaProceso,
  obtenerIngresoUnidadesAGeocerca,
} from "../../api/reportes";
import {
  STRING_ALIGN_LEFT,
  STRING_ALIGN_RIGHT,
  STRING_BOOL_FALSE,
  STRING_BOOL_TRUE,
} from "../../utils/const-string";
import { XSquare } from "feather-icons-react/build/IconComponents";
import moment from "moment";
import "react-widgets/styles.css";
import DatePicker from "react-widgets/DatePicker";
import NoInfo from "../../components/modal-no-info";
import { sharedGetIdEmpresa } from "../../share/shared-functions";
import ReactApexChart from "react-apexcharts";
import shortid from "shortid";

const idTipoUsuario = parseInt(localStorage.getItem("tipo"));

export default function Dashboard(props) {
  const history = useHistory();
  const [data, setData] = useState({});
  const [cargandoPage, setCargandoPage] = useState(true);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);
  const [permisos, setPermisos] = useState(null);
  // const [listaDashboardCard, setListaDashboardCard] = useState([]);
  const [listaDashboardCardOptions, setListaDashboardCardOptions] = useState(
    []
  );
  const [dashboardCardSelected, setDashboardCardSelected] = useState(null);
  const [showModalNuevoDashboardCard, setShowModalNuevoDashboardCard] =
    useState(false);
  const [leftColumn, setLeftColumn] = useState([]);
  const [rightColumn, setRightColumn] = useState([]);

  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const info = parseJwt(token);
      setData(info);
      let objPermisos = {};
      objPermisos.idMenuPrimerNivel = INT_ID_MODULO_DASHBOARD;
      objPermisos.idMenuSegundoNivel = 0;
      objPermisos.idMenuTercerNivel = 0;
      const obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(
        objPermisos
      );
      // console.log('obtenerPermisos');
      // console.log(obtenerPermisos);
      const listaDashboardCardOpt = await obtenerDashboardCardCombo();
      // console.log('listaDashboardCardOpt');
      // console.log(listaDashboardCardOpt);
      setListaDashboardCardOptions(listaDashboardCardOpt);
      const permiso = obtenerPermisos[0];
      setPermisos(permiso);
      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        setTieneAccesoAlModulo(true);
        await getDashboardCards();
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.accesoAlModulo === TRUE) {
          setTieneAccesoAlModulo(true);
          await getDashboardCards();
        }
      }
      setCargandoPage(false);
    } catch (err) {
      console.log("iniciar dashboard err");
      console.log(err);
      setCargandoPage(false);
    }
  };

  const loadingCard = (data) => {
    // console.log('loadingCard');
    // console.log(data);
    return (
      <div
        className="flex flex-col border border-gray-300 shadow-md m-4 p-4 rounded-lg"
        key={shortid.generate()}
      >
        <div className="flex justify-between items-center">
          <label className="font-bold">{data.title}</label>
          <button
            type="button"
            className={ESTILO_BOTON_LISTAR_ATRAS}
            title="Eliminar Card"
            onClick={() => {
              deleteDashboardCard(data);
            }}
          >
            <XSquare />
          </button>
        </div>
        <div className="flex flex-col items-center">
          <div className="loader"></div>
          <div className="mt-10">
            <p>Cargando...</p>
          </div>
        </div>
      </div>
    );
  };

  const handleNuevoClick = () => {
    setShowModalNuevoDashboardCard(true);
  };

  const hideModalNuevoDashboardCard = () => {
    setShowModalNuevoDashboardCard(false);
    setDashboardCardSelected(null);
  };

  const guardarNuevoDashboardCard = async (data) => {
    try {
      console.log("guardarNuevoDashboardCard");
      console.log(data);
      if (data === null) {
        alert("Debe seleccionar una opcion para guardar un nuevo Card");
        return;
      }
      let objEnviar = {};
      objEnviar.idDashboardCard = data.value;
      objEnviar.align = getLeftOrRightAlign();
      objEnviar.position = getPosition(objEnviar.align);
      const idGuardo = await guardarDashboardCard(objEnviar);
      if (idGuardo === null) {
        alert("No se pudo guardar el nuevo card, intente nuevamente");
        return;
      }
      if (idGuardo !== null) {
        let objNewCard = {};
        objNewCard.loading = true;
        objNewCard.align = objEnviar.align;
        objNewCard.position = objEnviar.position;
        objNewCard.id = idGuardo;
        objNewCard.idDashboardCard = data.value;
        objNewCard.title = data.label;
        if (objEnviar.align === STRING_ALIGN_LEFT) {
          let pivote = leftColumn;
          pivote.push(objNewCard);
          setLeftColumn([...pivote]);
        }
        if (objEnviar.align === STRING_ALIGN_RIGHT) {
          let pivote = rightColumn;
          pivote.push(objNewCard);
          setRightColumn([...pivote]);
        }
        hideModalNuevoDashboardCard();
      }
    } catch (err) {
      console.log("guardarNuevoDashboardCard err");
      console.log(err);
    }
  };

  const getDashboardCards = async () => {
    try {
      const listaDashboardCard = await obtenerDashboardCards();

      let currentDate = moment();
      const fechaInicio = new Date(
        currentDate.clone().startOf("week").add(1, "day").format("YYYY-MM-DD")
      );
      const fechaFin = new Date(
        currentDate.clone().endOf("week").add(1, "day").format("YYYY-MM-DD")
      );

      const lista = listaDashboardCard.map((obj) => ({
        ...obj,
        fechaInicio: fechaInicio,
        fechaFin: fechaFin,
        loading: true,
        noInfo: false,
      }));

      renderDashboardCards(lista);
    } catch (err) {
      console.log("getDashboardCards err");
      console.log(err);
    }
  };

  const renderDashboardCards = (lista) => {
    try {
      // console.log('renderDashboardCards');
      const listaCardLeft = lista.filter((e) => e.align === STRING_ALIGN_LEFT);
      const listaCardRight = lista.filter(
        (e) => e.align === STRING_ALIGN_RIGHT
      );
      setLeftColumn(listaCardLeft);
      setRightColumn(listaCardRight);
      getInfo(listaCardLeft);
      getInfo(listaCardRight);
    } catch (err) {
      console.log("renderDashboardCards err");
      console.log(err);
    }
  };

  const getInfo = (lista) => {
    try {
      // console.log('getInfo');
      // console.log(lista);
      lista.forEach((element) => {
        switch (element.idDashboardCard) {
          case CUMPLIMIENTO_DESCARGA:
            getInfoCumplimientoDescarga(element);
            break;
        }
      });
    } catch (err) {
      console.log("getInfo err");
      console.log(err);
    }
  };

  const getInfoCumplimientoDescarga = async (data) => {
    try {
      // console.log('getInfoCumplimientoDescarga');
      // console.log(data);
      let objEnviar = {};
      objEnviar.fechaInicio = moment(data.fechaInicio).format("YYYY/MM/DD");
      objEnviar.fechaFin = moment(data.fechaFin).format("YYYY/MM/DD");
      const descargas = await obtenerDescargaPorFechaProceso(objEnviar);
      // console.log("descargas");
      // console.log(descargas);
      data.loading = false;
      if (descargas.length === 0) {
        data.noInfo = true;
      }
      if (descargas.length > 0) {
        const groupedByPlanilla = groupBy(descargas, "planilla");
        const list = Object.values(groupedByPlanilla);
        // console.log("list");
        // console.log(list);
        let objEnviar = [];
        list.forEach((data) => {
          let obj = {};
          obj.unidad = data[0].placa;
          obj.codigoGeocercaDestino = data[0].codigoDestino;
          obj.fechaHoraInicio = data[0].fechaSalida;
          obj.fechaHoraFin = data[0].planDescarga;
          obj.tolerancia = 0;
          obj.idEmpresa = sharedGetIdEmpresa();
          objEnviar.push(obj);
        });
        // console.log('objEnviar');
        // console.log(objEnviar);
        const listaUbicaciones = await obtenerIngresoUnidadesAGeocerca(
          objEnviar
        );
        if (listaUbicaciones.length === 0) {
          data.noInfo = true;
        }
        // console.log("listaUbicaciones");
        // console.log(listaUbicaciones);
        if (listaUbicaciones.length > 0) {
          data.noInfo = false;
          const aTiempo = listaUbicaciones.filter(
            (e) => e.llegoATiempo === STRING_BOOL_TRUE
          );
          const noATiempo = listaUbicaciones.filter(
            (e) => e.llegoATiempo === STRING_BOOL_FALSE
          );
          const series = [aTiempo.length, noATiempo.length];
          const options = {
            chart: {
              width: 380,
              type: "pie",
            },
            labels: [
              "A Tiempo:" + aTiempo.length,
              "Fuera de tiempo:" + noATiempo.length,
            ],
            dataLabels: {
              enabled: true,
              formatter: function (val, opts) {
                const series = opts.w.config.series[opts.seriesIndex];
                const percent = (
                  (val / opts.w.globals.seriesTotals[opts.seriesIndex]) *
                  100
                ).toFixed(1);
                return `${series} (${percent}%)`;
              },
            },
            colors: ["#00e396", "#ff4560"],
          };
          data.series = series;
          data.options = options;
          /** buscar la placa en listaUbicaciones
           * y ver si llego a tiempo a su destino
           */
          for (let i = 0; i < descargas.length; i++) {
            const row = descargas[i];
            const info = listaUbicaciones.find(
              (e) =>
                e.placa === row.unidad &&
                e.codigoDestino === row.codigoGeocercaDestino &&
                e.fechaSalida === row.fechaHoraInicio &&
                e.planDescarga === row.fechaHoraFin
            );
            if (info !== undefined) {
              // console.log("info");
              // console.log(info);
              descargas[i].llegoATiempo = info.llegoATiempo;
            }
            if (info === undefined) {
              descargas[i].llegoATiempo = null;
            }
          }
        }
      }
      // console.log("descargas");
      // console.log(descargas);
      data.descargas = descargas;
      updateObject(data);
    } catch (err) {
      console.log("getInfoCumplimientoDescarga err");
      console.log(err);
    }
  };

  const groupBy = (list, key) => {
    return list.reduce((acc, obj) => {
      const keyValue = obj[key];
      if (!acc[keyValue]) {
        acc[keyValue] = [];
      }
      acc[keyValue].push(obj);
      return acc;
    }, {});
  };

  const getLeftOrRightAlign = () => {
    if (leftColumn.length === 0 && rightColumn.length === 0) {
      return STRING_ALIGN_LEFT;
    }
    if (leftColumn.length === rightColumn.length) {
      return STRING_ALIGN_LEFT;
    }
    if (leftColumn.length > rightColumn.length) {
      return STRING_ALIGN_RIGHT;
    }
    if (leftColumn.length < rightColumn.length) {
      return STRING_ALIGN_LEFT;
    }
    return STRING_ALIGN_RIGHT;
  };

  const getPosition = (align) => {
    if (align === STRING_ALIGN_LEFT) {
      return leftColumn.length;
    }
    if (align === STRING_ALIGN_RIGHT) {
      return rightColumn.length;
    }
  };

  const deleteDashboardCard = (data) => {
    try {
      console.log("deleteDashboardCard");
      console.log(data);
    } catch (err) {
      console.log("deleteDashboardCard err");
      console.log(err);
    }
  };

  const updateFecha = (date, data, attributo) => {
    data[attributo] = date;
    updateObject(data);
  };

  const goToReporte = (data) => {
    try {
      console.log("goToReporte");
      console.log(data);
      switch (CUMPLIMIENTO_DESCARGA) {
        case data.idDashboardCard:
          // history.push({
          //   pathname: "/reportes/cumplimiento-descarga",
          //   params: data,
          // });
          // history.push({
          //   pathname: "/reportes/cumplimiento-descarga",
          //   state: data,
          // });
          let params = {};
          params.fechaFin = data.fechaFin;
          params.fechaInicio = data.fechaInicio;
          params.descargas = data.descargas;
          history.push({
            pathname: "/reportes/cumplimiento-descarga",
            state: params,
          });

          break;
      }
    } catch (err) {
      console.log("goToReporte err");
      console.log(err);
    }
  };

  const cardCumplimientoDescarga = (data) => {
    try {
      // console.log('cardCumplimientoDescarga');
      // console.log(data);
      return (
        <div
          className="flex flex-col border border-gray-300 shadow-md m-4 p-4 rounded-lg"
          key={shortid.generate()}
        >
          {/* header */}
          <div className="flex justify-between items-center">
            <label className="font-bold">{data.title}</label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              title="Eliminar Card"
              onClick={() => {
                deleteDashboardCard(data);
              }}
            >
              <XSquare />
            </button>
          </div>
          {/* body */}
          <div className="flex flex-col">
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row ">
                <div className="w-full sm:w-auto">
                  <label htmlFor="fecha inicio" className="mb-2 font-bold">
                    FECHA INICIO
                  </label>
                  <DatePicker
                    defaultValue={data.fechaInicio}
                    value={data.fechaInicio}
                    valueFormat={{ dateStyle: "medium" }}
                    onChange={(date) => updateFecha(date, data, "fechaInicio")}
                  />
                </div>
                <div className="w-full sm:w-auto ml-5">
                  <label htmlFor="fecha fin" className="mb-2 font-bold">
                    FECHA FIN
                  </label>
                  <DatePicker
                    defaultValue={data.fechaFin}
                    value={data.fechaFin}
                    valueFormat={{ dateStyle: "medium" }}
                    onChange={(date) => updateFecha(date, data, "fechaFin")}
                  />
                </div>
              </div>

              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_ATRAS}
                onClick={() => {
                  actualizarCard(data);
                }}
              >
                BUSCAR
              </button>
            </div>
            {data.noInfo === false && (
              <div className="flex flex-col items-center">
                <ReactApexChart
                  options={data.options}
                  series={data.series}
                  type="pie"
                  width={380}
                />
              </div>
            )}
            {data.noInfo === false && (
              <div
                className="flex justify-end"
                onClick={() => {
                  goToReporte(data);
                }}
              >
                <label className="text-blue-500 hover:underline cursor-pointer">
                  Detalles
                </label>
              </div>
            )}
            {data.noInfo === true && <NoInfo />}
          </div>
        </div>
      );
    } catch (err) {
      console.log("cardCumplimientoDescarga render");
      console.log(err);
      return (
        <div className=" w-1/2 m-auto px-[2rem] ">
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  };

  const actualizarCard = (data) => {
    try {
      // console.log('actualizarCard');
      // console.log(data);
      data.loading = true;
      updateObject(data);
      switch (CUMPLIMIENTO_DESCARGA) {
        case data.idDashboardCard:
          getInfoCumplimientoDescarga(data);
          break;
      }
    } catch (err) {
      console.log("actualizarCard err");
      console.log(err);
    }
  };

  const updateObject = (data) => {
    if (data.align === STRING_ALIGN_LEFT) {
      setLeftColumn((leftColumn) =>
        leftColumn.map((objeto) =>
          objeto.id === data.id ? { ...objeto, ...data } : objeto
        )
      );
    }
    if (data.align === STRING_ALIGN_RIGHT) {
      setRightColumn((rightColumn) =>
        rightColumn.map((objeto) =>
          objeto.id === data.id ? { ...objeto, ...data } : objeto
        )
      );
    }
  };

  const renderColumn = (card) => {
    switch (card.idDashboardCard) {
      case CUMPLIMIENTO_DESCARGA:
        if (card.loading === true) {
          return loadingCard(card);
        }
        if (card.loading === false) {
          return cardCumplimientoDescarga(card);
        }
      default:
        return <NoInfo />;
    }
  };

  const render = () => {
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div style={{ marginTop: 10 }}>
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 30,
            }}
          >
            <div style={{ marginTop: 10 }}>
              <h3>{`Bienvenido ${data.nombre ? data.nombre : ""} ${data.apellido ? data.apellido : ""
                }`}</h3>
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="p-2">
              <h1 className="font-bold text-gray-700 text-[24px]">Dashboard</h1>
              <h3>{`Bienvenido ${data.nombre ? data.nombre : ""} ${data.apellido ? data.apellido : ""
                }`}</h3>
            </div>
            <div className="p-2">
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_NUEVO}
                onClick={handleNuevoClick}
              >
                NUEVO
              </button>
            </div>
          </div>
          <div className="lg:flex">
            <div className="lg:w-6/12 md:w-full sm:w-full">
              {leftColumn.map((card) => {
                return renderColumn(card);
              })}
            </div>
            <div className="lg:w-6/12 md:w-full sm:w-full">
              {rightColumn.map((card) => {
                return renderColumn(card);
              })}
            </div>
          </div>
          <ModalDashboardOptions
            show={showModalNuevoDashboardCard}
            hideModalNuevoDashboardCard={hideModalNuevoDashboardCard}
            guardarNuevoDashboardCard={guardarNuevoDashboardCard}
            options={listaDashboardCardOptions}
            optionSelected={dashboardCardSelected}
            setDashboardCardSelected={setDashboardCardSelected}
          />
        </div>
      );
    } catch (err) {
      console.log("dashboard render");
      console.log(err);
      return (
        <div className=" w-1/2 m-auto px-[2rem] ">
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  };

  return render();
}

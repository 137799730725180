import { useState, useEffect } from "react";
import Select from "react-select";
import readXlsxFile from "read-excel-file";
import {
  guardarConfiguracion,
  obtenerConfiguracion,
  modificarConfiguracion,
  obtenerConfiguracionDescargaCarga,
  guardarConfiguracionDescargaCarga,
  modificarConfiguracionDescargaCarga,
} from "../../api/descargas";

export default function Configuracion() {
  const [loading, setLoading] = useState(false);
  const [excelValue, setExcelValue] = useState(null);
  const [idConfiguracion, setIdConfiguracion] = useState(null);
  const [enlazarDescargaCarga, setEnlazarDescargaCarga] = useState(false);
  const [listColumnsExcel, setListColumnsExcel] = useState([]);
  const [data, setData] = useState({
    indexCodigoOrigen: null,
    indexDepositoOrigen: null,
    indexCodigoDestino: null,
    indexDepositoDestino: null,
    indexPlanilla: null,
    indexNombreFletero: null,
    indexCodigoUnidad: null,
    indexPlaca: null,
    indexFechaSalida: null,
    indexFechaEntrada: null,
    indexEstado: null,
    indexCodigoProducto: null,
    indexProductoNombre: null,
    indexBultos: null,
    indexTipoProducto: null,
    indexTipoViaje: null,
    indexReferencia: null,
    indexETA: null,
    indexObservacionETA: null,
    indexPlanDescarga: null,
    indexObservacionPlanDescarga: null,
    indexNroViajeCarga: null,

    valorCodigoOrigen: null,
    valorDepositoOrigen: null,
    valorCodigoDestino: null,
    valorDepositoDestino: null,
    valorPlanilla: null,
    valorNombreFletero: null,
    valorCodigoUnidad: null,
    valorPlaca: null,
    valorFechaSalida: null,
    valorFechaEntrada: null,
    valorEstado: null,
    valorCodigoProducto: null,
    valorProductoNombre: null,
    valorBultos: null,
    valorTipoProducto: null,
    valorTipoViaje: null,
    valorReferencia: null,
    valorETA: null,
    valorObservacionETA: null,
    valorPlanDescarga: null,
    valorObservacionPlanDescarga: null,
    valorNroViajeCarga: null,
  });

  const importar = () => {
    try {
      document.getElementById("fileUpload").click();
    } catch (err) {
      console.log("importarExcel err");
      console.log(err);
    }
  };

  const guardar = async () => {
    let resp;
    const respConfCargaDescarga = await modificarConfiguracionDescargaCarga({
      enlazarCargaDescarga: enlazarDescargaCarga,
    });
    if (idConfiguracion === null) {
      resp = await guardarConfiguracion(data);
    } else {
      resp = await modificarConfiguracion({ ...data, id: idConfiguracion });
    }
    if (resp.code === "SUCCESS") {
      alert("Se guardo correctamente la configuracion");
    } else {
      alert("Sucedio un problema porfavor vuelva a intentar");
    }
  };
  const obtener = async () => {
    try {
      const resp = await obtenerConfiguracion();
      const respConfCargaDescarga = await obtenerConfiguracionDescargaCarga();
      console.log(respConfCargaDescarga);
      if (respConfCargaDescarga.body.length > 0) {
        console.log("entra a crear 1");
        setEnlazarDescargaCarga(
          respConfCargaDescarga.body[0].enlazarCargaDescarga
        );
      } else {
        const respCrearConf = await guardarConfiguracionDescargaCarga({
          enlazarCargaDescarga: enlazarDescargaCarga,
        });
        console.log(respCrearConf);
        console.log("entra a crear");
      }
      if (resp.code === "SUCCESS") {
        setIdConfiguracion(resp.body[0].id);
        const listColumnsExcelFormat = [
          JSON.parse(resp.body[0].valorCodigoOrigen),
          JSON.parse(resp.body[0].valorDepositoOrigen),
          JSON.parse(resp.body[0].valorCodigoDestino),
          JSON.parse(resp.body[0].valorDepositoDestino),
          JSON.parse(resp.body[0].valorPlanilla),
          JSON.parse(resp.body[0].valorNombreFletero),
          JSON.parse(resp.body[0].valorCodigoUnidad),
          JSON.parse(resp.body[0].valorPlaca),
          JSON.parse(resp.body[0].valorFechaSalida),
          JSON.parse(resp.body[0].valorFechaEntrada),
          JSON.parse(resp.body[0].valorEstado),
          JSON.parse(resp.body[0].valorCodigoProducto),
          JSON.parse(resp.body[0].valorProductoNombre),
          JSON.parse(resp.body[0].valorBultos),
          JSON.parse(resp.body[0].valorTipoProducto),
          JSON.parse(resp.body[0].valorTipoViaje),
          JSON.parse(resp.body[0].valorReferencia),
          JSON.parse(resp.body[0].valorETA),
          JSON.parse(resp.body[0].valorObservacionETA),
          JSON.parse(resp.body[0].valorPlanDescarga),
          JSON.parse(resp.body[0].valorObservacionPlanDescarga),
          JSON.parse(resp.body[0].valorNroViajeCarga),
        ];
        setListColumnsExcel(listColumnsExcelFormat);
        const formatData = {
          ...resp.body[0],
          valorCodigoOrigen: JSON.parse(resp.body[0].valorCodigoOrigen),
          valorDepositoOrigen: JSON.parse(resp.body[0].valorDepositoOrigen),
          valorCodigoDestino: JSON.parse(resp.body[0].valorCodigoDestino),
          valorDepositoDestino: JSON.parse(resp.body[0].valorDepositoDestino),
          valorPlanilla: JSON.parse(resp.body[0].valorPlanilla),
          valorNombreFletero: JSON.parse(resp.body[0].valorNombreFletero),
          valorCodigoUnidad: JSON.parse(resp.body[0].valorCodigoUnidad),
          valorPlaca: JSON.parse(resp.body[0].valorPlaca),
          valorFechaSalida: JSON.parse(resp.body[0].valorFechaSalida),
          valorFechaEntrada: JSON.parse(resp.body[0].valorFechaEntrada),
          valorEstado: JSON.parse(resp.body[0].valorEstado),
          valorCodigoProducto: JSON.parse(resp.body[0].valorCodigoProducto),
          valorProductoNombre: JSON.parse(resp.body[0].valorProductoNombre),
          valorBultos: JSON.parse(resp.body[0].valorBultos),
          valorTipoProducto: JSON.parse(resp.body[0].valorTipoProducto),
          valorTipoViaje: JSON.parse(resp.body[0].valorTipoViaje),
          valorReferencia: JSON.parse(resp.body[0].valorReferencia),
          valorETA: JSON.parse(resp.body[0].valorETA),
          valorObservacionETA: JSON.parse(resp.body[0].valorObservacionETA),
          valorPlanDescarga: JSON.parse(resp.body[0].valorPlanDescarga),
          valorObservacionPlanDescarga: JSON.parse(
            resp.body[0].valorObservacionPlanDescarga
          ),
          valorNroViajeCarga: JSON.parse(resp.body[0].valorNroViajeCarga),

          valorCodigoOrigenJson: resp.body[0].valorCodigoOrigen,
          valorDepositoOrigenJson: resp.body[0].valorDepositoOrigen,
          valorCodigoDestinoJson: resp.body[0].valorCodigoDestino,
          valorDepositoDestinoJson: resp.body[0].valorDepositoDestino,
          valorPlanillaJson: resp.body[0].valorPlanilla,
          valorNombreFleteroJson: resp.body[0].valorNombreFletero,
          valorCodigoUnidadJson: resp.body[0].valorCodigoUnidad,
          valorPlacaJson: resp.body[0].valorPlaca,
          valorFechaSalidaJson: resp.body[0].valorFechaSalida,
          valorFechaEntradaJson: resp.body[0].valorFechaEntrada,
          valorEstadoJson: resp.body[0].valorEstado,
          valorCodigoProductoJson: resp.body[0].valorCodigoProducto,
          valorProductoNombreJson: resp.body[0].valorProductoNombre,
          valorBultosJson: resp.body[0].valorBultos,
          valorTipoProductoJson: resp.body[0].valorTipoProducto,
          valorTipoViajeJson: resp.body[0].valorTipoViaje,
          valorReferenciaJson: resp.body[0].valorReferencia,
          valorETAJson: resp.body[0].valorETA,
          valorObservacionETAJson: resp.body[0].valorObservacionETA,
          valorPlanDescargaJson: resp.body[0].valorPlanDescarga,
          valorObservacionPlanDescargaJson:
            resp.body[0].valorObservacionPlanDescarga,
          valorNroViajeCargaJson: resp.body[0].valorNroViajeCarga,
        };
        setData(formatData);
      } else {
      }
    } catch (e) {
      console.error(e.message);
    }
  };
  const reset = () => {
    try {
      setListColumnsExcel([]);
    } catch (err) {
      console.log("reset err");
      console.log(err);
    }
  };

  const procesarExcel = (rows) => {
    try {
      if (rows.length == 0) {
        return;
      }
      let comboExcel = [];
      let headers = rows[0];

      for (let i = 0; i < headers.length; i++) {
        const element = headers[i];
        let objColumna = {};
        objColumna.value = i;
        objColumna.label = i + " - " + element;
        comboExcel.push(objColumna);
      }
      comboExcel.sort((a, b) => a.value - b.value);
      comboExcel.push({ value: null, label: "Sin Asignar" });
      setListColumnsExcel(comboExcel);
    } catch (err) {
      console.log("procesarExcel err");
      console.log(err);
    }
  };
  useEffect(() => {
    try {
      const fileUpload = document.getElementById("fileUpload");
      fileUpload.addEventListener("change", () => {
        readXlsxFile(fileUpload.files[0]).then((rows) => {
          reset();
          procesarExcel(rows);
        });
      });
      obtener();
    } catch (e) {
      console.error(e.message);
    }
  }, []);
  return (
    <div className="p-[2rem]">
      <div className="flex">
        <div className="w-2/3">
          <h1 className="font-bold text-gray-700 text-[24px]">Configuracion</h1>
          <h3>Importar datos desde archivo Excel</h3>
          <label className="flex mt-[10px]">
            <input
              type="checkbox"
              checked={enlazarDescargaCarga}
              className="mr-[10px] my-auto"
              onClick={() => {
                setEnlazarDescargaCarga(!enlazarDescargaCarga);
                setData({
                  ...data,
                  indexNroViajeCarga: null,
                  valorNroViajeCarga: { label: "Sin Asignar", value: null },
                });
              }}
            />
            Enlazar el modulo de Descargas con el de Cargas
          </label>
        </div>
        <div className="flex w-1/3 justify-end">
          <input
            id="fileUpload"
            type="file"
            className="my-auto text-white p-[5px] px-[10px] mr-[10px] hidden"
          />
          <button
            className="my-auto bg-[#84B044] rounded text-white p-[5px] px-[10px] mr-[10px]"
            onClick={() => {
              importar();
            }}
          >
            IMPORTAR
          </button>
          <button
            className="my-auto bg-[#113A5F] rounded text-white p-[5px] px-[10px]"
            onClick={() => {
              guardar();
            }}
          >
            GUARDAR
          </button>
        </div>
      </div>
      <div className="flex justify-around mt-[15px]">
        <div className="border border-gray rounded w-1/2 mr-[10px] p-[10px]">
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">0 - Codigo Origen</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">1 - Deposito Origen</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">2 - Codigo Destino</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">3 - Deposito Destino</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">4 - Planilla</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">5 - Nombre Fletero</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">6 - Codigo Unidad</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">7 - Placa</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">8 - Fecha Salida</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">9 - Fecha Entrada</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">10 - Estado</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">11 - Codigo Producto</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">12 - Nombre del Producto</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">13 - Bultos</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">14 - Tipo Producto</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">15 - Tipo Viaje</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">16 - Referencia</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">17 - ETA</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">18 - Observacion ETA</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">19 - Plan de Descarga</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">20 - Observacion Plan de Descarga</p>
          </div>
          {enlazarDescargaCarga ? (
            <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
              <p className="my-auto">21 - Codigo de Viaje de Carga</p>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="border border-gray rounded w-1/2 ml-[10px] p-[10px]">
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorCodigoOrigen ? data.valorCodigoOrigen : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexCodigoOrigen: e.value,
                    valorCodigoOrigen: { value: e.value, label: e.label },
                    valorCodigoOrigenJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={
                  data.valorDepositoOrigen ? data.valorDepositoOrigen : null
                }
                onChange={(e) => {
                  setData({
                    ...data,
                    indexDepositoOrigen: e.value,
                    valorDepositoOrigen: { value: e.value, label: e.label },
                    valorDepositoOrigenJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>

          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorCodigoDestino ? data.valorCodigoDestino : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexCodigoDestino: e.value,
                    valorCodigoDestino: { value: e.value, label: e.label },
                    valorCodigoDestinoJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={
                  data.valorDepositoDestino ? data.valorDepositoDestino : null
                }
                onChange={(e) => {
                  setData({
                    ...data,
                    indexDepositoDestino: e.value,
                    valorDepositoDestino: {
                      value: e.value,
                      label: e.label,
                    },
                    valorDepositoDestinoJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorPlanilla ? data.valorPlanilla : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexPlanilla: e.value,
                    valorPlanilla: { value: e.value, label: e.label },
                    valorPlanillaJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorNombreFletero ? data.valorNombreFletero : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexNombreFletero: e.value,
                    valorNombreFletero: { value: e.value, label: e.label },
                    valorNombreFleteroJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorCodigoUnidad ? data.valorCodigoUnidad : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexCodigoUnidad: e.value,
                    valorCodigoUnidad: { value: e.value, label: e.label },
                    valorCodigoUnidadJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorPlaca ? data.valorPlaca : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexPlaca: e.value,
                    valorPlaca: { value: e.value, label: e.label },
                    valorPlacaJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorFechaSalida ? data.valorFechaSalida : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexFechaSalida: e.value,
                    valorFechaSalida: { value: e.value, label: e.label },
                    valorFechaSalidaJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorFechaEntrada ? data.valorFechaEntrada : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexFechaEntrada: e.value,
                    valorFechaEntrada: { value: e.value, label: e.label },
                    valorFechaEntradaJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorEstado ? data.valorEstado : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexEstado: e.value,
                    valorEstado: { value: e.value, label: e.label },
                    valorEstadoJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={
                  data.valorCodigoProducto ? data.valorCodigoProducto : null
                }
                onChange={(e) => {
                  setData({
                    ...data,
                    indexCodigoProducto: e.value,
                    valorCodigoProducto: { value: e.value, label: e.label },
                    valorCodigoProductoJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={
                  data.valorProductoNombre ? data.valorProductoNombre : null
                }
                onChange={(e) => {
                  setData({
                    ...data,
                    indexProductoNombre: e.value,
                    valorProductoNombre: { value: e.value, label: e.label },
                    valorProductoNombreJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorBultos ? data.valorBultos : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexBultos: e.value,
                    valorBultos: { value: e.value, label: e.label },
                    valorBultosJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorTipoProducto ? data.valorTipoProducto : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexTipoProducto: e.value,
                    valorTipoProducto: { value: e.value, label: e.label },
                    valorTipoProductoJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorTipoViaje ? data.valorTipoViaje : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexTipoViaje: e.value,
                    valorTipoViaje: { value: e.value, label: e.label },
                    valorTipoViajeJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorReferencia ? data.valorReferencia : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexReferencia: e.value,
                    valorReferencia: { value: e.value, label: e.label },
                    valorReferenciaJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorETA ? data.valorETA : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexETA: e.value,
                    valorETA: { value: e.value, label: e.label },
                    valorETAJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={
                  data.valorObservacionETA ? data.valorObservacionETA : null
                }
                onChange={(e) => {
                  setData({
                    ...data,
                    indexObservacionETA: e.value,
                    valorObservacionETA: { value: e.value, label: e.label },
                    valorObservacionETAJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorPlanDescarga ? data.valorPlanDescarga : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexPlanDescarga: e.value,
                    valorPlanDescarga: { value: e.value, label: e.label },
                    valorPlanDescargaJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={
                  data.valorObservacionPlanDescarga
                    ? data.valorObservacionPlanDescarga
                    : null
                }
                onChange={(e) => {
                  setData({
                    ...data,
                    indexObservacionPlanDescarga: e.value,
                    valorObservacionPlanDescarga: {
                      value: e.value,
                      label: e.label,
                    },
                    valorObservacionPlanDescargaJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          {enlazarDescargaCarga ? (
            <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
              <div className="my-auto w-1/2">
                <Select
                  placeholder="Seleccione columna..."
                  options={listColumnsExcel}
                  value={
                    data.valorNroViajeCarga ? data.valorNroViajeCarga : null
                  }
                  onChange={(e) => {
                    setData({
                      ...data,
                      indexNroViajeCarga: e.value,
                      valorNroViajeCarga: {
                        value: e.value,
                        label: e.label,
                      },
                      valorNroViajeCargaJson: JSON.stringify({
                        value: e.value,
                        label: e.label,
                      }),
                    });
                  }}
                />
              </div>
              <div>.</div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
}

import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ESTILO_BOTON_GENERAL_CANCELAR, ESTILO_BOTON_LISTAR_ATRAS } from "../../utils/const-estilos";
import "../../style/style-cargando.css";

export default function Bitacora() {
  const location = useLocation();
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(false);
  const [incidente, setIncidente] = useState(null);
  const [titulo, setTitulo] = useState("INCIDENTE");

  useEffect(() => {
    // console.log("entro a Disponibilidad");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      const data = location.state;
      // console.log("data");
      // console.log(data);
      if (data !== undefined && data !== null) {
        setIncidente(data.params);
        setTitulo(data.titulo);
      }
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const handleCancelClick = () => {
    history.goBack();
  };

  const handleAtrasClick = () => {
    history.goBack();
  }

  const render = () => {
    // console.log('render correo');
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (cargandoPage === false && incidente === null) {
        return (
          <div>
            <label> Sin data</label>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <label
              style={{
                paddingLeft: 4,
                fontWeight: 'bold',
              }}
            >
              {titulo}
            </label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <br />
          <hr />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="nombrepuntocontrol" className="mb-2 font-bold">
                  PUNTO CONTROL
                </label>
                <input
                  type="text"
                  id="nombrepuntocontrol"
                  name="nombrepuntocontrol"
                  value={incidente.nombrepuntocontrol}
                  placeholder="PUNTO CONTROL"
                  className="p-2 border border-gray-300 rounded"
                  readOnly
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="otropuntocontrol" className="mb-2 font-bold">
                  OTRO PUNTO CONTROL
                </label>
                <input
                  type="text"
                  id="otropuntocontrol"
                  name="otropuntocontrol"
                  value={incidente.otropuntocontrol}
                  placeholder="OTRO PUNTO CONTROL"
                  className="p-2 border border-gray-300 rounded"
                  readOnly
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="categoria" className="mb-2 font-bold">
                  CATEGORIA
                </label>
                <input
                  type="text"
                  id="categoria"
                  name="categoria"
                  value={incidente.categoria}
                  placeholder="CATEGORIA"
                  className="p-2 border border-gray-300 rounded"
                  readOnly
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="otrocategoria" className="mb-2 font-bold">
                  OTRO CATEGORIA
                </label>
                <input
                  type="text"
                  id="otrocategoria"
                  name="otrocategoria"
                  value={incidente.otrocategoria}
                  placeholder="CATEGORIA"
                  className="p-2 border border-gray-300 rounded"
                  readOnly
                />
              </div>
            </div>

          </div>
          <br />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-2/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="descripcion" className="mb-2 font-bold">
                  DESCRIPCION
                </label>
                <textarea

                  type="text"
                  id="descripcion"
                  name="descripcion"
                  value={incidente.descripcion}
                  placeholder="DESCRIPCION"
                  className="p-2 border border-gray-300 rounded"
                  readOnly
                />
              </div>
            </div>

          </div>
          <br />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-4/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="descripcion" className="mb-2 font-bold">
                  IMAGEN
                </label>
                <img src={incidente.url} alt="Descripción de la imagen" />
              </div>
            </div>

          </div>
          <br />
          <div className="flex justify-between p-4">
            <button className={ESTILO_BOTON_GENERAL_CANCELAR}
              onClick={handleCancelClick}
            >
              CANCELAR
            </button>
          </div>
        </div>
      );
    } catch (err) {
      console.log('Bitacora render err');
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  }

  return render();
}

export const INT_FONT_SIZE = 10;

export const INT_ID_SUBTIPO_CHECKLIST_SALIDA = 0;
export const INT_ID_SUBTIPO_CHECKLIST_SALIDA_PRECINTO_CBN = 15;

export const INT_PREGUNTAS_EXTRA_CHECKLIST = 1;
export const INT_FOTOGRAFIA = 2;
export const INT_COMENTARIO = 3;

/** TABLA CONCEPTO */
export const INT_GRUPO_TIPO_USUARIO_MOVIL = 3;
export const INT_TIPO_USUARIO_SUPERVISOR_MOVIL = 8;
export const INT_TIPO_USUARIO_CHOFER_MOVIL = 7;

export const INT_TIPO_USUARIO_ADMINISTRADOR_WEB = 10;
export const INT_TIPO_SUB_USUARIO_WEB = 11;

/**MODULOS */
/**PRIMER NIVEL */
export const INT_ID_MODULO_DASHBOARD = 4;
export const INT_ID_MODULO_CHECKLIST = 1;
export const INT_ID_MODULO_SEGUIMIENTO = 6;
export const INT_ID_MODULO_EVENTOS = 7;
export const INT_ID_MODULO_DESCARGAS = 5;
export const INT_ID_MODULO_CARGAS = 8;
export const INT_ID_MODULO_USUARIOS = 2;
export const INT_ID_MODULO_CONFIGURACION = 3;
export const INT_ID_MODULO_REPORTES = 9;
export const INT_ID_MODULO_GEORUTA = 10;

/**SEGUNDO NIVEL */
export const INT_ID_SEGUNDO_NIVEL_MIS_CHECKLIST = 1;
export const INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST = 2;
export const INT_ID_SEGUNDO_NIVEL_RESPUESTA_INGRESO_CHECKLIST = 5;
export const INT_ID_SEGUNDO_NIVEL_RESPUESTA_SALIDA_CHECKLIST = 6;
export const INT_ID_SEGUNDO_NIVEL_REPORTE_CHECKLIST = 7;

export const INT_ID_SEGUNDO_NIVEL_USUARIOS = 3;
export const INT_ID_SEGUNDO_NIVEL_PERMISOS = 4;

export const INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL = 17;
export const INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD = 18;
export const INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA = 19;

export const INT_ID_SEGUNDO_NIVEL_DESCARGAS_MIS_DESCARGAS = 11;
export const INT_ID_SEGUNDO_NIVEL_DESCARGAS_CONFIGURACION = 12;
export const INT_ID_SEGUNDO_NIVEL_DESCARGAS_CONFIGURACION_HORARIOS = 13;

export const INT_ID_SEGUNDO_NIVEL_EVENTOS_DISPONIBILIDAD = 8;
export const INT_ID_SEGUNDO_NIVEL_EVENTOS_INCIDENTE = 9;
export const INT_ID_SEGUNDO_NIVEL_EVENTOS_BITACORA = 10;

export const INT_ID_SEGUNDO_NIVEL_CARGAS_MIS_CARGAS = 14;
export const INT_ID_SEGUNDO_NIVEL_CARGAS_CONFIGURACION = 15;
export const INT_ID_SEGUNDO_NIVEL_CARGAS_CONFIGURACION_HORARIOS = 16;

/**TERCER NIVEL */
export const INT_ID_TERCER_NIVEL_CHECKLIST_TIPO_CARROCERIA = 1;
export const INT_ID_TERCER_NIVEL_CHECKLIST_CORREOS = 2;
export const INT_ID_TERCER_NIVEL_CHECKLIST_OPCIONES_RESPUESTA = 3;
export const INT_ID_TERCER_NIVEL_CHECKLIST_MOVIMIENTO = 4;
export const INT_ID_TERCER_NIVEL_CHECKLIST_TIPO_CHECKLIST = 5;
export const INT_ID_TERCER_NIVEL_CHECKLIST_GESTIONAR_ICONOS = 8;
export const INT_ID_TERCER_NIVEL_CHECKLIST_CATEGORIA = 9;
export const INT_ID_TERCER_NIVEL_CHECKLIST_GEOCERCA = 10;
export const INT_ID_TERCER_NIVEL_CHECKLIST_ASIGNAR_CORREO = 11;

export const INT_ID_TERCER_NIVEL_USUARIO_WEB = 6;
export const INT_ID_TERCER_NIVEL_USUARIO_MOVIL = 7;

export const INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_ASIGNAR_PLANTA_A_USUARIO = 12;
export const INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_CARGA = 13;
export const INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_DESCARGA = 14;

export const INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GRUPO_CIUDADES = 15;
export const INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GEOCERCA_DE_UNA_CIUDAD = 16;
export const INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_CARROCERIA = 17;
export const INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_SUPERVISOR = 18;
export const INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_PLANIFICACION = 19;
export const INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_DISPONIBILIDAD_CONDUCTOR = 20;

export const INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_SELECCIONAR_GRUPO_GEORUTA = 21;
export const INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_SELECCIONAR_PUNTOS_CONTROL = 22;
export const INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_TIPO_DETENCION_GEORUTA = 23;


/**ID DE LA TABLA CONCEPTO */
export const INT_ID_CONCEPTO_FOTOGRAFIA = 2;
export const INT_ID_CONCEPTO_COMENTARIO = 3;
export const INT_ID_CONCEPTO_FIRMA_RESPONSALBLE = 12;
export const INT_ID_CONCEPTO_FIRMA_CHOFER = 13;
export const INT_ID_CONCEPTO_RESPUESTA_NOK = 16;

/** ID DE LAS ACCIONES SALEN DE ACCIONESPERMISOS */
export const INT_ID_ACCIONES_NUEVO = 1;
export const INT_ID_ACCIONES_MODIFICAR = 2;
export const INT_ID_ACCIONES_LISTAR = 3;
export const INT_ID_ACCIONES_VER = 4;
export const INT_ID_ACCIONES_DESHABILITAR = 5;
export const INT_ID_ACCIONES_EXPORTAR = 6;
export const INT_ID_ACCIONES_IMPORTAR = 7;
export const INT_ID_ACCIONES_LISTAR_SUB_TIPO_CARROCERIA = 8;
export const INT_ID_ACCIONES_CREAR_SUB_TIPO_CARROCERIA = 9;
export const INT_ID_ACCIONES_MODIFICAR_SUB_TIPO_CARROCERIA = 10;
export const INT_ID_ACCIONES_DESHABILITAR_SUB_TIPO_CARROCERIA = 11;
export const INT_ID_ACCIONES_ITINERARIO = 12;
export const INT_ID_ACCIONES_ELIMINAR = 13;
export const INT_ID_ACCIONES_ENVIAR_SMS = 14;
export const INT_ID_ACCIONES_ENVIAR_SMS_MASIVO = 15;
export const INT_ID_ACCIONES_CARGAR = 16;
export const INT_ID_ACCIONES_GUARDAR = 17;

export const INT_TIPO_QUERY_INSERT = 0;
export const INT_TIPO_QUERY_UPDATE = 1;


export const INT_SUB_TIPO_CHECKLIST_PRECINTO_CBN = 21;
export const INT_SUB_TIPO_CHECKLIST_PRECINTO_PLASTICO = 22;

export const INT_SUB_TIPO_USUARIO_AUXILIAR_CBN = 7;
export const INT_SUB_TIPO_USUARIO_AUXILIAR_PLASTICO = 1;
import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import LayoutConfiguracion from "../../components/layout-configuracion";
import SeleccionarGrupoGeoRuta from "./seleccionar-grupo-georuta";
import SeleccionarGrupoPuntosControl from "./seleccionar-puntos-control";
import ConfiguracionesMain from ".";
import GestionarTipoDetencionDestino from "./gestionar-tipo-detencion-destino";

const RouterConfiguracionGeoruta = () => {
  const { path } = useRouteMatch();
  return (
    <LayoutConfiguracion>
      <Switch>

        <Route exact path={`${path}/seleccionar-grupo-georuta`}>
          <SeleccionarGrupoGeoRuta />
        </Route>

        <Route exact path={`${path}/seleccionar-grupo-puntos-control`}>
          <SeleccionarGrupoPuntosControl />
        </Route>

        <Route exact path={`${path}/gestionar-tipo-detencion-destino`}>
          <GestionarTipoDetencionDestino />
        </Route>

        <Route exact path={`${path}`}>
          <ConfiguracionesMain />
        </Route>

      </Switch>
    </LayoutConfiguracion>
  );
};

export default RouterConfiguracionGeoruta;

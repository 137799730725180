import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ESTILO_BOTON_LISTAR_ATRAS } from "../../utils/const-estilos";
import "./style.css";
import shortid from "shortid";
import { STRING_CHOICE } from "../../utils/const-string";
import { obtenerInfoExtraChecklistIngreso } from "../../api/checklist";
import { INT_ID_CONCEPTO_COMENTARIO, INT_ID_CONCEPTO_FOTOGRAFIA, INT_ID_CONCEPTO_RESPUESTA_NOK } from "../../utils/const-int";

export default function VerChecklistIngreso() {
  const location = useLocation();
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(false);
  const [checklist, setChecklist] = useState(null);
  const [titulo, setTitulo] = useState("VER CHECKLIST INGRESO");
  const [listaExtra, setListaExtra] = useState([]);

  useEffect(() => {
    console.log("entro a VerChecklistIngreso");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      const data = location.state;
      // console.log("data");
      // console.log(data);
      if (data !== undefined && data !== null) {
        setChecklist(data.params);
        setTitulo(data.titulo);
      }
      let infoExtra = await obtenerInfoExtraChecklistIngreso({ idChecklist: data.params.id });
      // console.log('infoExtra');
      // console.log(infoExtra)
      setListaExtra(infoExtra);
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const handleInputChange = (event) => {
    // console.log('handleInputChange');
    const { name, value } = event.target;
    // console.log(name);
    // console.log(value);
    setChecklist({ ...checklist, [name]: value });
  };

  const handleAtrasClick = () => {
    history.goBack();
  }

  const renderCheklist = () => {
    try {
      let respuestas = JSON.parse(checklist.respuestas);
      return respuestas.map((data) => {
        switch (data.tipo) {
          case STRING_CHOICE:
            return renderChoice(data);

          default:
        }
      });
    } catch (err) {
      console.log('renderCheklist err');
      console.log(err);
      return (
        <div className="flex flex-col md:flex-column px-4 ">
          <label htmlFor="checklist" className="mb-2 font-bold">
            NO SE PUDO LEER CORRECTAMENTE EL CHECKLIST
          </label>
          <label htmlFor="checklist" className="mb-2 font-bold">
            {JSON.stringify(err)}
          </label>
        </div>
      );
    }
  }

  const renderChoice = (data) => {
    try {
      return (

        <div className="flex flex-col md:flex-row justify-between border-bottom-grey"
          key={shortid.generate()}
        >
          <div>
            <label htmlFor="checklist" className="mb-2 font-bold">
              {data.pregunta}
            </label>
          </div>
          <div className="flex flex-col md:flex-row  " >
            {data.opcionesrespuestas.map((element) => {
              return (
                <div className=" py-2 flex items-right justify-right mx-1 " >
                  <input
                    type="checkbox"
                    checked={element.selected}
                    readOnly
                  />
                  {element.label}
                </div>
              );
            })}
          </div>
        </div >
      );
    } catch (err) {
      console.log('renderChoice err');
      console.log(err);
      return (
        <div className="flex flex-col md:flex-column px-4 ">
          <label htmlFor="checklist" className="mb-2 font-bold">
            ERROR
          </label>
        </div>
      );
    }
  }

  const renderInfoExtra = () => {
    try {
      return listaExtra.map((data) => {
        switch (data.idConcepto) {
          case INT_ID_CONCEPTO_FOTOGRAFIA:
            return renderFotografia(data);
          case INT_ID_CONCEPTO_COMENTARIO:
            return renderComentario(data);
          case INT_ID_CONCEPTO_RESPUESTA_NOK:
            return renderFotografiaRespuestaNOK(data);
          default:
        }
      });
    } catch (err) {
      console.log('renderInfoExtra err');
      console.log(err);
      return (
        <div className="flex flex-col md:flex-column px-4 ">
          <label htmlFor="checklist" className="mb-2 font-bold">
            ERROR
          </label>
        </div>
      );
    }
  }

  const renderFotografia = (data) => {
    try {
      return (

        <div className="flex flex-col md:flex-row justify-between border-bottom-grey"
          key={shortid.generate()}
        >
          <div className="flex flex-col md:flex-column  " >
            <label className="font-bold">FOTOGRAFIA:</label>
            <img src={data.urlArchivo} alt="Descripción de la imagen" />

          </div>
        </div >
      );
    } catch (err) {
      console.log('renderFotografia err');
      console.log(err);
      return (
        <div className="flex flex-col md:flex-column px-4 ">
          <label htmlFor="checklist" className="mb-2 font-bold">
            ERROR
          </label>
        </div>
      );
    }
  }

  const renderComentario = (data) => {
    try {
      return (

        <div className="flex flex-col md:flex-row justify-between border-bottom-grey"
          key={shortid.generate()}
        >
          <div className="flex flex-col md:flex-column  " >
            <label className="font-bold">COMENTARIO:</label>
            {data.descripcion}
          </div>
        </div >
      );
    } catch (err) {
      console.log('renderComentario err');
      console.log(err);
      return (
        <div className="flex flex-col md:flex-column px-4 ">
          <label htmlFor="checklist" className="mb-2 font-bold">
            ERROR
          </label>
        </div>
      );
    }
  }

  const renderFotografiaRespuestaNOK = (data) => {
    try {
      return (
        <div className="flex flex-col md:flex-row justify-between border-bottom-grey"
          key={shortid.generate()}
        >
          <div className="flex flex-col md:flex-column  " >
            <label className="font-bold">FOTOGRAFIA: {data.descripcion}</label>
            <img src={data.urlArchivo} alt="Descripción de la imagen" />

          </div>
        </div >
      );
    } catch (err) {
      console.log('renderFotografiaRespuestaNOK err');
      console.log(err);
      return (
        <div className="flex flex-col md:flex-column px-4 ">
          <label htmlFor="checklist" className="mb-2 font-bold">
            ERROR
          </label>
        </div>
      );
    }
  }

  const render = () => {
    // console.log('render correo');
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (cargandoPage === false && checklist === null) {
        return (
          <div>
            <label> Sin data</label>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <label
              style={{
                paddingLeft: 4,
                fontWeight: 'bold',
              }}
            >
              {titulo}
            </label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <br />
          <hr />
          <br />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="fecha" className="mb-2 font-bold">
                  FECHA
                </label>
                <input
                  type="text"
                  id="fecha"
                  name="fecha"
                  value={checklist.fecha}
                  onChange={handleInputChange}
                  placeholder="FECHA"
                  className="p-2 border border-gray-300 rounded"
                  readOnly
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="nombreplanta" className="mb-2 font-bold">
                  PLANTA/CD
                </label>
                <input
                  type="text"
                  id="nombreplanta"
                  name="nombreplanta"
                  value={checklist.nombreplanta}
                  onChange={handleInputChange}
                  placeholder="PLANTA"
                  className="p-2 border border-gray-300 rounded"
                  // maxLength={100}
                  readOnly
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="otroingreso" className="mb-2 font-bold">
                  OTRO
                </label>
                <input
                  type="text"
                  id="otrocomplemento"
                  name="otrocomplemento"
                  value={checklist.otrocomplemento}
                  onChange={handleInputChange}
                  placeholder="OTRO"
                  className="p-2 border border-gray-300 rounded"
                  // maxLength={45}
                  readOnly
                />
              </div>
            </div>


          </div>
          <br />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="unidad" className="mb-2 font-bold">
                  UNIDAD
                </label>
                <input
                  type="text"
                  id="unidad"
                  name="unidad"
                  value={checklist.unidad}
                  onChange={handleInputChange}
                  placeholder="UNIDAD"
                  className="p-2 border border-gray-300 rounded"
                  // maxLength={45}
                  readOnly
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="transportadora" className="mb-2 font-bold">
                  TRANSPORTADORA
                </label>
                <input
                  type="text"
                  id="transportadora"
                  name="transportadora"
                  value={checklist.transportadora}
                  onChange={handleInputChange}
                  placeholder="TRANSPORTADORA"
                  className="p-2 border border-gray-300 rounded"
                  // maxLength={45}
                  readOnly
                />
              </div>
            </div>

          </div>
          <br />
          <hr />
          <br />
          <div className="flex flex-col md:flex-column px-4 ">
            {renderCheklist()}
            {renderInfoExtra()}
          </div>
          <br />
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              {/* <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="nota" className="mb-2 font-bold">
                  NOTA
                </label>
                <textarea
                  type="text"
                  id="nota"
                  name="nota"
                  value={usuario.nota}
                  onChange={handleInputChange}
                  rows={3}
                  placeholder="NOTA"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={512}
                />
              </div> */}
            </div>
          </div>
          <br />
          <div className="flex justify-between p-4">
            {/* <button className={ESTILO_BOTON_GENERAL_CANCELAR}
              onClick={handleCancelClick}
            >
              CANCELAR
            </button>
            <button className={ESTILO_BOTON_GENERAL_ACEPTAR}
              onClick={handleAceptarClick}
            >
              ACEPTAR
            </button> */}
          </div>
        </div>
      );
    } catch (err) {
      console.log('VerChecklistIngreso render err');
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  }

  return render();
}

import shortid from "shortid";

const ModalInfoUnidadSeguimiento = ({ isOpen, onAccept, data }) => {

    const renderTupla = (tupla, index) => {
        try {
            // console.log('renderCelHeader');
            // console.log(column);
            return (
                <div
                    key={shortid.generate()}
                >
                    <div className="flex mt-4">
                        <div className="w-1/2 pr-2">
                            <label className="block mb-2">
                                {index + 1} - {tupla.productoNombre !== undefined ? tupla.productoNombre : ""}
                            </label>
                        </div>
                    </div>
                </div>
            );
        } catch (err) {
            return <div>{JSON.stringify(err)}</div>;
        }
    };

    return (
        <>
            {isOpen && (
                <div className="fixed z-50 top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                    {/* background */}
                    <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
                    {/* end background */}
                    <div className="bg-white w-11/12 h-3/6 md:max-w-3xl lg:max-w-3xl mx-auto rounded shadow-lg z-50 overflow-y-auto flex flex-col">
                        <div className="pt-4 text-left px-6">
                            <div className="mb-4">
                                <p className="text-xl">{'PRODUCTOS '}</p>
                                {/* {data.tuplas !== undefined && data.tuplas !== null && data.tuplas.map(tupla => )} */}
                                {data.tuplas !== undefined && data.tuplas !== null && data.tuplas.map((tupla, index) => renderTupla(tupla, index))}
                            </div>
                        </div>
                        <div className="flex justify-end pt-20 pb-4 pr-4">
                            <button
                                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 cursor-pointer"
                                onClick={onAccept}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ModalInfoUnidadSeguimiento;
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "../../components/grid";
import { ESTILO_BOTON_GRILLA_NEXT, ESTILO_BOTON_GRILLA_PREVIOUS, ESTILO_BOTON_LISTAR_ATRAS } from "../../utils/const-estilos";
import "../../style/style-cargando.css";
import {
  INT_ID_MODULO_EVENTOS,
  INT_ID_SEGUNDO_NIVEL_EVENTOS_INCIDENTE,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../utils/const-int";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
import { TRUE } from "../../utils/const-bool";
import { obtenerListaIncidente } from "../../api/incidente";
import { LISTAR_INCIDENTE } from "../../utils/const-screen-name";
import { compareHeaders } from "../../share/shared-functions";
import ModalConfiguracionGrilla from "../../components/modal-configuracion-grilla";
import { Settings } from "feather-icons-react/build/IconComponents";
import { actualizarHeadersGestionarDisponibilidadScreen, actualizarOrdenGestionarDisponibilidadScreen, guardarConfiguracionScreen, obtenerConfiguracionScreen } from "../../api/configuracion-screen/configuracion-screen";

const idTipoUsuario = parseInt(localStorage.getItem("tipo"));

export default function GestionarIncidente() {
  const headers = [
    { key: "acciones", title: "ACCIONES", showSettings: false, },
    { key: "id", title: "ID", visible: false, showSettings: false, },
    { key: "unidad", title: "UNIDAD", visible: true, filter: true, },
    { key: "idGeocerca", title: "idGeocerca", visible: false, filter: true, showSettings: false, },
    { key: "nombreGeocerca", title: "NOMBRE GEOCERCA", visible: true, filter: true, },
    { key: "otroGeocerca", title: "OTRO GEOCERCA", visible: true, filter: true, },
    { key: "idTipoIncidente", title: "idTipoIncidente", visible: false, filter: true, showSettings: false, },
    { key: "tipoIncidente", title: "TIPO INCIDENTE", visible: true, filter: true, },
    { key: "otroTipoIncidente", title: "otroTipoIncidente", visible: true, filter: true, },
    { key: "idCategoria", title: "idCategoria", visible: false, filter: true, showSettings: false, },
    { key: "categoria", title: "CATEGORIA", visible: true, filter: true, },
    { key: "otroCategoria", title: "OTRO CATEGORIA", visible: true, filter: true, },
    { key: "descripcion", title: "DESCRIPCION", visible: true, filter: true, },
    { key: "nota", title: "NOTA", visible: false, filter: true, },
    { key: "unidad", title: "UNIDAD", visible: true, filter: true, },
    { key: "url", title: "URL", visible: true, filter: true, },
    { key: "coordenadas", title: "COORDENADAS", visible: true, filter: true, },
    { key: "idEstadoCamion", title: "ID ESTADO CAMION", visible: false, filter: true, showSettings: false },
    { key: "estadoCamion", title: "ESTADO CAMION", visible: true, filter: true, showSettings: true },
    { key: "fechaProceso", title: "FECHA PROCESO", filter: true, visible: true, },
    { key: "fechaFinalizacion", title: "FECHA FINALIZACION", filter: true, visible: true, },
  ];

  const history = useHistory();
  const [minRows, setMinRows] = useState(0);
  const [maxRows, setMaxRows] = useState(5);
  const [cargandoPage, setCargandoPage] = useState(true);
  const [listaDisponibilidad, setListaDisponibilidad] = useState([]);
  const [permisos, setPermisos] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);

  const [exportar, setExportar] = useState(true);
  const [modalConfiguracionVisible, setModalConfiguracionVisible] = useState(false);
  const [configuracionOrden, setConfiguracionOrden] = useState(null);
  const [ascendente, setAscendente] = useState(false);
  const [descendente, setDescendente] = useState(false);
  const [gridHeaders, setGridHeaders] = useState(headers);

  useEffect(() => {
    // console.log("entro a listar correo");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      let obj = {};
      obj.idMenuPrimerNivel = INT_ID_MODULO_EVENTOS;
      obj.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_EVENTOS_INCIDENTE;
      obj.idMenuTercerNivel = 0;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(obj);
      let permiso = null;
      if (obtenerPermisos.length > 0) {
        permiso = obtenerPermisos[0];
        setPermisos(permiso);
      }

      const configuracionScreenInfo = await obtenerConfiguracionScreen({ nombre: LISTAR_INCIDENTE });
      // console.log('configuracionScreenInfo');
      // console.log(configuracionScreenInfo);
      let objConfiguracionEnviar = {};
      objConfiguracionEnviar.descendente = true;
      objConfiguracionEnviar.ascendente = false;
      if (configuracionScreenInfo !== null) {
        const configuracionOrden = JSON.parse(configuracionScreenInfo.orden);
        // console.log('configuracionOrden');
        // console.log(configuracionOrden);
        objConfiguracionEnviar.descendente = configuracionOrden.descendente;
        objConfiguracionEnviar.ascendente = configuracionOrden.ascendente;
        setAscendente(configuracionOrden.ascendente);
        setDescendente(configuracionOrden.descendente);
        let configuracionHeaders = null;
        if (configuracionScreenInfo.headers !== null) {
          configuracionHeaders = JSON.parse(configuracionScreenInfo.headers);
          // console.log('configuracionHeaders');
          // console.log(configuracionHeaders);
          const headerAux = compareHeaders(headers, configuracionHeaders);
          setGridHeaders(headerAux);
        }
      }
      setConfiguracionOrden(configuracionScreenInfo);


      let lista = [];
      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        setTieneAccesoAlModulo(true);
        lista = await obtenerListaIncidente(objConfiguracionEnviar);
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.accesoAlModulo === TRUE) {
          setTieneAccesoAlModulo(true);
          lista = await obtenerListaIncidente(objConfiguracionEnviar);
        }
      }
      agregarAccionesALista(lista, permiso);
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const agregarAccionesALista = (lista, permiso) => {
    try {
      if (lista.length === 0) {
        setListaDisponibilidad(lista);
      }
      if (lista.length > 0) {
        let objVer = {
          label: "Ver",
          color: "green",
          onClick: (param) => verIncidente(param),
        };
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            acciones.push(objVer);
          }
          if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            if (permiso.ver === TRUE) {
              acciones.push(objVer);
            }
          }
          lista[i].acciones = acciones;
        }
        setListaDisponibilidad(lista);
      }
    } catch (err) {
      console.log('agregarAccionesALista err');
      console.log(err);
    }
  }

  const nextPage = (page, params) => {
    try {
      // history.push(`${page}`);
      // console.log('params');
      // console.log(params);
      if (params !== undefined && params !== null) {
        if (params.acciones !== undefined) {
          delete params.acciones;
        }
        // console.log('params sin acciones');
        // console.log(params);
        history.push({
          pathname: `${page}`,
          state: params
        });
      } else {
        history.push(`${page}`);
      }
    } catch (err) {
      console.log('nextPage err');
      console.log(err);
    }
  };

  const handleNextPage = () => {
    if (maxRows >= listaDisponibilidad.length) return;
    setMinRows(maxRows);
    setMaxRows(maxRows + 5);
  };

  const handlePrevPage = () => {
    if (minRows <= 0) return;
    setMaxRows(minRows);
    setMinRows(Math.max(minRows - 5, 0));
  };

  const verIncidente = (data) => {
    try {
      // console.log("editarDisponibilidad");
      // console.log(data);
      if (data.acciones !== undefined) {
        delete data.acciones;
      }
      let obj = {};
      obj.titulo = "INCIDENTE";
      obj.params = data;
      // console.log('obj');
      // console.log(obj);
      nextPage("incidente/incidente", obj);
    } catch (err) {
      console.log("verIncidente err");
      console.log(err);
    }
  };

  const handleAtrasClick = () => {
    history.goBack();
  }

  const onHideModalConfiguracion = () => {
    setModalConfiguracionVisible(false);
  }

  const onClickAcceptarOrdenGrillaModalConfiguracion = async () => {
    try {
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = LISTAR_INCIDENTE;
      objConfiguracionEnviar.orden = objOrden;
      console.log('configuracionOrden');
      console.log(configuracionOrden);
      if (configuracionOrden === null) {
        objConfiguracionEnviar.headers = gridHeaders;
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        const actualizo = await actualizarOrdenGestionarDisponibilidadScreen(objConfiguracionEnviar);
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log('onClickAcceptarOrdenGrillaModalConfiguracion err');
      console.log(err);
    }
  }

  const onClickAcceptarHeadersGrillaModalConfiguracion = async () => {
    try {
      console.log('onClickAcceptarHeadersGrillaModalConfiguracion');
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = LISTAR_INCIDENTE;
      objConfiguracionEnviar.orden = objOrden;
      console.log('configuracionOrden');
      console.log(configuracionOrden);
      if (configuracionOrden === null) {
        objConfiguracionEnviar.headers = gridHeaders;
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        objConfiguracionEnviar.headers = gridHeaders;
        const actualizo = await actualizarHeadersGestionarDisponibilidadScreen(objConfiguracionEnviar);
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log('onClickAcceptarHeadersGrillaModalConfiguracion err');
      console.log(err);
    }
  }

  const openModalCondiguracion = () => {
    setModalConfiguracionVisible(true);
  }

  const reset = async (permiso) => {
    setCargandoPage(true);
    const configuracionScreenInfo = await obtenerConfiguracionScreen({ nombre: LISTAR_INCIDENTE });
    // console.log('configuracionScreenInfo');
    // console.log(configuracionScreenInfo);
    let objConfiguracionEnviar = {};
    objConfiguracionEnviar.descendente = true;
    objConfiguracionEnviar.ascendente = false;
    if (configuracionScreenInfo !== null) {
      const configuracionOrden = JSON.parse(configuracionScreenInfo.orden);
      console.log('configuracionOrden');
      console.log(configuracionOrden);
      objConfiguracionEnviar.descendente = configuracionOrden.descendente;
      objConfiguracionEnviar.ascendente = configuracionOrden.ascendente;
      setAscendente(configuracionOrden.ascendente);
      setDescendente(configuracionOrden.descendente);
      let configuracionHeaders = null;
      // debugger;
      if (configuracionScreenInfo.headers !== null) {
        configuracionHeaders = JSON.parse(configuracionScreenInfo.headers);
        console.log('configuracionHeaders');
        console.log(configuracionHeaders);
        const headerAux = compareHeaders(headers, configuracionHeaders);
        setGridHeaders(headerAux);
      }
      setConfiguracionOrden(configuracionScreenInfo);
    }
    let lista = await obtenerListaIncidente(objConfiguracionEnviar);
    agregarAccionesALista(lista, permisos);
    setCargandoPage(false);
  }

  const render = () => {
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div
              style={{ marginTop: 10, }}
            >
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <label
              style={{
                paddingLeft: 10,
                fontWeight: 'bold',
              }}
            >
              LISTA INCIDENTES
            </label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={openModalCondiguracion}
            >
              <Settings />
            </button>
          </div>
          <Grid rows={listaDisponibilidad} headers={gridHeaders}
            exportar={exportar} />
          <div>
            <button className={ESTILO_BOTON_GRILLA_PREVIOUS} onClick={handlePrevPage}>Previous</button>
            <button className={ESTILO_BOTON_GRILLA_NEXT} onClick={handleNextPage}>Next</button>
          </div>
          <ModalConfiguracionGrilla
            modalConfiguracionVisible={modalConfiguracionVisible}
            onHideModalConfiguracion={onHideModalConfiguracion}
            ascendente={ascendente}
            descendente={descendente}
            setAscendente={setAscendente}
            setDescendente={setDescendente}
            gridHeaders={gridHeaders}
            setGridHeaders={setGridHeaders}
            onClickAcceptarOrdenGrillaModalConfiguracion={onClickAcceptarOrdenGrillaModalConfiguracion}
            onClickAcceptarHeadersGrillaModalConfiguracion={onClickAcceptarHeadersGrillaModalConfiguracion}
          />
        </div>
      );
    } catch (err) {
      console.log('GestionarIncidente render err');
      console.log(err);
      return (
        <div><label>{JSON.stringify(err)}</label></div>
      );
    }
  }

  return render();
}

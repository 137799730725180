import moment from "moment/moment";
import { SUCCESS } from "../../page/const/http-const";
import { getHeaders } from "../utils";

export async function obtenerConfiguracionScreen(obj) {
    try {
        let rows = null;
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracionscreen/obtener-configuracion-screen/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        if (code === SUCCESS && body[0] !== undefined) {
            rows = body[0];
        }
        return rows;
    } catch (err) {
        console.log("obtenerConfiguracionScreen err");
        console.log(err);
        return null;
    }
}

export async function guardarConfiguracionScreen(obj) {
    try {
        let guardo = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracionscreen/guardar-configuracion-screen/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            guardo = true;
        }
        return guardo;
    } catch (err) {
        console.log("guardarConfiguracionScreen err");
        console.log(err);
        return false;
    }
}

export async function actualizarOrdenGestionarDisponibilidadScreen(obj) {
    try {
        let guardo = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracionscreen/actualizar-orden-gestionar-disponibilidad-screen/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            guardo = true;
        }
        return guardo;
    } catch (err) {
        console.log("actualizarOrdenGestionarDisponibilidadScreen err");
        console.log(err);
        return false;
    }
}

export async function actualizarHeadersGestionarDisponibilidadScreen(obj) {
    try {
        let guardo = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracionscreen/actualizar-headers-gestionar-disponibilidad-screen/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            guardo = true;
        }
        return guardo;
    } catch (err) {
        console.log("actualizarHeadersGestionarDisponibilidadScreen err");
        console.log(err);
        return false;
    }
}

export async function actualizarFontSizeGrilla(obj) {
    try {
        let guardo = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracionscreen/actualizar-font-size-grid-screen/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            guardo = true;
        }
        return guardo;
    } catch (err) {
        console.log("actualizarFontSizeGrilla err");
        console.log(err);
        return false;
    }
}

export async function actualizarAlignGrilla(obj) {
    try {
        let guardo = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracionscreen/actualizar-align-grid-screen/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            guardo = true;
        }
        return guardo;
    } catch (err) {
        console.log("actualizarAlignGrilla err");
        console.log(err);
        return false;
    }
}

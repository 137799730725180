export const GESTIONAR_DISPONIBILIDAD_SCREEN =
  "GESTIONAR_DISPONIBILIDAD_SCREEN";
export const GESTIONAR_CHECKLIST_SCREEN = "GESTIONAR_CHECKLIST_SCREEN";
export const LISTAR_DESCARGAS_IMPORTADAS_REPORTE =
  "LISTAR_DESCARGAS_IMPORTADAS_REPORTE";
export const LISTAR_TIPO_CARROCERIA = "LISTAR_TIPO_CARROCERIA";
export const LISTAR_SUB_TIPO_CARROCERIA = "LISTAR_SUB_TIPO_CARROCERIA";
export const LISTAR_INCIDENTE = "LISTAR_INCIDENTE";
export const LISTAR_USUARIOS_MOVIL = "LISTAR_USUARIOS_MOVIL";
export const LISTAR_GEORUTA = "LISTAR_GEORUTA";
export const LISTAR_CHECKLIST_INGRESO = "LISTAR_CHECKLIST_INGRESO";
export const LISTAR_CHECKLIST_SALIDA = "LISTAR_CHECKLIST_SALIDA";

export const REPORTES_CUMPLIMIENTO_DE_VIAJE = "REPORTES_CUMPLIMIENTO_DE_VIAJE";

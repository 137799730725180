import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import readXlsxFile from "read-excel-file";
import Grid from "../../components/grid";
import { obtenerGeocercaTiempoAFechaActual } from "../../api/reportes";
//import { obtenerListadoGeocercas } from "../../api/gestion-dts/gestion";
import moment from "moment";
import "moment-timezone";

export default function EntradasGeocercasTiemposFechaHoraSeleccionada() {
  const history = useHistory();
  const location = useLocation();
  const [nombreExcel, setNombreExcel] = useState("");
  const [excelValue, setExcelValue] = useState(null);
  const [dataEntradasGeocerca, setDataEntradasGeocerca] = useState([]);
  const [dataExcel, setDataExcel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [porcentajeAvance, setPorcentajeAvance] = useState(0);
  const [fechaSeleccionada, setFechaSeleccionada] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const headersEntradasGeocerca = [
    { key: "codGeocerca", title: "Cod. Destino", filter: true },
    { key: "nombreGeocerca", title: "Destino", filter: true },
    { key: "placa", title: "Placa", filter: true },
    { key: "fechaIngreso", title: "Fecha Entrada", filter: true },
    { key: "horaIngreso", title: "Hora Entrada", filter: true },
    { key: "fecha", title: "Fecha Salida", filter: true },
    { key: "hora", title: "Hora Salida", filter: true },
    { key: "tiempoEntreFechas", title: "tiempo en geocerca", filter: true },
    { key: "estado", title: "estado", filter: true },
  ];
  const headersDataExcel = [
    { key: "codigo", title: "Cod. Destino", filter: true },
    { key: "placa", title: "Placa", filter: true },
    { key: "fechaInicioVisualizada", title: "Fecha Inicio", filter: true },
    { key: "fechaCierreVisualizada", title: "Fecha Fin", filter: true },
  ];

  const importar = () => {
    try {
      document.getElementById("fileUpload").click();
    } catch (err) {
      console.log("importarExcel err");
      console.log(err);
    }
  };
  const recargarFecha = () => {
    let data = dataExcel;
    setDataExcel(null);
    console.log("entra");
    for (let x = 0; x < dataExcel.length; x++) {
      data[x].fechaCierre = new Date(`${fechaSeleccionada} 23:59:59`);
      data[x].fechaCierreVisualizada = `${fechaSeleccionada} 23:59:59`;
    }

    setDataExcel(data);
  };
  const procesarExcel = (rows) => {
    try {
      if (rows.length == 0) {
        return;
      }
      let dataFormat = [];
      /*console.log(fechaSeleccionada);
      console.log(moment().format("YYYY-MM-DD"));
      console.log(moment().format("YYYY-MM-DD") === fechaSeleccionada);
      console.log(moment().format("YYYY-MM-DD hh:mm:ss"));*/
      const zonaHorariaLocal = moment.tz.guess();
      for (let i = 1; i < rows.length; i++) {
        
        let row = {
          codigo: rows[i][0] ? rows[i][0].toString() : "",
          placa: rows[i][1] ? rows[i][1].toString() : "",
          fechaInicioVisualizada: rows[i][2]
            ? moment.utc(rows[i][2]).format("YYYY-MM-DD")
            : "",
          fechaCierreVisualizada:
            fechaSeleccionada === moment().format("YYYY-MM-DD")
              ? moment().format("YYYY-MM-DD HH:mm:ss")
              : `${fechaSeleccionada} 23:59:59`,
          fechaInicio: rows[i][2]
            ? `${moment.utc(rows[i][2]).format("YYYY-MM-DD HH:mm:ss")}`
            : "",
          fechaCierre:
            fechaSeleccionada === moment().format("YYYY-MM-DD")
              ? moment.tz(moment().format("YYYY-MM-DD HH:mm:ss"), zonaHorariaLocal)
              : moment.tz(`${fechaSeleccionada} 23:59:59`, zonaHorariaLocal),
        };
        dataFormat.push(row);
      }

      setDataExcel(dataFormat);

      if (dataFormat.length > 0) {
        alert("Se cargaron los datos correctamente");
      }
    } catch (err) {
      console.log("procesarExcel err");
      console.log(err);
    }
  };
  const obtainReportGeocerca = async () => {
    setLoading(true);
    let data = [];
    const batchSize = 10;
    for (let i = 0; i < dataExcel.length; i += batchSize) {
      const batch = dataExcel.slice(i, i + batchSize);
      setPorcentajeAvance(i / dataExcel.length);
      let resp = await obtenerGeocercaTiempoAFechaActual(batch);
      for (let x = 0; x < resp.length; x++) {
        if (resp[x].fechaHora !== null) {
          let fechaHora = new Date(resp[x].fechaHora);
          let fecha = fechaHora.toLocaleDateString("es-BO", {
            timeZone: "America/La_Paz",
          });
          let hora = fechaHora.toLocaleTimeString("es-BO", {
            timeZone: "America/La_Paz",
          });
          resp[x].fecha = fecha;
          resp[x].hora = hora;
        } else {
          resp[x].fecha = "-";
          resp[x].hora = "-";
        }
        if (resp[x].fechaHoraIngreso !== null) {
          let fechaHora = new Date(resp[x].fechaHoraIngreso);
          let fecha = fechaHora.toLocaleDateString("es-BO", {
            timeZone: "America/La_Paz",
          });
          let hora = fechaHora.toLocaleTimeString("es-BO", {
            timeZone: "America/La_Paz",
          });
          resp[x].fechaIngreso = fecha;
          resp[x].horaIngreso = hora;
        } else {
          resp[x].fechaIngreso = "-";
          resp[x].horaIngreso = "-";
        }
      }
      data.push(...resp);
    }
    setDataEntradasGeocerca(data);
    setLoading(false);
    console.log(data);
  };
  const reset = () => {
    try {
      setDataExcel([]);
    } catch (err) {
      console.log("reset err");
      console.log(err);
    }
  };
  useEffect(() => {
    if (excelValue !== null) {
      reset();
      procesarExcel(excelValue);
    }
  }, [excelValue]);
  //useEffect(() => {
  //obtainReportGeocerca();
  //}, [dataExcel]);
  useEffect(() => {
    setNombreExcel("");
    const fileUpload = document.getElementById("fileUpload");
    fileUpload.addEventListener("change", async () => {
      const resp = await readXlsxFile(fileUpload.files[0]);
      setNombreExcel(fileUpload.files[0].name);
      setExcelValue(resp);
      fileUpload.value = null;
    });
    /*  let now = new Date();
    now = moment(now).format("YYYY-MM-DD");
    setFechaSeleccionada(now); */
  }, []);

  return (
    <div className="p-[2rem]">
      <input
        id="fileUpload"
        type="file"
        className="my-auto text-white p-[5px] px-[10px] mr-[10px] hidden"
      />
      <div className="flex justify-between">
        <div>{`Entradas a geocercas (Excel)`}</div>
        <div>
          <p>Fecha fin:</p>
          <input
            type="date"
            value={fechaSeleccionada}
            onChange={(e) => {
              console.log(e.target.value);
              setFechaSeleccionada(e.target.value);
            }}
          />

          {/*<button
            className="my-auto bg-[#84B044] rounded text-white p-[5px] px-[10px] mr-[10px]"
            onClick={() => {
              recargarFecha();
            }}
          >
            Recargar Fecha
          </button>*/}
          <button
            className="my-auto bg-[#84B044] rounded text-white p-[5px] px-[10px] mr-[10px]"
            onClick={() => {
              importar();
            }}
          >
            CARGAR
          </button>
          <button
            className="my-auto bg-[#84B044] rounded text-white p-[5px] px-[10px] mr-[10px]"
            onClick={() => {
              obtainReportGeocerca();
            }}
          >
            GENERAR
          </button>
        </div>
      </div>
      <div>
        <Grid
          rows={dataExcel}
          headers={headersDataExcel}
          //filaClick={filaClick}
          center={true}
        />
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 30,
          }}
        >
          <div
            className="loader"
            style={{ marginTop: "15px", height: "50px", width: "50px" }}
          ></div>
          <div style={{ marginTop: 10 }}>
            <p>{`Cargando... ${Math.trunc(porcentajeAvance * 100)}%`}</p>
          </div>
        </div>
      ) : (
        <div className="mt-[15px]">
          <Grid
            rows={dataEntradasGeocerca}
            headers={headersEntradasGeocerca}
            exportar={true}
            //filaClick={filaClick}
            center={true}
          />
        </div>
      )}
    </div>
  );
}

import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import LayoutEventos from "../../components/layout-eventos";
import GestionarDisponibilidad from "../disponibilidad/gestionar-disponibilidad";

const RouterEventos = () => {
  const { path } = useRouteMatch();
  return (
    <LayoutEventos>
      <Switch>

        <Route exact path={`${path}`}>
          <GestionarDisponibilidad />
        </Route>

      </Switch>
    </LayoutEventos>
  );
};

export default RouterEventos;

import carIcon from "../../../assets/iconos/car.png"

export const LISTA_REPORTES_CHECKLIST = [
    {
        id: 1,
        titulo: 'CONSOLIDADOS CHECKLIST INGRESO',
        descripcion: 'Reporte excel',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'reporte-consolidado-ingreso',
    },
    {
        id: 2,
        titulo: 'CONSOLIDADO CHECKLIST SALIDA',
        descripcion: 'Reporte excel',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'reporte-consolidado-salida',
    },
];
import empty from "../assets/iconos/empty.png";

const NoInfo = ({ }) => {

    return (
        <>
            <div className="flex flex-col items-center mt-30  pt-30">
                <img
                    title="No information was found"
                    className="h-[75px] w-[75px]"
                    alt="No Informacion"
                    src={empty}
                />
                <div
                    style={{ marginTop: 10, }}
                >
                    <p>No se encontro informacion...</p>
                </div>
            </div>
        </>
    );
}

export default NoInfo;
export async function doFetchJSONGET(url) {
    try {
        let result = null;
        let data = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "GET",
        }).then((res) => res.json());
        // console.log('data');
        // console.log(data);
        if (data.data !== undefined) {
            result = data.data;
        }
        return result;
    } catch (err) {
        console.log("doFetchJSONGET err");
        console.log(err);
        return null;
    }
}
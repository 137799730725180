const ModalIncidente = ({ isOpen, onAccept, seguimiento, data }) => {

    return (
        <>
            {isOpen && (
                <div className="fixed z-50 top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                    {/* background */}
                    <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
                    {/* end background */}
                    <div className="bg-white w-11/12 h-4/6 md:max-w-3xl lg:max-w-3xl mx-auto rounded shadow-lg z-50 overflow-y-auto flex flex-col">
                        <div className="pt-4 text-left px-6">
                            <div className="mb-4">
                                <p className="text-xl">{'INCIDENTE ' + data.unidad}</p>
                                <label className="block mb-2 font-bold">{seguimiento.depositoOrigen + " -> " + seguimiento.depositoDestino}</label>
                                <div className="flex mt-4">
                                    <div className="w-1/2 pr-2">
                                        <label className="block mb-2 font-bold">GEOCERCA</label>
                                        <input
                                            type="text"
                                            value={data.nombrepuntocontrol}
                                            className="p-2 border border-gray-300 rounded"
                                            readOnly
                                        />
                                    </div>
                                    <div className="w-1/2 pr-2">
                                        <label className="block mb-2 font-bold">OTRO GEOCERCA</label>
                                        <input
                                            type="text"
                                            value={data.otropuntocontrol}
                                            className="p-2 border border-gray-300 rounded"
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="flex mt-4">
                                    <div className="w-1/2 pr-2">
                                        <label className="block mb-2 font-bold">CATEGORIA</label>
                                        <input
                                            type="text"
                                            value={data.categoria}
                                            className="p-2 border border-gray-300 rounded"
                                            readOnly
                                        />
                                    </div>
                                    <div className="w-1/2 pr-2">
                                        <label className="block mb-2 font-bold">OTRO CATEGORIA</label>
                                        <input
                                            type="text"
                                            value={data.otrocategoria}
                                            className="p-2 border border-gray-300 rounded"
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col mt-4 ml-6 mr-6">
                            <div className="w-full pr-2">
                                <label className="block mb-2 font-bold">DESCRIPCION</label>
                                <textarea
                                    type="text"
                                    value={data.descripcion}
                                    className="w-full p-2 border border-gray-300 rounded"
                                    readOnly
                                />
                            </div>
                            <div className="w-full pr-2">
                                <label className="block mb-2 font-bold">FOTOGRAFIA</label>
                                <input
                                    type="text"
                                    value={data.url}
                                    className="w-full p-2 border border-gray-300 rounded cursor-pointer"
                                    readOnly
                                    onClick={() => window.open(data.url, '_blank')}
                                />
                            </div>
                        </div>
                        <div className="flex justify-end pt-20 pb-4 pr-4">
                            <button
                                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 cursor-pointer"
                                onClick={onAccept}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ModalIncidente;
import { SUCCESS } from "../page/const/http-const";
import { getHeaders } from "./utils";

export async function obtenerDashboardCardCombo() {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/dashboard/obtener-dashboard-card-combo/",
            {
                headers: getHeaders(),
                method: "POST",
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerDashboardCardCombo err");
        console.log(err);
        return [];
    }
}

export async function obtenerDashboardCards() {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/dashboard/obtener-dashboard-card/",
            {
                headers: getHeaders(),
                method: "POST",
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerDashboardCards err");
        console.log(err);
        return [];
    }
}

export async function guardarDashboardCard(obj) {
    try {
        let result = null;
        const { code, id } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/dashboard/guardar-dashboard-card/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            result = id;
        }
        return result;
    } catch (err) {
        console.log("guardarDashboardCard err");
        console.log(err);
        return null;
    }
}
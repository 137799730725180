import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../style/style-cargando.css";
import {
  INT_FONT_SIZE,
  INT_ID_MODULO_REPORTES,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
  INT_TIPO_USUARIO_CHOFER_MOVIL,
} from "../../utils/const-int";
import Select from "react-select";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
import { TRUE } from "../../utils/const-bool";
import moment from "moment";
import "react-widgets/styles.css";
import DatePicker from "react-widgets/DatePicker";
import momentLocalizer from "react-widgets-moment";
import {
  ESTILO_BOTON_LISTAR_ATRAS,
  ESTILO_BOTON_LISTAR_NUEVO,
} from "../../utils/const-estilos";
import { obtenerListaUsuariosMovil } from "../../api/usuario/usuario";
// import { Info } from "feather-icons-react/build/IconComponents";
import HorizontalLine from "../../components/horizontal-line";
import { STRING_CENTER } from "../../utils/const-string";
import Grid from "../../components/grid";
import ModalConfiguracionGrilla from "../../components/modal-configuracion-grilla";
import { REPORTES_CUMPLIMIENTO_DE_VIAJE } from "../../utils/const-screen-name";
import {
  actualizarAlignGrilla,
  actualizarFontSizeGrilla,
  actualizarHeadersGestionarDisponibilidadScreen,
  actualizarOrdenGestionarDisponibilidadScreen,
  guardarConfiguracionScreen,
  obtenerConfiguracionScreen,
} from "../../api/configuracion-screen/configuracion-screen";
import { Settings } from "feather-icons-react/build/IconComponents";
import { compareHeaders } from "../../share/shared-functions";
import { obtenerCumplimientoDeViaje } from "../../api/reportes";
// import { AZUL_BOLTRACK1 } from "../../share/shared-colors";
new momentLocalizer(moment);

const idTipoUsuario = parseInt(localStorage.getItem("tipo"));

export default function CumplimientoDeViaje() {
  let headers = [
    // { key: "acciones", title: "ACCIONES", visible: true },
    {
      key: "codigoGeocercaDestino",
      title: "codigoGeocercaDestino",
      visible: false,
      filter: false,
    },
    {
      key: "codigoGeocercaOrigen",
      title: "codigoGeocercaOrigen",
      visible: false,
      filter: false,
    },
    {
      key: "estado",
      title: "ESTADO",
      visible: true,
      filter: true,
    },
    {
      key: "fechaHoraGeocercaDestinoEntrada",
      title: "FH DESTINO ENTRADA",
      visible: true,
      filter: true,
    },
    {
      key: "fechaHoraGeocercaDestinoSalida",
      title: "FH DESTINO SALIDA",
      visible: true,
      filter: true,
    },
    {
      key: "idAsignacionItinerarioDescarga",
      title: "idAsignacionItinerarioDescarga",
      visible: false,
      filter: false,
    },
    {
      key: "idGeocercaDestino",
      title: "idGeocercaDestino",
      visible: false,
      filter: false,
    },
    {
      key: "idGeocercaOrigen",
      title: "idGeocercaOrigen",
      visible: false,
      filter: false,
    },
    {
      key: "idGeoruta",
      title: "idGeoruta",
      visible: false,
      filter: false,
    },
    {
      key: "llegoATiempo",
      title: "LLEGO A TIEMPO",
      visible: true,
      filter: true,
    },
    {
      key: "nombreFletero",
      title: "NOMBRE FLETERO",
      visible: true,
      filter: true,
    },
    {
      key: "nombreGeocercaDestino",
      title: "GEOCERCA DESTINO",
      visible: true,
      filter: true,
    },
    {
      key: "nombreGeocercaOrigen",
      title: "GEOCERCA ORIGEN",
      visible: true,
      filter: true,
    },
    {
      key: "placa",
      title: "PLACA",
      visible: true,
      filter: true,
    },
    {
      key: "planilla",
      title: "PLANILLA",
      visible: true,
      filter: true,
    },
    {
      key: "tiempoEjecutado",
      title: "TIEMPO EJECUTADO",
      visible: true,
      filter: true,
    },
    {
      key: "tiempoExtraPermitido",
      title: "TIEMPO EXTRA PERMITIDO",
      visible: true,
      filter: true,
    },
    {
      key: "tiempoProgramado",
      title: "TIEMPO PROGRAMADO",
      visible: true,
      filter: true,
    },
    {
      key: "tiempoTolerancia",
      title: "TIEMPO TOLERANCIA",
      visible: true,
      filter: true,
    },
  ];

  const history = useHistory();
  const [permisos, setPermisos] = useState(null);
  const [cargandoPage, setCargandoPage] = useState(false);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);
  const [listaUnidades, setListaUnidades] = useState([]);
  const [fechaInicio, setFechaInicio] = useState(new Date());
  const [fechaFin, setFechaFin] = useState(new Date());
  const [unidadSelected, setUnidadSelected] = useState(null);
  const [listaCumplimientoDeViaje, setListaCumplimientoDeViaje] = useState([]);

  const [modalConfiguracionVisible, setModalConfiguracionVisible] =
    useState(false);
  const [configuracionOrden, setConfiguracionOrden] = useState(null);
  const [ascendente, setAscendente] = useState(false);
  const [descendente, setDescendente] = useState(false);
  const [gridHeaders, setGridHeaders] = useState(headers);
  const [exportar, setExportar] = useState(true);
  const [fontSizeValue, setFontSizeValue] = useState(INT_FONT_SIZE);
  const [alignValue, setAlignValue] = useState(STRING_CENTER);

  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      iniciarFechas();
      let obj = {};
      obj.idMenuPrimerNivel = INT_ID_MODULO_REPORTES;
      obj.idMenuSegundoNivel = 0;
      obj.idMenuTercerNivel = 0;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(obj);
      // console.log('obtenerPermisos');
      // console.log(obtenerPermisos);
      let permiso = obtenerPermisos[0];
      setPermisos(permiso);
      // console.log('idTipoUsuario');
      // console.log(idTipoUsuario);
      // debugger;
      let listaUsuarioMov = await obtenerListaUsuariosMovil();
      for (let i = 0; i < listaUsuarioMov.length; i++) {
        listaUsuarioMov[i].value = listaUsuarioMov[i].id;
        listaUsuarioMov[i].label =
          listaUsuarioMov[i].nombre + " - " + listaUsuarioMov[i].nombreUnidad;
      }
      listaUsuarioMov = listaUsuarioMov.filter(
        (e) => e.idtipousuario === INT_TIPO_USUARIO_CHOFER_MOVIL
      );
      listaUsuarioMov.unshift({ label: "TODOS", value: "TODOS" });
      setListaUnidades(listaUsuarioMov);
      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        setTieneAccesoAlModulo(true);
        await readConfiguracionGrilla();
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.accesoAlModulo === TRUE) {
          setTieneAccesoAlModulo(true);
          await readConfiguracionGrilla();
        }
      }

      setCargandoPage(false);
    } catch (err) {
      console.log("iniciar err");
      console.log(err);
      setCargandoPage(false);
    }
  };

  const readConfiguracionGrilla = async () => {
    try {
      const configuracionScreenInfo = await obtenerConfiguracionScreen({
        nombre: REPORTES_CUMPLIMIENTO_DE_VIAJE,
      });
      console.log("configuracionScreenInfo");
      console.log(configuracionScreenInfo);
      let objConfiguracionEnviar = {};
      objConfiguracionEnviar.descendente = true;
      objConfiguracionEnviar.ascendente = false;
      if (configuracionScreenInfo !== null) {
        const configuracionOrden = JSON.parse(configuracionScreenInfo.orden);
        console.log("configuracionOrden");
        console.log(configuracionOrden);
        objConfiguracionEnviar.descendente = configuracionOrden.descendente;
        objConfiguracionEnviar.ascendente = configuracionOrden.ascendente;
        setAscendente(configuracionOrden.ascendente);
        setDescendente(configuracionOrden.descendente);
        let configuracionHeaders = null;
        if (configuracionScreenInfo.headers !== null) {
          configuracionHeaders = JSON.parse(configuracionScreenInfo.headers);
          console.log("configuracionHeaders");
          console.log(configuracionHeaders);
          let headerAux = compareHeaders(headers, configuracionHeaders);
          //   headerAux = addFunctionToHeaders(headerAux);
          setGridHeaders(headerAux);
        }
        if (configuracionScreenInfo.fontSize !== null) {
          setFontSizeValue(configuracionScreenInfo.fontSize);
        }
        if (configuracionScreenInfo.align !== null) {
          setAlignValue(configuracionScreenInfo.align);
        }
      }
      setConfiguracionOrden(configuracionScreenInfo);
    } catch (err) {
      console.log("readConfiguracionGrilla err");
      console.log(err);
    }
  };

  const iniciarFechas = () => {
    try {
      let currentDate = moment();
      let fechaI = new Date(
        currentDate.clone().startOf("week").add(1, "day").format("YYYY-MM-DD")
      );
      let fechaF = new Date(
        currentDate.clone().endOf("week").add(1, "day").format("YYYY-MM-DD")
      );
      setFechaInicio(fechaI);
      setFechaFin(fechaF);
    } catch (err) {
      console.log("iniciarFechas err");
      console.log(err);
      setFechaInicio(new Date());
      setFechaFin(new Date());
    }
  };

  const handleBuscar = async () => {
    try {
      console.log("handleBuscar");
      setCargandoPage(true);
      if (unidadSelected !== null) {
        const placa =
          unidadSelected.value !== "TODOS" ? unidadSelected.value : null;
        let objEnviar = {};
        objEnviar.placa = placa;
        objEnviar.fechaInicio = fechaInicio;
        objEnviar.fechaFin = fechaFin;
        objEnviar.ascendente = ascendente;
        objEnviar.descendente = descendente;
        const listaCumplimiento = await obtenerCumplimientoDeViaje(objEnviar);
        // console.log("listaCumplimiento");
        // console.log(listaCumplimiento);
        setListaCumplimientoDeViaje(listaCumplimiento);
      }
      if (unidadSelected === null) {
        alert("Debe seleccionar una opcion de unidad");
      }
      setCargandoPage(false);
    } catch (err) {
      console.log("handleBuscar err");
      console.log(err);
      setCargandoPage(false);
    }
  };

  const handleUnidad = async (event) => {
    try {
      setUnidadSelected(event);
    } catch (err) {
      console.log("handleUnidad err");
      console.log(err);
    }
  };

  const handleAtrasClick = () => {
    history.goBack();
  };

  /** CONFIGURACION GRILLA */
  const openModalCondiguracion = () => {
    setModalConfiguracionVisible(true);
  };

  const onHideModalConfiguracion = () => {
    setModalConfiguracionVisible(false);
  };

  const onClickAcceptarOrdenGrillaModalConfiguracion = async () => {
    try {
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = REPORTES_CUMPLIMIENTO_DE_VIAJE;
      objConfiguracionEnviar.orden = objOrden;
      objConfiguracionEnviar.headers = gridHeaders;
      console.log("objConfiguracionEnviar");
      console.log(objConfiguracionEnviar);
      if (configuracionOrden === null) {
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        const actualizo = await actualizarOrdenGestionarDisponibilidadScreen(
          objConfiguracionEnviar
        );
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log("onClickAcceptarOrdenGrillaModalConfiguracion err");
      console.log(err);
    }
  };

  const onClickAcceptarHeadersGrillaModalConfiguracion = async () => {
    try {
      console.log("onClickAcceptarHeadersGrillaModalConfiguracion");
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = REPORTES_CUMPLIMIENTO_DE_VIAJE;
      objConfiguracionEnviar.orden = objOrden;
      console.log("gridHeaders");
      console.log(gridHeaders);
      console.log("headers");
      console.log(headers);
      objConfiguracionEnviar.headers = gridHeaders;
      console.log("objConfiguracionEnviar");
      console.log(objConfiguracionEnviar);
      if (configuracionOrden === null) {
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        const actualizo = await actualizarHeadersGestionarDisponibilidadScreen(
          objConfiguracionEnviar
        );
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log("onClickAcceptarHeadersGrillaModalConfiguracion err");
      console.log(err);
    }
  };

  const onClickAcceptarFontSizeModalConfiguracion = async (fontSizeValue) => {
    try {
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = REPORTES_CUMPLIMIENTO_DE_VIAJE;
      objConfiguracionEnviar.orden = objOrden;
      objConfiguracionEnviar.headers = gridHeaders;
      objConfiguracionEnviar.fontSize = fontSizeValue;
      //   console.log("objConfiguracionEnviar");
      //   console.log(objConfiguracionEnviar);
      // return;
      if (configuracionOrden === null) {
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        const actualizo = await actualizarFontSizeGrilla(
          objConfiguracionEnviar
        );
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log("onClickAcceptarFontSizeModalConfiguracion err");
      console.log(err);
    }
  };

  const onClickAcceptarAlignModalConfiguracion = async (align) => {
    try {
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = REPORTES_CUMPLIMIENTO_DE_VIAJE;
      objConfiguracionEnviar.orden = objOrden;
      objConfiguracionEnviar.headers = gridHeaders;
      objConfiguracionEnviar.fontSize = fontSizeValue;
      objConfiguracionEnviar.align = align;
      // console.log('objConfiguracionEnviar');
      // console.log(objConfiguracionEnviar);
      // return;
      if (configuracionOrden === null) {
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        const actualizo = await actualizarAlignGrilla(objConfiguracionEnviar);
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log("onClickAcceptarAlignModalConfiguracion err");
      console.log(err);
    }
  };

  const reset = async () => {
    await readConfiguracionGrilla();
    handleBuscar();
  };
  /** END CONFIGURACION GRILLA */

  const render = () => {
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div style={{ marginTop: 10 }}>
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 30,
            }}
          >
            <div style={{ marginTop: 10 }}>
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div className="m-5">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <label
              style={{
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              CUMPLIMIENTO DE VIAJE
            </label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex flex-row justify-start">
              <div className="w-full sm:w-auto sm:flex-shrink-0 sm:mr-4 mb-4 sm:mb-0">
                <label htmlFor="fecha inicio" className="mb-2 font-bold">
                  FECHA INICIO
                </label>
                <DatePicker
                  defaultValue={fechaInicio}
                  value={fechaInicio}
                  valueFormat={{ dateStyle: "medium" }}
                  onChange={(date) => setFechaInicio(date)}
                />
              </div>
              <div className="w-full sm:w-auto sm:flex-shrink-0 sm:mr-4 mb-4 sm:mb-0">
                <label htmlFor="fecha fin" className="mb-2 font-bold">
                  FECHA FIN
                </label>
                <DatePicker
                  defaultValue={fechaFin}
                  value={fechaFin}
                  valueFormat={{ dateStyle: "medium" }}
                  onChange={(date) => setFechaFin(date)}
                />
              </div>
              <div className="w-full sm:w-auto sm:flex-shrink-0 sm:mr-4 mb-4 sm:mb-0">
                <label htmlFor="unidad" className="mb-2 font-bold">
                  UNIDAD
                </label>
                <Select
                  options={listaUnidades}
                  isSearchable="true"
                  isClearable="true"
                  value={unidadSelected}
                  onChange={(event) => handleUnidad(event)}
                  placeholder="SELECCIONE UNIDAD"
                />
              </div>
            </div>
            <div className=" flex flex-row items-center">
              <button
                className={ESTILO_BOTON_LISTAR_NUEVO}
                onClick={handleBuscar}
              >
                BUSCAR
              </button>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_ATRAS}
                onClick={openModalCondiguracion}
                title="CONFIGURAR GRILLA"
              >
                <Settings />
              </button>
            </div>
          </div>
          <HorizontalLine />
          <div>
            <Grid
              rows={listaCumplimientoDeViaje}
              headers={gridHeaders}
              exportar={exportar}
              fontSize={fontSizeValue}
              align={alignValue}
            />
          </div>
          <ModalConfiguracionGrilla
            modalConfiguracionVisible={modalConfiguracionVisible}
            onHideModalConfiguracion={onHideModalConfiguracion}
            ascendente={ascendente}
            descendente={descendente}
            setAscendente={setAscendente}
            setDescendente={setDescendente}
            gridHeaders={gridHeaders}
            setGridHeaders={setGridHeaders}
            onClickAcceptarOrdenGrillaModalConfiguracion={
              onClickAcceptarOrdenGrillaModalConfiguracion
            }
            onClickAcceptarHeadersGrillaModalConfiguracion={
              onClickAcceptarHeadersGrillaModalConfiguracion
            }
            onClickAcceptarFontSizeModalConfiguracion={
              onClickAcceptarFontSizeModalConfiguracion
            }
            fontSize={fontSizeValue}
            align={alignValue}
            onClickAcceptarAlignModalConfiguracion={
              onClickAcceptarAlignModalConfiguracion
            }
          />
        </div>
      );
    } catch (err) {
      console.log("CumplimientoDeViaje render err");
      console.log(err);
      return (
        <div className=" w-1/2 m-auto px-[2rem] ">
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  };

  return render();
}

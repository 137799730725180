import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Settings } from "feather-icons-react/build/IconComponents";
import Grid from "../../../components/grid";
import { ESTILO_BOTON_GRILLA_NEXT, ESTILO_BOTON_GRILLA_PREVIOUS, ESTILO_BOTON_LISTAR_ATRAS, ESTILO_BOTON_LISTAR_NUEVO } from "../../../utils/const-estilos";
import { capitalize, compareHeaders } from "../../../share/shared-functions";
import "../../../style/style-cargando.css";
import { deshabilitarSubTipoCarroceria, obtenerListaSubTipoCarroceria } from "../../../api/checklist";
import ModalConfirmar from "../../../components/modal-confirmar";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../../api/permisos/permisos";
import {
  INT_ID_MODULO_CHECKLIST,
  INT_ID_SEGUNDO_NIVEL_MIS_CHECKLIST,
  INT_ID_TERCER_NIVEL_CHECKLIST_TIPO_CARROCERIA,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../../utils/const-int";
import { TRUE } from "../../../utils/const-bool";
import { LISTAR_SUB_TIPO_CARROCERIA } from "../../../utils/const-screen-name";
import { actualizarHeadersGestionarDisponibilidadScreen, actualizarOrdenGestionarDisponibilidadScreen, guardarConfiguracionScreen, obtenerConfiguracionScreen } from "../../../api/configuracion-screen/configuracion-screen";
import ModalConfiguracionGrilla from "../../../components/modal-configuracion-grilla";

const idTipoUsuario = parseInt(localStorage.getItem("tipo"));
let accesoBotonNuevo = 'none';

export default function ListarSubTipoCarroceria() {
  const location = useLocation();
  const history = useHistory();
  const headers = [
    { key: "acciones", title: "Acciones", id: 1, visible: true, },
    { key: "id", title: "ID", id: 2, visible: false, },
    { key: "descripcion", title: "Descripcion", filter: true, id: 3, visible: true, },
    { key: "fechaproceso", title: "Fecha Proceso", filter: true, id: 4, visible: true, },
  ];
  const [minRows, setMinRows] = useState(0);
  const [maxRows, setMaxRows] = useState(5);
  const [tipoCarroceria, setTipoCarroceria] = useState("");
  const [subTipoCarroceriaSelected, setSubTipoCarroceria] = useState(null);
  const [idTipoCarroceria, setIdTipoCarroceria] = useState(0);
  const [cargandoPage, setCargandoPage] = useState(true);
  const [listaSubTipoCarroceria, setListaSubTipoCarroceria] = useState([]);
  const [modalDeshabilitarSubTipoCarrocerriaVisible, setModalDeshabilitarSubTipoCarrocerriaVisible] = useState(false);
  const [permisos, setPermisos] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);

  const [exportar, setExportar] = useState(true);
  const [modalConfiguracionVisible, setModalConfiguracionVisible] = useState(false);
  const [configuracionOrden, setConfiguracionOrden] = useState(null);
  const [ascendente, setAscendente] = useState(false);
  const [descendente, setDescendente] = useState(false);
  const [gridHeaders, setGridHeaders] = useState(headers);

  useEffect(() => {
    console.log("entro a listar Tipo Sub Tipo Carroceria");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      const data = location.state;
      if (data !== undefined && data !== null) {
        /**params tiene el id del tipo carroceria, no necesito
         * que se actualize la pagina, que opcion usar diferente 
         * a useState si no quiero que se actualize la pagina?
         */
        let objPermisos = {};
        objPermisos.idMenuPrimerNivel = INT_ID_MODULO_CHECKLIST;
        objPermisos.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_MIS_CHECKLIST;
        objPermisos.idMenuTercerNivel = INT_ID_TERCER_NIVEL_CHECKLIST_TIPO_CARROCERIA;
        let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(objPermisos);
        let permiso = null;
        if (obtenerPermisos.length > 0) {
          permiso = obtenerPermisos[0];
          setPermisos(permiso);
        }

        const configuracionScreenInfo = await obtenerConfiguracionScreen({ nombre: LISTAR_SUB_TIPO_CARROCERIA });
        console.log('configuracionScreenInfo');
        console.log(configuracionScreenInfo);
        let objConfiguracionEnviar = {};
        objConfiguracionEnviar.descendente = true;
        objConfiguracionEnviar.ascendente = false;
        if (configuracionScreenInfo !== null) {
          const configuracionOrden = JSON.parse(configuracionScreenInfo.orden);
          console.log('configuracionOrden');
          console.log(configuracionOrden);
          objConfiguracionEnviar.descendente = configuracionOrden.descendente;
          objConfiguracionEnviar.ascendente = configuracionOrden.ascendente;
          setAscendente(configuracionOrden.ascendente);
          setDescendente(configuracionOrden.descendente);
          let configuracionHeaders = null;
          if (configuracionScreenInfo.headers !== null) {
            configuracionHeaders = JSON.parse(configuracionScreenInfo.headers);
            console.log('configuracionHeaders');
            console.log(configuracionHeaders);
            const headerAux = compareHeaders(headers, configuracionHeaders);
            setGridHeaders(headerAux);
          }
        }
        setConfiguracionOrden(configuracionScreenInfo);
        let listaSubTipoCarroceria = [];

        setIdTipoCarroceria(data.idTipoCarroceria);
        setTipoCarroceria(data.nombreTipoCarroceria);
        let obj = {};
        obj.idTipoCarroceria = data.idTipoCarroceria;
        obj.descendente = objConfiguracionEnviar.descendente;
        obj.ascendente = objConfiguracionEnviar.ascendente;

        if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
          accesoBotonNuevo = '';
          setTieneAccesoAlModulo(true);
          listaSubTipoCarroceria = await obtenerListaSubTipoCarroceria(obj);
        }

        if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
          if (permiso.listarSubTipoCarroceria === TRUE) {
            setTieneAccesoAlModulo(true);
            listaSubTipoCarroceria = await obtenerListaSubTipoCarroceria(obj);
          }
        }
        procesarListaSubTipoCarroceria(listaSubTipoCarroceria, permiso);
      }
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
    }
  }

  const procesarListaSubTipoCarroceria = (lista, permiso) => {
    try {
      if (lista.length == 0) {
        setListaSubTipoCarroceria(lista);
      }
      if (lista.length > 0) {
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          let objModificar = {
            label: "Modificar",
            color: "green",
            onClick: (param) => modificarSubTipoCarroceria(param),
          };
          let objDeshabilitar = {
            label: "Deshabilitar",
            color: "red",
            onClick: (param) => cambiarEstado(param),
          };
          if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            acciones.push(objModificar);
            acciones.push(objDeshabilitar);
          }
          if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            if (permiso.modificarSubTipoCarroceria === TRUE) {
              acciones.push(objModificar);
            }
            if (permiso.eliminarDeshabilitarSubTipoCarroceria === TRUE) {
              acciones.push(objDeshabilitar);
            }
            if (permiso.crearSubTipoCarroceria === TRUE) {
              accesoBotonNuevo = '';
            }
          }
          lista[i].acciones = acciones;
        }
        setListaSubTipoCarroceria(lista);
      }
      setCargandoPage(false);
    } catch (err) {
      console.log('procesarListaSubTipoCarroceria err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const nextPage = (page, parametros) => {
    try {
      if (parametros !== undefined && parametros !== null) {
        if (parametros.acciones != undefined) {
          delete parametros.acciones;
        }
        history.push({
          pathname: `${page}`,
          state: parametros
        });
      } else {
        history.push(`${page}`);
      }
    } catch (err) {
      console.log('nextPage err');
      console.log(err);
    }
  };

  const handleNextPage = () => {
    if (maxRows >= listaSubTipoCarroceria.length) return;
    setMinRows(maxRows);
    setMaxRows(maxRows + 5);
  };

  const handlePrevPage = () => {
    if (minRows <= 0) return;
    setMaxRows(minRows);
    setMinRows(Math.max(minRows - 5, 0));
  };

  const modificarSubTipoCarroceria = (subTipoCarroceria) => {
    try {
      // console.log("modificarSubTipoCarroceria");
      // console.log(subTipoCarroceria);
      if (subTipoCarroceria.acciones !== undefined) {
        delete subTipoCarroceria.acciones;
      }
      let obj = {};
      let data = {};

      data.id = subTipoCarroceria.id;
      data.idtipocarroceria = subTipoCarroceria.idTipoCarroceria;
      data.tipoCarroceria = subTipoCarroceria.tipoCarroceria;
      data.subtipocarroceria = subTipoCarroceria.descripcion;

      obj.titulo = "Modificar Sub Tipo Carroceria";
      obj.params = data;
      // console.log('obj modificar');
      // console.log(obj);
      nextPage("sub-tipo-carroceria", obj);
    } catch (err) {
      console.log("editarTipoCarroceria err");
      console.log(err);
    }
  };

  const cambiarEstado = (data) => {
    try {
      // console.log("cambiarEstado");
      // console.log(data);
      setSubTipoCarroceria(data);
      setModalDeshabilitarSubTipoCarrocerriaVisible(true);
    } catch (err) {
      console.log("cambiarEstado err");
      console.log(err);
    }
  };

  const handleNuevoSubTipoClick = () => {
    // console.log("handleNuevoSubTipoClick");
    let obj = {};
    let data = {};

    data.id = 0;
    data.idtipocarroceria = idTipoCarroceria;
    data.tipoCarroceria = tipoCarroceria;
    data.subtipocarroceria = "";

    obj.titulo = "Crear Sub Tipo Carroceria";
    obj.params = data;
    nextPage("sub-tipo-carroceria", obj);
  }

  const handleAtrasClick = () => {
    nextPage('listar-tipo-carroceria', null);
  }

  const handleCancelDeshabilitarModal = () => {
    // console.log('handleCancelModal');
    setModalDeshabilitarSubTipoCarrocerriaVisible(false);
  };

  const handleConfirmDeshabilitarModal = async () => {
    try {
      setModalDeshabilitarSubTipoCarrocerriaVisible(false);
      console.log('subtipocarroceriaselected');
      console.log(subTipoCarroceriaSelected);
      let objDeshabilitar = {};
      objDeshabilitar.id = subTipoCarroceriaSelected.id;
      objDeshabilitar.idTipoCarroceria = subTipoCarroceriaSelected.idTipoCarroceria;
      let deshabilitado = await deshabilitarSubTipoCarroceria(objDeshabilitar);
      console.log("deshabilitado");
      console.log(deshabilitado);
      if (deshabilitado === false) {
        alert('No se pudo deshabilitar tipo carroceria');
      }
      if (deshabilitado === true) {
        alert('Se deshabilito correctmente');
        reset();
      }
    } catch (err) {
      console.log('handleConfirmModal err');
      console.log(err);
    }
  };

  const reset = async () => {
    setCargandoPage(true);
    let obj = {};
    obj.idTipoCarroceria = idTipoCarroceria;
    obj.descendente = descendente;
    obj.ascendente = ascendente;
    let listaSubTipoCarroceria = await obtenerListaSubTipoCarroceria(obj);
    // console.log('listaSubTipoCarroceria');
    // console.log(listaSubTipoCarroceria);
    procesarListaSubTipoCarroceria(listaSubTipoCarroceria);
  }

  const onHideModalConfiguracion = () => {
    setModalConfiguracionVisible(false);
  }

  const onClickAcceptarOrdenGrillaModalConfiguracion = async () => {
    try {
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = LISTAR_SUB_TIPO_CARROCERIA;
      objConfiguracionEnviar.orden = objOrden;
      console.log('configuracionOrden');
      console.log(configuracionOrden);
      if (configuracionOrden === null) {
        objConfiguracionEnviar.headers = gridHeaders;
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        const actualizo = await actualizarOrdenGestionarDisponibilidadScreen(objConfiguracionEnviar);
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log('onClickAcceptarOrdenGrillaModalConfiguracion err');
      console.log(err);
    }
  }

  const onClickAcceptarHeadersGrillaModalConfiguracion = async () => {
    try {
      console.log('onClickAcceptarHeadersGrillaModalConfiguracion');
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = LISTAR_SUB_TIPO_CARROCERIA;
      objConfiguracionEnviar.orden = objOrden;
      console.log('configuracionOrden');
      console.log(configuracionOrden);
      if (configuracionOrden === null) {
        objConfiguracionEnviar.headers = gridHeaders;
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        objConfiguracionEnviar.headers = gridHeaders;
        const actualizo = await actualizarHeadersGestionarDisponibilidadScreen(objConfiguracionEnviar);
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log('onClickAcceptarHeadersGrillaModalConfiguracion err');
      console.log(err);
    }
  }

  const openModalCondiguracion = () => {
    setModalConfiguracionVisible(true);
  }

  const render = () => {
    try {
      // console.log('listar tipo sub carroceria render');
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <label
              style={{
                paddingLeft: 10,
                fontWeight: 'bold',
              }}
            >
              {capitalize('tipo carroceria: ')} {capitalize(tipoCarroceria)}
            </label>
            <div>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_NUEVO}
                onClick={handleNuevoSubTipoClick}
                style={{
                  display: accesoBotonNuevo,
                }}
              >
                NUEVO SUB TIPO
              </button>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_ATRAS}
                onClick={handleAtrasClick}
              >
                ATRAS
              </button>
            </div>
          </div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={openModalCondiguracion}
            >
              <Settings />
            </button>
          </div>
          <Grid rows={listaSubTipoCarroceria} headers={gridHeaders} exportar={exportar} />
          <div
            style={{ paddingLeft: 10 }}
          >
            <button className={ESTILO_BOTON_GRILLA_PREVIOUS} onClick={handlePrevPage}>Previous</button>
            <button className={ESTILO_BOTON_GRILLA_NEXT} onClick={handleNextPage}>Next</button>
          </div>
          <ModalConfirmar
            isOpen={modalDeshabilitarSubTipoCarrocerriaVisible}
            onCancel={handleCancelDeshabilitarModal}
            onConfirm={handleConfirmDeshabilitarModal}
            message="¿Estás de crear Sub Tipo Carroceria?"
          />
          <ModalConfiguracionGrilla
            modalConfiguracionVisible={modalConfiguracionVisible}
            onHideModalConfiguracion={onHideModalConfiguracion}
            ascendente={ascendente}
            descendente={descendente}
            setAscendente={setAscendente}
            setDescendente={setDescendente}
            gridHeaders={gridHeaders}
            setGridHeaders={setGridHeaders}
            onClickAcceptarOrdenGrillaModalConfiguracion={onClickAcceptarOrdenGrillaModalConfiguracion}
            onClickAcceptarHeadersGrillaModalConfiguracion={onClickAcceptarHeadersGrillaModalConfiguracion}
          />
        </div>
      );
    } catch (err) {
      console.log('ListarTipoCarroceria render err');
      console.log(err);
      return (
        <div><label>{JSON.stringify(err)}</label></div>
      );
    }
  }

  return render();
}

import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import LayoutSeguimiento from "../../components/layout-seguimiento";
import LineaTiempo from "./linea-tiempo";
import VerEnMapa from ".";

const RouterSeguimiento = () => {
  const { path } = useRouteMatch();
  return (
    <LayoutSeguimiento>
      <Switch>
        <Route exact path={`${path}/linea-tiempo`}>
          <LineaTiempo />
        </Route>
        <Route path={`${path}`}>
          <VerEnMapa />
        </Route>
      </Switch>
    </LayoutSeguimiento>
  );
};

export default RouterSeguimiento;

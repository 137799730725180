import moment from "moment/moment";
import { SUCCESS } from "../page/const/http-const";
import { getHeaders } from "./utils";

export async function obtenerItinerario(obj) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/itinerario/obtener-itinerario/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            rows = body;
            for (let i = 0; i < rows.length; i++) {
                const element = rows[i];
                rows[i].fechaproceso = moment(element.fechaProceso).format(
                    "DD/MM/YYYY HH:mm"
                );
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerItinerario err");
        console.log(err);
        return [];
    }
}

export async function guardarItinerario(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/itinerario/guardar-itinerario/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("guardarItinerario err");
        console.log(err);
        return false;
    }
}

export async function modificarItinerario(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/itinerario/modificar-itinerario/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarItinerario err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarItinerario(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/itinerario/deshabilitar-itinerario/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarItinerario err");
        console.log(err);
        return false;
    }
}

export async function actualizarInicioFinGeoruta(obj) {
    try {
        await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/georuta/actualizar-inicio-fin-georuta/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        // if (code === SUCCESS) {
        //     rows = body;
        //     for (let i = 0; i < rows.length; i++) {
        //         const element = rows[i];
        //         rows[i].fechaproceso = moment(element.fechaProceso).format(
        //             "DD/MM/YYYY HH:mm"
        //         );
        //     }
        // }
        // return rows;
    } catch (err) {
        console.log("obtenerItinerario err");
        console.log(err);
        // return [];
    }
}
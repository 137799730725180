import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import LayoutEventos from "../../components/layout-eventos";
import GestionarIncidente from "./index";
import Incidente from "./incidente";

const RouterIncidente = () => {
  const { path } = useRouteMatch();
  return (
    <LayoutEventos>
      <Switch>

        <Route exact path={`${path}`}>
          <GestionarIncidente />
        </Route>
        <Route exact path={`${path}/incidente`}>
          <Incidente />
        </Route>

      </Switch>
    </LayoutEventos>
  );
};

export default RouterIncidente;

import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import ListarChecklist from "./index";
import GestionarChecklist from "./checklist/gestionar-checklist";
import AdministracionCheckList from "./administracion";

import ListarCorreos from "./correo/listar-correo";
import Correos from "./correo/correo";

import ListarTipoCarroceria from "./tipo-carroceria/listar-tipo-carroceria";
import ListarSubTipoCarroceria from "./tipo-carroceria/listar-sub-tipo-carroceria";
import Carroceria from "./tipo-carroceria/carroceria";
import SubTipoCarroceria from "./tipo-carroceria/sub-tipo-carroceria";

import LayoutChecklist from "../../components/layout-checklist";
import ListarOpcionesRespuesta from "./opciones-respuesta/listar-opciones-respuesta";
import OpcionesRespuesta from "./opciones-respuesta/opciones-respuesta";
import ListarMovimiento from "./movimiento/listar-movimiento";
import Movimiento from "./movimiento/movimiento";
import ListarTipoChecklist from "./tipo-checklist/listar-tipo-checklist";
import TipoChecklist from "./tipo-checklist/tipo-checklist";
import ListarChecklistIngreso from "./listar-checklist-ingreso";
import VerChecklistIngreso from "./ver-checklist-ingreso";
import ListarChecklistSalida from "./listar-checklist-salida";
import VerChecklistSalida from "./ver-checklist-salida";
import ListarIconos from "./gestionar-iconos/listar-iconos";
import Icono from "./gestionar-iconos/icono";
import SeleccionarGrupoGeocercas from "./seleccionar-grupo-geocerca";
import AsignarCorreoAGeocerca from "./asignar-correo-a-geocerca";
import ReportesChecklist from "./reportes/reportes-checklist";
import ReporteConsolidadoIngreso from "./reportes/reporte-consolidado-ingreso";
import ReporteConsolidadoSalida from "./reportes/reporte-consolidado-salida";
import ReporteConsolidadoPrecinto from "./reportes/reporte-consolidado-precinto";

const RouterChecklist = () => {
  const { path } = useRouteMatch();
  return (
    <LayoutChecklist>
      <Switch>

        <Route path={`${path}/listar-tipo-carroceria`}>
          <ListarTipoCarroceria />
        </Route>
        <Route path={`${path}/listar-sub-tipo-carroceria`}>
          <ListarSubTipoCarroceria />
        </Route>
        <Route path={`${path}/carroceria`}>
          <Carroceria />
        </Route>
        <Route path={`${path}/sub-tipo-carroceria`}>
          <SubTipoCarroceria />
        </Route>

        <Route path={`${path}/listar-correo`}>
          <ListarCorreos />
        </Route>
        <Route path={`${path}/correo`}>
          <Correos />
        </Route>

        <Route path={`${path}/listar-opciones-respuesta`}>
          <ListarOpcionesRespuesta />
        </Route>
        <Route path={`${path}/opciones-respuesta`}>
          <OpcionesRespuesta />
        </Route>

        <Route path={`${path}/listar-movimiento`}>
          <ListarMovimiento />
        </Route>
        <Route path={`${path}/movimiento`}>
          <Movimiento />
        </Route>

        <Route exact path={`${path}/administracion`}>
          <AdministracionCheckList />
        </Route>
        <Route exact path={`${path}/gestionar-checklist`}>
          <GestionarChecklist />
        </Route>
        <Route exact path={`${path}/listar-checklist-ingreso`}>
          <ListarChecklistIngreso />
        </Route>
        <Route exact path={`${path}/ver-checklist-ingreso`}>
          <VerChecklistIngreso />
        </Route>
        <Route exact path={`${path}/listar-checklist-salida`}>
          <ListarChecklistSalida />
        </Route>
        <Route exact path={`${path}/ver-checklist-salida`}>
          <VerChecklistSalida />
        </Route>

        <Route path={`${path}/listar-tipo-checklist`}>
          <ListarTipoChecklist />
        </Route>
        <Route path={`${path}/tipo-checklist`}>
          <TipoChecklist />
        </Route>

        <Route path={`${path}/listar-iconos`}>
          <ListarIconos />
        </Route>
        <Route path={`${path}/icono`}>
          <Icono />
        </Route>
        <Route path={`${path}/seleccionar-grupo-geocerca`}>
          <SeleccionarGrupoGeocercas />
        </Route>
        <Route path={`${path}/asignar-correo-a-geocerca`}>
          <AsignarCorreoAGeocerca />
        </Route>
        <Route path={`${path}/reportes-checklist`}>
          <ReportesChecklist />
        </Route>
        <Route path={`${path}/reporte-consolidado-ingreso`}>
          <ReporteConsolidadoIngreso />
        </Route>
        <Route path={`${path}/reporte-consolidado-salida`}>
          <ReporteConsolidadoSalida />
        </Route>
        <Route path={`${path}/reporte-consolidado-precinto`}>
          <ReporteConsolidadoPrecinto />
        </Route>

        <Route exact path={`${path}`}>
          <ListarChecklist />
        </Route>
      </Switch>
    </LayoutChecklist>
  );
};

export default RouterChecklist;

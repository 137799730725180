import moment from "moment/moment";
import { SUCCESS } from "../../page/const/http-const";
import { getHeaders } from "../utils";

export async function obtenerListaUsuariosAsignadosAGeocerca() {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracion/obtener-lista-usuarios-asignados-a-geocerca/",
            {
                headers: getHeaders(),
                method: "POST",
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            for (let i = 0; i < body.length; i++) {
                body[i].fechaproceso = moment(body[i].fechaProceso).format('DD/MM/YYYY HH:mm');
                body[i].fecha = moment(body[i].fecha).format('DD/MM/YYYY');
            }
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaUsuariosAsignadosAGeocerca err");
        console.log(err);
        return [];
    }
}

export async function guardarUsuariosAsignadoAGeocerca(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracion/guardar-usuarios-asignado-a-geocerca/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("guardarUsuariosAsignadoAGeocerca err");
        console.log(err);
        return false;
    }
}

export async function modificarListaUsuariosAsignadosAGeocerca(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracion/modificar-lista-usuarios-asignados-a-geocerca/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarListaUsuariosAsignadosAGeocerca err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarListaUsuariosAsignadosAGeocerca(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracion/deshabilitar-lista-usuarios-asignados-a-geocerca/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarListaUsuariosAsignadosAGeocerca err");
        console.log(err);
        return false;
    }
}

export async function obtenerListaTiempoCarga() {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracion/obtener-lista-tiempo-carga/",
            {
                headers: getHeaders(),
                method: "POST",
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            for (let i = 0; i < body.length; i++) {
                body[i].fechaproceso = moment(body[i].fechaProceso).format('DD/MM/YYYY HH:mm');
            }
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaTiempoCarga err");
        console.log(err);
        return [];
    }
}

export async function guardarTiempoCarga(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracion/guardar-tiempo-carga/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("guardarTiempoCarga err");
        console.log(err);
        return false;
    }
}

export async function modificarConfiguracionTiempoCarga(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracion/modificar-tiempo-carga/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarConfiguracionTiempoCarga err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarConfiguracionTiempoCarga(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracion/deshabilitar-tiempo-carga/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarConfiguracionTiempoCarga err");
        console.log(err);
        return false;
    }
}

export async function obtenerListaTiempoDescarga() {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracion/obtener-lista-tiempo-descarga/",
            {
                headers: getHeaders(),
                method: "POST",
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            for (let i = 0; i < body.length; i++) {
                body[i].fechaproceso = moment(body[i].fechaProceso).format('DD/MM/YYYY HH:mm');
            }
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaTiempoDescarga err");
        console.log(err);
        return [];
    }
}

export async function guardarTiempoDescarga(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracion/guardar-tiempo-descarga/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("guardarTiempoDescarga err");
        console.log(err);
        return false;
    }
}

export async function modificarConfiguracionTiempoDescarga(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracion/modificar-tiempo-descarga/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarConfiguracionTiempoDescarga err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarConfiguracionTiempoDescarga(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracion/deshabilitar-tiempo-descarga/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarConfiguracionTiempoDescarga err");
        console.log(err);
        return false;
    }
}

export async function obtenerListaTipoDetencionDestino() {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracion/obtener-lista-tipo-detencion-destino/",
            {
                headers: getHeaders(),
                method: "POST",
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            for (let i = 0; i < body.length; i++) {
                body[i].fechaproceso = moment(body[i].fechaProceso).format('DD/MM/YYYY HH:mm');
            }
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaTipoDetencionDestino err");
        console.log(err);
        return [];
    }
}

export async function guardarTipoDetencionDestino(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracion/guardar-tipo-detencion-destino/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("guardarTiempoDescarga err");
        console.log(err);
        return false;
    }
}

export async function modificarTipoDetencionDestino(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracion/modificar-tipo-detencion-destino/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarConfiguracionTiempoDescarga err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarTipoDetencionDestino(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/configuracion/deshabilitar-tipo-detencion-destino/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarTipoDetencionDestino err");
        console.log(err);
        return false;
    }
}

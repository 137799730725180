import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";

export default function Administracion() {
  const [dataTest, setDataTest] = useState([
    {
      fecha: "2024-09-23",
      nombre: "Salida de ruta La Paz",
      tipo: "Seguimiento",
    },
    {
      fecha: "2024-09-22",
      nombre: "Salida de ruta Ilo",
      modulo: "Seguimiento",
    },
  ]);
  const history = useHistory();
  const nextPage = (page, params) => {
    try {
      if (params !== undefined && params !== null) {
        if (params.acciones !== undefined) {
          delete params.acciones;
        }
        history.push({
          pathname: `${page}`,
          state: params,
        });
      } else {
        history.push(`${page}`);
      }
    } catch (e) {
      console.error(e.message);
    }
  };
  useEffect(() => {}, []);
  return (
    <div>
      <div className="p-[2rem] flex justify-between">
        <div className="w-2/4 ">
          <h1 className="font-bold text-gray-700 text-[24px]">
            Creacion de alertas
          </h1>
          <h3>Crea tus alertas</h3>
        </div>
        <div className="flex justify-end">
          <div>
            <button
              className="my-auto bg-[#113A5F] rounded text-white p-[5px] px-[15px] mx-[10px]"
              onClick={() => {
                nextPage("/alertas");
              }}
            >
              Atras
            </button>
          </div>
          <div>
            <button
              className="my-auto bg-[#113A5F] rounded text-white p-[5px] px-[15px]"
              onClick={() => {
                //guardar();
              }}
            >
              Modificar
            </button>
          </div>
        </div>
      </div>
      <div className="flex w-full px-8 pt-8 justify-left">
        <div className="w-1/3 mr-10">
          <div>
            <div>Nombre</div>
            <div>
              <input
                type="text"
                className="block w-full p-2 rounded-md border-2 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={"" /*data ? data.codigoOrigen : ""*/}
                onChange={(e) => {
                  //setData({ ...data, codigoOrigen: e.currentTarget.value });
                }}
              />
            </div>
          </div>
          <div>
            <div>Modulo</div>
            <div>
              <Select
                //options={listaGeocerca}
                isSearchable="true"
                isClearable="true"
                //value={data ? data.depositoOrigen : ""}
                /*onChange={(e) => {
                  setData({
                    ...data,
                    depositoOrigen: e.label,
                    codigoOrigen: e.codigo,
                  });
                }}*/
                /*placeholder={
                  data ? data.depositoOrigen : "Seleccione un Deposito origen"
                }*/
              />
            </div>
          </div>
          <div>
            <div>Tipo</div>
            <div>
              <Select
                //options={listaGeocerca}
                isSearchable="true"
                isClearable="true"
                //value={data ? data.depositoOrigen : ""}
                /*onChange={(e) => {
                  setData({
                    ...data,
                    depositoOrigen: e.label,
                    codigoOrigen: e.codigo,
                  });
                }}*/
                /*placeholder={
                  data ? data.depositoOrigen : "Seleccione un Deposito origen"
                }*/
              />
            </div>
          </div>
          <div>
            <div>Horas de retraso</div>
            <div>
              <input
                type="text"
                className="block w-full p-2 rounded-md border-2 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={"" /*data ? data.codigoOrigen : ""*/}
                onChange={(e) => {
                  //setData({ ...data, codigoOrigen: e.currentTarget.value });
                }}
              />
            </div>
          </div>
          <div>
            <div>Correos a enviar</div>
            <div>
              <input
                type="text"
                className="block w-full p-2 rounded-md border-2 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={"" /*data ? data.codigoOrigen : ""*/}
                onChange={(e) => {
                  //setData({ ...data, codigoOrigen: e.currentTarget.value });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { urlGestion } from "../../conexion/conexion";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export async function obtenerTodasLasUnidades() {
  try {
    let token = localStorage.getItem("accessToken");
    let obj = {};
    obj.token = token;
    let rows = [];
    let objDatos = {
      data_op: obj,
      op: "READ-OBTENERTODASLASUNIDADES",
    };
    let data = await fetch(urlGestion, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(objDatos),
    }).then((res) => res.json());
    // console.log('data');
    // console.log(data);
    if (data.error === false && data.data !== undefined) {
      rows = data.data;
    }
    return rows;
  } catch (err) {
    console.log("obtenerTodasLasUnidades err");
    console.log(err);
    return [];
  }
}

export async function obtenerInfoConductorConNumeroPlaca(obj) {
  try {
    let token = localStorage.getItem("accessToken");
    obj.token = token;
    let info = null;
    let objDatos = {
      data_op: obj,
      op: "READ-OBTENERINFOCONDUCTORCONNUMEROPLACA",
    };
    let data = await fetch(urlGestion, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(objDatos),
    }).then((res) => res.json());
    // console.log('data');
    // console.log(data);
    // debugger;
    if (data.error === false && data.data !== undefined) {
      info = data.data[0] !== undefined ? data.data[0] : null;
    }
    return info;
  } catch (err) {
    console.log("obtenerInfoConductorConNumeroPlaca err");
    console.log(err);
    return null;
  }
}

export async function obtenerInfoGrupoSubGrupo() {
  try {
    let token = localStorage.getItem("accessToken");
    let rows = [];
    let objDatos = {
      data_op: token,
      op: "READ-GRUPO",
    };
    let data = await fetch(urlGestion, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(objDatos),
    }).then((res) => res.json());
    // console.log('data');
    // console.log(data);
    if (data.error === false && data.data !== undefined) {
      rows = data.data;
    }
    if (rows[0] !== undefined) {
      // console.log('traer info subgrupo de cada uno');
      // console.log('rows');
      // console.log(rows);
      const idsGrupo = rows.map(item => `'${item.idGrupo}'`).join(',');
      let objEnviar = {};
      objEnviar.token = token;
      objEnviar.idGrupo = idsGrupo;
      const infoSubGrupo = await obtenerInfoSubGrupo(objEnviar);
      // console.log('infoSubGrupo');
      // console.log(infoSubGrupo);
      // const cantidad = rows.length;
      for (let i = 0; i < rows.length; i++) {
        const element = rows[i];
        rows[i].checked = false;
        const subGrupo = infoSubGrupo.filter(e => e.idGrupo === element.idGrupo);
        rows[i].listaSubGrupo = subGrupo;
      }
    }
    return rows;
  } catch (err) {
    console.log("obtenerTodasLasUnidades err");
    console.log(err);
    return [];
  }
}

export async function obtenerInfoSubGrupo(objEnviar) {
  try {
    let rows = [];
    let objDatos = {
      data_op: objEnviar,
      op: "READ-SUBGRUPOMULTIPLE",
    };
    let data = await fetch(urlGestion, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(objDatos),
    }).then((res) => res.json());
    // console.log('data');
    // console.log(data);
    if (data.error === false && data.data !== undefined) {
      rows = data.data;
    }
    if (rows[0] !== undefined) {
      for (let i = 0; i < rows.length; i++) {
        rows[i].checked = false;
      }
    }
    return rows;
  } catch (err) {
    console.log("obtenerInfoSubGrupo err");
    console.log(err);
    return [];
  }
}

export async function obtenerInfoGeocerca(obj) {
  try {
    let rows = [];
    obj.token = localStorage.getItem("accessToken");
    let objDatos = {
      data_op: obj,
      op: "READ-OBTENERINFOGEOCERCA",
    };
    let data = await fetch(urlGestion, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(objDatos),
    }).then((res) => res.json());
    // console.log('data');
    // console.log(data);
    if (data.error === false && data.data !== undefined) {
      rows = data.data;
    }
    if (rows[0] !== undefined) {
      for (let i = 0; i < rows.length; i++) {
        rows[i].checked = false;
      }
    }
    return rows;
  } catch (err) {
    console.log("obtenerInfoGeocerca err");
    console.log(err);
    return [];
  }
}

export async function obtenerListadoGeocercas() {
  try {
    const obj = localStorage.getItem("accessToken");
    let objDatos = {
      data_op: obj,
      op: "READ-GEOCERCAS",
    };
    let data = await fetch(urlGestion, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(objDatos),
    }).then((res) => res.json());

    return data;
  } catch (err) {
    console.log("obtenerInfoGeocerca err");
    console.log(err);
    return [];
  }
}

export async function obtenerGeocercaConCodigoYNombre(obj) {
  try {
    obj.token = localStorage.getItem("accessToken");
    let objDatos = {
      data_op: obj,
      op: "READ-GEOCERCASXCODIGOYNOMBRE",
    };
    let data = await fetch(urlGestion, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(objDatos),
    }).then((res) => res.json());

    return data;
  } catch (err) {
    console.log("obtenerInfoGeocerca err");
    console.log(err);
    return [];
  }
}

export async function obtenerGeocercaXId(obj) {
  try {
    obj.token = localStorage.getItem("accessToken");
    let objDatos = {
      data_op: obj,
      op: "READ-GEOCERCA-ID",
    };
    let data = await fetch(urlGestion, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(objDatos),
    }).then((res) => res.json());

    return data;
  } catch (err) {
    console.log("obtenerInfoGeocerca err");
    console.log(err);
    return [];
  }
}

export async function obtenerGrupoGeocerca() {
  try {
    let token = localStorage.getItem("accessToken");
    let rows = [];
    let obj = { token: token };
    let objDatos = {
      data_op: obj,
      op: "READ-OBTENERGRUPOGEOCERCA",
    };
    let data = await fetch(urlGestion, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(objDatos),
    }).then((res) => res.json());
    // console.log('data');
    // console.log(data);
    if (data.error === false && data.data !== undefined) {
      rows = data.data;
    }
    return rows;
  } catch (err) {
    console.log("obtenerGrupoGeocerca err");
    console.log(err);
    return [];
  }
}

export async function obtenerInfoGeocercaMedianteIdGrupo(obj) {
  try {
    let token = localStorage.getItem("accessToken");
    let rows = [];
    obj.token = token;
    let objDatos = {
      data_op: obj,
      op: "READ-OBTENERINFOGEOCERCAMEDIANTEIDGRUPO",
    };
    let data = await fetch(urlGestion, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(objDatos),
    }).then((res) => res.json());
    // console.log('data');
    // console.log(data);
    if (data.error === false && data.data !== undefined) {
      rows = data.data;
    }
    return rows;
  } catch (err) {
    console.log("obtenerInfoGeocercaMedianteIdGrupo err");
    console.log(err);
    return [];
  }
}

export async function obtenerPuntoActualVehiculoXGrupoPlaca(obj) {
  try {
    let token = localStorage.getItem("accessToken");
    let rows = [];
    obj.token = token;
    let objDatos = {
      data_op: obj,
      op: "READ-OBTENERPUNTOACTUALVEHICULOXGRUPOPLACA",
    };
    let data = await fetch(urlGestion, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(objDatos),
    }).then((res) => res.json());
    // console.log('data');
    // console.log(data);
    if (data.error === false && data.data !== undefined) {
      rows = data.data;
    }
    return rows;
  } catch (err) {
    console.log("obtenerPuntoActualVehiculoXGrupoPlaca err");
    console.log(err);
    return [];
  }
}
//READ-OBTENERPUNTOACTUALVEHICULOXPLACA
export async function obtenerPuntoActualVehiculoXPlaca(obj) {
  try {
    let token = localStorage.getItem("accessToken");
    let rows = [];
    obj.token = token;
    let objDatos = {
      data_op: obj,
      op: "READ-OBTENERPUNTOACTUALVEHICULOXPLACA",
    };
    let data = await fetch(urlGestion, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(objDatos),
    }).then((res) => res.json());
    // console.log('data');
    // console.log(data);
    if (data.error === false && data.data !== undefined) {
      rows = data.data;
    }
    return rows;
  } catch (err) {
    console.log("obtenerPuntoActualVehiculoXPlaca err");
    console.log(err);
    return [];
  }
}

export async function obtenerHistorico(obj) {
  try {
    let token = localStorage.getItem("accessToken");
    let rows = [];
    obj.token = token;
    let objDatos = {
      data_op: obj,
      op: "READ-HISTORICO",
    };
    let data = await fetch(urlGestion, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(objDatos),
    }).then((res) => res.json());
    // console.log('data');
    // console.log(data);
    if (data.error === false && data.data !== undefined) {
      rows = data.data;
    }
    return rows;
  } catch (err) {
    console.log("obtenerHistorico err");
    console.log(err);
    return [];
  }
}

export async function obtenerCoordenadasGeocercas(obj) {
  try {
    let token = localStorage.getItem("accessToken");
    let rows = [];
    obj.token = token;
    let objDatos = {
      data_op: obj,
      op: "READ-OBTENERCOORDENADASGEOCERCAS",
    };
    let data = await fetch(urlGestion, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(objDatos),
    }).then((res) => res.json());
    // console.log('data');
    // console.log(data);
    if (data.error === false && data.data !== undefined) {
      rows = data.data;
    }
    return rows;
  } catch (err) {
    console.log("obtenerCoordenadasGeocercas err");
    console.log(err);
    return [];
  }
}

export async function obtenerComboGeocerca(obj) {
  try {
    let token = localStorage.getItem("accessToken");
    let rows = [];
    obj.token = token;
    let objDatos = {
      data_op: obj,
      op: "READ-COMBOGEOCERCA",
    };
    let data = await fetch(urlGestion, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(objDatos),
    }).then((res) => res.json());
    // console.log('data');
    // console.log(data);
    if (data.error === false && data.data !== undefined) {
      rows = data.data;
    }
    return rows;
  } catch (err) {
    console.log("obtenerComboGeocerca err");
    console.log(err);
    return [];
  }
}
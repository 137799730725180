import { getHeaders } from "./utils";

export async function guardarConfiguracion(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/cargas/configuracion",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("Guardar Configuracion Cargas err");
    console.log(err);
    return [];
  }
}

export async function modificarConfiguracion(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/cargas/configuracion",
      {
        headers: getHeaders({ authenticate: true }),
        method: "PUT",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("Modificar Configuracion Cargas err");
    console.log(err);
    return [];
  }
}

export async function obtenerConfiguracion() {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/cargas/configuracion",
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("Obtener Configuracion Cargas err");
    console.log(err);
    return [];
  }
}

export async function obtenerCargas(data) {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/despachoapi/api/cargas/registros`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("Obtener Cargas err");
    console.log(err);
    return [];
  }
}

export async function guardarMensaje(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/cargas/configuracion-mensaje-carga",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("guardar mensaje carga err");
    console.log(err);
    return [];
  }
}

export async function modificarMensaje(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/cargas/configuracion-mensaje-carga",
      {
        headers: getHeaders({ authenticate: true }),
        method: "PUT",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("modificar mensaje carga err");
    console.log(err);
    return [];
  }
}

export async function obtenerMensaje() {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/cargas/configuracion-mensaje-carga",
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("obtener mensaje carga err");
    console.log(err);
    return [];
  }
}

export async function obtenerCargasEmpresa(data) {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/despachoapi/api/cargas/registros-empresa`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("Obtener Cargas err");
    console.log(err);
    return [];
  }
}
export async function obtenerCargasPlanilla(data) {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/despachoapi/api/cargas/registros-planilla`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("Obtener Cargas err");
    console.log(err);
    return [];
  }
}

export async function obtenerListadoImportaciones() {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/despachoapi/api/cargas/`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("Obtener Cargas err");
    console.log(err);
    return [];
  }
}
export async function obtenerHorarioCargas(idGeocerca) {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/despachoapi/api/cargas/horarios-geocerca/${idGeocerca}`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("Obtener Cargas err");
    console.log(err);
    return [];
  }
}

export async function obtenerCantidadRegistrosEnHorario(codigoOrigen, origen) {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/despachoapi/api/cargas/cantidad-registros-horario/${codigoOrigen}/${origen}`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("Obtener Cargas err");
    console.log(err);
    return [];
  }
}

export async function guardarHorarioCargas(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/cargas/guardar-horario",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("guardar horario Cargas err");
    console.log(err);
    return [];
  }
}
export async function eliminarHorarioCargas(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/cargas/eliminar-horario",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("eliminar horario Cargas err");
    console.log(err);
    return [];
  }
}
export async function guardarCargas(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/cargas/",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("guardar Cargas err");
    console.log(err);
    return [];
  }
}

export async function enviarSMSCargas(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/cargas/enviar-sms-cargas",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("guardar Cargas err");
    console.log(err);
    return [];
  }
}

export async function enviarSMSCarga(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/cargas/enviar-sms-carga",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("guardar Cargas err");
    console.log(err);
    return [];
  }
}
export async function guardarCargasEnRegistroExistente(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/cargas/guardar-en-registro-existente",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("guardar Cargas err");
    console.log(err);
    return [];
  }
}

export async function modificarCargas(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/cargas/",
      {
        headers: getHeaders({ authenticate: true }),
        method: "PUT",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("modificar Cargas err");
    console.log(err);
    return [];
  }
}

export async function modificarCargasPlanilla(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/cargas/planilla",
      {
        headers: getHeaders({ authenticate: true }),
        method: "PUT",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("modificar Cargas err");
    console.log(err);
    return [];
  }
}
export async function modificarHorarioCargas(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/cargas/modificar-horario",
      {
        headers: getHeaders({ authenticate: true }),
        method: "PUT",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("modificar Cargas err");
    console.log(err);
    return [];
  }
}
export async function modificarVariasCargas(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/cargas/modificar-varios",
      {
        headers: getHeaders({ authenticate: true }),
        method: "PUT",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("modificar Cargas err");
    console.log(err);
    return [];
  }
}

//
export async function obtenerEnvioData(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/cargas/obtener-envio-sms",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("modificar sms data err");
    console.log(err);
    return [];
  }
}
export async function eliminarCarga(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/cargas/eliminar-carga",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("eliminar horario Descargas err");
    console.log(err);
    return [];
  }
}
export async function eliminarCargaId(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/cargas/eliminar-carga-id",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("eliminar horario Descargas err");
    console.log(err);
    return [];
  }
}
export async function eliminarCargaCodigoViaje(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/cargas/eliminar-carga-codigo-viaje",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("eliminar horario Descargas err");
    console.log(err);
    return [];
  }
}
//

import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import {
  guardarHorarioDescargas,
  obtenerHorarioDescargas,
  modificarHorarioDescargas,
  eliminarHorarioDescargas,
} from "../../api/descargas";
import { obtenerListadoGeocercas } from "../../api/gestion-dts/gestion";
import moment from "moment";
import "moment-timezone";

export default function ConfiguracionHorarios() {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [modificar, setModificar] = useState({});
  const [geocerca, setGeocerca] = useState(null);
  const [listaGeocerca, setListaGeocerca] = useState([]);
  const [listaHorario, setListaHorario] = useState([]);
  const [horario, setHorario] = useState({
    id: null,
    espacios: null,
    horario: null,
  });
  const [nuevoHorario, setNuevoHorario] = useState(false);

  const obtenerGeocercas = async () => {
    const resp = await obtenerListadoGeocercas();
    const lista = resp.data.map((data) => {
      return { value: data.id, codigo: data.codigo, label: data.nombre };
    });
    setListaGeocerca(lista);
  };
  const guardarHorario = async () => {
    const resp = await guardarHorarioDescargas({
      ...horario,
      idGeocerca: geocerca.value,
    });
    obtenerHorario();
    alert("Se guardo el horario correctamente");
  };
  const eliminarHorario = async (id) => {
    const resp = await eliminarHorarioDescargas({ id });
    obtenerHorario();
    alert("Se elimino el horario correctamente");
  };
  const obtenerHorario = async () => {
    const resp = await obtenerHorarioDescargas(geocerca.value);
    if (Array.isArray(resp.body)) {
      setListaHorario(
        resp.body.sort((a, b) => {
          const horaA = parseInt(a.horario.split(":").join(""));
          const horaB = parseInt(b.horario.split(":").join(""));
          return horaA - horaB;
        })
      );
    }
  };
  const modificarHorario = async () => {
    const resp = await modificarHorarioDescargas(horario);
    obtenerHorario();
    alert("Se modifico el horario correctamente");
  };
  useEffect(() => {
    obtenerGeocercas();
  }, []);
  useEffect(() => {
    if (geocerca !== null) {
      obtenerHorario();
    }
  }, [geocerca]);
  return (
    <div className="p-[2rem]">
      <div className="flex">
        <div className="w-2/3">
          <h1 className="font-bold text-gray-700 text-[24px]">
            Configuracion de Horarios
          </h1>
          <h3>
            Configura los horarios para descargas de los puntos de descarga
          </h3>
        </div>
        <div className="flex w-1/3 justify-end">
          <input
            id="fileUpload"
            type="file"
            className="my-auto text-white p-[5px] px-[10px] mr-[10px] hidden"
          />
          <button
            className="my-auto bg-[#84B044] rounded text-white p-[5px] px-[10px] mr-[10px]"
            onClick={() => {
              setNuevoHorario(!nuevoHorario);
            }}
          >
            {nuevoHorario ? "Cancelar" : "Agregar"}
          </button>
        </div>
      </div>
      <div className=" w-full px-8 pt-8 justify-left">
        <div className="w-1/3 mr-10">
          <div>
            <div>Geocerca</div>
            <div>
              <Select
                options={listaGeocerca}
                isSearchable="true"
                isClearable="true"
                value={geocerca}
                onChange={(e) => {
                  setGeocerca(e);
                }}
                placeholder={"Seleccione una geocerca para descarga"}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="flex border-b-[1px] border-gray-400 w-1/2 mt-[2rem]">
            <div className="w-1/3">Docks</div>
            <div className="w-1/3">Hora</div>
            <div className="w-1/3"></div>
          </div>
          <div>
            {nuevoHorario && (
              <div className="flex w-1/2">
                <input
                  type="number"
                  min={0}
                  className="block w-1/3 p-2 rounded-md border-2 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  onChange={(e) => {
                    setHorario({ ...horario, espacios: e.currentTarget.value });
                  }}
                ></input>
                <input
                  type="time"
                  className="block w-1/3 p-2 rounded-md border-2 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  onChange={(e) => {
                    setHorario({ ...horario, horario: e.currentTarget.value });
                  }}
                ></input>
                <div className="w-1/3 flex">
                  <button
                    className="my-auto bg-[#113A5F] w-[87px] rounded text-white p-[5px] px-[10px] ml-[10px]"
                    onClick={() => {
                      guardarHorario();
                    }}
                  >
                    Guardar
                  </button>
                </div>
              </div>
            )}
            {listaHorario.map((data, index) => (
              <div>
                {modificar[index] == false || modificar[index] == undefined ? (
                  <div className="flex w-1/2 mt-[5px]">
                    <div className="w-1/3 p-2">{data.espacios}</div>
                    <div className="w-1/3 p-2">{data.horario}</div>
                    <div className="w-1/3 flex">
                      <button
                        className="my-auto bg-[#113A5F] rounded text-white p-[5px] px-[10px] ml-[10px]"
                        onClick={() => {
                          setModificar({ ...modificar, [index]: true });
                          setHorario({
                            espacios: data.espacios,
                            horario: data.horario,
                            id: data.id,
                          });
                          setNuevoHorario(false);
                        }}
                      >
                        Modificar
                      </button>
                      <button
                        className="my-auto bg-[#113A5F] rounded text-white p-[5px] px-[10px] ml-[10px]"
                        onClick={() => {
                          eliminarHorario(data.id);
                        }}
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="flex w-1/2">
                    <input
                      type="number"
                      min={0}
                      className="block w-1/3 p-2 rounded-md border-2 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      value={horario.espacios}
                      onChange={(e) => {
                        setHorario({
                          ...horario,
                          espacios: e.currentTarget.value,
                        });
                      }}
                    ></input>
                    <input
                      type="time"
                      className="block w-1/3 p-2 rounded-md border-2 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      value={horario.horario}
                      onChange={(e) => {
                        setHorario({
                          ...horario,
                          horario: e.currentTarget.value,
                        });
                      }}
                    ></input>
                    <div className="w-1/3 flex">
                      <button
                        className="my-auto bg-[#113A5F] w-[87px] rounded text-white p-[5px] px-[10px] ml-[10px]"
                        onClick={() => {
                          modificarHorario();
                          setModificar({ ...modificar, [index]: false });
                        }}
                      >
                        Guardar
                      </button>
                      <button
                        className="my-auto bg-[#113A5F] w-[87px] rounded text-white p-[5px] px-[10px] ml-[10px]"
                        onClick={() => {
                          setModificar({ ...modificar, [index]: false });
                        }}
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

import carIcon from "../../assets/iconos/car.png";

export const LISTA_REPORTES_POR_MODULO = [
  {
    id: 1,
    titulo: "Carga",
    descripcion: "Carga",
    icono: carIcon,
    color: "rgba(0, 128, 0, 0.5)",
    expandido: false,
    modulos: [
      {
        id: 1,
        titulo: "Tiempo de carga",
        descripcion: "Tiempo de carga",
        icono: carIcon,
        color: "rgba(0, 128, 0, 0.5)",
        href: "reportes/tiempo-carga",
      },
    ],
  },
  {
    id: 2,
    titulo: "Descarga",
    descripcion: "descarga",
    icono: carIcon,
    color: "rgba(0, 128, 0, 0.5)",
    expandido: false,
    modulos: [
      {
        id: 1,
        titulo: "Descarga",
        descripcion: "Descarga",
        icono: carIcon,
        color: "rgba(0, 128, 0, 0.5)",
        href: "reportes/reporte-descarga",
      },
      {
        id: 2,
        titulo: "Tiempo de descarga",
        descripcion: "Tiempo de descarga",
        icono: carIcon,
        color: "rgba(0, 128, 0, 0.5)",
        href: "reportes/tiempo-descarga",
      },
      {
        id: 3,
        titulo: "Estado Almacenes Descarga",
        descripcion: "Estado Almacenes Descarga",
        icono: carIcon,
        color: "rgba(0, 128, 0, 0.5)",
        href: "reportes/estado-almacenes-descarga",
      },
      {
        id: 4,
        titulo: "Cumplimiento Descarga",
        descripcion: "Descarga llego a tiempo a geocerca destino",
        icono: carIcon,
        color: "rgba(0, 128, 0, 0.5)",
        href: "reportes/cumplimiento-descarga",
      },
    ],
  },
  {
    id: 3,
    titulo: "Seguimiento",
    descripcion: "Seguimiento",
    icono: carIcon,
    color: "rgba(0, 128, 0, 0.5)",
    expandido: false,
    modulos: [
      {
        id: 1,
        titulo: "Cumplimiento de Viaje",
        descripcion: "Cumplimiento de Viaje",
        icono: carIcon,
        color: "rgba(0, 128, 0, 0.5)",
        href: "reportes/cumplimiento-de-viaje",
      },
    ],
  },
  {
    id: 4,
    titulo: "Geocerca",
    descripcion: "Geocerca",
    icono: carIcon,
    color: "rgba(0, 128, 0, 0.5)",
    expandido: false,
    modulos: [
      {
        id: 1,
        titulo: "Entradas a geocerca",
        descripcion: "Entradas a geocercas",
        icono: carIcon,
        color: "rgba(0, 128, 0, 0.5)",
        href: "reportes/entradas-geocercas",
      },
      {
        id: 2,
        titulo: "Entradas y salida a geocerca mas tiempo",
        descripcion: "Entradas y salida a geocercas mas tiempo",
        icono: carIcon,
        color: "rgba(0, 128, 0, 0.5)",
        href: "reportes/entradas-geocercas-tiempo",
      },
      {
        id: 2,
        titulo: "Entradas a geocerca mas tiempo a fecha hora seleccionada",
        descripcion: "Entradas a geocerca mas tiempo a fecha hora seleccionada",
        icono: carIcon,
        color: "rgba(0, 128, 0, 0.5)",
        href: "reportes/entradas-geocercas-tiempo-fecha-seleccionada",
      },
    ],
  },
];

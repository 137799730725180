import moment from "moment/moment";
import { SUCCESS } from "../../page/const/http-const";
import { getHeaders } from "../utils";

export async function obtenerListaUsuariosWeb(obj) {
  try {
    let rows = [];
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/usuario/obtener-lista-usuarios-web/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      for (let i = 0; i < body.length; i++) {
        body[i].fechaproceso = moment(body[i].fechaProceso).format(
          "DD/MM/YYYY HH:mm"
        );
        rows.push(body[i]);
      }
    }
    return rows;
  } catch (err) {
    console.log("obtenerOpcionesRespuestaChecklist err");
    console.log(err);
    return [];
  }
}

export async function crearUsuarioWeb(obj) {
  try {
    let result = { creado: false, id: 0, };
    const { code, id } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/usuario/crear-usuario-web/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result.creado = true;
      result.id = id;
    }
    return result;
  } catch (err) {
    console.log("crearUsuarioWeb err");
    console.log(err);
    return { creado: false, id: 0, };
  }
}

export async function crearPermisoUsuarioWeb(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/usuario/crear-permisos-usuario-web/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    if (code === SUCCESS) {
      result = true;;
    }
    return result;
  } catch (err) {
    console.log("crearUsuarioWeb err");
    console.log(err);
    return false;
  }
}

export async function crearAsignacionGrupoSubGrupoUsuarioWeb(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/usuario/crear-asignacion-grupo-subgrupo-usuario-web/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    if (code === SUCCESS) {
      result = true;;
    }
    return result;
  } catch (err) {
    console.log("crearAsignacionGrupoSubGrupoUsuarioWeb err");
    console.log(err);
    return false;
  }
}

export async function modificarUsuarioWeb(obj) {
  try {
    let token = localStorage.getItem("token");
    obj.token = token;
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/usuario/modificar-usuario-web/",
      {
        headers: getHeaders(),
        method: "PUT",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("modificarUsuarioWeb err");
    console.log(err);
    return false;
  }
}

export async function deshabilitarUsuarioWeb(obj) {
  try {
    let token = localStorage.getItem("token");
    obj.token = token;
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/usuario/deshabilitar-usuario-web/",
      {
        headers: getHeaders(),
        method: "PUT",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("deshabilitarUsuarioWeb err");
    console.log(err);
    return false;
  }
}

export async function habilitarUsuarioWeb(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/usuario/habilitar-usuario-web/",
      {
        headers: getHeaders(),
        method: "PUT",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("habilitarUsuarioWeb err");
    console.log(err);
    return false;
  }
}

export async function obtenerListaUsuariosMovil(obj) {
  try {
    let rows = [];
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/usuario/obtener-lista-usuarios-movil/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      for (let i = 0; i < body.length; i++) {
        body[i].fechaproceso = moment(body[i].fechaProceso).format(
          "DD/MM/YYYY HH:mm"
        );
        rows.push(body[i]);
      }
    }
    return rows;
  } catch (err) {
    console.log("obtenerOpcionesRespuestaChecklist err");
    console.log(err);
    return [];
  }
}

export async function crearUsuarioMovil(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/usuario/crear-usuario-movil/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("crearUsuarioMovil err");
    console.log(err);
    return false;
  }
}

export async function modificarUsuarioMovil(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/usuario/modificar-usuario-movil/",
      {
        headers: getHeaders(),
        method: "PUT",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("modificarUsuarioMovil err");
    console.log(err);
    return false;
  }
}

export async function deshabilitarUsuarioMovil(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/usuario/deshabilitar-usuario-movil/",
      {
        headers: getHeaders(),
        method: "PUT",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("deshabilitarUsuarioMovil err");
    console.log(err);
    return false;
  }
}

export async function habilitarUsuarioMovil(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/usuario/habilitar-usuario-movil/",
      {
        headers: getHeaders(),
        method: "PUT",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("deshabilitarUsuarioMovil err");
    console.log(err);
    return false;
  }
}
import { useState, useEffect } from "react";
import {
  obtenerMensaje,
  guardarMensaje,
  modificarMensaje,
} from "../../api/descargas";
import moment from "moment";
import "moment-timezone";

export default function ConfiguracionMensaje() {
  const [loading, setLoading] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [sms, setSms] = useState(true);
  const [email, setEmail] = useState(false);
  const [notification, setNotification] = useState(false);
  const [whatsapp, setWhatsapp] = useState(false);
  const [sinRegistro, setSinRegistro] = useState(true);

  const mensajeDefault =
    "Se ha asignado un registro de descarga en despacho para la unidad ${placa} el deposito de destino es ${depositoDestino} y la fecha de descarga es ${planDescarga}";

  const handleCheckSMSChange = (event) => {
    setSms(event.target.checked);
  };
  const handleCheckEmailChange = (event) => {
    setEmail(event.target.checked);
  };
  const handleCheckNotificationChange = (event) => {
    setNotification(event.target.checked);
  };

  const guardar = async () => {
    const data = {
      mensaje,
      sms,
      email,
      notificationPush: notification,
      whatsapp,
    };
    if (sinRegistro) {
      await guardarMensaje(data);
    } else {
      await modificarMensaje(data);
    }
    alert("Se guardo con exito el nuevo mensaje");
  };
  const obtenerDataMensajes = async () => {
    const resp = await obtenerMensaje();
    if (resp.body.length > 0) {
      setSinRegistro(false);
      setEmail(resp.body[0].email);
      setSms(resp.body[0].sms);
      setNotification(resp.body[0].notificationPush);
      setWhatsapp(resp.body[0].whatsapp);
      setMensaje(resp.body[0].mensaje);
    } else {
      setMensaje(mensajeDefault);
    }
  };
  useEffect(() => {
    obtenerDataMensajes();
  }, []);

  return (
    <div className="p-[2rem]">
      <div className="flex">
        <div className="w-2/3">
          <h1 className="font-bold text-gray-700 text-[24px]">
            Configuracion de mensaje
          </h1>
          <h3>Configura el mensaje de descarga programada</h3>
        </div>
        <div className="flex w-1/3 justify-end">
          <button
            className="my-auto bg-[#84B044] rounded text-white p-[5px] px-[10px] mr-[10px]"
            onClick={() => {
              guardar();
            }}
          >
            Guardar
          </button>
        </div>
      </div>
      <div className=" w-full px-8 pt-8 justify-left">
        <p className="text-[12px]">
          {
            "Coloque ${placa} ${depositoDestino} y ${planDescarga} en los lugares del mensaje donde los necesite con el formato indicado."
          }
        </p>
        <textarea
          maxLength={161}
          className="block w-full p-2 rounded-md border-2 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm resize-none"
          rows="6"
          value={mensaje}
          onChange={(e) => {
            setMensaje(e.target.value);
          }}
        ></textarea>
        <h2 className="mt-[10px] font-bold text-gray-700">
          Enviar mensaje por los siguientes medios:
        </h2>
        <div className="flex">
          <p className="w-[150px]">SMS</p>
          <input
            type="checkbox"
            className=" form-check-input h-4 w-4 border inline border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            checked={sms}
            onChange={handleCheckSMSChange}
          />
        </div>
        <div className="flex">
          <p className="w-[150px]">Email</p>
          <input
            type="checkbox"
            className=" form-check-input h-4 w-4 border inline border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            checked={email}
            disabled
            onChange={handleCheckEmailChange}
          />
        </div>
        <div className="flex">
          <p className="w-[150px]">Notification Push</p>
          <input
            type="checkbox"
            className=" form-check-input h-4 w-4 border inline border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            checked={notification}
            disabled
            onChange={handleCheckNotificationChange}
          />
        </div>
        <div className="flex">
          <p className="w-[150px]">Whatsapp</p>
          <input
            type="checkbox"
            className=" form-check-input  h-4 w-4 border inline border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            disabled
            checked={whatsapp}
          />
        </div>
        {!whatsapp && (
          <p className="text-[9px]">
            Para habilitar la opcion de envio por whatsapp porfavor comunicarce
            con soporte ya que se tendra que efectuar cargos adicionales.
          </p>
        )}
      </div>
    </div>
  );
}

export const OBJECT = "object";
export const ACCIONES = "acciones";
export const CORREOS = "correos";
export const GEOCERCAS = "geocercas";
export const COLOR = "color";
export const COLOR_A_TIEMPO = "coloratiempo";
export const COLOR_RETRASO = "colorretraso";
export const UBICACION_GPS = "ubicaciongps";
export const CHECK = "check";
export const URL = "url";

export const ESTILO_BOTON_GRILLA_NEXT =
  "inline-flex justify-center px-4 py-2 mt-[10px] ml-[8px] border border-transparent shadow-sm text-sm font-medium rounded-md text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
export const ESTILO_BOTON_GRILLA_PREVIOUS =
  "inline-flex justify-center px-4 py-2 mt-[10px] ml-[8px] border border-transparent shadow-sm text-sm font-medium rounded-md text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";

export const ESTILO_BOTON_LISTAR_NUEVO =
  "inline-flex justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-indigo -500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
export const ESTILO_BOTON_LISTAR_ATRAS =
  "inline-flex justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-indigo -500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";

export const ESTILO_BOTON_GENERAL_ACEPTAR =
  "inline-flex justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-indigo -500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";

export const ESTILO_BOTON_GENERAL_CANCELAR =
  "inline-flex justify-center px-4 py-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";

export const ESTILO_INPUT_GENERICO =
  "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md";

export const ESTILO_BOTON_MODAL_CANCELAR = "inline-flex justify-center px-4 py-2 ml-5 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md hover:bg-green-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green-500 cursor-pointer";

export const ESTILO_BOTON_UP =
  "inline-flex justify-center px-4 py-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";

export const ESTILO_BOTON_DOWN =
  "inline-flex justify-center px-4 py-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";

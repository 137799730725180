import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../style/style-cargando.css";
import {
  INT_ID_MODULO_CHECKLIST,
  INT_ID_MODULO_REPORTES,
  INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../utils/const-int";
import Select from "react-select";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
import { doFetchJSONGET } from "../../api/gestion-dts/reportes-formato-dts";
import { Settings } from "feather-icons-react/build/IconComponents";
import {
  obtenerDescargasPlanilla,
  obtenerImportaciones,
} from "../../api/descargas";
import "react-widgets/styles.css";
import {
  ESTILO_BOTON_LISTAR_ATRAS,
  ESTILO_BOTON_MODAL_CANCELAR,
} from "../../utils/const-estilos";
import Grid from "../../components/grid";
import moment from "moment";
import "react-widgets/styles.css";
import DatePicker from "react-widgets/DatePicker";
// import momentLocalizer from "react-widgets-moment";
import { TRUE } from "../../utils/const-bool";
import shortid from "shortid";
import { exportarDocumento } from "../../share/shared-functions";
import {
  INT_REPORTE_CONDUCCION_FUERA_DE_HORARIO_DESCARGA,
  LISTA_REPORTES_EXPORTABLES,
} from "./lista-reportes-exportables";
import {
  actualizarOrdenGestionarDisponibilidadScreen,
  guardarConfiguracionScreen,
  obtenerConfiguracionScreen,
} from "../../api/configuracion-screen/configuracion-screen";
import { LISTAR_DESCARGAS_IMPORTADAS_REPORTE } from "../../utils/const-screen-name";

const idTipoUsuario = parseInt(localStorage.getItem("tipo"));
const idEmpresa = parseInt(localStorage.getItem("idEmpresa"));

export default function ReporteDescarga() {
  const headersDescarga = [
    { key: "acciones", title: "ACCIONES" },
    { key: "fecharegistro", title: "FECHA ", filter: true },
  ];

  const headersDescargaDetalle = [
    { key: "acciones", title: "ACCIONES" },
    { key: "planilla", title: "PLANILLA", filter: true },
    { key: "placa", title: "PLACA", filter: true },
    { key: "codigoorigen", title: "CODIGO ORIGEN", filter: true },
    { key: "despositoorigen", title: "DEPOSITO ORIGEN", filter: true },
    { key: "codigodestino", title: "CODIGO DESTINO", filter: true },
    { key: "despositodestino", title: "DEPOSITO DESTINO", filter: true },
    { key: "fechasalida", title: "FECHA SALIDA", filter: true },
    { key: "plandescarga", title: "PLAN DESCARGA", filter: true },
  ];

  const history = useHistory();
  const [permisos, setPermisos] = useState(null);
  const [cargandoInfo, setCargandoInfo] = useState(false);
  const [cargandoPage, setCargandoPage] = useState(false);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);
  const [listaDescargaAgrupado, setListaDescargaAgrupado] = useState([]);
  const [listaDescargaDetalle, setListaDescargaDetalle] = useState([]);
  const [reporteSelected, setReporteSelected] = useState(null);
  const [fechaInicio, setFechaInicio] = useState(new Date());
  const [fechaFin, setFechaFin] = useState(new Date());
  const [modalConfiguracionVisible, setModalConfiguracionVisible] =
    useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const [ascendente, setAscendente] = useState(false);
  const [descendente, setDescendente] = useState(false);
  const [configuracionOrden, setConfiguracionOrden] = useState(null);

  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      iniciarFechas();
      let obj = {};
      obj.idMenuPrimerNivel = INT_ID_MODULO_REPORTES;
      obj.idMenuSegundoNivel = 0;
      obj.idMenuTercerNivel = 0;
      const configuracionScreenInfo = await obtenerConfiguracionScreen({
        nombre: LISTAR_DESCARGAS_IMPORTADAS_REPORTE,
      });
      console.log("configuracionScreenInfo");
      console.log(configuracionScreenInfo);
      let objConfiguracionEnviar = {};
      objConfiguracionEnviar.descendente = true;
      objConfiguracionEnviar.ascendente = false;
      objConfiguracionEnviar.fechaInicio =
        moment(fechaInicio).format("YYYY-MM-DD");
      objConfiguracionEnviar.fechaFin = moment(fechaFin).format("YYYY-MM-DD");
      if (configuracionScreenInfo !== null) {
        const configuracionOrden = JSON.parse(configuracionScreenInfo.orden);
        // console.log('configuracionOrden');
        // console.log(configuracionOrden);
        objConfiguracionEnviar.descendente = configuracionOrden.descendente;
        objConfiguracionEnviar.ascendente = configuracionOrden.ascendente;
        setAscendente(configuracionOrden.ascendente);
        setDescendente(configuracionOrden.descendente);
      }
      setConfiguracionOrden(configuracionScreenInfo);
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(obj);
      // console.log('obtenerPermisos');
      // console.log(obtenerPermisos);
      let permiso = obtenerPermisos[0];
      setPermisos(permiso);
      // console.log('idTipoUsuario');
      // console.log(idTipoUsuario);
      // debugger;
      let listaDescarga = [];
      const descargaAgrupado = await obtenerImportaciones(
        objConfiguracionEnviar
      );
      // console.log('descargaAgrupado');
      // console.log(descargaAgrupado);
      for (let i = 0; i < descargaAgrupado.length; i++) {
        const element = descargaAgrupado[i];
        let obj = {};
        obj.fecharegistro = moment
          .utc(element.fechaRegistro)
          .utcOffset(0)
          .format("YYYY-MM-DD HH:mm:ss");
        listaDescarga.push(obj);
      }
      agregarAccionesALista(listaDescarga, permiso);
      setTieneAccesoAlModulo(true);
      setCargandoPage(false);
    } catch (err) {
      console.log("iniciar err");
      console.log(err);
      setCargandoPage(false);
    }
  };

  const iniciarFechas = () => {
    try {
      let currentDate = moment();
      let fechaI = new Date(
        currentDate.clone().startOf("week").add(1, "day").format("YYYY-MM-DD")
      );
      let fechaF = new Date(
        currentDate.clone().endOf("week").add(1, "day").format("YYYY-MM-DD")
      );
      setFechaInicio(fechaI);
      setFechaFin(fechaF);
    } catch (err) {
      console.log("iniciarFechas err");
      console.log(err);
      setFechaInicio(new Date());
      setFechaFin(new Date());
    }
  };

  const handleBuscar = async () => {
    reset();
  };

  const reset = async () => {
    try {
      setCargandoPage(true);
      const configuracionScreenInfo = await obtenerConfiguracionScreen({
        nombre: LISTAR_DESCARGAS_IMPORTADAS_REPORTE,
      });
      // console.log('configuracionScreenInfo');
      // console.log(configuracionScreenInfo);
      let objConfiguracionEnviar = {};
      objConfiguracionEnviar.descendente = true;
      objConfiguracionEnviar.ascendente = false;
      objConfiguracionEnviar.fechaInicio =
        moment(fechaInicio).format("YYYY-MM-DD");
      objConfiguracionEnviar.fechaFin = moment(fechaFin).format("YYYY-MM-DD");
      if (configuracionScreenInfo !== null) {
        const configuracionOrden = JSON.parse(configuracionScreenInfo.orden);
        // console.log('configuracionOrden');
        // console.log(configuracionOrden);
        objConfiguracionEnviar.descendente = configuracionOrden.descendente;
        objConfiguracionEnviar.ascendente = configuracionOrden.ascendente;
        setAscendente(configuracionOrden.ascendente);
        setDescendente(configuracionOrden.descendente);
      }
      let listaDescarga = [];
      const descargaAgrupado = await obtenerImportaciones(
        objConfiguracionEnviar
      );
      // console.log('descargaAgrupado');
      // console.log(descargaAgrupado);
      for (let i = 0; i < descargaAgrupado.length; i++) {
        const element = descargaAgrupado[i];
        let obj = {};
        obj.fecharegistro = moment
          .utc(element.fechaRegistro)
          .utcOffset(0)
          .format("YYYY-MM-DD HH:mm:ss");
        listaDescarga.push(obj);
      }
      agregarAccionesALista(listaDescarga, permisos);
      setCargandoPage(false);
    } catch (err) {
      setCargandoPage(false);
    }
  };

  const agregarAccionesALista = (lista, permiso) => {
    try {
      if (lista.length === 0) {
        setListaDescargaAgrupado(lista);
      }
      if (lista.length > 0) {
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          let objVer = {
            label: "Ver",
            color: "green",
            onClick: (param) => verRegistros(param),
          };
          if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            acciones.push(objVer);
          }
          if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            if (permiso.eliminarDeshabilitar === TRUE) {
              acciones.push(objVer);
            }
          }
          lista[i].acciones = acciones;
        }
        setListaDescargaAgrupado(lista);
      }
    } catch (err) {
      console.log("agregarAccionesALista err");
      console.log(err);
    }
  };

  const verRegistros = async (data) => {
    try {
      // console.log(' verRegistros');
      // console.log(data);
      setCargandoInfo(true);
      let objEnviar = {};
      objEnviar.fecha = data.fecharegistro;
      const listaDescarga = await obtenerDescargasPlanilla(objEnviar);
      console.log(" listaDescarga");
      console.log(listaDescarga);
      let lista = [];
      for (let i = 0; i < listaDescarga.body.length; i++) {
        const element = listaDescarga.body[i];
        let obj = {};
        obj.planilla = element.planilla;
        obj.placa = element.placa;
        obj.codigoorigen = element.codigoOrigen;
        obj.despositoorigen = element.depositoOrigen;
        obj.codigodestino = element.codigoDestino;
        obj.despositodestino = element.depositoDestino;
        obj.fechasalida = element.fechaSalida;
        obj.plandescarga = element.planDescarga;
        lista.push(obj);
      }
      agregarAccionesADescargaDetalle(lista);
      // setListaDescargaDetalle(lista);
      setCargandoInfo(false);
    } catch (err) {
      console.log(" verRegistros err");
      console.log(err);
      setCargandoInfo(true);
    }
  };

  const agregarAccionesADescargaDetalle = (lista) => {
    try {
      if (lista.length === 0) {
        setListaDescargaDetalle(lista);
      }
      if (lista.length > 0) {
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          let objExportar = {
            label: "EXPORTAR",
            color: "green",
            onClick: (param) => validarExportar(param),
          };
          acciones.push(objExportar);
          lista[i].acciones = acciones;
        }
        setListaDescargaDetalle(lista);
      }
    } catch (err) {
      console.log("agregarAccionesALista err");
      console.log(err);
    }
  };

  const handleAtrasClick = () => {
    history.goBack();
  };

  const handleReporte = async (event) => {
    try {
      setReporteSelected(event);
    } catch (err) {
      console.log("handleUnidad err");
      console.log(err);
    }
  };

  const handleExportarClick = () => {
    try {
      console.log("handleExportarClick");
      if (reporteSelected === null) {
        alert("Debe seleccionar reporte para exportar");
        return;
      }
      switch (reporteSelected.value) {
        case INT_REPORTE_CONDUCCION_FUERA_DE_HORARIO_DESCARGA:
          exportarReporteConduccionFueraHorario();
          break;
      }
    } catch (err) {
      console.log("handleExportarClick err");
      console.log(err);
    }
  };

  const exportarReporteConduccionFueraHorario = async () => {
    try {
      // console.log('exportarReporteConduccionFueraHorario');
      if (listaDescargaDetalle.length === 0) {
        alert("No existen datos para exportar");
        return;
      }
      setCargandoInfo(true);
      const list = listaDescargaDetalle.filter((e) => e.plandescarga !== "");
      // console.log('list');
      // console.log(list);
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        exportarReporte(element);
      }
      setCargandoInfo(false);
    } catch (err) {
      console.log("exportarReporteConduccionFueraHorario err");
      console.log(err);
      setCargandoInfo(false);
    }
  };

  const validarExportar = async (element) => {
    if (element.plandescarga === "") {
      alert("El elemento seleccionado no tiene plan descarga");
      return;
    }
    exportarReporte(element);
  };

  const exportarReporte = async (element) => {
    try {
      console.log("exportarReporte");
      console.log(element);
      const placa = element.placa;
      const fechaInicio = element.fechasalida;
      const fechaFin = element.plandescarga;
      const vPlanilla = element.planilla;
      let url =
        "https://gestiondeflota.boltrack.net/reportes/RP502BodyV13" +
        ".rep?E=" +
        idEmpresa +
        "&T=0" +
        "&IMEI=" +
        placa +
        "&mesi=" +
        moment(fechaInicio).format("YYMM") +
        "&diai=" +
        moment(fechaInicio).format("DD") +
        "&horai=" +
        moment(fechaInicio).format("HH") +
        "&mini=" +
        moment(fechaInicio).format("mm") +
        "&segi=" +
        moment(fechaInicio).format("ss") +
        "&mesf=" +
        moment(fechaFin).format("YYMM") +
        "&diaf=" +
        moment(fechaFin).format("DD") +
        "&horaf=" +
        moment(fechaFin).format("HH") +
        "&minf=" +
        moment(fechaFin).format("mm") +
        "&segf=" +
        moment(fechaFin).format("ss") +
        "&vPlanilla=" +
        vPlanilla;
      // console.log('url');
      // console.log(url);
      const data = await doFetchJSONGET(url);
      // console.log('data');
      // console.log(data);
      const info = data.split("/");
      // console.log('info');
      // console.log(info);
      exportarDocumento(info[info.length - 1]);
      sleep(200);
    } catch (err) {
      console.log("exportarReporte err");
      console.log(err);
    }
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const openModalCondiguracion = () => {
    setModalConfiguracionVisible(true);
    setActiveTab("tab1");
  };

  const onHideModalConfiguracion = () => {
    setModalConfiguracionVisible(false);
    setActiveTab(null);
  };

  const onAcceptarModalConfiguracion = async () => {
    try {
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = LISTAR_DESCARGAS_IMPORTADAS_REPORTE;
      objConfiguracionEnviar.orden = objOrden;
      console.log("configuracionOrden");
      console.log(configuracionOrden);
      if (configuracionOrden === null) {
        objConfiguracionEnviar.headers = null;
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        const actualizo = await actualizarOrdenGestionarDisponibilidadScreen(
          objConfiguracionEnviar
        );
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {}
  };

  const openTab = (tabName) => {
    setActiveTab(tabName);
  };

  const handleAscendenteChange = () => {
    setAscendente(!ascendente);
    setDescendente(false); // Desmarca el otro checkbox
  };

  const handleDescendenteChange = () => {
    setDescendente(!descendente);
    setAscendente(false); // Desmarca el otro checkbox
  };

  const render = () => {
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div style={{ marginTop: 10 }}>
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 30,
            }}
          >
            <div style={{ marginTop: 10 }}>
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div className="m-5">
          <div>
            {cargandoInfo === true && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: 30,
                }}
              >
                <div className="loader-cargando-info"></div>
                <div style={{ marginTop: 10 }}></div>
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <label
              style={{
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              REPORTE DESCARGA
            </label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              className="w-full md:w-1/2 lg:w-2/4 p-4"
              key={shortid.generate()}
            >
              <div
                style={{
                  paddingBottom: 5,
                  paddingRight: 10,
                  paddingLeft: 10,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <button
                  type="button"
                  className={ESTILO_BOTON_LISTAR_ATRAS}
                  onClick={openModalCondiguracion}
                >
                  <Settings />
                </button>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="w-full sm:w-auto sm:flex-shrink-0 sm:mr-4 mb-4 sm:mb-0">
                  <label htmlFor="fecha inicio" className="mb-2 font-bold">
                    FECHA INICIO
                  </label>
                  <DatePicker
                    defaultValue={fechaInicio}
                    value={fechaInicio}
                    valueFormat={{ dateStyle: "medium" }}
                    onChange={(date) => setFechaInicio(date)}
                  />
                </div>
                <div className="w-full sm:w-auto sm:flex-shrink-0 sm:mr-4 mb-4 sm:mb-0">
                  <label htmlFor="fecha fin" className="mb-2 font-bold">
                    FECHA FIN
                  </label>
                  <DatePicker
                    defaultValue={fechaFin}
                    value={fechaFin}
                    valueFormat={{ dateStyle: "medium" }}
                    onChange={(date) => setFechaFin(date)}
                  />
                </div>
                <div>
                  <button
                    type="button"
                    className={ESTILO_BOTON_LISTAR_ATRAS}
                    onClick={handleBuscar}
                  >
                    BUSCAR
                  </button>
                </div>
              </div>

              <Grid
                rows={listaDescargaAgrupado}
                headers={headersDescarga}
                key={shortid.generate()}
              />
            </div>
            <div
              className="w-full md:w-1/2 lg:w-2/4 p-4"
              key={shortid.generate()}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Select
                  options={LISTA_REPORTES_EXPORTABLES}
                  isSearchable="true"
                  isClearable="true"
                  value={reporteSelected}
                  onChange={(event) => handleReporte(event)}
                  placeholder="SELECCIONE REPORTE"
                />
                <button
                  type="button"
                  className={ESTILO_BOTON_LISTAR_ATRAS}
                  onClick={handleExportarClick}
                >
                  EXPORTAR
                </button>
              </div>
              <Grid
                rows={listaDescargaDetalle}
                headers={headersDescargaDetalle}
                key={shortid.generate()}
              />
            </div>
          </div>
          {modalConfiguracionVisible && (
            <div className="fixed z-50 top-0 left-0 right-0 bottom-0 flex items-center justify-center">
              {/* background */}
              <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
              {/* end background */}
              <div className="bg-white w-11/12 h-2/6 md:max-w-3xl lg:max-w-3xl mx-auto rounded shadow-lg z-50 overflow-y-auto flex flex-col">
                <div className="pt-4 text-left px-6">
                  <p className="text-xl">{"CONFIGURAR GRILLA "}</p>
                  <div className="tabs">
                    <button
                      className={`tab ${
                        activeTab === "tab1"
                          ? "text-white bg-green-500 rounded transition duration-300"
                          : "text-black"
                      }`}
                      onClick={() => openTab("tab1")}
                    >
                      ORDEN
                    </button>
                    {/* <button className={`tab ${activeTab === 'tab2' ? 'text-white bg-green-500 rounded transition duration-300' : 'text-black'}`} onClick={() => openTab('tab2')}>COLUMNAS</button> */}
                  </div>

                  {activeTab === "tab1" && (
                    <div
                      id="tab1"
                      style={{
                        paddingTop: 10,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label>
                        <input
                          type="checkbox"
                          checked={ascendente}
                          onChange={handleAscendenteChange}
                        />
                        ASCENDENTE
                      </label>

                      <label>
                        <input
                          type="checkbox"
                          checked={descendente}
                          onChange={handleDescendenteChange}
                        />
                        DESCENDENTE
                      </label>
                    </div>
                  )}
                  {/* {activeTab === 'tab2' &&
                    <div id="tab2">
                      <p>Contenido de la pestaña 2</p>
                    </div>
                  } */}
                </div>

                <div className="flex justify-between pt-20 pb-4 pr-4">
                  <button
                    className={ESTILO_BOTON_MODAL_CANCELAR}
                    onClick={onHideModalConfiguracion}
                  >
                    Cancelar
                  </button>
                  <button
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 cursor-pointer"
                    onClick={onAcceptarModalConfiguracion}
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } catch (err) {
      console.log("ReporteDescarga render");
      console.log(err);
      return (
        <div className=" w-1/2 m-auto px-[2rem] ">
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  };

  return render();
}

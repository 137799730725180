const HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const getHeaders = (config = {}) => {
  const { authenticate } = config;
  // console.log('authenticate');
  // console.log(authenticate);
  // if (authenticate) {
  const bearer = localStorage.getItem("accessToken");
  // console.log('bearer');
  // console.log(bearer);
  if (typeof bearer !== "string") throw new Error("missing bearer token");
  return {
    ...HEADERS,
    Authorization: `Bearer ${bearer}`,
  };
  // }
  // return HEADERS;
};

// module.exports = {
  // getHeaders,
// };

import carIcon from "../assets/iconos/car.png"

export const MODULOS_CONFIGURACION_GEORUTA = [
    {
        id: 1,
        titulo: 'Seleccionar Grupo GeoRuta',
        descripcion: 'Seleccionar Grupo GeoRuta',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion-georuta/seleccionar-grupo-georuta',
    },
    {
        id: 2,
        titulo: 'Seleccionar puntos control',
        descripcion: 'Seleccionar puntos control de una georuta',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion-georuta/seleccionar-grupo-puntos-control',
    },
    {
        id: 3,
        titulo: 'Tipo Detencion Georuta',
        descripcion: 'Tipo Detencion Georuta',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion-georuta/gestionar-tipo-detencion-destino',
    },
];
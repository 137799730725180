import { useState, useEffect } from "react";
import {
  Award,
  ChevronDown,
  ChevronUp,
} from "feather-icons-react/build/IconComponents";
import shortid from "shortid";
import "../style/style-cargando.css";

const ModuloLista = ({ data }) => {
  const [modulo, setModulo] = useState(data);

  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = () => {
    // console.log("data");
    // console.log(data);
  };

  const render = () => {
    try {
      return (
        <div className="w-full md:w-2/2 lg:w-4/4 p-4" key={shortid.generate()}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              width: "100%",
              background: `${modulo.color}`,
              height: 128,
              borderRadius: 15,
              minWidth: "fit-content",
              minHeight: "fit-content",
            }}
          >
            <div
              style={{
                width: "20%",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <img
                style={{ objectFit: "contain" }}
                src={modulo.icono}
                width="64"
                height="64"
                alt=""
              />
            </div>
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                marginLeft: 5,
                width: "75%",
                borderLeft: "1px white solid",
                cursor: "pointer",
              }}
              onClick={() => {
                setModulo({ ...modulo, expandido: !modulo.expandido });
              }}
            >
              <label
                style={{
                  fontWeight: "bold",
                  paddingLeft: 5,
                }}
              >
                {modulo.titulo}
              </label>
              <hr />
              <label style={{ paddingLeft: 5 }}>{modulo.descripcion}</label>
            </div>
            <div
              style={{
                width: "5%",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                setModulo({ ...modulo, expandido: !modulo.expandido });
              }}
            >
              {modulo.expandido ? (
                <ChevronDown color={"white"} />
              ) : (
                <ChevronUp color={"white"} />
              )}
            </div>
          </div>
          <div>
            {modulo.expandido === true &&
              modulo.modulos.map((data) => (
                <a href={`${data.href}`} key={shortid.generate()}>
                  <div
                    style={{
                      cursor: "pointer",
                      marginLeft: "21%",
                      marginTop: 10,
                      flexDirection: "row",
                      display: "flex",
                      borderBottom: "1.5px solid grey",
                      ":hover": {
                        backgroundColor: "#E5E7EB", // Cambia este valor por el color gris deseado
                      },
                    }}
                    key={shortid.generate()}
                  >
                    <Award color={"black"} />
                    <label>{data.titulo}</label>
                  </div>
                </a>
              ))}
          </div>
        </div>
      );
    } catch (err) {
      console.log("ModuloLista err");
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  };

  return render();
};

export default ModuloLista;

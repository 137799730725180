import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ESTILO_BOTON_GENERAL_ACEPTAR, ESTILO_BOTON_GENERAL_CANCELAR, ESTILO_BOTON_LISTAR_ATRAS } from "../../utils/const-estilos";
import Select from "react-select";
import "../../style/style-cargando.css";
import { modificarDisponibilidad, obtenerListaEstadosCarroceria, obtenerListaEstadosPlanificacion, obtenerListaEstadosSupervisor } from "../../api/disponibilidad";

export default function Disponibilidad() {
  const location = useLocation();
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(false);
  const [disponibilidad, setDisponibilidad] = useState(null);
  const [titulo, setTitulo] = useState("DISPONIBILIDAD");
  const [listaEstadoCarroceria, setListaEstadoCarroceria] = useState([]);
  const [listaEstadoSupervisor, setListaEstadoSupervisor] = useState([]);
  const [listaEstadoPlanificacion, setListaEstadoPlanificacion] = useState([]);
  const [estadoCarroceriaSelected, setEstadoCarroceriaSelected] = useState(null);
  const [estadoSupervisorSelected, setEstadoSupervisorSelected] = useState(null);
  const [estadoPlanificacionSelected, setEstadoPlanificacionSelected] = useState(null);



  useEffect(() => {
    // console.log("entro a Disponibilidad");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      const data = location.state;
      console.log("data");
      console.log(data);
      const listaEstadoSup = await obtenerListaEstadosSupervisor(true);
      const listaEstadoCarro = await obtenerListaEstadosCarroceria(true);
      setListaEstadoCarroceria(listaEstadoCarro);
      // console.log("listaEstadoSup");
      // console.log(listaEstadoSup);
      setListaEstadoSupervisor(listaEstadoSup);
      const listaEstadoPlan = await obtenerListaEstadosPlanificacion(true);
      // console.log("listaEstadoPlan");
      // console.log(listaEstadoPlan);
      setListaEstadoPlanificacion(listaEstadoPlan);
      if (data !== undefined && data !== null) {
        setDisponibilidad(data.params);
        setTitulo(data.titulo);
        const estadoSup = listaEstadoSup.find(e => e.value === data.params.idestadosupervisor);
        // console.log("estadoSup");
        // console.log(estadoSup);
        if (estadoSup !== undefined) {
          setEstadoSupervisorSelected(estadoSup);
        }
        const estadoPlan = listaEstadoPlan.find(e => e.value === data.params.idestadoplanificacion);
        // console.log("estadoPlan");
        // console.log(estadoPlan);
        if (estadoPlan !== undefined) {
          setEstadoPlanificacionSelected(estadoPlan);
        }
        const estadoCar = listaEstadoCarro.find(e => e.value === data.params.idestadocarroceria);
        // console.log("estadoPlan");
        // console.log(estadoPlan);
        if (estadoCar !== undefined) {
          setEstadoCarroceriaSelected(estadoCar);
        }
      }
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const handleCancelClick = () => {
    history.goBack();
  };

  const handleAceptarClick = async () => {
    try {
      // console.log('handleAceptarClick');
      if (estadoCarroceriaSelected === null) {
        alert('Debe selecccionar Estado Carroceria');
        return;
      }
      let objEnviar = {};
      objEnviar.id = disponibilidad.id;
      if (estadoSupervisorSelected === null) {
        objEnviar.idEstadoSupervisor = 0;
      } else {
        objEnviar.idEstadoSupervisor = estadoSupervisorSelected.value;
      }
      if (estadoPlanificacionSelected === null) {
        objEnviar.idEstadoPlanificacion = 0;
      } else {
        objEnviar.idEstadoPlanificacion = estadoPlanificacionSelected.value;
      }
      if (estadoCarroceriaSelected === null) {
        objEnviar.idEstadoCarroceria = 0;
      } else {
        objEnviar.idEstadoCarroceria = estadoCarroceriaSelected.value;
      }
      if (disponibilidad.id > 0) {
        // console.log('actualizar correo');
        let modificado = await modificarDisponibilidad(objEnviar);
        // console.log('modificado');
        // console.log(modificado);
        if (modificado === true) {
          alert('Se modifico correctamente');
          history.goBack();
        }
        if (modificado === false) {
          alert('No se pudo modificar');
        }
      }
    } catch (err) {
      console.log('handleAceptarClick err');
      console.log(err);
      alert('No se pudo continuar, por favor revise los campos');
    }
  };

  const handleAtrasClick = () => {
    history.goBack();
  }

  const handleEstadoSupervisor = async (event) => {
    try {
      setEstadoSupervisorSelected(event);
    } catch (err) {
      console.log('handleEstadoSupervisor err');
      console.log(err);
    }
  }

  const handleEstadoCarroceria = async (event) => {
    try {
      setEstadoCarroceriaSelected(event);
    } catch (err) {
      console.log('handleEstadoCarroceria err');
      console.log(err);
    }
  }

  const handleEstadoPlanificacion = async (event) => {
    try {
      setEstadoPlanificacionSelected(event);
    } catch (err) {
      console.log('handleEstadoPlanificacion err');
      console.log(err);
    }
  }

  const handleInputChange = (event) => {
    // console.log('handleInputChange');
    const { name, value } = event.target;
    // console.log(name);
    // console.log(value);
    setDisponibilidad({ ...disponibilidad, [name]: value });
  };

  const render = () => {
    // console.log('render correo');
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (cargandoPage === false && disponibilidad === null) {
        return (
          <div>
            <label> Sin data</label>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <label
              style={{
                paddingLeft: 4,
                fontWeight: 'bold',
              }}
            >
              {titulo}
            </label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <br />
          <hr />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="nombreplantageocercadestino" className="mb-2 font-bold">
                  PLANTA/GEOCERCA
                </label>
                <input
                  type="text"
                  id="nombreplantageocercadestino"
                  name="nombreplantageocercadestino"
                  value={disponibilidad.nombreplantageocercadestino}
                  placeholder="NOMBRE PLANTA"
                  className="p-2 border border-gray-300 rounded"
                  readOnly
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="otroplantageocercadestino" className="mb-2 font-bold">
                  OTRO PLANTA/GEOCERCA
                </label>
                <input
                  type="text"
                  id="otroplantageocercadestino"
                  name="otroplantageocercadestino"
                  value={disponibilidad.otroplantageocercadestino}
                  placeholder="OTRO PLANTA/GEOCERCA"
                  className="p-2 border border-gray-300 rounded"
                  readOnly
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="nombrechofer" className="mb-2 font-bold">
                  NOMBRE CHOFER
                </label>
                <input
                  type="text"
                  id="nombrechofer"
                  name="nombrechofer"
                  value={disponibilidad.nombrechofer}
                  placeholder="NOMBRE CHOFER"
                  className="p-2 border border-gray-300 rounded"
                  readOnly
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="estadocarroceria" className="mb-2 font-bold">
                  ESTADO CARROCERIA
                </label>
                <Select options={listaEstadoCarroceria} isSearchable='true'
                  isClearable='true'
                  value={estadoCarroceriaSelected}
                  onChange={(event) => handleEstadoCarroceria(event)}
                  placeholder='SELECCIONE ESTADO CARROCERIA'
                />
                {/*
                <input
                  type="text"
                  id="estadocarroceria"
                  name="estadocarroceria"
                  value={disponibilidad.estadocarroceria}
                  placeholder="ESTADO CARROCERIA"
                  className="p-2 border border-gray-300 rounded"
                  readOnly
                />
                  */}
              </div>
            </div>

          </div>
          <br />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="estadosupervisor" className="mb-2 font-bold">
                  ESTADO SUPERVISOR
                </label>
                <Select options={listaEstadoSupervisor} isSearchable='true'
                  isClearable='true'
                  value={estadoSupervisorSelected}
                  onChange={(event) => handleEstadoSupervisor(event)}
                  placeholder='SELECCIONE ESTADO SUPERVISOR'
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="estadoplanificacion" className="mb-2 font-bold">
                  ESTADO PLANIFICACION
                </label>
                <Select options={listaEstadoPlanificacion} isSearchable='true'
                  isClearable='true'
                  value={estadoPlanificacionSelected}
                  onChange={(event) => handleEstadoPlanificacion(event)}
                  placeholder='SELECCIONE ESTADO PLANIFICACION'
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="estadoplanificacion" className="mb-2 font-bold">
                  NOTA
                </label>
                <textarea
                  type="text"
                  id="nota"
                  name="nota"
                  value={disponibilidad.nota}
                  placeholder="NOTA"
                  className="p-2 border border-gray-300 rounded"
                  rows={3}
                  onChange={handleInputChange}
                  maxlength={1023}
                />
              </div>
            </div>

          </div>
          <br />
          <div className="flex justify-between p-4">
            <button className={ESTILO_BOTON_GENERAL_CANCELAR}
              onClick={handleCancelClick}
            >
              CANCELAR
            </button>
            <button className={ESTILO_BOTON_GENERAL_ACEPTAR}
              onClick={handleAceptarClick}
            >
              ACEPTAR
            </button>
          </div>
        </div>
      );
    } catch (err) {
      console.log('Disponibilidad render err');
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  }

  return render();
}

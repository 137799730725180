import "../style/style-cargando.css";

const ModalLoading = ({ isOpen }) => {

    return (
        <>
            {isOpen && (
                <div className="fixed z-50 top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                    <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
                    <div className="bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
                        <div className="py-4 text-left px-6">
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginTop: 30,
                                }}
                            >
                                <div className="loader"></div>
                                <div
                                    style={{ marginTop: 10, }}
                                >
                                    <p>Cargando...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ModalLoading;
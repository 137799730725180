import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ESTILO_BOTON_GENERAL_CANCELAR, ESTILO_BOTON_LISTAR_ATRAS } from "../../utils/const-estilos";
import "../../style/style-cargando.css";

import moment from "moment";
import "react-widgets/styles.css";
import DatePicker from "react-widgets/DatePicker";
import momentLocalizer from "react-widgets-moment";
import { actualizarIncidente } from "../../api/incidente";
new momentLocalizer(moment);

export default function Incidente() {
  const location = useLocation();
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(false);
  const [incidente, setIncidente] = useState(null);
  const [titulo, setTitulo] = useState("INCIDENTE");
  const [fechaFinalizacion, setFechaFinalizacion] = useState(null);

  useEffect(() => {
    // console.log("entro a Disponibilidad");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      const data = location.state;
      console.log("data");
      console.log(data);
      if (data !== undefined && data !== null) {
        setIncidente(data.params);
        setTitulo(data.titulo);
        setFechaFinalizacion(new Date(data.params.fechaFinalizacion));
      }
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const handleCancelClick = () => {
    history.goBack();
  };

  const handleAtrasClick = () => {
    history.goBack();
  }

  /*const handleFechaIncidente = (date) => {
    try {
      console.log('date:');
      console.log(date.target.value);
      // console.log('typeof date:', typeof date);

      // if (date instanceof Date && !isNaN(date)) {
      setIncidente(prevIncidente => ({
        ...prevIncidente,
        fechaFinalizacion: new Date(moment(date.target.value).format('YYYY/MM/DD'))
      }));
      // } else {
      // console.error('Invalid date:', date);
      // }
    } catch (err) {
      console.log('handleFechaIncidente err');
      console.log(err);
    }
  };*/

  const handleActualizar = async () => {
    try {
      // console.log('handleActualizar');
      incidente.fechaFinalizacion = moment(fechaFinalizacion).format('YYYY/MM/DD');
      // console.log(incidente);
      const actualizo = await actualizarIncidente(incidente);
      if (actualizo === true) {
        alert("Se actualizo correctamente");
        handleAtrasClick();
        return;
      }
      if (actualizo === false) {
        alert("No se pudo realizar proceso");
      }
    } catch (err) {
      console.log('handleActualizar err');
      console.log(err);
    }
  }

  const render = () => {
    // console.log('render correo');
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (cargandoPage === false && incidente === null) {
        return (
          <div>
            <label> Sin data</label>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <label
              style={{
                paddingLeft: 4,
                fontWeight: 'bold',
              }}
            >
              {titulo}
            </label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <br />
          <hr />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="nombreGeocerca" className="mb-2 font-bold">
                  GEOCERCA/CIUDAD
                </label>
                <input
                  type="text"
                  id="nombreGeocerca"
                  name="nombreGeocerca"
                  value={incidente.nombreGeocerca}
                  placeholder="GEOCERCA/CIUDAD"
                  className="p-2 border border-gray-300 rounded"
                  readOnly
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="otroGeocerca" className="mb-2 font-bold">
                  OTRO GEOCERCA/CUIDAD
                </label>
                <input
                  type="text"
                  id="otroGeocerca"
                  name="otroGeocerca"
                  value={incidente.otroGeocerca}
                  placeholder="OTRO GEOCERCA/CUIDAD"
                  className="p-2 border border-gray-300 rounded"
                  readOnly
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="tipoIncidente" className="mb-2 font-bold">
                  TIPO INCIDENTE
                </label>
                <input
                  type="text"
                  id="tipoIncidente"
                  name="tipoIncidente"
                  value={incidente.tipoIncidente}
                  placeholder="TIPO INCIDENTE"
                  className="p-2 border border-gray-300 rounded"
                  readOnly
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="otroTipoIncidente" className="mb-2 font-bold">
                  OTRO TIPO INCIDENTE
                </label>
                <input
                  type="text"
                  id="otroTipoIncidente"
                  name="otroTipoIncidente"
                  value={incidente.otroTipoIncidente}
                  placeholder="CATEGORIA"
                  className="p-2 border border-gray-300 rounded"
                  readOnly
                />
              </div>
            </div>

          </div>
          <br />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="categoria" className="mb-2 font-bold">
                  CATEGORIA
                </label>
                <input
                  type="text"
                  id="categoria"
                  name="categoria"
                  value={incidente.categoria}
                  placeholder="CATEGORIA"
                  className="p-2 border border-gray-300 rounded"
                  readOnly
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="otrocategoria" className="mb-2 font-bold">
                  OTRO CATEGORIA
                </label>
                <input
                  type="text"
                  id="otrocategoria"
                  name="otrocategoria"
                  value={incidente.otrocategoria}
                  placeholder="CATEGORIA"
                  className="p-2 border border-gray-300 rounded"
                  readOnly
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="unidad" className="mb-2 font-bold">
                  UNIDAD
                </label>
                <input
                  type="text"
                  id="unidad"
                  name="unidad"
                  value={incidente.unidad}
                  placeholder="UNIDAD"
                  className="p-2 border border-gray-300 rounded"
                  readOnly
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="coordenadas" className="mb-2 font-bold">
                  COORDENADAS
                </label>
                <input
                  type="text"
                  id="coordenadas"
                  name="coordenadas"
                  value={incidente.coordenadas}
                  placeholder="COORDENADAS"
                  className="p-2 border border-gray-300 rounded"
                  readOnly
                />
              </div>
            </div>

          </div>
          <br />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="estadoCamion" className="mb-2 font-bold">
                  ESTADO CAMION
                </label>
                <input
                  type="text"
                  id="estadoCamion"
                  name="estadoCamion"
                  value={incidente.estadoCamion}
                  placeholder="ESTADO CAMION"
                  className="p-2 border border-gray-300 rounded"
                  readOnly
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="fechaProceso" className="mb-2 font-bold">
                  FECHA PROCESO
                </label>
                <input
                  type="text"
                  id="fechaProceso"
                  name="fechaProceso"
                  value={incidente.fechaProceso}
                  placeholder="FECHA PROCESO"
                  className="p-2 border border-gray-300 rounded"
                  readOnly
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="descripcion" className="mb-2 font-bold">
                  DESCRIPCION
                </label>
                <textarea
                  type="text"
                  id="descripcion"
                  name="descripcion"
                  value={incidente.descripcion}
                  placeholder="DESCRIPCION"
                  className="p-2 border border-gray-300 rounded"
                  readOnly
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="nota" className="mb-2 font-bold">
                  NOTA
                </label>
                <textarea
                  type="text"
                  id="nota"
                  name="nota"
                  value={incidente.nota}
                  placeholder="NOTA"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={4000}
                  rows={3}
                />
              </div>
            </div>

          </div>
          <br />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="fechaFinalizacion" className="mb-2 font-bold">
                  FECHA FINALIZACION
                </label>
                {/* <input
                  className="border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-black" */}
                <DatePicker
                  valueFormat={{ dateStyle: "medium" }}
                  // defaultValue={incidente.fechaFinalizacion}
                  value={fechaFinalizacion}
                  onChange={(date) => setFechaFinalizacion(date)}
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0 text-right">
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_ATRAS}
                onClick={handleActualizar}
              >
                ACTUALIZAR
              </button>
            </div>

          </div>
          <br />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-4/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="descripcion" className="mb-2 font-bold">
                  IMAGEN
                </label>
                <img src={incidente.url} alt="Descripción de la imagen" />
              </div>
            </div>

          </div>
          <br />
          <div className="flex justify-between p-4">
            <button className={ESTILO_BOTON_GENERAL_CANCELAR}
              onClick={handleCancelClick}
            >
              CANCELAR
            </button>
          </div>
        </div>
      );
    } catch (err) {
      console.log('Incidente render err');
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  }

  return render();
}

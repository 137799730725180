import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Layout from "../components/layout";
import RouterDescargas from "./descargas/router";
import RouterCargas from "./cargas/router";
import RouterChecklist from "./checklist/router";
import RouterUsuarios from "./usuario/router";
import RouterPermisos from "./permisos/router";
import Dashboard from "./dashboard";
import Salir from "./salir";
import RouterConfiguracion from "./configuraciones/router";
import RouterDisponibilidad from "./disponibilidad/router";
import RouterSeguimiento from "./seguimiento/router";
import RouterEventos from "./eventos/router";
import RouterIncidente from "./incidente/router";
import RouterBitacora from "./bitacora/router";
import RouterReportes from "./reportes/router";
import RouterGeoruta from "./georuta/router";
import RouterConfiguracionGeoruta from "./configuraciones-georuta/router";
import RouterAlertas from "./alertas/router";

const RouterPage = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Layout>
        <Route path={`${path}checklist`}>
          <RouterChecklist />
        </Route>
        <Route path={`${path}usuario`}>
          <RouterUsuarios />
        </Route>
        <Route path={`${path}permisos`}>
          <RouterPermisos />
        </Route>
        <Route path={`${path}descargas`}>
          <RouterDescargas />
        </Route>
        <Route path={`${path}cargas`}>
          <RouterCargas />
        </Route>
        <Route path={`${path}configuracion`}>
          <RouterConfiguracion />
        </Route>
        <Route path={`${path}configuracion-georuta`}>
          <RouterConfiguracionGeoruta />
        </Route>
        <Route path={`${path}disponibilidad`}>
          <RouterDisponibilidad />
        </Route>
        <Route path={`${path}seguimiento`}>
          <RouterSeguimiento />
        </Route>
        <Route path={`${path}eventos`}>
          <RouterEventos />
        </Route>
        <Route path={`${path}incidente`}>
          <RouterIncidente />
        </Route>
        <Route path={`${path}bitacora`}>
          <RouterBitacora />
        </Route>
        <Route path={`${path}reportes`}>
          <RouterReportes />
        </Route>
        <Route path={`${path}georuta`}>
          <RouterGeoruta />
        </Route>
        <Route path={`${path}alertas`}>
          <RouterAlertas />
        </Route>
        <Route path={`${path}salir`}>
          <Salir />
        </Route>
        <Route exact path={`${path}`}>
          <Dashboard />
        </Route>
      </Layout>
    </Switch>
  );
};

export default RouterPage;

import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ESTILO_BOTON_GENERAL_ACEPTAR, ESTILO_BOTON_GENERAL_CANCELAR, ESTILO_INPUT_GENERICO } from "../../../utils/const-estilos";
import { crearTipoCarroceria, modificarTipoCarroceria } from "../../../api/checklist";
import "../../../style/style-cargando.css";
import "../../../style/style-procesando.css";

export default function Carroceria() {
  const location = useLocation();
  const history = useHistory();

  const [cargandoPage, setCargandoPage] = useState(true);
  const [procesando, setProcesando] = useState(false);
  const [carroceria, setCarroceria] = useState(null);
  const [titulo, setTitulo] = useState("");

  useEffect(() => {
    // console.log("entro a Correos");
    iniciar();
  }, []);

  const iniciar = () => {
    try {
      const data = location.state;
      // console.log("data");
      // console.log(data);
      if (data !== undefined && data !== null) {
        setCarroceria(data.params);
        setTitulo(data.titulo);
      }
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const nextPage = (page) => {
    history.push(`${page}`);
  };

  const handleInputChange = (event) => {
    console.log('handleInputChange');
    const { name, value } = event.target;
    console.log(name);
    console.log(value);
    setCarroceria({ ...carroceria, [name]: value });
  };

  const handleCancelClick = () => {
    history.goBack();
  };

  const handleSaveClick = async () => {
    try {
      // console.log('handleSaveClick');
      // lógica para guardar los cambios
      console.log('carroceria');
      console.log(carroceria);
      setProcesando(true);
      if (carroceria.id > 0) {
        console.log('actualizar carroceria');
        let objModificar = {};
        objModificar.id = carroceria.id;
        objModificar.descripcion = carroceria.descripcion;
        let modificado = await modificarTipoCarroceria(objModificar);
        console.log('modificado');
        console.log(modificado);
        if (modificado === true) {
          alert('Se modifico correctamente');
          nextPage("listar-tipo-carroceria");
        }
        if (modificado === false) {
          alert('No se pudo modificar');
        }
      }
      if (carroceria.id <= 0) {
        console.log('crear carroceria');
        let objCrear = {};
        objCrear.descripcion = carroceria.descripcion;
        let creado = await crearTipoCarroceria(objCrear);
        console.log('creado');
        console.log(creado);
        if (creado === true) {
          alert('Se creo correctamente');
          nextPage("listar-tipo-carroceria");
        }
        if (creado === false) {
          alert('No se pudo crear');
        }
      }
      setProcesando(false);
    } catch (err) {
      console.log('handleSaveClick err');
      console.log(err);
      setProcesando(false);
    }
  };

  const render = () => {
    // console.log('render modificar correo');
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (cargandoPage === false && carroceria === null) {
        return (
          <div>
            <label> Sin data</label>
          </div>
        );
      }
      return (
        <div className="min-h-screen bg-gray-100 flex flex-col  py-12 sm:px-6 lg:px-8">
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="text-center text-3xl font-extrabold text-gray-900">
              {titulo}
            </h2>
            <div className="mt-6 bg-white py-6 px-4 shadow-md rounded-lg sm:px-10">
              <div className="space-y-6">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Descripcion
                  </label>
                  <div className="mt-1">
                    <input
                      id="descripcion"
                      name="descripcion"
                      type="text"
                      value={carroceria.descripcion}
                      onChange={handleInputChange}
                      autoComplete="given-name"
                      className={ESTILO_INPUT_GENERICO}
                      required
                    />
                  </div>
                </div>

                <div className="flex justify-end">
                  <div
                    style={{
                      justifyContent: 'space-between',
                      display: 'flex',
                      width: '100%'
                    }}
                  >
                    <button
                      type="button"
                      className={ESTILO_BOTON_GENERAL_CANCELAR}
                      onClick={handleCancelClick}
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      className={ESTILO_BOTON_GENERAL_ACEPTAR}
                      onClick={handleSaveClick}
                    >
                      Aceptar
                    </button>
                  </div>
                </div>

                {
                  procesando === true ?
                    <div className="processing">
                      <div className="spinner"></div>
                      <p>Procesando información...</p>
                    </div>
                    :
                    null
                }
              </div>
            </div>
          </div>
        </div>
      );
    } catch (err) {
      console.log('ModificarCorreos render err');
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  }

  return render();
}

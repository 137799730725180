import { useState, useEffect } from "react";
import { ChevronRightIcon, ClockIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import {
  obtenerItinerarioGeoruta,
  obtenerItinerarioGeorutaDescarga,
  obtenerItinerarioGeorutaDescargaXEmpresa,
  obtenerEstadoItinerarioDescarga,
  finalizarGeorutaAsignadad,
} from "../../api/georuta";
import {
  obtenerGeocercaXId,
  obtenerPuntoActualVehiculoXPlaca,
  obtenerHistorico,
} from "../../api/gestion-dts/gestion";
import Select from "react-select";
import { MarkerWithLabel } from "@googlemaps/markerwithlabel";
import DetailsCard from "../../components/details-card";
//import Spinner from "../../components/spinner";

export default function VerEnMapa() {
  const [hiddenGrid, setHiddenGrid] = useState(false);
  const [map, setMap] = useState(null);
  const [google, setGoogle] = useState(null);
  const [markerWithLabel, setMarkerWithLabel] = useState(null);

  const [loadingMap, setLoadingMap] = useState(false);
  const [showDetails, setShowDetails] = useState([false]);
  const [listadoDescargasConGeoruta, setListadoDescargasConGeoruta] = useState(
    []
  );
  const [
    listadoDescargasConGeorutaFiltrada,
    setListadoDescargasConGeorutaFiltrada,
  ] = useState([]);
  const [
    listadoDescargasConGeorutaFiltradaOld,
    setListadoDescargasConGeorutaFiltradaOld,
  ] = useState([]);
  const [descargasConGeorutaSeleccionada, setDescargasConGeorutaSeleccionada] =
    useState(null);
  const [tabSeguimiento, setTabSeguimiento] = useState("Activos");
  const [georutaDescargaSeleccionada, setGeorutaDescargaSeleccionada] =
    useState(null);
  const [
    georutaDescargaPlacaSeleccionada,
    setGeorutaDescargaPlacaSeleccionada,
  ] = useState(null);
  const [optionFilter, setOptionFilter] = useState([]);
  const [georutaSeleccionada, setGeorutaSeleccionada] = useState(null);
  const [placaSeleccionada, setPlacaSeleccionada] = useState(null);
  const [itinerarioGeoruta, setItinerarioGeoruta] = useState([]);
  const [geocercaItinerario, setGeocercaItinerario] = useState(null);
  const [geocercaPrincipal, setGeocercaPrincipal] = useState(null);
  const [idAsignacionItinerarioDescarga, setIdAsignacionItinerarioDescarga] =
    useState(null);
  const [vehiculo, setVehiculo] = useState(null);
  const [tiempoManejado, setTiempoManejado] = useState("0:00");
  const [kmRecorrido, setKmRecorrido] = useState(0);
  const [kmRestante, setKmRestante] = useState(null);

  const mapInit = () => {
    var boltracksrl = { lat: -17.759, lng: -63.194 };
    let mapNew = new google.maps.Map(
      document.getElementById("gmapsCanvasContainer"),
      {
        zoom: 8,
        center: boltracksrl,
        zoomControl: true,
        mapTypeControl: false,
        mapTypeControlOptions: {
          mapTypeIds: ["hybrid", "roadmap", "satellite", "terrain"],
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
        streetViewControl: true,
        fullScreenControl: true,
        scrollwheel: true,
        noClear: true,
      }
    );
    setMap(mapNew);
  };

  const obtenerIcon = (vH, isA, eEstado, eVelocidad, id_icono) => {
    let esFlecha = isA;
    let motorEncendido = eEstado === 1;
    let tieneVelocidad = eVelocidad > 0.1;
    let angulo = (((vH + 22.5 > 360 ? vH + 22.5 - 360 : vH) / 45) | 0) * 45;
    if (esFlecha) id_icono = 0;
    let id_color_flecha = 1;
    if (!motorEncendido) id_color_flecha = 2;
    if (motorEncendido && !tieneVelocidad) id_color_flecha = 3;
    return `https://gestion.boltrack.net/assets/icons/${id_icono}_${angulo}_${id_color_flecha}.png`;
  };
  const obtenerListadoDescargasConGeoruta = async () => {
    const resp = await obtenerItinerarioGeorutaDescargaXEmpresa();
    setListadoDescargasConGeoruta(resp);
    console.log(resp);
    const listadoFiltrado = resp.filter((data) => data.georutaFinalizada === 0);
    setListadoDescargasConGeorutaFiltrada(listadoFiltrado);
    setListadoDescargasConGeorutaFiltradaOld(listadoFiltrado);
    let options = [];
    for (let x = 0; x < listadoFiltrado.length; x++) {
      options.push({
        value: listadoFiltrado[x].idAsignacionItinerarioDescarga,
        label: listadoFiltrado[x].planilla,
        tipo: "planilla",
      });
      options.push({
        value: listadoFiltrado[x].idAsignacionItinerarioDescarga,
        label: listadoFiltrado[x].placa,
        tipo: "placa",
      });
    }
    options.sort((a, b) => {
      if (a.tipo === "placa" && b.tipo === "planilla") {
        return -1; // a debe ir antes que b
      }
      if (a.tipo === "planilla" && b.tipo === "placa") {
        return 1; // a debe ir después que b
      }
      return 0; // no se cambian de posición
    });
    setOptionFilter(options);
    let array = [];
    for (let x = 0; x < resp.length; x++) {
      array.push(false);
    }
    setShowDetails(array);
  };
  const obtenerListadoItinerarioGeoruta = async () => {
    const resp = await obtenerItinerarioGeoruta({
      id: georutaDescargaSeleccionada,
    });
    setItinerarioGeoruta(resp);
  };
  const actualizarMarkerVehiculo = async () => {
    const resp = await obtenerPuntoActualVehiculoXPlaca({
      placa: placaSeleccionada,
      esSubcuenta: "0",
    });

    const updatedVehiculo = vehiculo;
    updatedVehiculo.setPosition(
      new google.maps.LatLng(resp[0].LATITUD, resp[0].LONGITUD)
    );

    setVehiculo(updatedVehiculo);
  };
  function obtenerFechasOrdenadas(puntosInteres) {
    // Extraer las fechas no nulas en un array

    const fechas = Object.values(puntosInteres)
      .map((puntoInteres) => puntoInteres.fechaHoraGeocercaDestinoSalida)
      .filter((fecha) => fecha !== null);

    // Ordenar las fechas
    fechas.sort((a, b) => new Date(a) - new Date(b));

    return fechas;
  }
  const obtenerPuntoActualVehiculo = async () => {
    let errorVehiculo = false;
    try {
      const resp = await obtenerPuntoActualVehiculoXPlaca({
        placa: placaSeleccionada,
        esSubcuenta: "0",
      });

      if (resp.length === 0) {
        errorVehiculo = true;
      }
      const marker = new MarkerWithLabel({
        position: new google.maps.LatLng(resp[0].LATITUD, resp[0].LONGITUD),
        icon: {
          url: obtenerIcon(
            resp[0].ORIENT,
            false,
            resp[0].I_ENCENDIDO,
            resp[0].VELOCIDAD,
            resp[0].icono
          ),
          scaledSize: new google.maps.Size(50, 50),
          //anchor: { x: 15, y: 15 },
        },
        //clickable: true,

        map: map,
        labelContent: `<div style='color: white; background-color: #0F3457;border-radius: 5px; font-weight: 900;padding-left: 10px; padding-right: 10px;'>
        <p>${resp[0].nombre}</p>
         </div>`, // can also be HTMLElement
        labelAnchor: new google.maps.Point(-21, 3),
        labelClass: "labels", // the CSS class for the label
        labelStyle: { opacity: 1.0 },
      });
      setVehiculo(marker);
    } catch (e) {
      if (errorVehiculo) {
        alert(
          "La placa asignada a esta georuta no existe en la web de gestion"
        );
      } else {
        alert(
          "Hay un error con esta georuta asignada, porfavor revise que los datos esten correctos"
        );
      }
    }
  };
  var rad = function (x) {
    return (x * Math.PI) / 180;
  };
  var getDistance = function (p1, p2) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2.lat - p1.lat);
    var dLong = rad(p2.lng - p1.lng);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat)) *
        Math.cos(rad(p2.lat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d; // returns the distance in meter
  };
  const finalizarGeorutaAsignada = async (data) => {
    const resp = await finalizarGeorutaAsignadad({
      id: data,
    });
    obtenerListadoDescargasConGeoruta();
    alert("La ruta a sido finalizada manualmente");
  };
  const obtenerGeocerca = async () => {
    await inicializarGeocercas();
    const itinerarios = [];
    let ptsArray = [];
    let completePoint = [];
    let poligonos = [];
    let markerGeocerca = [];
    let infoGeocerca = [];
    const respEstadoItinerarioDescarga = await obtenerEstadoItinerarioDescarga({
      id: idAsignacionItinerarioDescarga,
    });

    const jsonEstadoItinerario = JSON.parse(
      respEstadoItinerarioDescarga[0].estadoItinerario
    );
    console.log(jsonEstadoItinerario);
    const resFechas = obtenerFechasOrdenadas(jsonEstadoItinerario);
    if (resFechas.length > 1) {
      const fechaInicio = new Date(resFechas[0]);
      const fechaFin = new Date(resFechas[resFechas.length - 1]);
      const diferencia = fechaFin - fechaInicio;
      const horas = Math.floor(diferencia / (1000 * 60 * 60));
      let minutos = Math.floor((diferencia / (1000 * 60)) % 60);
      minutos = minutos.toString().padStart(2, "0");
      let diaInicio = fechaInicio.getDate().toString().padStart(2, "0");
      let mesInicio = (fechaInicio.getMonth() + 1).toString().padStart(2, "0"); // Los meses empiezan en 0
      let anoInicio = fechaInicio.getFullYear();
      let horasInicioFormat = fechaInicio
        .getHours()
        .toString()
        .padStart(2, "0");
      let minutosInicioFormat = fechaInicio
        .getMinutes()
        .toString()
        .padStart(2, "0");

      let horasFinFormat = fechaFin.getHours().toString().padStart(2, "0");
      let minutosFinFormat = fechaFin.getMinutes().toString().padStart(2, "0");
      let diaFin = fechaFin.getDate().toString().padStart(2, "0");
      let mesFin = (fechaFin.getMonth() + 1).toString().padStart(2, "0"); // Los meses empiezan en 0
      let anoFin = fechaFin.getFullYear();
      setTiempoManejado(`${horas}:${minutos}`);
      const respHistorico = await obtenerHistorico({
        placa: placaSeleccionada,
        FechaIni: `${anoInicio}-${mesInicio}-${diaInicio}`,
        FechaFin: `${anoFin}-${mesFin}-${diaFin}`,
        HoraIni: `${horasInicioFormat}:${minutosInicioFormat}`,
        HoraFin: `${horasFinFormat}:${minutosFinFormat}`,
      });

      let distanciaTotalEnMetros = 0;
      for (let x = 0; x < respHistorico.length; x++) {
        if (x > 0) {
          let resp = getDistance(respHistorico[x - 1], respHistorico[x]);
          distanciaTotalEnMetros = distanciaTotalEnMetros + resp;
        }
      }

      setKmRecorrido(Math.trunc(distanciaTotalEnMetros / 1000));
    }
    let totalKmRestante = 0;
    itinerarioGeoruta.map(async (data, index) => {
      totalKmRestante = totalKmRestante + data.distancia;
      const resp = await obtenerGeocercaXId({ id: data.idGeocercaDestino });

      let objGeocerca = JSON.parse(resp.data[0].coordenadas);

      if (objGeocerca["type"] === "Polygon") {
        let objCoordenadas = objGeocerca["coordinates"];
        for (let x = 0; x < objCoordenadas.length; x++) {
          let objCoordenada = objCoordenadas[x];
          ptsArray = [];
          for (let j = 0; j < objCoordenada.length; j++) {
            let sCoorLat = objCoordenada[j][0];
            let sCoorLng = objCoordenada[j][1];
            ptsArray.push(new google.maps.LatLng(sCoorLat, sCoorLng));
          }
          if (x === 0) {
            if (objCoordenadas.length === 1) {
              completePoint = ptsArray;
            } else {
              completePoint.push(ptsArray);
            }
          } else {
            completePoint.push(ptsArray);
          }
        }
      }

      poligonos[index] = new google.maps.Polygon({
        paths: completePoint,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: "#FF0000",
        fillOpacity: 0.4,
        clickable: false,
      });
      poligonos[index].setMap(map);
      var bounds = new google.maps.LatLngBounds();
      let polygonCoords =
        completePoint[0].length > 0 ? completePoint[0] : completePoint;
      for (let j = 0; j < polygonCoords.length; j++) {
        bounds.extend(polygonCoords[j]);
      }
      let corlatlng = bounds.getCenter();
      markerGeocerca[index] = new MarkerWithLabel({
        icon:
          jsonEstadoItinerario[data.id].estado === "Finalizado"
            ? "https://mts.googleapis.com/vt/icon/name=icons/spotlight/spotlight-waypoint-a.png&psize=16&font=fonts/Roboto-Regular.ttf&color=ff333333&ax=44&ay=48&scale=1"
            : null,
        position: bounds.getCenter(),
        draggable: true,
        clickable: false,
        raiseOnDrag: true,
        map: map,

        labelContent:
          "<div style='color: white; background-color: #0F3457;border-radius: 5px; font-weight: 900;'>. " +
          data.nombreGeocercaDestino +
          " .</div>",
        labelAnchor: new google.maps.Point(22, -15),
        labelClass: "labelGeocerca", //the CSS class for the label
        labelStyle: { opacity: 0.95 },
      });
      console.log(data);
      markerGeocerca[index].setMap(map);
      infoGeocerca[index] = new google.maps.InfoWindow({
        content:
          "<div><div>Nombre:" + data.nombreGeocercaDestino + " </div></div>",
      });
      const fechaDestinoEntrada =
        jsonEstadoItinerario[data.id].fechaHoraGeocercaDestinoEntrada;
      const fechaDestinoSalida =
        jsonEstadoItinerario[data.id].fechaHoraGeocercaDestinoSalida;

      const fechaOrigenSalida =
        jsonEstadoItinerario[data.id].fechaHoraGeocercaOrigenSalida;
      // Calcula la diferencia en milisegundos
      const duracionEspera = moment(fechaDestinoSalida).diff(
        moment(fechaDestinoEntrada)
      );
      const duracionViaje = moment(fechaDestinoEntrada).diff(
        moment(fechaOrigenSalida)
      );
      // Convierte la duración a un formato legible
      const duracionEsperaFormateada = moment
        .utc(duracionEspera)
        .format("HH:mm:ss");
      const duracionViajeFormateada = moment
        .utc(duracionViaje)
        .format("HH:mm:ss");

      google.maps.event.addListener(
        markerGeocerca[index],
        "click",
        function (e) {
          console.log("entra " + index);
          infoGeocerca[index].setContent(`<div>
            <div class='flex'><div class='font-bold'>Hora de inicio de viaje:</div> ${
              jsonEstadoItinerario[data.id].fechaHoraGeocercaOrigenSalida
                ? moment(
                    jsonEstadoItinerario[data.id].fechaHoraGeocercaOrigenSalida
                  ).format("DD-MM-YYYY HH:mm:ss")
                : "-"
            } </div>
            <div class='flex'><div class='font-bold'>Hora de entrada Destino:</div> ${
              jsonEstadoItinerario[data.id].fechaHoraGeocercaDestinoEntrada
                ? moment(
                    jsonEstadoItinerario[data.id]
                      .fechaHoraGeocercaDestinoEntrada
                  ).format("DD-MM-YYYY HH:mm:ss")
                : "-"
            } </div>
            <div class='flex'><div class='font-bold'>Hora de salida Destino:</div> ${
              jsonEstadoItinerario[data.id].fechaHoraGeocercaDestinoSalida
                ? moment(
                    jsonEstadoItinerario[data.id].fechaHoraGeocercaDestinoSalida
                  ).format("DD-MM-YYYY HH:mm:ss")
                : "-"
            }</div>
            <div class='flex'><div class='font-bold'>Horas de espera programadas: </div>${
              data.tiempoExtraPermitido != "" ? data.tiempoExtraPermitido : "-"
            }</div>
            <div class='flex'><div class='font-bold'>Tiempo de espera en destino:</div> ${
              duracionEsperaFormateada != "Invalid date"
                ? duracionEsperaFormateada
                : "-"
            }</div>
            <div class='flex'><div class='font-bold'>Horas programadas de viaje: </div>${
              data.tiempoProgramado != "" ? data.tiempoProgramado : "-"
            }</div>
            <div class='flex'><div class='font-bold'>Tiempo de viaje: </div>${
              duracionViajeFormateada != "Invalid date"
                ? duracionViajeFormateada
                : "-"
            }</div>
            </div>`);
          infoGeocerca[index].setPosition(this.getPosition());
          infoGeocerca[index].open(map);
          //zoomLevel(this.getPosition());
        },
        {
          passive: true,
        } /*TODO: Revisar si se necesita el passive true */
      );
      setMarkerWithLabel(markerGeocerca);
    });

    setKmRestante(totalKmRestante);

    const resp = await obtenerGeocercaXId({ id: georutaSeleccionada });

    let objGeocerca = JSON.parse(resp.data[0].coordenadas);

    if (objGeocerca["type"] === "Polygon") {
      let objCoordenadas = objGeocerca["coordinates"];
      for (let x = 0; x < objCoordenadas.length; x++) {
        let objCoordenada = objCoordenadas[x];
        ptsArray = [];
        for (let j = 0; j < objCoordenada.length; j++) {
          let sCoorLat = objCoordenada[j][0];
          let sCoorLng = objCoordenada[j][1];
          ptsArray.push(new google.maps.LatLng(sCoorLat, sCoorLng));
        }
        if (x === 0) {
          if (objCoordenadas.length === 1) {
            completePoint = ptsArray;
          } else {
            completePoint.push(ptsArray);
          }
        } else {
          completePoint.push(ptsArray);
        }
      }
    }

    const poligonoPrincipal = new google.maps.Polygon({
      paths: completePoint,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: "#FF0000",
      fillOpacity: 0.4,
      clickable: false,
    });
    poligonoPrincipal.setMap(map);

    setGeocercaPrincipal(poligonoPrincipal);
    setGeocercaItinerario(poligonos);
  };
  const inicializarGeocercas = async () => {
    setTiempoManejado("0:00");
    setKmRecorrido(0);
    setKmRestante(null);

    if (geocercaPrincipal !== null) {
      geocercaPrincipal.setMap(null);
      setGeocercaPrincipal(null);
    }
    if (geocercaItinerario !== null) {
      if (geocercaItinerario.length > 0) {
        for (let x = 0; x < geocercaItinerario.length; x++) {
          geocercaItinerario[x].setMap(null);
        }
        setGeocercaItinerario(null);
      }
    }

    if (markerWithLabel) {
      for (let j = 0; j < markerWithLabel.length; j++) {
        markerWithLabel[j].setMap(null);
      }
    }
    setMarkerWithLabel(null);
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/jsapi";
    script.onload = () => {
      setGoogle(window.google);
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    if (google) {
      mapInit();
    } else {
    }
  }, [google]);

  useEffect(() => {
    if (map) {
      obtenerListadoDescargasConGeoruta();
    }
  }, [map]);
  useEffect(() => {
    if (georutaDescargaPlacaSeleccionada) {
      if (markerWithLabel) {
        for (let j = 0; j < markerWithLabel.length; j++) {
          markerWithLabel[j].setMap(null);
        }
      }
      setMarkerWithLabel(null);
      if (geocercaItinerario) {
        for (let x = 0; x < geocercaItinerario.length; x++) {
          geocercaItinerario[x].setMap(null);
        }
      }

      if (vehiculo) {
        vehiculo.setMap(null);
        setVehiculo(null);
      }
      setGeocercaItinerario(null);
      obtenerListadoItinerarioGeoruta();
      obtenerPuntoActualVehiculo();
    }
  }, [georutaDescargaPlacaSeleccionada]);
  useEffect(() => {
    if (itinerarioGeoruta.length > 0) {
      obtenerGeocerca();
    }
  }, [itinerarioGeoruta]);
  useEffect(() => {
    if (vehiculo) {
      const interval = setInterval(() => {
        actualizarMarkerVehiculo();
      }, 12000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [vehiculo]);

  return (
    <>
      <div className="flex">
        <div
          className={`w-[30px] flex bg-[#091d30] h-screen ${
            !hiddenGrid ? "hidden" : "block"
          }`}
          onClick={() => {
            setHiddenGrid(false);
          }}
        >
          <ChevronRightIcon className="text-white m-auto" />
        </div>
        <div
          className={`min-w-[300px] w-[30vw] bg-[#091d30] h-screen p-[10px] ${
            hiddenGrid ? "hidden" : "block"
          }`}
        >
          <div className="pb-[5px] flex justify-center">
            <p className="text-white font-medium w-[90%] text-center">
              Ver Seguimientos
            </p>
            <p
              className="text-white font-medium text-rigth"
              onClick={() => {
                setHiddenGrid(true);
              }}
            >
              X
            </p>
          </div>
          <div className="bg-[#113A5F] h-[97%] mb-[16px] w-full rounded-md p-[5px] overflow-y-scroll">
            <div className="flex justify-start ">
              <button
                className={`mr-[5px] ${
                  tabSeguimiento === "Activos"
                    ? "bg-[#091d30] text-white"
                    : "bg-white"
                } rounded-t-lg px-2 py-1`}
                onClick={() => {
                  setTabSeguimiento("Activos");
                  inicializarGeocercas();
                  if (vehiculo) {
                    vehiculo.setMap(null);
                    setVehiculo(null);
                  }
                  setDescargasConGeorutaSeleccionada(null);
                  setShowDetails([]);
                  const listadoFiltrado = listadoDescargasConGeoruta.filter(
                    (data) => data.georutaFinalizada === 0
                  );
                  const resShowDetails = listadoFiltrado.map((data) => false);
                  setShowDetails(resShowDetails);
                  setListadoDescargasConGeorutaFiltrada(listadoFiltrado);
                  setListadoDescargasConGeorutaFiltradaOld(listadoFiltrado);
                  let options = [];
                  for (let x = 0; x < listadoFiltrado.length; x++) {
                    options.push({
                      value: listadoFiltrado[x].idAsignacionItinerarioDescarga,
                      label: listadoFiltrado[x].planilla,
                      tipo: "planilla",
                    });
                    options.push({
                      value: listadoFiltrado[x].idAsignacionItinerarioDescarga,
                      label: listadoFiltrado[x].placa,
                      tipo: "placa",
                    });
                  }
                  options.sort((a, b) => {
                    if (a.tipo === "placa" && b.tipo === "planilla") {
                      return -1; // a debe ir antes que b
                    }
                    if (a.tipo === "planilla" && b.tipo === "placa") {
                      return 1; // a debe ir después que b
                    }
                    return 0; // no se cambian de posición
                  });
                  setOptionFilter(options);
                }}
              >
                Activos
              </button>
              <button
                className={`mr-[5px] ${
                  tabSeguimiento === "Finalizados"
                    ? "bg-[#091d30] text-white"
                    : "bg-white"
                } rounded-t-lg px-2 py-1`}
                onClick={() => {
                  setTabSeguimiento("Finalizados");
                  inicializarGeocercas();
                  if (vehiculo) {
                    vehiculo.setMap(null);
                    setVehiculo(null);
                  }
                  setDescargasConGeorutaSeleccionada(null);
                  const listadoFiltrado = listadoDescargasConGeoruta.filter(
                    (data) => data.georutaFinalizada === 1
                  );
                  setShowDetails([]);
                  const resShowDetails = listadoFiltrado.map((data) => false);
                  setShowDetails(resShowDetails);
                  setListadoDescargasConGeorutaFiltrada(listadoFiltrado);
                  setListadoDescargasConGeorutaFiltradaOld(listadoFiltrado);
                  let options = [];
                  for (let x = 0; x < listadoFiltrado.length; x++) {
                    options.push({
                      value: listadoFiltrado[x].idAsignacionItinerarioDescarga,
                      label: listadoFiltrado[x].planilla,
                      tipo: "planilla",
                    });
                    options.push({
                      value: listadoFiltrado[x].idAsignacionItinerarioDescarga,
                      label: listadoFiltrado[x].placa,
                      tipo: "placa",
                    });
                  }
                  options.sort((a, b) => {
                    if (a.tipo === "placa" && b.tipo === "planilla") {
                      return -1; // a debe ir antes que b
                    }
                    if (a.tipo === "planilla" && b.tipo === "placa") {
                      return 1; // a debe ir después que b
                    }
                    return 0; // no se cambian de posición
                  });
                  setOptionFilter(options);
                }}
              >
                Finalizados
              </button>
              <button
                className={`mr-[5px] ${
                  tabSeguimiento === "Todos"
                    ? "bg-[#091d30] text-white"
                    : "bg-white"
                } rounded-t-lg px-2 py-1`}
                onClick={() => {
                  setTabSeguimiento("Todos");
                  inicializarGeocercas();
                  if (vehiculo) {
                    vehiculo.setMap(null);
                    setVehiculo(null);
                  }
                  setShowDetails([]);
                  setDescargasConGeorutaSeleccionada(null);
                  const resShowDetails = listadoDescargasConGeoruta.map(
                    (data) => false
                  );
                  setShowDetails(resShowDetails);
                  setListadoDescargasConGeorutaFiltrada(
                    listadoDescargasConGeoruta
                  );
                  setListadoDescargasConGeorutaFiltradaOld(
                    listadoDescargasConGeoruta
                  );
                  let options = [];
                  for (let x = 0; x < listadoDescargasConGeoruta.length; x++) {
                    options.push({
                      value:
                        listadoDescargasConGeoruta[x]
                          .idAsignacionItinerarioDescarga,
                      label: listadoDescargasConGeoruta[x].planilla,
                      tipo: "planilla",
                    });
                    options.push({
                      value:
                        listadoDescargasConGeoruta[x]
                          .idAsignacionItinerarioDescarga,
                      label: listadoDescargasConGeoruta[x].placa,
                      tipo: "placa",
                    });
                  }
                  options.sort((a, b) => {
                    if (a.tipo === "placa" && b.tipo === "planilla") {
                      return -1; // a debe ir antes que b
                    }
                    if (a.tipo === "planilla" && b.tipo === "placa") {
                      return 1; // a debe ir después que b
                    }
                    return 0; // no se cambian de posición
                  });
                  setOptionFilter(options);
                }}
              >
                Todos
              </button>
            </div>
            <div className="bg-[#091d30] h-auto p-[5px] w-full rounded-r-lg rounded-bl-lg">
              <Select
                options={optionFilter}
                isSearchable="true"
                isClearable="true"
                value={descargasConGeorutaSeleccionada}
                onInputChange={(event) => {
                  if (event !== "") {
                    setListadoDescargasConGeorutaFiltrada(
                      listadoDescargasConGeorutaFiltradaOld.filter((data) =>
                        `${data.planilla + " - " + data.placa}`.includes(event)
                      )
                    );

                    let array = [...optionFilter];
                    if (optionFilter[optionFilter.length - 1].value == 0) {
                      array[optionFilter.length - 1] = {
                        value: 0,
                        label: event,
                      };
                    } else {
                      array[optionFilter.length] = { value: 0, label: event };
                    }
                    setOptionFilter([...array]);
                  }
                }}
                onChange={(event) => {
                  inicializarGeocercas();
                  if (vehiculo) {
                    vehiculo.setMap(null);
                    setVehiculo(null);
                  }
                  if (event !== null) {
                    setListadoDescargasConGeorutaFiltrada(
                      listadoDescargasConGeorutaFiltradaOld.filter((data) =>
                        `${data.planilla + " - " + data.placa}`.includes(
                          event.label
                        )
                      )
                    );
                    const resShowDetails = listadoDescargasConGeorutaFiltrada
                      .filter((data) =>
                        `${data.planilla + " - " + data.placa}`.includes(
                          event.label
                        )
                      )
                      .map((data) => false);
                    setShowDetails(resShowDetails);
                  } else {
                    setListadoDescargasConGeorutaFiltrada(
                      listadoDescargasConGeorutaFiltradaOld
                    );
                    const resShowDetails =
                      listadoDescargasConGeorutaFiltradaOld.map(
                        (data) => false
                      );
                    setShowDetails(resShowDetails);
                  }

                  setDescargasConGeorutaSeleccionada(event);
                }}
                placeholder="Filtre por placa o planilla"
                className="mt-[10px] mx-[10px]"
              />
              {listadoDescargasConGeorutaFiltrada.length > 0 &&
                listadoDescargasConGeorutaFiltrada.map((data, index) => (
                  <div
                    key={"card-viaje-" + index}
                    className="bg-white min-h-[100px] h-auto m-[10px] p-2"
                    onClick={() => {
                      let arrayShowDetails = showDetails;
                      for (let i = 0; i < arrayShowDetails.length; i++) {
                        if (index !== i) {
                          arrayShowDetails[i] = false;
                        }
                      }

                      arrayShowDetails[index] = !showDetails[index];

                      setShowDetails(arrayShowDetails);

                      setGeorutaDescargaSeleccionada(data.georuta);
                      setGeorutaDescargaPlacaSeleccionada(
                        data.georuta +
                          "-" +
                          data.placa +
                          "-" +
                          data.planilla +
                          "-" +
                          tabSeguimiento
                      );
                      setGeorutaSeleccionada(data.idGeoruta);
                      setIdAsignacionItinerarioDescarga(
                        data.idAsignacionItinerarioDescarga
                      );
                      setPlacaSeleccionada(data.placa);
                    }}
                  >
                    <p>{`${data.planilla} - ${data.placa} - ${data.nombreFletero} - ${data.depositoOrigen}/${data.depositoDestino}`}</p>
                    {data.georutaFinalizada ? <p>{"(Finalizada)"}</p> : ""}
                    {!data.georutaFinalizada ? (
                      <button
                        className="my-auto bg-[#84B044] rounded text-white p-[2px] px-[10px] mr-[10px] mt-[5px] disabled:bg-gray-300"
                        onClick={() => {
                          finalizarGeorutaAsignada(
                            data.idAsignacionItinerarioDescarga
                          );
                          inicializarGeocercas();
                        }}
                      >
                        Finalizar
                      </button>
                    ) : (
                      ""
                    )}
                    <DetailsCard
                      tiempoManejado={tiempoManejado}
                      kmRecorrido={kmRecorrido}
                      kmRestante={kmRestante}
                      showDetails={showDetails[index]}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {!loadingMap ? (
          <div
            id="gmapsCanvasContainer"
            style={{
              width: "100%",
              minHeight: "855px",
              height: "100vh",
              zIndex: 1,
            }}
          ></div>
        ) : (
          <div>Cargando...</div>
        )}
      </div>
    </>
  );
}

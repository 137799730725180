import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "../../components/grid";
import {
  ESTILO_BOTON_GRILLA_NEXT,
  ESTILO_BOTON_GRILLA_PREVIOUS,
  ESTILO_BOTON_LISTAR_ATRAS,
  ESTILO_BOTON_LISTAR_NUEVO,
} from "../../utils/const-estilos";
import {
  deshabilitarListaCorreosAsignadosAGeocerca,
  guardarListaCorreosAsignadosAGeocerca,
  modificarListaCorreosAsignadosAGeocerca,
  obtenerGrupoGeocercas,
  obtenerListaCorreos,
  obtenerListaCorreosAsignadosAGeocerca,
} from "../../api/checklist";
import "../../style/style-cargando.css";
import { INT_ID_MODULO_CHECKLIST, INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST, INT_ID_TERCER_NIVEL_CHECKLIST_ASIGNAR_CORREO, INT_TIPO_USUARIO_ADMINISTRADOR_WEB } from "../../utils/const-int";
import { obtenerInfoGeocercaMedianteIdGrupo } from "../../api/gestion-dts/gestion";
import Select from "react-select";
import ModalConfirmar from "../../components/modal-confirmar";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
import { TRUE } from "../../utils/const-bool";

const idTipoUsuario = parseInt(localStorage.getItem("tipo"));
let accesoBotonNuevo = '';

export default function AsignarCorreoAGeocerca() {
  const headers = [
    { key: "acciones", title: "ACCIONES" },
    { key: "idgeocerca", title: "CODIGO", },
    { key: "nombregeocerca", title: "GEOCERCA" },
    { key: "correos", title: "CORREOS", visible: true, },
    { key: "fechaproceso", title: "FECHA PROCESO", filter: true },
  ];
  const history = useHistory();
  const [minRows, setMinRows] = useState(0);
  const [maxRows, setMaxRows] = useState(5);
  const [cargandoPage, setCargandoPage] = useState(true);
  const [listaCorreosAsignados, setListaCorreosAsignados] = useState([]);
  const [correoAsignadoSelected, setCorreoAsignadoSelected] = useState(null);
  const [listaGeocercas, setListaGeocercas] = useState([]);
  const [geocercaSelected, setGeocercaSelected] = useState(null);
  const [listaCorreos, setListaCorreos] = useState([]);
  const [correosSelected, setCorreosSelected] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalModificarVisible, setModalModificarVisible] = useState(false);
  const [modalDeshabilitarVisible, setModalDeshabilitarVisible] = useState(false);
  const [permisos, setPermisos] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);

  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      let objPermiso = {};
      objPermiso.idMenuPrimerNivel = INT_ID_MODULO_CHECKLIST;
      objPermiso.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST;
      objPermiso.idMenuTercerNivel = INT_ID_TERCER_NIVEL_CHECKLIST_ASIGNAR_CORREO;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(objPermiso);
      let permiso = obtenerPermisos[0];
      setPermisos(permiso);

      reset(permiso);

      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const agregarAccionesALista = (lista, permiso) => {
    try {
      if (lista.length === 0) {
        setListaCorreosAsignados(lista);
      }
      if (lista.length > 0) {
        let objDeshabilitar = {
          label: "DESHABILITAR",
          color: "red",
          onClick: (param) => deshabilitarAsignacion(param),
        };
        let objModificar = {
          label: "MODIFICAR",
          color: "green",
          onClick: (param) => modificarAsignacion(param),
        };
        if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
          if (permiso.nuevo === TRUE) {
            accesoBotonNuevo = '';
          }
        }
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            acciones.push(objModificar);
            acciones.push(objDeshabilitar);
          }
          if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            if (permiso.modificar === TRUE) {
              acciones.push(objModificar);
            }
            if (permiso.eliminarDeshabilitar === TRUE) {
              acciones.push(objDeshabilitar);
            }
          }
          lista[i].acciones = acciones;

          lista[i].correos = JSON.parse(lista[i].correos);
        }
        setListaCorreosAsignados(lista);
      }
    } catch (err) {
      console.log('agregarAccionesALista err');
      console.log(err);
    }
  }

  const deshabilitarAsignacion = (data) => {
    setCorreoAsignadoSelected(data);
    setModalDeshabilitarVisible(true);
  }

  const handleNextPage = () => {
    if (maxRows >= listaCorreosAsignados.length) return;
    setMinRows(maxRows);
    setMaxRows(maxRows + 5);
  };

  const handlePrevPage = () => {
    if (minRows <= 0) return;
    setMaxRows(minRows);
    setMinRows(Math.max(minRows - 5, 0));
  };

  const modificarAsignacion = (data) => {
    try {
      setGeocercaSelected({ label: data.nombregeocerca, value: data.idgeocerca });
      setCorreosSelected(data.correos);
      setCorreoAsignadoSelected(data);
      setModalModificarVisible(true);
    } catch (err) {
      console.log("modificarAsignacion err");
      console.log(err);
    }
  };

  const reset = async (permiso) => {
    try {
      setCargandoPage(true);
      let lista = [];
      // console.log('lista');
      // console.log(lista);
      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        accesoBotonNuevo = '';
        lista = await procesarParaPermiso();
        setTieneAccesoAlModulo(true);
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.accesoAlModulo === TRUE) {
          lista = await procesarParaPermiso();
          setTieneAccesoAlModulo(true);
        }
      }
      agregarAccionesALista(lista, permiso);
      setCargandoPage(false);
    } catch (err) {
      console.log('reset err');
      console.log(err);
      setListaCorreosAsignados([]);
      setCargandoPage(false);
    }
  }

  const procesarParaPermiso = async () => {
    try {
      let lista = [];
      let listaEmails = await obtenerListaCorreos();
      // console.log('listaEmails');
      // console.log(listaEmails);
      let correos = [];
      for (let i = 0; i < listaEmails.length; i++) {
        const element = listaEmails[i];
        let obj = {};
        obj.value = element.id;
        obj.label = element.email;
        correos.push(obj);
      }
      setListaCorreos(correos);
      lista = await obtenerListaCorreosAsignadosAGeocerca();
      // console.log('lista');
      // console.log(lista);
      let listaGrupo = await obtenerGrupoGeocercas();
      if (listaGrupo !== null) {
        // console.log('listaGrupo');
        // console.log(listaGrupo);
        let meta = JSON.parse(listaGrupo.meta);
        let listaIdGrupo = "";
        for (let i = 0; i < meta.length; i++) {
          const element = meta[i];
          listaIdGrupo = listaIdGrupo + element + ",";
        }
        listaIdGrupo = listaIdGrupo.substring(0, listaIdGrupo.length - 1);
        // console.log('listaIdGrupo');
        // console.log(listaIdGrupo);
        let obj = {};
        obj.idGrupoGeocerca = listaIdGrupo;
        let listaGeo = await obtenerInfoGeocercaMedianteIdGrupo(obj);
        // console.log('listaGeo');
        // console.log(listaGeo);
        listaGeo = listaGeo.filter(element => {
          return !lista.some(elem => elem.idgeocerca === element.value);
        });
        // console.log('listaGeo');
        // console.log(listaGeo);
        setListaGeocercas(listaGeo);
      }
      return lista;
    } catch (err) {
      console.log('procesarParaPermiso');
      console.log(err);
      return [];
    }
  }

  const handleNuevoClick = () => {
    console.log("handleNuevoClick");
    setGeocercaSelected(null);
    setCorreosSelected(null);
    setModalVisible(true);
  };

  const handleOptionSeleccionarGeocerca = (event) => {
    setGeocercaSelected(event);
  };

  const handleOptionSeleccionarCorreos = (event) => {
    setCorreosSelected(event);
  };

  const handleModalButtonCancel = () => {
    setModalVisible(false)
  };

  const handleModalButtonAceptar = async () => {
    if (geocercaSelected === null) {
      alert('Debe seleccionar geocerca');
      return;
    }
    if (correosSelected === null) {
      alert('Debe seleccionar correos');
      return;
    }
    let objEnviar = {};
    objEnviar.idGeocerca = geocercaSelected.value;
    objEnviar.nombreGeocerca = geocercaSelected.label;
    objEnviar.correos = correosSelected;
    let guardo = await guardarListaCorreosAsignadosAGeocerca(objEnviar);
    // console.log(guardo);
    if (guardo === true) {
      alert('Se creo correctamente');
      reset(permisos);
      setModalVisible(false);
    }
    if (guardo === false) {
      alert('No se pudo guardar, intente nuevamente');
    }
  };

  const handleModalModificarButtonCancel = () => {
    setModalModificarVisible(false)
  };

  const handleModalModificarButtonAceptar = async () => {
    if (correosSelected === null) {
      alert('Debe seleccionar correos');
      return;
    }
    let objEnviar = {};
    objEnviar.correos = correosSelected;
    objEnviar.id = correoAsignadoSelected.id;
    const modifico = await modificarListaCorreosAsignadosAGeocerca(objEnviar);
    if (modifico === true) {
      alert('Se modifico correctamente');
      reset(permisos);
      setModalModificarVisible(false);
    }
    if (modifico === false) {
      alert('No se pudo modificar, intente nuevamente');
    }
  }

  const handleCancelDeshabilitarModal = () => {
    setModalDeshabilitarVisible(false);
  }

  const handleConfirmDeshabilitarModal = async () => {
    let objEnviar = {};
    objEnviar.id = correoAsignadoSelected.id;
    const deshabilitado = await deshabilitarListaCorreosAsignadosAGeocerca(objEnviar);
    // console.log('deshabilitado');
    // console.log(deshabilitado);
    if (deshabilitado === true) {
      alert('Se deshabilito correctamente');
      reset(permisos);
      setModalDeshabilitarVisible(false);
    }
    if (deshabilitado === false) {
      alert('No se pudo deshabilitar, intente nuevamente');
    }
  }

  const handleAtrasClick = () => {
    history.goBack();
  }

  const render = () => {
    try {
      // console.log('listar correo render');
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div
              style={{ marginTop: 10, }}
            >
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <label
              style={{
                paddingLeft: 4,
                fontWeight: 'bold',
              }}
            >

            </label>
            <button className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <label
              style={{
                paddingLeft: 10,
                fontWeight: 'bold',
              }}
            >
              LISTA CORREOS ASIGNADOS A GEOCERCA
            </label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_NUEVO}
              onClick={handleNuevoClick}
              style={{
                display: accesoBotonNuevo,
              }}
            >
              NUEVO
            </button>
          </div>
          <Grid rows={listaCorreosAsignados} headers={headers} />
          <div>
            <button className={ESTILO_BOTON_GRILLA_PREVIOUS} onClick={handlePrevPage}>Previous</button>
            <button className={ESTILO_BOTON_GRILLA_NEXT} onClick={handleNextPage}>Next</button>
          </div>
          {modalVisible && (
            <div className="fixed z-50 top-0 left-0 right-0 bottom-0 flex items-center justify-center">
              <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
              <div className="bg-white w-11/12 h-2/4 md:max-w-3xl mx-auto rounded shadow-lg z-50 overflow-y-auto flex flex-col justify-between">
                <div className="py-4 text-left px-6">
                  <div className="mb-4">
                    <p className="text-xl">{'AGREGAR CORREOS A GEOCERCAS'}</p>
                    <div className="flex mt-4">
                      <div className="w-1/2 pr-2">
                        <div>
                          <label className="block mb-2 font-bold">SELECCIONAR GEOCERCA</label>
                          <Select options={listaGeocercas} isSearchable='true'
                            isClearable='true'
                            value={geocercaSelected}
                            onChange={(event) => handleOptionSeleccionarGeocerca(event)}
                            placeholder='Seleccione Geocerca'
                          />
                        </div>
                      </div>
                      <div className="w-1/2 pl-2">
                        <div>
                          <label className="block mb-2 font-bold">SELECCIONAR CORREOS</label>
                          <Select options={listaCorreos} isSearchable='true'
                            isMulti
                            isClearable='true'
                            value={correosSelected}
                            onChange={(event) => handleOptionSeleccionarCorreos(event)}
                            placeholder='Seleccione Correo'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end pt-4 pb-4 pr-4">
                  <button
                    className="mr-4 inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => handleModalButtonCancel()}
                  >
                    Cancelar
                  </button>
                  <button
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => handleModalButtonAceptar()}
                  >
                    Confirmar
                  </button>
                </div>
              </div>
            </div>
          )}
          {modalModificarVisible && (
            <div className="fixed z-50 top-0 left-0 right-0 bottom-0 flex items-center justify-center">
              <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
              <div className="bg-white w-11/12 h-2/4 md:max-w-3xl mx-auto rounded shadow-lg z-50 overflow-y-auto flex flex-col justify-between">
                <div className="py-4 text-left px-6">
                  <div className="mb-4">
                    <p className="text-xl">{'MODIFICAR CORREOS A GEOCERCAS'}</p>
                    <div className="flex mt-4">
                      <div className="w-1/2 pr-2">
                        <div>
                          <label className="block mb-2 font-bold">NOMBRE GEOCERCA</label>
                          <input
                            type="text"
                            value={geocercaSelected.label}
                            className="p-2 border border-gray-300 rounded"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="w-1/2 pl-2">
                        <div>
                          <label className="block mb-2 font-bold">SELECCIONAR CORREOS</label>
                          <Select options={listaCorreos} isSearchable='true'
                            isMulti
                            isClearable='true'
                            value={correosSelected}
                            onChange={(event) => handleOptionSeleccionarCorreos(event)}
                            placeholder='Seleccione Correo'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end pt-4 pb-4 pr-4">
                  <button
                    className="mr-4 inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => handleModalModificarButtonCancel()}
                  >
                    Cancelar
                  </button>
                  <button
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => handleModalModificarButtonAceptar()}
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </div>
          )}
          <ModalConfirmar
            isOpen={modalDeshabilitarVisible}
            onCancel={handleCancelDeshabilitarModal}
            onConfirm={handleConfirmDeshabilitarModal}
            message="¿Confirma deshabilitar?"
          />
        </div>
      );
    } catch (err) {
      console.log('AsignarCorreoAGeocerca render err');
      console.log(err);
      return (
        <div><label>{JSON.stringify(err)}</label></div>
      );
    }
  }

  return render();
}

import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Alertas from "./index";
import Administracion from "./administracion";

const RouterAlertas = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/administracion`}>
        <Administracion />
      </Route>
      <Route path={`${path}`}>
        <Alertas />
      </Route>
    </Switch>
  );
};

export default RouterAlertas;

import { SUCCESS } from "../page/const/http-const";
import { getHeaders } from "./utils";

export async function guardarConfiguracion(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/configuracion",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("Guardar Configuracion Descargas err");
    console.log(err);
    return [];
  }
}

export async function modificarConfiguracion(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/configuracion",
      {
        headers: getHeaders({ authenticate: true }),
        method: "PUT",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("Modificar Configuracion Descargas err");
    console.log(err);
    return [];
  }
}

export async function obtenerConfiguracion() {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/configuracion",
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("Obtener Configuracion Descargas err");
    console.log(err);
    return [];
  }
}

export async function obtenerConfiguracionDescargaCarga() {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/configuracion-descarga-carga",
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("Obtener Configuracion Descargas carga err");
    console.log(err);
    return [];
  }
}
export async function guardarConfiguracionDescargaCarga(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/configuracion-descarga-carga",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("Guardar Configuracion Descargas carga err");
    console.log(err);
    return [];
  }
}

export async function modificarConfiguracionDescargaCarga(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/configuracion-descarga-carga",
      {
        headers: getHeaders({ authenticate: true }),
        method: "PUT",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("Modificar Configuracion Descargas carga err");
    console.log(err);
    return [];
  }
}
export async function obtenerDescargas(data) {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/despachoapi/api/descargas/registros`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("Obtener Descargas err");
    console.log(err);
    return [];
  }
}
export async function obtenerDescargasEnlazadas(data) {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/despachoapi/api/descargas/registros-enlazados`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("Obtener Descargas enlazados err");
    console.log(err);
    return [];
  }
}
export async function obtenerDescargasPlanilla(data) {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/despachoapi/api/descargas/registros-planilla`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("Obtener Descargas err");
    console.log(err);
    return [];
  }
}

export async function obtenerListadoImportaciones() {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/despachoapi/api/descargas/`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("Obtener Descargas err");
    console.log(err);
    return [];
  }
}

export async function obtenerHorarioDescargas(idGeocerca) {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/despachoapi/api/descargas/horarios-geocerca/${idGeocerca}`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("Obtener Descargas err");
    console.log(err);
    return [];
  }
}

export async function obtenerCantidadRegistrosEnHorario(
  codigoDestino,
  depositoDestino,
  planilla
) {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/despachoapi/api/descargas/cantidad-registros-horario/${codigoDestino}/${depositoDestino}/${planilla}`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("Obtener Descargas err");
    console.log(err);
    return [];
  }
}

export async function guardarHorarioDescargas(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/guardar-horario",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("guardar horario Descargas err");
    console.log(err);
    return [];
  }
}

export async function eliminarHorarioDescargas(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/eliminar-horario",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("eliminar horario Descargas err");
    console.log(err);
    return [];
  }
}
//
export async function eliminarDescarga(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/eliminar-descarga",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("eliminar horario Descargas err");
    console.log(err);
    return [];
  }
}
export async function eliminarDescargaId(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/eliminar-descarga-id",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("eliminar horario Descargas err");
    console.log(err);
    return [];
  }
}
export async function eliminarDescargaPlanilla(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/eliminar-descarga-planilla",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("eliminar horario Descargas err");
    console.log(err);
    return [];
  }
}
//

export async function guardarDescargas(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("guardar Descargas err");
    console.log(err);
    return [];
  }
}

export async function enviarSMSDescargas(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/enviar-sms-descargas",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("enviar sms Descargas err");
    console.log(err);
    return [];
  }
}
export async function enviarSMSDescarga(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/enviar-sms-descarga",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("enviar sms Descargas err");
    console.log(err);
    return [];
  }
}

export async function guardarDescargasEnRegistroExistente(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/guardar-en-registro-existente",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("guardar Descargas err");
    console.log(err);
    return [];
  }
}

export async function guardarMensaje(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/configuracion-mensaje-descarga",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("guardar mensaje descarga err");
    console.log(err);
    return [];
  }
}

export async function modificarMensaje(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/configuracion-mensaje-descarga",
      {
        headers: getHeaders({ authenticate: true }),
        method: "PUT",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("modificar mensaje descarga err");
    console.log(err);
    return [];
  }
}

export async function obtenerMensaje() {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/configuracion-mensaje-descarga",
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("obtener mensaje descarga err");
    console.log(err);
    return [];
  }
}

export async function obtenerEnvioData(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/obtener-envio-sms",
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("modificar sms data err");
    console.log(err);
    return [];
  }
}
export async function modificarDescargas(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/",
      {
        headers: getHeaders({ authenticate: true }),
        method: "PUT",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("modificar Descargas err");
    console.log(err);
    return [];
  }
}

export async function modificarDescargasPlanilla(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/planilla",
      {
        headers: getHeaders({ authenticate: true }),
        method: "PUT",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("modificar Descargas err");
    console.log(err);
    return [];
  }
}

export async function modificarDescargasPlanillaEnlazada(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/planilla-enlazada",
      {
        headers: getHeaders({ authenticate: true }),
        method: "PUT",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("modificar Descargas err");
    console.log(err);
    return [];
  }
}
export async function modificarHorarioDescargas(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/modificar-horario",
      {
        headers: getHeaders({ authenticate: true }),
        method: "PUT",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("modificar Descargas err");
    console.log(err);
    return [];
  }
}
export async function modificarVariasDescargas(data) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/modificar-varios",
      {
        headers: getHeaders({ authenticate: true }),
        method: "PUT",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("modificar Descargas err");
    console.log(err);
    return [];
  }
}

export async function obtenerImportaciones(obj) {
  try {
    let rows = [];
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/descargas/obtener-importaciones/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      rows = body;
    }
    return rows;
  } catch (err) {
    console.log("obtenerImportaciones err");
    console.log(err);
    return [];
  }
}

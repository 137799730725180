import carIcon from "../../assets/iconos/car.png"

export const INT_REPORTE_CONDUCCION_FUERA_DE_HORARIO_DESCARGA = 1;

export const STRING_REPORTE_CONDUCCION_FUERA_DE_HORARIO_DESCARGA = 'CONDUCCION FUERA DE HORARIO';

export const LISTA_REPORTES_EXPORTABLES = [
    {
        value: INT_REPORTE_CONDUCCION_FUERA_DE_HORARIO_DESCARGA,
        label: STRING_REPORTE_CONDUCCION_FUERA_DE_HORARIO_DESCARGA,
    },
];
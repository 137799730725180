import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "../../components/grid";
import {
  ESTILO_BOTON_GRILLA_NEXT, ESTILO_BOTON_GRILLA_PREVIOUS,
  ESTILO_BOTON_LISTAR_ATRAS,
  ESTILO_BOTON_LISTAR_NUEVO,
  ESTILO_BOTON_MODAL_CANCELAR
} from "../../utils/const-estilos";
import ModalConfirmar from "../../components/modal-confirmar";
import "../../style/style-cargando.css";
import Select from "react-select";
import {
  INT_ID_MODULO_GEORUTA,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../utils/const-int";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
import { TRUE } from "../../utils/const-bool";
import {
  obtenerCantidadCodigoGeoruta,
  obtenerGrupoGeoruta,
  obtenerGeoruta,
  guardarGeoruta,
  deshabilitarGeoruta,
  modificarGeoruta
} from "../../api/georuta";
import { obtenerInfoGeocercaMedianteIdGrupo } from "../../api/gestion-dts/gestion";
import { Settings } from "feather-icons-react/build/IconComponents";
import { actualizarHeadersGestionarDisponibilidadScreen, actualizarOrdenGestionarDisponibilidadScreen, guardarConfiguracionScreen, obtenerConfiguracionScreen } from "../../api/configuracion-screen/configuracion-screen";
import { LISTAR_GEORUTA } from "../../utils/const-screen-name";
import { compareHeaders } from "../../share/shared-functions";
import ModalConfiguracionGrilla from "../../components/modal-configuracion-grilla";

const idTipoUsuario = parseInt(localStorage.getItem("tipo"));
let accesoBotonNuevo = 'none';


export default function GeorutaMain() {
  const history = useHistory();
  const headers = [
    { key: "acciones", title: "ACCIONES", visible: true, },
    { key: "id", title: "CODIGO", visible: true, },
    { key: "idgeoruta", title: "IDGEORUTA", visible: false, },
    { key: "codigogeoruta", title: "CODIGO GEORUTA", visible: true, filter: true, },
    { key: "nombregeoruta", title: "NOMBRE GEORUTA", visible: true, filter: true, },
    { key: "codigo", title: "CODIGO", filter: true, visible: true, },
    { key: "nombre", title: "NOMBRE", filter: true, visible: true, },
    { key: "descripcion", title: "DESCRIPCION", filter: true, visible: true, },
    { key: "codigogeocercainicio", title: "CODIGO GEO ORIGEN", filter: true, visible: true, },
    { key: "nombregeocercainicio", title: "NOMBRE GEO ORIGEN", filter: true, visible: true, },
    { key: "codigogeocercafin", title: "CODIGO GEO FIN", filter: true, visible: true, },
    { key: "nombregeocercafin", title: "NOMBRE GEO FIN", filter: true, visible: true, },
    { key: "tiemporecorrido", title: "TIEMPO RECORRIDO", filter: true, visible: true, },
    { key: "fechaproceso", title: "FECHA PROCESO", filter: true, visible: true, },
  ];
  const infoGeoruta = {
    id: -1,
    idgeoruta: -1,
    codigogeoruta: "",
    nombregeoruta: "",
    codigo: "",
    nombre: "",
    descripcion: "",
  };
  const [minRows, setMinRows] = useState(0);
  const [maxRows, setMaxRows] = useState(5);
  const [cargandoPage, setCargandoPage] = useState(true);
  const [listaGeorutas, setListaGeorutas] = useState([]);
  const [modalViajeVisible, setModalViajeVisible] = useState(false);
  const [grupoGeoruta, setGrupoGeoruta] = useState(null);
  const [georutaSelected, setGeorutaSelected] = useState({ ...infoGeoruta });
  const [permisos, setPermisos] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);
  const [modalInfoGeoruta, setModalInfoGeoruta] = useState(false);
  const [listaGeocercas, setListaGeocercas] = useState([]);
  const [geocercaSelected, setGeocercaSelected] = useState(null);

  const [exportar, setExportar] = useState(true);
  const [modalConfiguracionVisible, setModalConfiguracionVisible] = useState(false);
  const [configuracionOrden, setConfiguracionOrden] = useState(null);
  const [ascendente, setAscendente] = useState(false);
  const [descendente, setDescendente] = useState(false);
  const [gridHeaders, setGridHeaders] = useState(headers);

  useEffect(() => {
    // console.log("entro a GeorutaMain");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      let objPermiso = {};
      objPermiso.idMenuPrimerNivel = INT_ID_MODULO_GEORUTA;
      objPermiso.idMenuSegundoNivel = 0;
      objPermiso.idMenuTercerNivel = 0;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(objPermiso);
      let permiso = null;
      if (obtenerPermisos.length > 0) {
        permiso = obtenerPermisos[0];
        setPermisos(permiso);
      }

      const configuracionScreenInfo = await obtenerConfiguracionScreen({ nombre: LISTAR_GEORUTA });
      console.log('configuracionScreenInfo');
      console.log(configuracionScreenInfo);
      let objConfiguracionEnviar = {};
      objConfiguracionEnviar.descendente = true;
      objConfiguracionEnviar.ascendente = false;
      if (configuracionScreenInfo !== null) {
        const configuracionOrden = JSON.parse(configuracionScreenInfo.orden);
        console.log('configuracionOrden');
        console.log(configuracionOrden);
        objConfiguracionEnviar.descendente = configuracionOrden.descendente;
        objConfiguracionEnviar.ascendente = configuracionOrden.ascendente;
        setAscendente(configuracionOrden.ascendente);
        setDescendente(configuracionOrden.descendente);
        let configuracionHeaders = null;
        if (configuracionScreenInfo.headers !== null) {
          configuracionHeaders = JSON.parse(configuracionScreenInfo.headers);
          console.log('configuracionHeaders');
          console.log(configuracionHeaders);
          const headerAux = compareHeaders(headers, configuracionHeaders);
          setGridHeaders(headerAux);
        }
      }
      setConfiguracionOrden(configuracionScreenInfo);


      let lista = [];
      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        accesoBotonNuevo = '';
        setTieneAccesoAlModulo(true);
        lista = await obtenerGeoruta(objConfiguracionEnviar);
        crearComboGeocerca();
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.accesoAlModulo === TRUE) {
          setTieneAccesoAlModulo(true);
          lista = await obtenerGeoruta(objConfiguracionEnviar);
          crearComboGeocerca();
        }
      }

      agregarAccionesALista(lista, permiso);
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const crearComboGeocerca = async () => {
    try {
      let infoGrupoGeoruta = await obtenerGrupoGeoruta();
      // console.log('infoGrupoGeoruta');
      // console.log(infoGrupoGeoruta);
      setGrupoGeoruta(infoGrupoGeoruta);
      if (infoGrupoGeoruta !== null) {
        let meta = JSON.parse(infoGrupoGeoruta.meta);
        // console.log('meta');
        // console.log(meta);
        if (meta !== null) {
          let idGrupoGeocerca = meta.join(',');
          // console.log('idGrupoGeocerca');
          // console.log(idGrupoGeocerca);
          let objEnviar = {};
          objEnviar.idGrupoGeocerca = idGrupoGeocerca;
          let geocercas = await obtenerInfoGeocercaMedianteIdGrupo(objEnviar);
          // console.log('geocercas');
          // console.log(geocercas);
          for (let i = 0; i < geocercas.length; i++) {
            geocercas[i].nombre = geocercas[i].label;
            geocercas[i].label = geocercas[i].codigo + " - " + geocercas[i].label;
          }
          setListaGeocercas(geocercas);
        }
      }
    } catch (err) {
      console.log('crearComboGeocerca err');
      console.log(err);
    }
  }

  const agregarAccionesALista = (lista, permiso) => {
    try {
      if (lista.length === 0) {
        setListaGeorutas(lista);
      }
      if (lista.length > 0) {
        let objModificar = {
          label: "Modificar",
          color: "green",
          onClick: (param) => modificarGeorutaSeleccionada(param),
        };
        let objDeshabilitar = {
          label: "Deshabilitar",
          color: "red",
          onClick: (param) => cambiarEstado(param),
        };
        let objItinerario = {
          label: "Itinerario",
          color: "blue",
          onClick: (param) => crearItinerario(param),
        };
        if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
          if (permiso.nuevo === TRUE) {
            accesoBotonNuevo = '';
          }
        }
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            acciones.push(objDeshabilitar);
            acciones.push(objModificar);
            acciones.push(objItinerario);
          }
          if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            if (permiso.eliminarDeshabilitar === TRUE) {
              acciones.push(objDeshabilitar);
            }
            if (permiso.modificar === TRUE) {
              acciones.push(objModificar);
            }
            if (permiso.itinerario === TRUE) {
              acciones.push(objItinerario);
            }
          }
          lista[i].acciones = acciones;
        }
        setListaGeorutas(lista);
      }
    } catch (err) {
      console.log('agregarAccionesALista err');
      console.log(err);
    }
  }

  const crearItinerario = (data) => {
    try {
      console.log("crearItinerario");
      console.log(data);
      if (data.acciones !== undefined) {
        delete data.acciones;
      }
      let obj = {};
      obj.titulo = "ITINERARIO " + data.codigo + " - " + data.nombre;
      obj.params = data;
      // console.log('obj');
      // console.log(obj);
      nextPage("georuta/itinerario", obj);
    } catch (err) {
      console.log("crearItinerario err");
      console.log(err);
    }
  };

  const cambiarEstado = async (data) => {
    try {
      console.log("cambiarEstado");
      console.log(data);
      setGeorutaSelected(data);
      setModalViajeVisible(true);
    } catch (err) {
      console.log("cambiarEstado err");
      console.log(err);
    }
  };

  const handleInputChange = (event) => {
    // console.log('handleInputChange');
    const { name, value } = event.target;
    // console.log(name);
    // console.log(value);
    setGeorutaSelected({ ...georutaSelected, [name]: value });
  };

  const modificarGeorutaSeleccionada = async (data) => {
    try {
      // console.log("modificarGeorutaSeleccionada");
      // console.log(data);
      setGeorutaSelected(data);

      // console.log('listaGeocercas');
      // console.log(listaGeocercas);
      // let geocerca = listaGeocercas.find(e => e.value == data.idgeoruta);
      let geocerca = {};
      geocerca.codigo = data.codigogeoruta;
      geocerca.label = data.codigogeoruta + " - " + data.nombregeoruta;
      geocerca.nombre = data.nombregeoruta;
      geocerca.value = data.idgeoruta;
      // console.log('geocerca');
      // console.log(geocerca);
      setGeocercaSelected(geocerca);

      setModalInfoGeoruta(true);
    } catch (err) {
      console.log("modificarGeorutaSeleccionada err");
      console.log(err);
    }
  };

  const nextPage = (page, params) => {
    try {
      // history.push(`${page}`);
      // console.log('params');
      // console.log(params);
      if (params !== undefined && params !== null) {
        if (params.acciones !== undefined) {
          delete params.acciones;
        }
        // console.log('params sin acciones');
        // console.log(params);
        history.push({
          pathname: `${page}`,
          state: params
        });
      } else {
        history.push(`${page}`);
      }
    } catch (err) {
      console.log('nextPage err');
      console.log(err);
    }
  };

  const handleNextPage = () => {
    if (maxRows >= listaGeorutas.length) return;
    setMinRows(maxRows);
    setMaxRows(maxRows + 5);
  };

  const handlePrevPage = () => {
    if (minRows <= 0) return;
    setMaxRows(minRows);
    setMinRows(Math.max(minRows - 5, 0));
  };

  const handleNuevoClick = () => {
    // console.log("handleNuevoClick");
    setModalInfoGeoruta(true);
    setGeocercaSelected(null);
    setGeorutaSelected({ ...infoGeoruta });
    console.log('georutaSelected');
    console.log(georutaSelected);
  }

  const handleAtrasClick = () => {
    history.goBack();
  }

  const handleCancelModal = () => {
    setModalViajeVisible(false);
  }

  const handleConfirmModal = async () => {
    console.log('handleConfirmModal');
    setModalViajeVisible(false);
    // console.log('georutaSelected');
    // console.log(georutaSelected);
    // return;
    if (georutaSelected == null) {
      return;
    }
    let objEnviar = {};
    objEnviar.id = georutaSelected.id;
    if (georutaSelected.id > 0) {
      // console.log('crear correo');
      let deshabilitado = await deshabilitarGeoruta(objEnviar);
      // console.log('creado');
      // console.log(creado);
      if (deshabilitado === true) {
        alert('Se deshabilito correctamente');
        reset();
      }
      if (deshabilitado === false) {
        alert('No se pudo deshabilitar');
      }
    }
  }

  const reset = async () => {
    setCargandoPage(true);
    const configuracionScreenInfo = await obtenerConfiguracionScreen({ nombre: LISTAR_GEORUTA });
    // console.log('configuracionScreenInfo');
    // console.log(configuracionScreenInfo);
    let objConfiguracionEnviar = {};
    objConfiguracionEnviar.descendente = true;
    objConfiguracionEnviar.ascendente = false;
    if (configuracionScreenInfo !== null) {
      const configuracionOrden = JSON.parse(configuracionScreenInfo.orden);
      console.log('configuracionOrden');
      console.log(configuracionOrden);
      objConfiguracionEnviar.descendente = configuracionOrden.descendente;
      objConfiguracionEnviar.ascendente = configuracionOrden.ascendente;
      setAscendente(configuracionOrden.ascendente);
      setDescendente(configuracionOrden.descendente);
      let configuracionHeaders = null;
      // debugger;
      if (configuracionScreenInfo.headers !== null) {
        configuracionHeaders = JSON.parse(configuracionScreenInfo.headers);
        console.log('configuracionHeaders');
        console.log(configuracionHeaders);
        const headerAux = compareHeaders(headers, configuracionHeaders);
        setGridHeaders(headerAux);
      }
      setConfiguracionOrden(configuracionScreenInfo);
    }
    let lista = await obtenerGeoruta(objConfiguracionEnviar);
    // console.log("listarCorreos");
    // console.log(listarCorreos);
    agregarAccionesALista(lista);
    setCargandoPage(false);
  }

  /**Modal Area */
  const onAcceptModal = async () => {
    try {
      // console.log('onAcceptModal');
      // console.log('georutaSelected');
      // console.log(georutaSelected);
      // console.log('geocercaSelected');
      // console.log(geocercaSelected);
      // debugger;
      let valido = validarCamposEnModal();
      if (valido === true) {
        /**validar que codigo sea unico */
        let objEnviar = {};
        objEnviar.codigo = georutaSelected.codigo;
        if (georutaSelected.id === -1) {
          let esCodigoUnico = await obtenerCantidadCodigoGeoruta(objEnviar);
          // console.log('esCodigoUnico');
          // console.log(esCodigoUnico);
          if (esCodigoUnico === false) {
            alert('El codigo debe ser unico');
            return;
          }
        }
        objEnviar.idGeoruta = geocercaSelected.value;
        objEnviar.codigoGeoruta = geocercaSelected.codigo;
        objEnviar.nombreGeoruta = geocercaSelected.nombre;
        objEnviar.codigo = georutaSelected.codigo;
        objEnviar.nombre = georutaSelected.nombre;
        objEnviar.descripcion = georutaSelected.descripcion;
        objEnviar.id = georutaSelected.id;
        // console.log('objEnviar');
        // console.log(objEnviar);
        if (georutaSelected.id === -1) {
          let guardo = await guardarGeoruta(objEnviar);
          // console.log('guardo');
          // console.log(guardo);
          if (guardo === true) {
            alert('Se guardo correctamente');
            onCancelModal();
            reset();
          }
        }
        if (georutaSelected.id > 0) {
          // console.log('modificar');
          let modifico = await modificarGeoruta(objEnviar);
          // console.log('guardo');
          // console.log(guardo);
          if (modifico === true) {
            alert('Se modifico correctamente');
            onCancelModal();
            reset();
          }
        }
      }
    } catch (err) {
      console.log('onAcceptModal err');
      console.log(err);
    }
  }

  const handleGeocercaSelected = (value) => {
    try {
      // console.log('handleGeocercaSelected');
      // console.log(value);
      setGeocercaSelected(value);
      if (value != null) {
        georutaSelected.idgeoruta = value.value;
        georutaSelected.codigogeoruta = value.codigo;
        georutaSelected.nombregeoruta = value.nombre;
      }
      if (value == null) {
        georutaSelected.idgeoruta = -1;
        georutaSelected.codigogeoruta = "";
        georutaSelected.nombregeoruta = "";
      }
    } catch (err) {
      console.log('handleGeocercaSelected err');
      console.log(err);
    }
  }

  const onCancelModal = () => {
    setModalInfoGeoruta(false);
  }

  const validarCamposEnModal = () => {
    try {
      // console.log('validarCamposEnModal');
      // console.log(georutaSelected);
      // console.log(geocercaSelected);
      if (geocercaSelected == null) {
        alert('Debe seleccionar geocerca');
        return false;
      }
      if (georutaSelected.codigo === undefined ||
        georutaSelected.codigo === null ||
        String(georutaSelected.codigo).trim().length === 0) {
        alert('Debe ingresar Codigo');
        return false;
      }
      if (georutaSelected.nombre === undefined ||
        georutaSelected.nombre === null ||
        String(georutaSelected.nombre).trim().length === 0) {
        alert('Debe ingresar Codigo');
        return false;
      }
      return true;
    } catch (err) {
      console.log('validarCamposEnModal err');
      console.log(err);
      return false;
    }
  }

  const onHideModalConfiguracion = () => {
    setModalConfiguracionVisible(false);
  }

  const onClickAcceptarOrdenGrillaModalConfiguracion = async () => {
    try {
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = LISTAR_GEORUTA;
      objConfiguracionEnviar.orden = objOrden;
      console.log('configuracionOrden');
      console.log(configuracionOrden);
      if (configuracionOrden === null) {
        objConfiguracionEnviar.headers = gridHeaders;
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        const actualizo = await actualizarOrdenGestionarDisponibilidadScreen(objConfiguracionEnviar);
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log('onClickAcceptarOrdenGrillaModalConfiguracion err');
      console.log(err);
    }
  }

  const onClickAcceptarHeadersGrillaModalConfiguracion = async () => {
    try {
      console.log('onClickAcceptarHeadersGrillaModalConfiguracion');
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = LISTAR_GEORUTA;
      objConfiguracionEnviar.orden = objOrden;
      console.log('configuracionOrden');
      console.log(configuracionOrden);
      if (configuracionOrden === null) {
        objConfiguracionEnviar.headers = gridHeaders;
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        objConfiguracionEnviar.headers = gridHeaders;
        const actualizo = await actualizarHeadersGestionarDisponibilidadScreen(objConfiguracionEnviar);
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log('onClickAcceptarHeadersGrillaModalConfiguracion err');
      console.log(err);
    }
  }

  const openModalCondiguracion = () => {
    setModalConfiguracionVisible(true);
  }

  const render = () => {
    try {
      // console.log('listar correo render');
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div
              style={{ marginTop: 10, }}
            >
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <label
              style={{
                paddingLeft: 10,
                fontWeight: 'bold',
              }}
            >
              LISTA GEORUTA
            </label>
            <div>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_NUEVO}
                onClick={handleNuevoClick}
                style={{
                  display: accesoBotonNuevo,
                }}
              >
                NUEVO
              </button>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_ATRAS}
                onClick={handleAtrasClick}
              >
                ATRAS
              </button>
            </div>
          </div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={openModalCondiguracion}
            >
              <Settings />
            </button>
          </div>
          <Grid rows={listaGeorutas} headers={gridHeaders}
            exportar={exportar} />
          <div>
            <button className={ESTILO_BOTON_GRILLA_PREVIOUS} onClick={handlePrevPage}>Previous</button>
            <button className={ESTILO_BOTON_GRILLA_NEXT} onClick={handleNextPage}>Next</button>
          </div>
          <ModalConfirmar
            isOpen={modalViajeVisible}
            onCancel={handleCancelModal}
            onConfirm={handleConfirmModal}
            message="¿Estás de deshabilitar?"
          />
          <ModalConfiguracionGrilla
            modalConfiguracionVisible={modalConfiguracionVisible}
            onHideModalConfiguracion={onHideModalConfiguracion}
            ascendente={ascendente}
            descendente={descendente}
            setAscendente={setAscendente}
            setDescendente={setDescendente}
            gridHeaders={gridHeaders}
            setGridHeaders={setGridHeaders}
            onClickAcceptarOrdenGrillaModalConfiguracion={onClickAcceptarOrdenGrillaModalConfiguracion}
            onClickAcceptarHeadersGrillaModalConfiguracion={onClickAcceptarHeadersGrillaModalConfiguracion}
          />
          {modalInfoGeoruta && (
            <div className="fixed z-50 top-0 left-0 right-0 bottom-0 flex items-center justify-center">
              {/* background */}
              <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
              {/* end background */}
              <div className="bg-white w-11/12 h-3/6 md:max-w-3xl lg:max-w-3xl mx-auto rounded shadow-lg z-50 overflow-y-auto flex flex-col">
                <div className="pt-4 text-left px-6">
                  <div>
                    <p className="text-xl">{'GEORUTA '}</p>
                    <div className="flex mt-4">
                      <div className="w-1/2 pr-2">
                        <label className="block mb-2 font-bold">GEOCERCA</label>
                        <Select options={listaGeocercas} isSearchable='true'
                          isClearable='true'
                          value={geocercaSelected}
                          onChange={(event) => handleGeocercaSelected(event)}
                          placeholder='Seleccione Geocerca'
                        />
                      </div>
                    </div>
                    <div className="flex mt-4">
                      <div className="w-1/2 pr-2">
                        <label className="block mb-2 font-bold">CODIGO</label>
                        <input
                          type="text"
                          id="codigo"
                          name="codigo"
                          value={georutaSelected.codigo}
                          onChange={handleInputChange}
                          placeholder="CODIGO"
                          className="w-full p-2 border border-gray-300 rounded"

                        />
                      </div>
                      <div className="w-1/2 pr-2">
                        <label className="block mb-2 font-bold">NOMBRE</label>
                        <input
                          type="text"
                          id="nombre"
                          name="nombre"
                          value={georutaSelected.nombre}
                          onChange={handleInputChange}
                          placeholder="NOMBRE"
                          className="w-full  p-2 border border-gray-300 rounded"

                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col ml-6 mr-6">
                  <div className="w-full pr-2">
                    <label className="block mb-2 font-bold">DESCRIPCION</label>
                    <textarea
                      type="text"
                      id="descripcion"
                      name="descripcion"
                      value={georutaSelected.descripcion}
                      onChange={handleInputChange}
                      placeholder="DESCRIPCION"
                      className="w-full p-2 border border-gray-300 rounded"

                    />
                  </div>
                </div>
                <div className="flex justify-between pt-20 pb-4 pr-4">
                  <button
                    className={ESTILO_BOTON_MODAL_CANCELAR}
                    onClick={onCancelModal}
                  >
                    Cancelar
                  </button>
                  <button
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 cursor-pointer"
                    onClick={onAcceptModal}
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } catch (err) {
      console.log('index render err');
      console.log(err);
      return (
        <div><label>{JSON.stringify(err)}</label></div>
      );
    }
  }

  return render();
}

import shortid from "shortid";
import { AZUL_BOLTRACK1 } from "../share/shared-colors";

const HorarioCargaDescarga = (data) => {

  const renderInfo = (info) => {
    try {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              width: '25%',
              display: 'flex',
              justifyContent: 'center',
              border: '1px solid  #A0A0A0',
            }}
          >
            <label
              style={{
              }}
            >
              {info.hora}
            </label>
          </div>
          <div
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              width: '25%',
              display: 'flex',
              justifyContent: 'center',
              border: '1px solid  #A0A0A0',
            }}
          >
            <label
              style={{
              }}
            >
              {info.capacidad}
            </label>
          </div>
          <div
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              width: '25%',
              display: 'flex',
              justifyContent: 'center',
              border: '1px solid  #A0A0A0',
            }}
          >
            <label
              style={{

              }}
            >
              {info.plan.join(', ')}
            </label>
          </div>
          <div
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              width: '25%',
              display: 'flex',
              justifyContent: 'center',
              border: '1px solid  #A0A0A0',
            }}
          >
            <label
              style={{
              }}
            >
              {info.diferencia}
            </label>
          </div>
        </div>
      );
    } catch (err) {
      return (<div>
        {JSON.stringify(err)}
      </div>);
    }
  }

  try {
    return (
      <div className="w-full md:w-2/2 lg:w-2/4 p-4" key={shortid.generate()}>
        <div
          style={{
            display: "flex",
            width: "100%",
            background: 'white',
          }}
        >
          <div
            style={{
              background: AZUL_BOLTRACK1,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <label
              style={{
                color: 'white',
              }}
            >{data.codigoDestino} - {data.depositoDestino}</label>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <div
            style={{
              background: AZUL_BOLTRACK1,
              paddingLeft: 10,
              paddingRight: 10,
              width: '25%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <label
              style={{
                color: 'white',
              }}
            >HORA LLEGADA</label>
          </div>
          <div
            style={{
              background: AZUL_BOLTRACK1,
              paddingLeft: 10,
              paddingRight: 10,
              width: '25%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <label
              style={{
                color: 'white',
              }}
            >CAPACIDAD</label>
          </div>
          <div
            style={{
              background: AZUL_BOLTRACK1,
              paddingLeft: 10,
              paddingRight: 10,
              width: '25%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <label
              style={{
                color: 'white',
              }}
            >PLAN
            </label>
          </div>
          <div
            style={{
              background: AZUL_BOLTRACK1,
              paddingLeft: 10,
              paddingRight: 10,
              width: '25%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <label
              style={{
                color: 'white',
              }}
            >DIFERENCIA</label>
          </div>
        </div>
        <div>
          {data.horario.map((info) => renderInfo(info))}
        </div>
      </div>
    );
  } catch (err) {
    console.log("HorarioCargaDescarga err");
    console.log(err);
    return (
      <div>
        <label>{JSON.stringify(err)}</label>
      </div>
    );
  }
};

export default HorarioCargaDescarga;
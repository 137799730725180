import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "../../components/grid";
import { ESTILO_BOTON_GRILLA_NEXT, ESTILO_BOTON_GRILLA_PREVIOUS, ESTILO_BOTON_LISTAR_ATRAS } from "../../utils/const-estilos";
import "../../style/style-cargando.css";
import {
  INT_ID_MODULO_EVENTOS,
  INT_ID_SEGUNDO_NIVEL_EVENTOS_BITACORA,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../utils/const-int";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
import { TRUE } from "../../utils/const-bool";
import { obtenerListaBitacora } from "../../api/bitacora";

const idTipoUsuario = parseInt(localStorage.getItem("tipo"));

export default function GestionarBitacora() {
  const headers = [
    { key: "acciones", title: "ACCIONES" },
    { key: "id", title: "ID", visible: false, },
    { key: "unidad", title: "UNIDAD", visible: true, },
    { key: "idpuntocontrol", title: "idpuntocontrol", visible: false, },
    { key: "nombrepuntocontrol", title: "PUNTO CONTROL", visible: true, },
    { key: "otropuntocontrol", title: "OTRO PUNTO CONTROL", visible: true, },
    { key: "idcategoria", title: "idcategoria", visible: false, },
    { key: "categoria", title: "CATEGORIA", visible: true, },
    { key: "otrocategoria", title: "OTRO CATEGORIA", visible: true, },
    { key: "descripcion", title: "DESCRIPCION", visible: true, },
    { key: "tiempo", title: "TIEMPO", visible: true, },
    { key: "url", title: "URL", visible: true, },
    { key: "fechaproceso", title: "FECHA PROCESO", filter: true },
  ];

  const history = useHistory();
  const [minRows, setMinRows] = useState(0);
  const [maxRows, setMaxRows] = useState(5);
  const [cargandoPage, setCargandoPage] = useState(true);
  const [listaBitacora, setListaBitacora] = useState([]);
  const [permisos, setPermisos] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);

  useEffect(() => {
    // console.log("entro a listar correo");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      let obj = {};
      obj.idMenuPrimerNivel = INT_ID_MODULO_EVENTOS;
      obj.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_EVENTOS_BITACORA;
      obj.idMenuTercerNivel = 0;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(obj);
      let permiso = obtenerPermisos[0];
      setPermisos(permiso);

      let lista = [];
      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        setTieneAccesoAlModulo(true);
        lista = await obtenerListaBitacora();
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.accesoAlModulo === TRUE) {
          setTieneAccesoAlModulo(true);
          lista = await obtenerListaBitacora(false);
        }
      }
      agregarAccionesALista(lista, permiso);
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const agregarAccionesALista = (lista, permiso) => {
    try {
      if (lista.length === 0) {
        setListaBitacora(lista);
      }
      if (lista.length > 0) {
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          let objVer = {
            label: "Ver",
            color: "green",
            onClick: (param) => ver(param),
          };
          if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            acciones.push(objVer);
          }
          if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            if (permiso.ver === TRUE) {
              acciones.push(objVer);
            }
          }
          lista[i].acciones = acciones;
        }
        setListaBitacora(lista);
      }
    } catch (err) {
      console.log('agregarAccionesALista err');
      console.log(err);
    }
  }

  const nextPage = (page, params) => {
    try {
      // history.push(`${page}`);
      // console.log('params');
      // console.log(params);
      if (params !== undefined && params !== null) {
        if (params.acciones !== undefined) {
          delete params.acciones;
        }
        // console.log('params sin acciones');
        // console.log(params);
        history.push({
          pathname: `${page}`,
          state: params
        });
      } else {
        history.push(`${page}`);
      }
    } catch (err) {
      console.log('nextPage err');
      console.log(err);
    }
  };

  const handleNextPage = () => {
    if (maxRows >= listaBitacora.length) return;
    setMinRows(maxRows);
    setMaxRows(maxRows + 5);
  };

  const handlePrevPage = () => {
    if (minRows <= 0) return;
    setMaxRows(minRows);
    setMinRows(Math.max(minRows - 5, 0));
  };

  const ver = (data) => {
    try {
      // console.log("ver");
      // console.log(data);
      if (data.acciones !== undefined) {
        delete data.acciones;
      }
      let obj = {};
      obj.titulo = "BITACORA";
      obj.params = data;
      // console.log('obj');
      // console.log(obj);
      nextPage("bitacora/bitacora", obj);
    } catch (err) {
      console.log("ver err");
      console.log(err);
    }
  };

  const handleAtrasClick = () => {
    history.goBack();
  }

  const render = () => {
    try {
      // console.log('listar correo render');
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div
              style={{ marginTop: 10, }}
            >
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <label
              style={{
                paddingLeft: 10,
                fontWeight: 'bold',
              }}
            >
              LISTA BITACORA
            </label>
          </div>
          <Grid rows={listaBitacora} headers={headers} />
          <div>
            <button className={ESTILO_BOTON_GRILLA_PREVIOUS} onClick={handlePrevPage}>Previous</button>
            <button className={ESTILO_BOTON_GRILLA_NEXT} onClick={handleNextPage}>Next</button>
          </div>
        </div>
      );
    } catch (err) {
      console.log('GestionarBitacora render err');
      console.log(err);
      return (
        <div><label>{JSON.stringify(err)}</label></div>
      );
    }
  }

  return render();
}

import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Settings } from "feather-icons-react/build/IconComponents";
import Grid from "../../../components/grid";
import {
  ESTILO_BOTON_GRILLA_NEXT,
  ESTILO_BOTON_GRILLA_PREVIOUS,
  ESTILO_BOTON_LISTAR_ATRAS,
  ESTILO_BOTON_LISTAR_NUEVO,
} from "../../../utils/const-estilos";
import { TRUE, FALSE } from "../../../utils/const-bool";
import "../../../style/style-cargando.css";
import {
  deshabilitarTipoCarroceria,
  habilitarSubTipoCarroceria,
  obtenerListaTipoCarroceria,
} from "../../../api/checklist";
import ModalConfirmar from "../../../components/modal-confirmar";
import ModalOk from "../../../components/modal-ok";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../../api/permisos/permisos";
import {
  INT_ID_MODULO_CHECKLIST,
  INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST,
  INT_ID_TERCER_NIVEL_CHECKLIST_TIPO_CARROCERIA,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../../utils/const-int";
import { LISTAR_TIPO_CARROCERIA } from "../../../utils/const-screen-name";
import { actualizarHeadersGestionarDisponibilidadScreen, actualizarOrdenGestionarDisponibilidadScreen, guardarConfiguracionScreen, obtenerConfiguracionScreen } from "../../../api/configuracion-screen/configuracion-screen";
import { compareHeaders } from "../../../share/shared-functions";
import ModalConfiguracionGrilla from "../../../components/modal-configuracion-grilla";

const idTipoUsuario = parseInt(localStorage.getItem("tipo"));
let accesoBotonNuevo = 'none';

export default function ListarTipoCarroceria() {
  const history = useHistory();
  const headers = [
    { key: "acciones", title: "ACCIONES", id: 1, visible: true, },
    { key: "id", title: "ID", id: 2, visible: false, },
    { key: "descripcion", title: "Descripcion", filter: true, id: 3, visible: true, },
    { key: "fechaproceso", title: "Fecha Proceso", filter: true, id: 4, visible: true, },
  ];
  const [minRows, setMinRows] = useState(0);
  const [maxRows, setMaxRows] = useState(5);
  const [cargandoPage, setCargandoPage] = useState(true);
  const [listaTipoCarroceria, setListaTipoCarroceria] = useState([]);
  const [
    modalConfirmarCrearSubTipoCarroceriaVisible,
    setModalConfirmarCrearTubTipoCarroceriaVisible,
  ] = useState(false);
  const [tipoCarroceriaSelected, setTipoCarroceriaSelected] = useState(null);
  const [modalHabilitarSubTipoOkVisible, setModalHabilitarSubTipoOkVisible] =
    useState(false);
  const [
    modalDeshabilitarTipoCarrocerriaVisible,
    setModalDeshabilitarTipoCarrocerriaVisible,
  ] = useState(false);
  const [permisos, setPermisos] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);

  const [exportar, setExportar] = useState(true);
  const [modalConfiguracionVisible, setModalConfiguracionVisible] = useState(false);
  const [configuracionOrden, setConfiguracionOrden] = useState(null);
  const [ascendente, setAscendente] = useState(false);
  const [descendente, setDescendente] = useState(false);
  const [gridHeaders, setGridHeaders] = useState(headers);

  useEffect(() => {
    // console.log("entro a listar Tipo Carroceria");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      let obj = {};
      obj.idMenuPrimerNivel = INT_ID_MODULO_CHECKLIST;
      obj.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST;
      obj.idMenuTercerNivel = INT_ID_TERCER_NIVEL_CHECKLIST_TIPO_CARROCERIA;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(obj);
      let permiso = null;
      if (obtenerPermisos.length > 0) {
        permiso = obtenerPermisos[0];
        setPermisos(permiso);
      }

      const configuracionScreenInfo = await obtenerConfiguracionScreen({ nombre: LISTAR_TIPO_CARROCERIA });
      console.log('configuracionScreenInfo');
      console.log(configuracionScreenInfo);
      let objConfiguracionEnviar = {};
      objConfiguracionEnviar.descendente = true;
      objConfiguracionEnviar.ascendente = false;
      if (configuracionScreenInfo !== null) {
        const configuracionOrden = JSON.parse(configuracionScreenInfo.orden);
        console.log('configuracionOrden');
        console.log(configuracionOrden);
        objConfiguracionEnviar.descendente = configuracionOrden.descendente;
        objConfiguracionEnviar.ascendente = configuracionOrden.ascendente;
        setAscendente(configuracionOrden.ascendente);
        setDescendente(configuracionOrden.descendente);
        let configuracionHeaders = null;
        if (configuracionScreenInfo.headers !== null) {
          configuracionHeaders = JSON.parse(configuracionScreenInfo.headers);
          console.log('configuracionHeaders');
          console.log(configuracionHeaders);
          const headerAux = compareHeaders(headers, configuracionHeaders);
          setGridHeaders(headerAux);
        }
      }
      setConfiguracionOrden(configuracionScreenInfo);

      let listaTipoCarroceria = [];

      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        accesoBotonNuevo = '';
        setTieneAccesoAlModulo(true);
        listaTipoCarroceria = await obtenerListaTipoCarroceria(objConfiguracionEnviar);
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.accesoAlModulo === TRUE) {
          setTieneAccesoAlModulo(true);
          listaTipoCarroceria = await obtenerListaTipoCarroceria(objConfiguracionEnviar);
        }
      }
      // console.log("listaTipoCarroceria");
      // console.log(listaTipoCarroceria);
      agregarAccionesALista(listaTipoCarroceria, permiso);
      setCargandoPage(false);
    } catch (err) {
      console.log("iniciar err");
      console.log(err);
      setCargandoPage(false);
    }
  };

  const agregarAccionesALista = (lista, permiso) => {
    try {
      console.log('permiso');
      console.log(permiso);
      if (lista.length === 0) {
        setListaTipoCarroceria(listaTipoCarroceria);
      }
      if (lista.length > 0) {
        let objModificar = {
          label: "Modificar",
          color: "#00b894",
          onClick: (param) => modificarTipoCarroceria(param),
        };
        let objDeshabilitar = {
          label: "Deshabilitar",
          color: "#6c757d",
          onClick: (param) => cambiarEstado(param),
        };
        let objCrearSubTipo = {
          label: "Crear Sub Tipo",
          color: "#6c8ebf",
          onClick: (param) => modalConfirmarCrearSubTipoCarroceria(param),
        };
        let objListarSubTipo = {
          label: "Listar Sub Tipo",
          color: "#6c8ebf",
          onClick: (param) => listarSubTipoCarroceria(param),
        };
        if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
          if (permiso.nuevo === TRUE) {
            accesoBotonNuevo = '';
          }
        }
        for (let i = 0; i < lista.length; i++) {
          const element = lista[i];
          let acciones = [];
          if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            acciones.push(objModificar);
            if (element.tieneSubTipoCarroceria === TRUE) {
              acciones.push(objListarSubTipo);
            }
            if (element.tieneSubTipoCarroceria === FALSE) {
              acciones.push(objCrearSubTipo);
            }
            acciones.push(objDeshabilitar);
          }
          if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            if (permiso.modificar === TRUE) {
              acciones.push(objModificar);
            }

            if (element.tieneSubTipoCarroceria === TRUE) {
              if (permiso.listarSubTipoCarroceria === TRUE) {
                acciones.push(objListarSubTipo);
              }
            }

            if (element.crearSubTipoCarroceria === FALSE) {
              if (permiso.listarSubTipoCarroceria === TRUE) {
                acciones.push(objCrearSubTipo);
              }
            }

            if (permiso.eliminarDeshabilitar === TRUE) {
              acciones.push(objDeshabilitar);
            }

          }
          lista[i].acciones = acciones;
        }
        setListaTipoCarroceria(lista);
      }
    } catch (err) {
      console.log("agregarAccionesALista err");
      console.log(err);
    }
  };

  const nextPage = (page, params) => {
    try {
      if (params !== undefined && params !== null) {
        if (params.acciones !== undefined) {
          delete params.acciones;
        }
        history.push({
          pathname: `${page}`,
          state: params,
        });
      } else {
        history.push(`${page}`);
      }
    } catch (err) {
      console.log("nextPage err");
      console.log(err);
    }
  };

  const handleNextPage = () => {
    if (maxRows >= listaTipoCarroceria.length) return;
    setMinRows(maxRows);
    setMaxRows(maxRows + 5);
  };

  const handlePrevPage = () => {
    if (minRows <= 0) return;
    setMaxRows(minRows);
    setMinRows(Math.max(minRows - 5, 0));
  };

  const modificarTipoCarroceria = (data) => {
    try {
      // console.log("modificarTipoCarroceria");
      // console.log(data);
      if (data.acciones !== undefined) {
        delete data.acciones;
      }
      let obj = {};
      obj.titulo = "Modificar Tipo Carroceria";
      obj.params = data;
      nextPage("carroceria", obj);
    } catch (err) {
      console.log("modificarTipoCarroceria err");
      console.log(err);
    }
  };

  const modalConfirmarCrearSubTipoCarroceria = (data) => {
    try {
      // console.log("modificarTipoCarroceria");
      // console.log(data);
      setTipoCarroceriaSelected(data);
      setModalConfirmarCrearTubTipoCarroceriaVisible(true);
    } catch (err) {
      console.log("modalConfirmarCrearSubTipoCarroceria err");
      console.log(err);
    }
  };

  const cambiarEstado = async (data) => {
    try {
      // console.log("cambiarEstado");
      // console.log(data);
      setTipoCarroceriaSelected(data);
      setModalDeshabilitarTipoCarrocerriaVisible(true);
    } catch (err) {
      console.log("cambiarEstado err");
      console.log(err);
    }
  };

  const listarSubTipoCarroceria = (data) => {
    console.log("listarSubTipoCarroceria");
    if (data.acciones !== undefined) {
      delete data.acciones;
    }
    console.log("data");
    console.log(data);
    let obj = {};
    obj.nombreTipoCarroceria = data.descripcion;
    obj.idTipoCarroceria = data.id;
    console.log("obj");
    console.log(obj);
    nextPage("listar-sub-tipo-carroceria", obj);
  };

  const handleNuevoClick = () => {
    // console.log("handleNuevoClick");
    let obj = {};
    let data = {};
    data.id = 0;
    data.descripcion = "";
    obj.titulo = "Crear Tipo Carroceria";
    obj.params = data;
    nextPage("carroceria", obj);
  };

  const handleAtrasClick = () => {
    nextPage("administracion", null);
  };

  const handleCancelModal = () => {
    // console.log('handleCancelModal');
    setModalConfirmarCrearTubTipoCarroceriaVisible(false);
  };

  const handleConfirmModal = async () => {
    try {
      // console.log("Modal confirmado!");
      // console.log('tipoCarroceriaSelected');
      // console.log(tipoCarroceriaSelected);
      setModalConfirmarCrearTubTipoCarroceriaVisible(false);
      let obj = {};
      obj.id = tipoCarroceriaSelected.id;
      let habilitado = await habilitarSubTipoCarroceria(obj);
      // console.log('habilitado');
      // console.log(habilitado);
      if (habilitado === true) {
        setModalHabilitarSubTipoOkVisible(true);
      }
      if (habilitado === false) {
        alert("No se pudo habilitar sub tipo carroceria");
      }
    } catch (err) {
      console.log("handleConfirmModal err");
      console.log(err);
    }
  };

  const handleAceptarModalOk = () => {
    setModalHabilitarSubTipoOkVisible(false);
    // console.log('handleAceptarModalOk');
    // console.log('tipoCarroceriaSelected');
    // console.log(tipoCarroceriaSelected);
    let obj = {};
    obj.idTipoCarroceria = tipoCarroceriaSelected.id;
    obj.nombreTipoCarroceria = tipoCarroceriaSelected.descripcion;
    nextPage("listar-sub-tipo-carroceria", obj);
  };

  const handleCancelDeshabilitarModal = () => {
    // console.log('handleCancelModal');
    setModalDeshabilitarTipoCarrocerriaVisible(false);
  };

  const handleConfirmDeshabilitarModal = async () => {
    try {
      setModalDeshabilitarTipoCarrocerriaVisible(false);
      let objDeshabilitar = {};
      objDeshabilitar.id = tipoCarroceriaSelected.id;
      let deshabilitado = await deshabilitarTipoCarroceria(objDeshabilitar);
      console.log("deshabilitado");
      console.log(deshabilitado);
      if (deshabilitado === false) {
        alert("No se pudo deshabilitar tipo carroceria");
      }
      if (deshabilitado === true) {
        alert("Se deshabilito correctmente");
        reset();
      }
    } catch (err) {
      console.log("handleConfirmModal err");
      console.log(err);
    }
  };

  const reset = () => {
    setCargandoPage(true);
    setListaTipoCarroceria([]);
    iniciar();
  };

  const onHideModalConfiguracion = () => {
    setModalConfiguracionVisible(false);
  }

  const onClickAcceptarOrdenGrillaModalConfiguracion = async () => {
    try {
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = LISTAR_TIPO_CARROCERIA;
      objConfiguracionEnviar.orden = objOrden;
      console.log('configuracionOrden');
      console.log(configuracionOrden);
      if (configuracionOrden === null) {
        objConfiguracionEnviar.headers = gridHeaders;
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        const actualizo = await actualizarOrdenGestionarDisponibilidadScreen(objConfiguracionEnviar);
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log('onClickAcceptarOrdenGrillaModalConfiguracion err');
      console.log(err);
    }
  }

  const onClickAcceptarHeadersGrillaModalConfiguracion = async () => {
    try {
      console.log('onClickAcceptarHeadersGrillaModalConfiguracion');
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = LISTAR_TIPO_CARROCERIA;
      objConfiguracionEnviar.orden = objOrden;
      console.log('configuracionOrden');
      console.log(configuracionOrden);
      if (configuracionOrden === null) {
        objConfiguracionEnviar.headers = gridHeaders;
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        objConfiguracionEnviar.headers = gridHeaders;
        const actualizo = await actualizarHeadersGestionarDisponibilidadScreen(objConfiguracionEnviar);
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log('onClickAcceptarHeadersGrillaModalConfiguracion err');
      console.log(err);
    }
  }

  const openModalCondiguracion = () => {
    setModalConfiguracionVisible(true);
  }


  const render = () => {
    try {
      // console.log('listar tipo carroceria render');
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div style={{ marginTop: 10 }}>
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div
              style={{ marginTop: 10, }}
            >
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <label
              style={{
                paddingLeft: 10,
                fontWeight: "bold",
              }}
            >
              TIPO CARROCERIA
            </label>
            <div>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_NUEVO}
                onClick={handleNuevoClick}
                style={{
                  display: accesoBotonNuevo,
                }}
              >
                NUEVO
              </button>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_NUEVO}
                onClick={handleAtrasClick}
              >
                ATRAS
              </button>
            </div>
          </div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={openModalCondiguracion}
            >
              <Settings />
            </button>
          </div>
          <Grid rows={listaTipoCarroceria} headers={gridHeaders} exportar={exportar} />
          <div style={{ paddingLeft: 10 }}>
            <button
              className={ESTILO_BOTON_GRILLA_PREVIOUS}
              onClick={handlePrevPage}
            >
              Previous
            </button>
            <button
              className={ESTILO_BOTON_GRILLA_NEXT}
              onClick={handleNextPage}
            >
              Next
            </button>
          </div>
          <ModalConfirmar
            isOpen={modalConfirmarCrearSubTipoCarroceriaVisible}
            onCancel={handleCancelModal}
            onConfirm={handleConfirmModal}
            message="¿Estás de crear Sub Tipo Carroceria?"
          />
          <ModalOk
            isOpen={modalHabilitarSubTipoOkVisible}
            handleAceptar={handleAceptarModalOk}
            message="Se proceso correctamente"
          />
          <ModalConfirmar
            isOpen={modalDeshabilitarTipoCarrocerriaVisible}
            onCancel={handleCancelDeshabilitarModal}
            onConfirm={handleConfirmDeshabilitarModal}
            message="¿Estás de crear Sub Tipo Carroceria?"
          />
          <ModalConfiguracionGrilla
            modalConfiguracionVisible={modalConfiguracionVisible}
            onHideModalConfiguracion={onHideModalConfiguracion}
            ascendente={ascendente}
            descendente={descendente}
            setAscendente={setAscendente}
            setDescendente={setDescendente}
            gridHeaders={gridHeaders}
            setGridHeaders={setGridHeaders}
            onClickAcceptarOrdenGrillaModalConfiguracion={onClickAcceptarOrdenGrillaModalConfiguracion}
            onClickAcceptarHeadersGrillaModalConfiguracion={onClickAcceptarHeadersGrillaModalConfiguracion}
          />
        </div>
      );
    } catch (err) {
      console.log("ListarTipoCarroceria render err");
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  };

  return render();
}

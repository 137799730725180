import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import ExcelJS from "exceljs";
import { Filter } from "feather-icons-react/build/IconComponents";
import excelIcon from "../assets/iconos/excel.png";
import {
  ACCIONES,
  CHECK,
  COLOR,
  COLOR_A_TIEMPO,
  COLOR_RETRASO,
  CORREOS,
  GEOCERCAS,
  OBJECT,
  UBICACION_GPS,

} from "../utils/const-estilos";
import shortid from "shortid";

const Grid = ({
  rows,
  headers,
  pageSize,
  minRows,
  maxRows,
  filaClick,
  center,
  exportar,
  fontSize,
}) => {
  const [anchuraColumnaBotones, setAnchuraColumnaBotones] = useState(100);
  const [verFiltro, setVerFiltro] = useState({});
  const [valoresColumnasFiltrar, setValoresColumnasFiltrar] = useState({});
  const [valoresSeleccionadosFiltrar, setValoresSeleccionadosFiltrar] =
    useState({});
  const [dataFiltrada, setDataFiltrada] = useState([]);
  useEffect(() => {
    // console.log(dataFiltrada);
    if (dataFiltrada.length > 0) {
      if (dataFiltrada[0]["acciones"]) {
        if (dataFiltrada[0]["acciones"].length > 0) {
          setAnchuraColumnaBotones(100 * dataFiltrada[0]["acciones"].length);
        }
      }
    }
  }, [dataFiltrada]);
  useEffect(() => {
    setDataFiltrada(rows);
  }, [rows]);
  const eliminarValorDeFiltrado = (key) => {
    setValoresSeleccionadosFiltrar({
      ...valoresSeleccionadosFiltrar,
      [key]: null,
    });
  };
  useEffect(() => {
    if (headers) {
      let objHeader = {};
      for (let x = 0; x < headers.length; x++) {
        objHeader[headers[x].title] = false;
      }
      setVerFiltro(objHeader);
    }
  }, [headers]);
  useEffect(() => {
    if (Object.keys(valoresSeleccionadosFiltrar).length > 0) {
      if (rows.length > 0) {
        const resultadosFiltrados = rows.filter((item) => {
          for (const key in valoresSeleccionadosFiltrar) {
            const selectedValues = valoresSeleccionadosFiltrar[key];
            if (Array.isArray(selectedValues) && selectedValues.length > 0) {
              if (!selectedValues.includes(item[key])) {
                return false;
              }
            }
          }
          return true;
        });

        //console.log(resultadosFiltrados);
        setDataFiltrada(resultadosFiltrados);
      }
    }
  }, [valoresSeleccionadosFiltrar]);
  const obtenerValoresAFiltrar = (key) => {
    let valoresAfiltrar = [{ value: null, label: "sin filtrar" }];
    valoresAfiltrar.push(
      ...rows.map((row) => {
        return { value: row[key], label: row[key] === "" ? "-" : row[key] };
      })
    );
    const valoresAfiltrarSinRepetidos = valoresAfiltrar.filter(
      (valor, indice) =>
        valoresAfiltrar.findIndex((item) => item.value === valor.value) ===
        indice
    );
    setValoresColumnasFiltrar({
      ...valoresColumnasFiltrar,
      [key]: valoresAfiltrarSinRepetidos,
    });
    //console.log(valoresAfiltrar);
  };
  const estaElFiltroActivo = () => {
    return Object.values(verFiltro).every((valor) => valor === false);
  };
  const exportarAExcel = async (data) => {
    try {
      // console.log('exportarAExcel');
      // console.log(data);
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Hoja1");
      //console.log(headers);
      const headerExport = headers.filter(
        (valor) =>
          valor.key !== "acciones" &&
          valor.key !== "check" &&
          (valor.visible === true || valor.visible === undefined)
      );
      worksheet.columns = headerExport.map((value) => {
        return { header: value.title, key: value.key, width: 20 };
      });

      data.forEach((fila) => {
        const row = worksheet.addRow(fila);

        Object.keys(fila).forEach((columnaKey, columnIndex) => {
          const valor = fila[columnaKey];
          if (valor instanceof Date) {
            const cell = row.getCell(columnIndex + 1);
            const formatoFecha =
              valor.getHours() === 0 &&
                valor.getMinutes() === 0 &&
                valor.getSeconds() === 0
                ? "yyyy-mm-dd"
                : "yyyy-mm-dd HH:mm:ss";
            cell.numFmt = formatoFecha;
          }
        });
        /*data.forEach((fila) => {
          const filaFormateada = {};
          Object.keys(fila).forEach((columnaKey) => {
            const valor = fila[columnaKey];
            if (valor instanceof Date) {
              filaFormateada[columnaKey] = { date: valor }; // Formatear como objeto Date
            } else {
              filaFormateada[columnaKey] = valor;
            }
          });
          worksheet.addRow(filaFormateada);
        });*/
      });
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "archivo.xlsx";
      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(url);
    } catch (err) {
      console.log('exportarAExcel err');
      console.log(err);
    }
  };

  const renderHeader = () => {
    try {
      let display = "";
      return (
        <tr>
          {headers.map((header, index) => {
            if (header.visible !== undefined && header.visible === false) {
              display = "none";
            } else {
              display = "";
            }
            return (
              <th
                key={header.key + "-" + index}
                scope="col"
                className={`px-3 ${fontSize !== undefined ? "w-auto" : "min-w-[180px]"
                  } ${!estaElFiltroActivo() ? "py-2" : "py-3.5 "} ${header.title === "Acciones"
                    ? "w-[" + anchuraColumnaBotones + "px] "
                    : "w-auto"
                  }  text-sm font-semibold text-gray-900 text-center`}
                style={{
                  display: display,
                  fontSize: fontSize !== undefined ? fontSize + "px" : "12px",
                }}
              >
                {header.filter ? (
                  <div
                    className={`w-full ${!estaElFiltroActivo() ? "h-[58px]" : ""
                      }`}
                  >
                    <div
                      className={`flex w-full ${center
                        ? "justify-center"
                        : header.align
                          ? header.align
                          : ""
                        }`}
                    >
                      <div className="my-auto">{header.title}</div>
                      <div
                        className="my-auto ml-[8px]"
                        onClick={() => {
                          obtenerValoresAFiltrar(header.key);
                          setVerFiltro({
                            ...verFiltro,
                            [header.title]: !verFiltro?.[header.title],
                          });

                          //eliminarValorDeFiltrado(header.key);
                        }}
                      >
                        <Filter
                          className={` h-[15px] w-[15px] ${Array.isArray(
                            valoresSeleccionadosFiltrar[header.key]
                          )
                            ? "text-blue-300"
                            : "text-gray-500"
                            }`}
                        />
                      </div>
                    </div>

                    <div
                      className={`max-w-[200px] max-h-[58px] ${verFiltro[header.title] ? "" : "hidden"
                        }`}
                    >
                      <Select
                        options={valoresColumnasFiltrar[header.key]}
                        isMulti
                        /*styles={{
                            valueContainer: (base) => ({
                              ...base,
                              overflowY: "scroll",
                            }),
                          }}*/
                        onChange={(e) => {
                          //const value = e.values();
                          //console.log(e);
                          const array = e.map(
                            (filterValue) => filterValue.value
                          );
                          if (array.length === 0) {
                            eliminarValorDeFiltrado(header.key);
                          } else {
                            //console.log(e.value);
                            setValoresSeleccionadosFiltrar({
                              ...valoresSeleccionadosFiltrar,
                              [header.key]: [...array],
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className={` ${!estaElFiltroActivo() ? "h-[58px]" : ""}`}
                  >
                    {header.title}
                  </div>
                )}
              </th>
            );
          })}
        </tr>
      );
    } catch (err) {
      console.log("renderHeader err");
      console.log(err);
      return <tr>err</tr>;
    }
  };

  const renderFila = (row) => {
    try {
      return (
        <tr
          key={row.id}
          className={`${filaClick ? "hover:bg-gray-200 cursor-pointer" : ""}`}
          onClick={() => {
            if (filaClick) {
              filaClick(row);
            }
          }}
        >
          {headers.map((header) => renderCelda(row, header))}
        </tr>
      );
    } catch (err) {
      console.log("renderFila err");
      console.log(err);
      return <tr>err</tr>;
    }
  };

  const renderCelda = (row, header) => {
    try {
      // console.log('renderCelda');
      if (header.key === CHECK) {
        // console.log("row");
        // console.log(row);
        // console.log("header");
        // console.log(header);
        // console.log('row[header.key]');
        // console.log(row[header.key]);
        return (
          <td
            className={` px-3 py-2 text-gray-500 `}
            style={{
              fontSize: fontSize !== undefined ? fontSize + "px" : "12px",
            }}
            key={shortid.generate()}
          >
            <div
              className="text-gray-900 py-2 w-auto px-[5px]"
              key={shortid.generate()}
              style={{
                justifyContent: "center",
                display: "flex",
                whiteSpace: "nowrap",
              }}
            >
              <input
                type="checkbox"
                checked={
                  row.checked !== undefined &&
                    row.checked !== null &&
                    row.checked === true
                    ? true
                    : false
                }
                onChange={(event) => {
                  /**
                   * PREGUNTAR AQUI A PAUL
                   * POR QUE TENGO QUE ENVIAR ROWS
                   */
                  header.funcion(rows, row.id, "checked", event.target.checked);
                }}
              />
            </div>
          </td>
        );
      }
      if (header.key === UBICACION_GPS) {
        let display = "";
        if (header.visible !== undefined && header.visible === false) {
          display = "none";
        } else {
          display = "";
        }
        return (
          <td
            className={` px-3 py-2 text-gray-500 `}
            style={{
              fontSize: fontSize !== undefined ? fontSize + "px" : "12px",
              display: display,
            }}
            key={shortid.generate()}
          >
            <div
              className="text-gray-900 py-2 w-auto px-[5px]"
              key={shortid.generate()}
              style={{
                justifyContent: "center",
                display: "flex",
                whiteSpace: "nowrap",
              }}
            >
              {row[header.key] !== undefined && row[header.key] !== null ? (
                <a
                  href={
                    "https://www.google.com/maps/search/?api=1&query=" +
                    row[header.key]
                  }
                  target="_blank"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <label
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {row[header.key]}
                  </label>
                </a>
              ) : (
                "-"
              )}
            </div>
          </td>
        );
      }
      if (header.key === URL) {
        let display = "";
        if (header.visible !== undefined && header.visible === false) {
          display = "none";
        } else {
          display = "";
        }
        return (
          <td
            className={` px-3 py-2 text-gray-500 `}
            style={{
              fontSize: fontSize !== undefined ? fontSize + "px" : "12px",
              display: display,
            }}
            key={shortid.generate()}
          >
            <div
              className="text-gray-900 py-2 w-auto px-[5px]"
              key={shortid.generate()}
              style={{
                justifyContent: "center",
                display: "flex",
                whiteSpace: "nowrap",
              }}
            >
              {row[header.key] !== undefined && row[header.key] !== null ? (
                <a
                  href={
                    row[header.key]
                  }
                  target="_blank"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <label
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {row[header.key]}
                  </label>
                </a>
              ) : (
                "-"
              )}
            </div>
          </td>
        );
      }
      if (
        header.key === COLOR ||
        header.key === COLOR_A_TIEMPO ||
        header.key === COLOR_RETRASO
      ) {
        return (
          <td
            className={` px-3 py-2  text-gray-500 `}
            // key={header.id}
            style={{
              width: anchuraColumnaBotones + "px",
              fontSize: fontSize !== undefined ? fontSize + "px" : "12px",
            }}
            key={shortid.generate()}
          >
            <div
              className="flex mr-[0px]"
              style={{ width: anchuraColumnaBotones + "px" }}
              key={shortid.generate()}
            >
              <div
                className="text-gray-900 py-2 w-auto px-[5px]"
                key={shortid.generate()}
                style={{
                  justifyContent: "center",
                  display: "flex",
                  whiteSpace: "nowrap",
                }}
              >
                {row[header.key] !== null ? (
                  <input
                    id="color"
                    name="color"
                    type="color"
                    value={row[header.key]}
                    readOnly
                  />
                ) : (
                  "-"
                )}
              </div>
            </div>
          </td>
        );
      }
      if (typeof row[header.key] === OBJECT && header.key === ACCIONES) {
        return (
          <td
            className={` px-3 py-2 text-gray-500 `}
            // key={header.id}
            style={{
              width: header.onlyIcon ? "auto" : anchuraColumnaBotones + "px",
              fontSize: fontSize !== undefined ? fontSize + "px" : "12px",
            }}
            key={shortid.generate()}
          >
            <div
              className="flex mr-[0px]"
              style={{
                width: header.onlyIcon ? "auto" : anchuraColumnaBotones + "px",
              }}
              key={shortid.generate()}
            >
              {row[header.key].map((accion, index) =>
                drawAcctionButton(accion, row, index, header.onlyIcon)
              )}
            </div>
          </td>
        );
      }
      if (
        typeof row[header.key] === OBJECT &&
        (header.key === CORREOS || header.key === GEOCERCAS)
      ) {
        return (
          <td
            className={` px-3 py-2 text-gray-500 `}
            // key={header.id}
            // style={{ width: anchuraColumnaBotones + 'px' }}
            style={{
              fontSize: fontSize !== undefined ? fontSize + "px" : "12px",
            }}
            key={shortid.generate()}
          >
            <div
              className="flex mr-[0px]"
              style={{
                // width: anchuraColumnaBotones + 'px',
                flexDirection: "column",
              }}
              key={shortid.generate()}
            >
              {row[header.key].map((correo) => (
                <label key={correo.value}>{correo.label}</label>
              ))}
            </div>
          </td>
        );
      }
      if (row[header.key] === null) {
        row[header.key] = "";
      }
      if (typeof row[header.key] !== OBJECT) {
        let display = "";
        if (header.visible !== undefined && header.visible === false) {
          display = "none";
        } else {
          display = "";
        }
        return (
          <td
            key={shortid.generate()}
            style={{
              display: display,
            }}
          >
            <div
              className={`text-gray-900 py-2 px-[5px] }`}
              key={shortid.generate()}
              style={{
                justifyContent: row[header.key]
                  ? center
                    ? "center"
                    : "left"
                  : "center",
                display: "flex",
                whiteSpace: "nowrap",
                fontSize: fontSize !== undefined ? fontSize + "px" : "12px",
                width: "100%",
              }}
            >
              <p
                style={{
                  textAlign: center
                    ? "center"
                    : header.align
                      ? header.align
                      : "left",
                  width: "100%",
                }}
              >
                {row[header.key] ? row[header.key] : "-"}
              </p>
            </div>
          </td>
        );
      }
      return (
        <td
          key={shortid.generate()}
          style={{
            display: "",
          }}
        >
          <div
            className="text-gray-900"
            key={shortid.generate()}
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            error if
          </div>
        </td>
      );
    } catch (err) {
      console.log("renderCelda err");
      console.log(err);
      return <div>{JSON.stringify(err)}</div>;
    }
  };

  const drawAcctionButton = (accion, todaLaFila, index, onlyIcon) => {
    try {
      return (
        <span
          key={index}
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop: "5px",
            paddingBottom: "5px",
            maxHeight: "30px",
            fontSize: "12px",
            backgroundColor: `${accion.color}`,
            color: "white",
            borderRadius: "15px",
            cursor: "pointer",
            marginLeft: 10,
            marginTop: 5,
          }}
          onClick={() => {
            accion.onClick(todaLaFila);
          }}
          title={accion.label}
        >
          {onlyIcon ? (
            <accion.icon style={{ height: "18px", with: "18px" }} />
          ) : (
            accion.label
          )}
        </span>
      );
    } catch (err) {
      console.log("drawAcctionButton err");
      console.log(err);
      return <label>btn err</label>;
    }
  };

  return (
    <div className="mx-5 px-4 sm:px-6 lg:px-8">
      <div className="mt-1 flex flex-col">
        <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full max-w-full max-h-[600px] pt-2 mt-1 align-middle">
            {exportar !== undefined && exportar === true ? (
              <div className="w-full mb-[5px] flex justify-end">
                <img
                  title="Exportar a Excel"
                  className="h-[35px] w-[35px]"
                  alt="excel"
                  src={excelIcon}
                  onClick={() => {
                    console.log("exportarAExcel");
                    exportarAExcel(dataFiltrada);
                  }}
                ></img>
              </div>
            ) : null}
            <div className="  overflow-hidden min-h-[500px] max-h-[500px] overflow-y-auto overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-t-lg">
              <div className="min-h-[500px]">
                <table className="min-w-full divide-y divide-gray-300 ">
                  <thead className="bg-gray-50 sticky top-0">
                    {renderHeader()}
                  </thead>
                  <tbody className="min-w-full divide-y divide-gray-200 bg-white max-h-[600px]">
                    {dataFiltrada.length > 0 ? (
                      dataFiltrada.map(renderFila)
                    ) : (
                      <tr>
                        <td className="h-10 p-10">Sin Datos</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="w-full h-4 bg-gray-50 border-y-[1px] border-gray-200" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Grid.propTypes = {
  rows: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
};

export default Grid;

import React, { useState, useEffect } from 'react';
import './css/time-line.css'
import moment from "moment";
import ModalIncidente from "../components/modal-incidente";
import ModalInfoUnidadIncidente from "../components/modal-info-unidad-incidente";
import { obtenerIcono, sumTimeStrings } from '../share/shared-functions';


// const TimeLine = ({ seguimiento }) => {
export default function TimeLine({ seguimiento }) {
    const numRows = 4;
    const numCols = 50;

    const [modalIncidenteVisible, setModalIncidenteVisible] = useState(false);
    const [incidenteSelected, setIncidenteSelected] = useState({});

    const [modalInfoUnidadSeguimientoVisible, setModalInfoUnidadSeguimientoVisible] = useState(false);

    useEffect(() => {
        // console.log("entro a listar correo");
        // console.log('seguimiento');
        // console.log(seguimiento);
    }, []);

    const calcularLeft = (data) => {
        try {
            let resultado = "0%";
            // console.log('calcularLeft');
            // console.log('seguimiento');
            // console.log(seguimiento);
            // console.log('data');
            // console.log(data);

            const fechaSalidaUnix = seguimiento.fechaSalidaUnix;
            const fechaIncidenteUnix = data.fechaProcesoUnix;
            const fechaDescargaUnix = seguimiento.fechaDescargaUnix;

            const diffTiempoViajeTotal = fechaDescargaUnix - fechaSalidaUnix;

            /*console.log('fechaSalidaUnix');
            console.log(fechaSalidaUnix);

            console.log('diffTiempoViajeTotal');
            console.log(diffTiempoViajeTotal);

            console.log('fechaSalidaUnix + diffTiempoViajeTotal');
            console.log(fechaSalidaUnix + diffTiempoViajeTotal);*/

            const diffTiempoIncidente = fechaIncidenteUnix - fechaSalidaUnix;

            /*console.log('diffTiempoIncidente');
            console.log(diffTiempoIncidente);

            console.log('fechaSalidaUnix + diffTiempoIncidente');
            console.log(fechaSalidaUnix + diffTiempoIncidente);*/

            const incidenteUnix = parseInt((diffTiempoIncidente * 100) / diffTiempoViajeTotal);

            /*console.log('incidenteUnix');
            console.log(incidenteUnix);*/

            resultado = incidenteUnix + "%"

            return resultado;
        } catch (err) {
            console.log('calcularLeft err');
            console.log(err);
            return "0%";
        }
    }

    const calcularLeftUnidad = (data) => {
        try {
            let resultado = "0%";

            const fechaSalidaUnix = seguimiento.fechaSalidaUnix;
            const fechaEventoActualUnix = data.fechaEventoUnix;
            const fechaDescargaUnix = seguimiento.fechaDescargaUnix;

            const posicionUnix = calcularPorcentaje(fechaSalidaUnix, fechaEventoActualUnix, fechaDescargaUnix);

            resultado = posicionUnix + "%";

            return resultado;
        } catch (err) {
            console.log('calcularLeftUnidad err');
            console.log(err);
            return "0%";
        }
    }

    const calcularPorcentaje = (fechaSalidaUnix, fechaEventoUnix, fechaDescargaUnix) => {
        try {
            const diffTiempoViajeTotal = fechaDescargaUnix - fechaSalidaUnix;

            const diffTiempoIncidente = fechaEventoUnix - fechaSalidaUnix;

            let posicionUnix = parseInt((diffTiempoIncidente * 100) / diffTiempoViajeTotal);

            if (posicionUnix > 99) {
                posicionUnix = 99;
            }

            return posicionUnix;

        } catch (err) {
            console.log('calcularPorcentaje err');
            console.log(err);
            return 0;
        }
    }


    const handleOnClickIncidente = (data) => {
        try {
            console.log('handleOnClickIncidente');
            console.log(data);
            console.log(seguimiento);
            setIncidenteSelected(data);
            setModalIncidenteVisible(true);
        } catch (err) {
            console.log('handleOnClickIncidente err');
            console.log(err);
        }
    }

    const hideModalIncidente = () => {
        setModalIncidenteVisible(false);
    }

    const handleOnClickUnidad = () => {
        try {
            console.log('handleOnClickUnidad');
            console.log(seguimiento);
            setModalInfoUnidadSeguimientoVisible(true);
        } catch (err) {
            console.log('handleOnClickUnidad err');
            console.log(err);
        }
    }

    const hideModalInfoUnidadSeguimiento = () => {
        setModalInfoUnidadSeguimientoVisible(false);
    }

    const obtenerUrl = () => {
        try {
            // console.log('obtenerUrl seguimiento url');
            // console.log(seguimiento);
            return obtenerIcono(seguimiento.ubicacionActual.ORIENT, false, seguimiento.ubicacionActual.I_ENCENDIDO, seguimiento.ubicacionActual.VELOCIDAD, seguimiento.ubicacionActual.icono);
        } catch (err) {
            console.log('obtenerUrl err');
            return "";
        }
    }

    const handleOnClickItinerario = (data) => {
        try {
            console.log('handleOnClickItinerario');
            console.log(data);
            console.log('seguimiento');
            console.log(seguimiento);
        } catch (err) {
            console.log('handleOnClickItinerario err');
            console.log(err);
        }
    }

    const calcularLeftItinerario = (data, index) => {
        try {
            // console.log('calcularLeftItinerario');
            // console.log(seguimiento);
            // console.log(data);
            // console.log(index);

            // Obtener el tiempo Unix
            // listaSeguimientoTemporal[i].fechaSalidaUnix = fechaHoraIniMoment.unix();
            let arrTime = [];
            for (let i = 0; i <= index; i++) {
                const element = seguimiento.itinerario[i];
                arrTime.push(element.tiempoProgramado);
            }

            const totalTiempo = sumTimeStrings(arrTime);
            console.log('totalTiempo');
            console.log(totalTiempo);

            const tiempoArr = totalTiempo.split(':');
            // console.log('tiempoArr');
            // console.log(tiempoArr);

            let fechaSalidaDeGeocerca = moment(seguimiento.fechaSalida, 'YYYY-MM-DD HH:mm:ss');
            // console.log('fechaSalida');
            // console.log(moment(fechaSalidaDeGeocerca).format('YYYY-MM-DD HH:mm:ss'));
            let fechaSalidaDeGeocercaMasTiempoProgramado = fechaSalidaDeGeocerca.add({ hours: parseInt(tiempoArr[0]), minutes: parseInt(tiempoArr[1]) });
            console.log('fechaSalidaDeGeocercaMasTiempoProgramado');
            console.log(moment(fechaSalidaDeGeocercaMasTiempoProgramado).format('YYYY-MM-DD HH:mm:ss'));

            const fechaEventoPivote = moment(fechaSalidaDeGeocercaMasTiempoProgramado, 'YYYY-MM-DD HH:mm:ss');
            // Obtener el tiempo Unix
            const fechaSalidaDeGeocercaMasTiempoProgramadoUnix = fechaEventoPivote.unix();

            const posicionUnix = calcularPorcentaje(seguimiento.fechaSalidaUnix, fechaSalidaDeGeocercaMasTiempoProgramadoUnix, seguimiento.fechaDescargaUnix);
            console.log('posicionUnix');
            console.log(posicionUnix);
            return posicionUnix + "%";
        } catch (err) {
            console.log('calcularLeft err');
            console.log(err);
            return "0%";
        }
    }

    return (
        <div
            style={{
                display: 'relative',
                position: 'relative',
                height: 200,
            }}
        >
            <div className="flex-column">
                {Array.from({ length: numRows }).map((_, rowIndex) => (
                    <div key={rowIndex} className="grid-row">
                        {Array.from({ length: numCols }).map((_, colIndex) => (
                            <div key={colIndex} className="grid-item"></div>
                        ))}
                    </div>
                ))}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: 60,
                    left: 0
                }}
            >
                <div className="circle"></div>
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: 80,
                }}
            >
                <label
                    style={{
                        fontSize: 10,
                        paddingTop: 5,
                        transform: 'rotate(0deg)', // Inclina el label 45 grados
                        transformOrigin: 'bottom left', // Define el punto de origen de la rotación
                        writingMode: 'vertical-rl', // Orienta el texto verticalmente de derecha a izquierda
                        whiteSpace: 'nowrap', // Evita que el texto se envuelva si es largo
                    }}
                    title={seguimiento.fechaSalida}
                >
                    {seguimiento.fechaSalida}
                </label>
            </div>

            <div
                style={{
                    position: 'absolute',
                    top: 60,
                    right: 0,
                }}
            >
                <div className="circle"></div>
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: 80,
                    right: 0,
                }}
            >
                <label
                    style={{
                        fontSize: 10,
                        paddingTop: 5,
                        transform: 'rotate(0deg)', // Inclina el label 45 grados
                        transformOrigin: 'bottom left', // Define el punto de origen de la rotación
                        writingMode: 'vertical-rl', // Orienta el texto verticalmente de derecha a izquierda
                        whiteSpace: 'nowrap', // Evita que el texto se envuelva si es largo
                    }}
                    title={seguimiento.fechaDescarga}
                >
                    {seguimiento.fechaDescarga}
                </label>
            </div>

            {seguimiento.incidentes !== undefined && seguimiento.incidentes.map((data) =>
                <div
                    onClick={() => handleOnClickIncidente(data)}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: 40,
                            left: calcularLeft(data)
                        }}
                    >
                        <div className="circle-purple"></div>
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            top: 80,
                            left: calcularLeft(data)
                        }}
                    >
                        <label
                            style={{
                                fontSize: 10,
                                paddingTop: 5,
                                transform: 'rotate(0deg)', // Inclina el label 45 grados
                                transformOrigin: 'bottom left', // Define el punto de origen de la rotación
                                writingMode: 'vertical-rl', // Orienta el texto verticalmente de derecha a izquierda
                                whiteSpace: 'nowrap', // Evita que el texto se envuelva si es largo
                            }}
                            title={data.fechaProceso}
                        >
                            {data.fechaProceso}
                        </label>
                    </div>
                </div>
            )}
            {seguimiento.fechaEventoUnix !== undefined && seguimiento.fechaEventoUnix !== null && String(seguimiento.fechaEventoUnix).length > 0 &&
                <div
                    onClick={() => handleOnClickUnidad()}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: 15,
                            // right: 10,
                            left: calcularLeftUnidad(seguimiento),
                            height: 35,
                            width: 35,
                            alignContent: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: 10,
                        }}
                    >
                        <img src={obtenerUrl()} alt="UNIDAD"
                        />
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            top: 80,
                            left: calcularLeftUnidad(seguimiento)
                        }}
                    >
                        <label
                            style={{
                                fontSize: 10,
                                paddingTop: 5,
                                transform: 'rotate(0deg)', // Inclina el label 45 grados
                                transformOrigin: 'bottom left', // Define el punto de origen de la rotación
                                writingMode: 'vertical-rl', // Orienta el texto verticalmente de derecha a izquierda
                                whiteSpace: 'nowrap', // Evita que el texto se envuelva si es largo
                            }}
                            title={seguimiento.fechaEvento}
                        >
                            {seguimiento.fechaEvento}
                        </label>
                    </div>
                </div>
            }
            {seguimiento.itinerario !== undefined && seguimiento.itinerario.map((data, index) =>
                <div
                    onClick={() => handleOnClickItinerario(data)}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: 10,
                            left: calcularLeftItinerario(data, index)
                        }}
                    >
                        <div className="circle-green"></div>
                    </div>
                </div>
            )}
            <ModalIncidente
                isOpen={modalIncidenteVisible}
                onAccept={hideModalIncidente}
                seguimiento={seguimiento}
                data={incidenteSelected}
            />
            <ModalInfoUnidadIncidente
                isOpen={modalInfoUnidadSeguimientoVisible}
                onAccept={hideModalInfoUnidadSeguimiento}
                seguimiento={seguimiento}
            />
        </div>
    );
}

// export default TimeLine;
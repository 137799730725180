import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "../../../components/grid";
import { ESTILO_BOTON_GRILLA_NEXT, ESTILO_BOTON_GRILLA_PREVIOUS, ESTILO_BOTON_LISTAR_ATRAS, ESTILO_BOTON_LISTAR_NUEVO } from "../../../utils/const-estilos";
import { deshabilitarCorreo, obtenerListaCorreos } from "../../../api/checklist";
import ModalConfirmar from "../../../components/modal-confirmar";
import "../../../style/style-cargando.css";
import {
  INT_ID_MODULO_CHECKLIST,
  INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST,
  INT_ID_TERCER_NIVEL_CHECKLIST_CORREOS,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../../utils/const-int";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../../api/permisos/permisos";
import { TRUE } from "../../../utils/const-bool";

const idTipoUsuario = parseInt(localStorage.getItem("tipo"));
let accesoBotonNuevo = 'none';

export default function ListarCorreos() {
  const headers = [
    { key: "acciones", title: "Acciones" },
    { key: "id", title: "ID", visible: false, },
    { key: "nombre", title: "Nombre", filter: true },
    { key: "email", title: "Correo", filter: true },
    { key: "telefono", title: "Telefono", filter: true },
    { key: "nota", title: "Nota", filter: true },
    { key: "fechaproceso", title: "Fecha Proceso", filter: true },
  ];
  const history = useHistory();
  const [minRows, setMinRows] = useState(0);
  const [maxRows, setMaxRows] = useState(5);
  const [cargandoPage, setCargandoPage] = useState(true);
  const [listaCorreos, setListaCorreos] = useState([]);
  const [modalConfirmarDeshabilitarVisible, setModalConfirmarDeshabilitarVisible] = useState(false);
  const [correoSelected, setCorreoSelected] = useState(null);
  const [permisos, setPermisos] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);

  useEffect(() => {
    // console.log("entro a listar correo");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      let obj = {};
      obj.idMenuPrimerNivel = INT_ID_MODULO_CHECKLIST;
      obj.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST;
      obj.idMenuTercerNivel = INT_ID_TERCER_NIVEL_CHECKLIST_CORREOS;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(obj);
      let permiso = obtenerPermisos[0];
      setPermisos(permiso);

      let listarCorreos = [];
      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        accesoBotonNuevo = '';
        setTieneAccesoAlModulo(true);
        listarCorreos = await obtenerListaCorreos();
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.accesoAlModulo === TRUE) {
          setTieneAccesoAlModulo(true);
          listarCorreos = await obtenerListaCorreos();
        }
      }
      agregarAccionesALista(listarCorreos, permiso);
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const agregarAccionesALista = (lista, permiso) => {
    try {
      if (lista.length === 0) {
        setListaCorreos(lista);
      }
      if (lista.length > 0) {
        let objModificar = {
          label: "Modificar",
          color: "green",
          onClick: (param) => editarCorreo(param),
        };
        let objDeshabilitar = {
          label: "Deshabilitar",
          color: "red",
          onClick: (param) => cambiarEstado(param),
        };
        if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
          if (permiso.nuevo === TRUE) {
            accesoBotonNuevo = '';
          }
        }
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            acciones.push(objModificar);
            acciones.push(objDeshabilitar);
          }
          if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            if (permiso.modificar === TRUE) {
              acciones.push(objModificar);
            }
            if (permiso.eliminarDeshabilitar === TRUE) {
              acciones.push(objDeshabilitar);
            }
          }
          lista[i].acciones = acciones;
        }
        setListaCorreos(lista);
      }
    } catch (err) {
      console.log('agregarAccionesALista err');
      console.log(err);
    }
  }

  const nextPage = (page, params) => {
    try {
      // history.push(`${page}`);
      // console.log('params');
      // console.log(params);
      if (params !== undefined && params !== null) {
        if (params.acciones !== undefined) {
          delete params.acciones;
        }
        // console.log('params sin acciones');
        // console.log(params);
        history.push({
          pathname: `${page}`,
          state: params
        });
      } else {
        history.push(`${page}`);
      }
    } catch (err) {
      console.log('nextPage err');
      console.log(err);
    }
  };

  const handleNextPage = () => {
    if (maxRows >= listaCorreos.length) return;
    setMinRows(maxRows);
    setMaxRows(maxRows + 5);
  };

  const handlePrevPage = () => {
    if (minRows <= 0) return;
    setMaxRows(minRows);
    setMinRows(Math.max(minRows - 5, 0));
  };

  const editarCorreo = (data) => {
    try {
      // console.log("editarCorreo");
      // console.log(data);
      if (data.acciones !== undefined) {
        delete data.acciones;
      }
      let obj = {};
      obj.titulo = "Modificar Correo";
      obj.params = data;
      // console.log('obj');
      // console.log(obj);
      nextPage("correo", obj);
    } catch (err) {
      console.log("editarCorreo err");
      console.log(err);
    }
  };

  const cambiarEstado = async (data) => {
    try {
      console.log("cambiarEstado");
      console.log(data);
      setCorreoSelected(data);
      setModalConfirmarDeshabilitarVisible(true);
    } catch (err) {
      console.log("cambiarEstado err");
      console.log(err);
    }
  };

  const handleNuevoClick = () => {
    // console.log("handleNuevoClick");
    let obj = {};
    let data = {};
    data.id = 0;
    data.nombre = "";
    data.email = "";
    data.telefono = "";
    data.nota = "";
    obj.titulo = "Crear Correo";
    obj.params = data;
    nextPage("correo", obj);
  }

  const handleAtrasClick = () => {
    nextPage("administracion", null);
  }

  const reset = async () => {
    setCargandoPage(true);
    let listarCorreos = await obtenerListaCorreos(6056);
    // console.log("listarCorreos");
    // console.log(listarCorreos);
    agregarAccionesALista(listarCorreos);
    setCargandoPage(false);
  }

  const handleCancelModal = () => {
    setModalConfirmarDeshabilitarVisible(false);
  }

  const handleConfirmModal = async () => {
    setModalConfirmarDeshabilitarVisible(false);
    if (correoSelected == null) {
      return;
    }
    let objEnviar = {};
    objEnviar.id = correoSelected.id;
    if (correoSelected.id > 0) {
      // console.log('crear correo');
      let deshabilitado = await deshabilitarCorreo(objEnviar);
      // console.log('creado');
      // console.log(creado);
      if (deshabilitado === true) {
        alert('Se deshabilito correctamente');
        reset();
      }
      if (deshabilitado === false) {
        alert('No se pudo deshabilitar');
      }
    }
  }

  const render = () => {
    try {
      // console.log('listar correo render');
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div
              style={{ marginTop: 10, }}
            >
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <label
              style={{
                paddingLeft: 10,
                fontWeight: 'bold',
              }}
            >
              GESTIONAR CORREOS
            </label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_NUEVO}
              onClick={handleNuevoClick}
              style={{
                display: accesoBotonNuevo,
              }}
            >
              NUEVO
            </button>
          </div>
          <Grid rows={listaCorreos} headers={headers} />
          <div>
            <button className={ESTILO_BOTON_GRILLA_PREVIOUS} onClick={handlePrevPage}>Previous</button>
            <button className={ESTILO_BOTON_GRILLA_NEXT} onClick={handleNextPage}>Next</button>
          </div>
          <ModalConfirmar
            isOpen={modalConfirmarDeshabilitarVisible}
            onCancel={handleCancelModal}
            onConfirm={handleConfirmModal}
            message="¿Estás de deshabilitar correo?"
          />
        </div>
      );
    } catch (err) {
      console.log('ListarCorreos render err');
      console.log(err);
      return (
        <div><label>{JSON.stringify(err)}</label></div>
      );
    }
  }

  return render();
}

import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  ESTILO_BOTON_GENERAL_ACEPTAR,
  ESTILO_BOTON_GENERAL_CANCELAR,
  ESTILO_INPUT_GENERICO
} from "../../../utils/const-estilos";
import { crearTipoChekclist, modificarTipoChekclist } from "../../../api/checklist";
import "../../../style/style-cargando.css";

export default function TipoChecklist() {
  const location = useLocation();
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(true);
  const [tipoChecklist, setTipoChecklist] = useState(null);
  const [titulo, setTitulo] = useState("");
  const [entradaCheck, setEntradaCheck] = useState(false);
  const [salidaCheck, setSalidaCheck] = useState(false);

  useEffect(() => {
    // console.log("entro a Correos");
    iniciar();
  }, []);

  const iniciar = () => {
    try {
      const data = location.state;
      console.log("data");
      console.log(data);
      if (data !== undefined && data !== null) {
        setTipoChecklist(data.params);
        setTitulo(data.titulo);
        setEntradaCheck(data.params.entrada);
        setSalidaCheck(data.params.salida);
      }
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const handleInputChange = (event) => {
    // console.log('handleInputChange');
    const { name, value } = event.target;
    // console.log(name);
    // console.log(value);
    setTipoChecklist({ ...tipoChecklist, [name]: value });
  };

  const handleCancelClick = () => {
    history.goBack();
  };

  const handleSaveClick = async () => {
    // console.log('handleSaveClick');
    // lógica para guardar los cambios

    const valido = validar();
    if (valido === false) {
      alert('Por favor verifique que los campos');
      return;
    }
    let objEnviar = {};
    objEnviar.descripcion = String(tipoChecklist.descripcion).trim();
    objEnviar.id = tipoChecklist.id;
    objEnviar.entrada = entradaCheck;
    objEnviar.salida = salidaCheck;
    console.log('objEnviar');
    console.log(objEnviar);
    if (tipoChecklist.id <= 0) {
      // console.log('crear correo');
      let creado = await crearTipoChekclist(objEnviar);
      // console.log('creado');
      // console.log(creado);
      if (creado === true) {
        alert('Se creo correctamente');
        handleCancelClick();
      }
      if (creado === false) {
        alert('No se pudo crear');
      }
    }
    if (tipoChecklist.id > 0) {
      // console.log('actualizar correo');
      let modificado = await modificarTipoChekclist(objEnviar);
      // console.log('modificado');
      // console.log(modificado);
      if (modificado === true) {
        alert('Se modifico correctamente');
        handleCancelClick();
      }
      if (modificado === false) {
        alert('No se pudo modificar');
      }
    }
  };

  const validar = () => {
    try {
      if (tipoChecklist.descripcion === undefined || tipoChecklist.descripcion === null || String(tipoChecklist.descripcion).toString().trim().length === 0) {
        return false;
      }
      if (entradaCheck === false && salidaCheck === false) {
        return false;
      }
      return true;
    } catch (err) {
      console.log('validar err');
      console.log(err);
      return false;
    }
  }

  const handleCheckboxEntrada = (event) => {
    setEntradaCheck(event.target.checked);
    setSalidaCheck(false);
  }

  const handleCheckboxSalida = (event) => {
    setSalidaCheck(event.target.checked);
    setEntradaCheck(false);
  }

  const render = () => {
    // console.log('render correo');
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (cargandoPage === false && tipoChecklist === null) {
        return (
          <div>
            <label> Sin data</label>
          </div>
        );
      }
      return (
        <div className="min-h-screen bg-gray-100 flex flex-col  py-12 sm:px-6 lg:px-8">
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="text-center text-3xl font-extrabold text-gray-900">
              {titulo}
            </h2>
            <div className="mt-6 bg-white py-6 px-4 shadow-md rounded-lg sm:px-10">
              <div className="space-y-6">
                <div>
                  <label
                    htmlFor="nombre"
                    className="block text-sm font-medium text-gray-700"
                  >
                    DESCRIPCION
                  </label>
                  <div className="mt-1">
                    <input
                      id="descripcion"
                      name="descripcion"
                      type="text"
                      value={tipoChecklist.descripcion}
                      onChange={handleInputChange}
                      autoComplete="given-name"
                      className={ESTILO_INPUT_GENERICO}
                      required
                      maxLength={127}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="nombre"
                    className="block text-sm font-medium text-gray-700"
                  >
                    ENTRADA
                  </label>
                  <div className="mt-1">
                    <input
                      type="checkbox"
                      checked={entradaCheck}
                      onChange={handleCheckboxEntrada}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="nombre"
                    className="block text-sm font-medium text-gray-700"
                  >
                    SALIDA
                  </label>
                  <div className="mt-1">
                    <input
                      type="checkbox"
                      checked={salidaCheck}
                      onChange={handleCheckboxSalida}
                    />
                  </div>
                </div>

                <div className="flex justify-end">
                  <div
                    style={{
                      justifyContent: 'space-between',
                      display: 'flex',
                      width: '100%'
                    }}
                  >
                    <button
                      type="button"
                      className={ESTILO_BOTON_GENERAL_CANCELAR}
                      onClick={handleCancelClick}
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      className={ESTILO_BOTON_GENERAL_ACEPTAR}
                      onClick={handleSaveClick}
                    >
                      Aceptar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } catch (err) {
      console.log('ModificarCorreos render err');
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  }

  return render();
}

import moment from "moment/moment";
import { SUCCESS } from "../page/const/http-const";
import { getHeaders } from "./utils";
// import { TRUE } from "../utils/const-bool";

export async function obtenerListaCategoriaBitacora() {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/obtener-lista-categoria-bitacora/",
            {
                headers: getHeaders(),
                method: "POST",
                // body: JSON.stringify({data: 'hello'}),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            rows = body;
            for (let i = 0; i < rows.length; i++) {
                const element = rows[i];
                rows[i].fechaproceso = moment(element.fechaProceso).format(
                    "DD/MM/YYYY HH:mm"
                );
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaCategoriaBitacora err");
        console.log(err);
        return [];
    }
}

export async function crearCategoriaBitacora(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/crear-categoria-bitacora/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("crearCategoriaBitacora err");
        console.log(err);
        return false;
    }
}

export async function modificarCategoriaBitacora(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/modificar-categoria-bitacora/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarCategoriaBitacora err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarCategoriaBitacora(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/deshabilitar-categoria-bitacora/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarCategoriaBitacora err");
        console.log(err);
        return false;
    }
}
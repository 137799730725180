import carIcon from "../assets/iconos/car.png"

export const MODULOS_ADMINISTRACION = [
    {
        id: 1,
        titulo: 'TIPO CARROCERIA',
        descripcion: 'Crear los tipos de unidades de la flota',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'listar-tipo-carroceria',
    },
    {
        id: 2,
        titulo: 'CORREOS',
        descripcion: 'Son los correos a los que se enviara notificacion de el checklist',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'listar-correo',
    },
    {
        id: 3,
        titulo: 'OPCIONES RESPUESTA',
        descripcion: 'Opciones Respuesta que se ocupan en el checklist',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'listar-opciones-respuesta',
    },
    {
        id: 4,
        titulo: 'MOVIMIENTO',
        descripcion: 'Movimientos del area de transporte',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'listar-movimiento',
    },
    {
        id: 5,
        titulo: 'TIPO CHECKLIST',
        descripcion: 'Tipos de Checklist',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'listar-tipo-checklist',
    },
    {
        id: 6,
        titulo: 'GESTIONAR ICONOS',
        descripcion: 'Iconos de Checklist',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'listar-iconos',
    },
    {
        id: 7,
        titulo: 'GEOCERCA',
        descripcion: 'Seleccionar el grupo geocercas que se usara',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'seleccionar-grupo-geocerca',
    },
    {
        id: 8,
        titulo: 'ASIGNAR CORREO',
        descripcion: 'Asigncar correo a Geocerca para recibir notificacion de checklist',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'asignar-correo-a-geocerca',
    },
    // {
    //     id: 9,
    //     titulo: 'CATEGORIA',
    //     descripcion: 'Referencia en Bitacora e Incidentes',
    //     icono: carIcon,
    //     color: 'rgba(0, 128, 0, 0.5)',
    //     href: 'listar-categoria',
    // },
];
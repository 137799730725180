export let HORARIO = [
    {
        id: 0,
        hora: '00:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 1,
        hora: '01:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 2,
        hora: '02:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 3,
        hora: '03:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 4,
        hora: '04:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 5,
        hora: '05:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 6,
        hora: '06:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 7,
        hora: '07:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 8,
        hora: '08:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 9,
        hora: '09:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 10,
        hora: '10:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 11,
        hora: '11:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 12,
        hora: '12:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 13,
        hora: '13:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 14,
        hora: '14:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 15,
        hora: '15:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 16,
        hora: '16:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 17,
        hora: '17:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 18,
        hora: '18:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 19,
        hora: '19:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 20,
        hora: '20:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 21,
        hora: '21:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 22,
        hora: '22:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
    {
        id: 23,
        hora: '23:00',
        capacidad: '',
        plan: [],
        diferencia: '',
    },
];
import moment from "moment/moment";
import { SUCCESS } from "../page/const/http-const";
import { getHeaders } from "./utils";

export async function crearSubTipoUsuarioWeb(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/subtipo-usuario-web/crear-subtipo-usuario-web/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("crearSubTipoUsuarioWeb err");
        console.log(err);
        return false;
    }
}

export async function obtenerListaSubTipoUsuarioWeb(obj) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/subtipo-usuario-web/obtener-lista-subtipo-usuario-web/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            rows = body;
            for (let i = 0; i < rows.length; i++) {
                const element = rows[i];
                rows[i].fechaProceso = moment(element.fechaProceso).format(
                    "DD/MM/YYYY HH:mm"
                );
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaSubTipoUsuarioWeb err");
        console.log(err);
        return [];
    }
}

export async function deshabilitarSubTipoUsuarioWeb(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/subtipo-usuario-web/deshabilitar-subtipo-usuario-web/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarSubTipoUsuarioWeb err");
        console.log(err);
        return false;
    }
}

export async function modificarSubTipoUsuarioWeb(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/subtipo-usuario-web/modificar-subtipo-usuario-web/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarSubTipoUsuarioWeb err");
        console.log(err);
        return false;
    }
}
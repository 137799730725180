import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Grid from "../../components/grid";

export default function Alertas() {
  const [dataTest, setDataTest] = useState([
    {
      fecha: "2024-09-23",
      nombre: "Salida de ruta La Paz",
      tipo: "Seguimiento",
    },
    {
      fecha: "2024-09-22",
      nombre: "Salida de ruta Ilo",
      modulo: "Seguimiento",
    },
  ]);
  const history = useHistory();
  const headersListado = [
    {
      key: "fecha",
      title: "Fecha de creacion",
      filter: true,
    },
    {
      key: "nombre",
      title: "Nombre de la alerta",
      filter: true,
    },
    {
      key: "modulo",
      title: "Modulo",
      filter: true,
    },
    { key: "acciones", title: "Acciones", id: 1 },
  ];
  const nextPage = (page, params) => {
    try {
      if (params !== undefined && params !== null) {
        if (params.acciones !== undefined) {
          delete params.acciones;
        }
        history.push({
          pathname: `${page}`,
          state: params,
        });
      } else {
        history.push(`${page}`);
      }
    } catch (e) {
      console.error(e.message);
    }
  };
  const agregarAccionesVerALista = (lista) => {
    try {
      if (lista.length > 0) {
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          let objModificar = {
            label: "Modificar",
            color: "#00b894",
            //onClick: (param) => modificar(param),
          };

          let objEliminar = {
            label: "Eliminar",
            color: "#00b894",
            //onClick: (param) => modificar(param),
          };

          let objVer = {
            label: "Ver",
            color: "#00b894",
            //onClick: (param) => modificar(param),
          };
          acciones.push(objEliminar);
          acciones.push(objModificar);
          acciones.push(objVer);
          lista[i].acciones = acciones;
        }
        setDataTest(lista);
      }
    } catch (err) {
      console.log("agregarAccionesALista err");
      console.log(err);
    }
  };
  useEffect(() => {
    agregarAccionesVerALista(dataTest);
  }, []);
  return (
    <div>
      <div className="p-[2rem] flex justify-between">
        <div className="w-2/4 ">
          <h1 className="font-bold text-gray-700 text-[24px]">Alertas</h1>
          <h3>Revisa, crea y modifica tus alertas</h3>
        </div>
        <div>
          <button
            className="my-auto bg-[#113A5F] rounded text-white p-[5px] px-[15px]"
            onClick={() => {
              nextPage("alertas/administracion");
            }}
          >
            Crear alerta
          </button>
        </div>
      </div>
      <Grid rows={dataTest} headers={headersListado} />
    </div>
  );
}

import { SUCCESS } from "../page/const/http-const";
import { getHeaders } from "./utils";

export async function obtenerTiempoDescarga(
  idEmpresa,
  unidad,
  mesi,
  diai,
  horai,
  mini,
  mesf,
  diaf,
  horaf,
  minf,
  vCodOri,
  vNombOri,
  vCodDes,
  vNombDest
) {
  try {
    //https://gestiondeflota.boltrack.net/reportes/RP502BodyV10.rep?E=3698&T=0&IMEI=TODOS&mesi=2307&diai=01&horai=00&mini=00&mesf=2308&diaf=31&horaf=23&minf=59&                                                                                                                vCodOri=&vNombOri=&vCodDes=&vNombDest=
    let url =
      "https://gestiondeflota.boltrack.net/reportes/RP502BodyV10.rep?E=" +
      idEmpresa +
      "&T=0&IMEI=" +
      unidad +
      "&mesi=" +
      mesi +
      "&diai=" +
      diai +
      "&horai=" +
      horai +
      "&mini=" +
      mini +
      "&mesf=" +
      mesf +
      "&diaf=" +
      diaf +
      "&horaf=" +
      horaf +
      "&minf=" +
      minf +
      "&vCodOri=" +
      vCodOri +
      "&vNombOri=" +
      vNombOri +
      "&vCodDes=" +
      vCodDes +
      "&vNombDest=" +
      vNombDest;
    const response = await fetch(url);
    if (!response.ok) {
      return null;
    }
    const data = await response.json();
    // Aquí puedes hacer lo que necesites con los datos recibidos
    return data;
  } catch (err) {
    console.log("obtenerTiempoDescarga err");
    console.log(err);
    return null;
  }
}

export async function obtenerTiempoCarga(
  idEmpresa,
  unidad,
  mesi,
  diai,
  horai,
  mini,
  mesf,
  diaf,
  horaf,
  minf,
  vCodOri,
  vNombOri
) {
  try {
    //https://gestiondeflota.boltrack.net/reportes/RP502BodyV11.rep?E=3698&T=0&                       IMEI=TODOS&mesi=2307&diai=01&horai=00&mini=00&mesf=2308&diaf=31&horaf=23&minf=59&                                                                                    vCodOri=&vNombreOrigen=
    let url =
      "https://gestiondeflota.boltrack.net/reportes/RP502BodyV11.rep?E=" +
      idEmpresa +
      "&T=0&IMEI=" +
      unidad +
      "&mesi=" +
      mesi +
      "&diai=" +
      diai +
      "&horai=" +
      horai +
      "&mini=" +
      mini +
      "&mesf=" +
      mesf +
      "&diaf=" +
      diaf +
      "&horaf=" +
      horaf +
      "&minf=" +
      minf +
      "&vCodOri=" +
      vCodOri +
      "&vNombreOrigen=" +
      vNombOri;
    const response = await fetch(url);
    if (!response.ok) {
      return null;
    }
    const data = await response.json();
    // Aquí puedes hacer lo que necesites con los datos recibidos
    return data;
  } catch (err) {
    console.log("obtenerTiempoCarga err");
    console.log(err);
    return null;
  }
}

export async function obtenerHorariosDescarga(obj) {
  try {
    let rows = [];
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/reportes/obtener-horarios-descarga/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    if (code === SUCCESS) {
      rows = body;
    }
    return rows;
  } catch (err) {
    console.log("obtenerHorariosDescarga err");
    console.log(err);
    return [];
  }
}

export async function obtenerDescargaPorFechaProceso(obj) {
  try {
    let rows = [];
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/reportes/obtener-descarga-por-fecha-proceso/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    if (code === SUCCESS) {
      rows = body;
    }
    return rows;
  } catch (err) {
    console.log("obtenerDescargaPorFechaProceso err");
    console.log(err);
    return [];
  }
}

export async function obtenerIngresoUnidadesAGeocerca(obj) {
  try {
    let result = [];
    const data = await fetch(
      "https://gestiondeflota.boltrack.net/reportes/RP502BodyV05.rep",
      {
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    if (data.error === true) {
      alert(data.mensaje);
      return result;
    }
    result = data.data;
    return result;
  } catch (err) {
    console.log("obtenerIngresoUnidadesAGeocerca err");
    console.log(err);
    return [];
  }
}

export async function obtenerGeocerca(obj) {
  try {
    let result = [];
    let token = localStorage.getItem("accessToken");
    let objDatos = {
      data_op: { data: obj, token },
      op: "READ-OBTENERGEOCERCAEMPRESA",
    };
    console.log(objDatos);
    const data = await fetch("https://gestiondeflota.boltrack.net/api/datos", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objDatos),
    }).then((res) => res.json());
    if (data.error === true) {
      alert(data.mensaje);
      return result;
    }
    result = data.data;
    return result;
  } catch (err) {
    console.log("obtenerIngresoUnidadesAGeocerca err");
  }
}

export async function obtenerGeocercaTiempo(obj) {
  try {
    let result = [];
    let token = localStorage.getItem("accessToken");
    let objDatos = {
      data_op: { data: obj, token },
      op: "READ-OBTENERGEOCERCAEMPRESAYTIEMPO",
    };
    console.log(objDatos);
    const data = await fetch("https://gestiondeflota.boltrack.net/api/datos", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objDatos),
    }).then((res) => res.json());
    if (data.error === true) {
      alert(data.mensaje);
      return result;
    }
    result = data.data;
    return result;
  } catch (err) {
    console.log("obtenerIngresoUnidadesAGeocerca err");
  }
}

export async function obtenerGeocercaTiempoAFechaActual(obj) {
  try {
    let result = [];
    let token = localStorage.getItem("accessToken");
    let objDatos = {
      data_op: { data: obj, token },
      op: "READ-OBTENERGEOCERCAEMPRESAYTIEMPOAFECHAACTUAL",
    };
    console.log(objDatos);
    const data = await fetch("https://gestiondeflota.boltrack.net/api/datos", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objDatos),
    }).then((res) => res.json());
    if (data.error === true) {
      alert(data.mensaje);
      return result;
    }
    result = data.data;
    return result;
  } catch (err) {
    console.log("obtenerIngresoUnidadesAGeocerca err");
  }
}

export async function obtenerCumplimientoDeViaje(obj) {
  try {
    let rows = [];
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/reportes/obtener-cumplimiento-de-viaje/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    if (code === SUCCESS) {
      rows = body;
    }
    return rows;
  } catch (err) {
    console.log("obtenerCumplimientoDeViaje err");
    console.log(err);
    return [];
  }
}

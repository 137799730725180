import carIcon from "../assets/iconos/car.png"

export const MODULOS_CONFIGURACION_GENERAL = [
    {
        id: 1,
        titulo: 'Asignar Planta A Usuario',
        descripcion: 'Asignar Planta a Usuario',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion/asignar-geocerca-usuario',
    },
    {
        id: 2,
        titulo: 'Configurar Tiempo Carga',
        descripcion: 'Configurar Tiempo Carga',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion/gestionar_tiempo_carga',
    },
    {
        id: 3,
        titulo: 'Configurar Tiempo Descarga',
        descripcion: 'Configurar Tiempo Descarga',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion/gestionar_tiempo_descarga',
    },
    {
        id: 4,
        titulo: 'Incidente',
        descripcion: 'Seleccionar Geocerca Ciudad',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion/seleccionar-geocerca-ciudad-incidente',
    },
    {
        id: 5,
        titulo: 'Gestionar Categoria',
        descripcion: 'Incidente',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion/categoria/listar-categoria',
    },
    {
        id: 5,
        titulo: 'Gestionar Categoria',
        descripcion: 'Bitacora',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion/categoria-bitacora/listar-bitacora',
    },
    {
        id: 6,
        titulo: 'Gestionar Tipo Incidente',
        descripcion: 'Tipo Incidente',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion/listar-tipo-incidente',
    },
    {
        id: 7,
        titulo: 'Gestionar Estado Camion',
        descripcion: 'Estado Camion',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion/listar-estado-camion',
    },
    {
        id: 8,
        titulo: 'Gestionar Sub Tipo Usuario Web',
        descripcion: 'Sub Tipo Usuario Web',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion/subtipo-usuario-web/listar-subtipo-usuario-web',
    },
];
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  ESTILO_BOTON_DOWN,
  ESTILO_BOTON_GENERAL_ACEPTAR,
  ESTILO_BOTON_GENERAL_CANCELAR,
  ESTILO_BOTON_LISTAR_ATRAS,
  ESTILO_BOTON_UP,
} from "../../../utils/const-estilos";
import Select from "react-select";
import {
  crearCheklist,
  modificarCheklist,
  obtenerConcepto,
  obtenerListaIconos,
  obtenerListaSubTipoCarroceria,
  obtenerOpcionesRespuestaChecklist,
  obtenerTipoChecklist,
} from "../../../api/checklist";
import "../../../style/style-cargando.css";
import { obtenerListaTipoCarroceria } from "../../../api/checklist";
import { TRUE } from "../../../utils/const-bool";
import {
  INT_COMENTARIO,
  INT_FOTOGRAFIA,
  INT_PREGUNTAS_EXTRA_CHECKLIST,
  INT_SUB_TIPO_CHECKLIST_PRECINTO_CBN,
  INT_SUB_TIPO_CHECKLIST_PRECINTO_PLASTICO,
} from "../../../utils/const-int";
import {
  STRING_CHOICE,
  STRING_FOTOGRAFIA,
  STRING_COMENTARIO,
} from "../../../utils/const-string";
import { EMPRESA_CBN, EMPRESA_PLASTICO_USR } from "../../../utils/const-id-empresa";
import { SUBTIPO_CHECKLIST_SALIDA_CBN } from "../../../utils/const-subtipo-checklist-salida";
import { ArrowUpCircle } from "feather-icons-react/build/IconComponents";
import ArrowDownCircle from "feather-icons-react/build/IconComponents/ArrowDownCircle";

const idEmpresa = parseInt(localStorage.getItem("idEmpresa"));

export default function GestionarChecklist() {
  const location = useLocation();
  const history = useHistory();

  let draggingItem = null;

  const [cargandoPage, setCargandoPage] = useState(true);
  const [tipoCarroceriaSelected, setTipoCarroceriaSelected] = useState(null);
  const [subTipoCarroceriaSelected, setSubTipoCarroceriaSelected] =
    useState(null);
  const [tieneSubTipoCarroceria, setTieneSubTipoCarroceria] = useState(false);
  const [listaTipoCarroceria, setListaTipoCarroceria] = useState([1]);
  const [listaSubTipoCarroceria, setListaSubTipoCarroceria] = useState([]);
  const [nombreChecklist, setNombreChecklist] = useState("");
  const [questions, setQuestions] = useState([]);
  const [listaTipoChecklist, setListaTipoChecklist] = useState([]);
  const [tipoChecklistSelected, setTipoChecklistSelected] = useState(null);
  const [listaOpcionesTipoRespuestaChecklist, setTipoRespuestaChecklist] =
    useState([]);
  const [listaPreguntasExtra, setListaPreguntasExtra] = useState([]);
  const [preguntaExtraSelected, setPreguntaExtraSelected] = useState(null);
  const [idChecklist, setIdChecklist] = useState(0);
  const [titulo, setTitulo] = useState("");
  const [listaIconos, setListaIconos] = useState(null);

  const listaSubTipoChecklistSalida = SUBTIPO_CHECKLIST_SALIDA_CBN;
  const [subTipoChecklistSalidaSelected, setSubTipoChecklistSalidaSelected] = useState(null);
  const [showSelectSubTipoChecklist, setShowSelectSubTipoChecklist] = useState(false);

  useEffect(() => {
    // console.log("entro a Checklist");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      const data = location.state;
      // console.log("data");
      // console.log(data);
      let params = null;
      if (data !== undefined && data !== null) {
        setTitulo(data.titulo);
      }
      if (data.params !== undefined && data.params !== null) {
        params = data.params;
      }
      let opcionesTipoChecklist = await obtenerTipoChecklist(true);
      setListaTipoChecklist(opcionesTipoChecklist);
      let listarIconos = await obtenerListaIconos();
      for (let i = 0; i < listarIconos.length; i++) {
        // const element = listarIconos[i];
        listarIconos[i].label = listarIconos[i].nombre;
        listarIconos[i].value = listarIconos[i].id;
      }
      setListaIconos(listarIconos);
      /**Cargando el combo tipo checklist */
      if (params !== undefined && params !== null) {
        let tipoCheckL = opcionesTipoChecklist.find(
          (e) => e.value === params.idtipochecklist
        );
        if (tipoCheckL !== undefined) {
          setTipoChecklistSelected(tipoCheckL);
        }
      }
      /**FIN Cargando el combo tipo checklist */
      let opcionesTipoCarroceria = await obtenerListaTipoCarroceria();
      if (opcionesTipoCarroceria.length > 0) {
        const opciones = opcionesTipoCarroceria.map((e) => {
          return {
            value: e.id,
            label: e.descripcion,
            tieneSubTipoCarroceria: e.tieneSubTipoCarroceria,
          };
        });
        setListaTipoCarroceria(opciones);
        /**Cargando el combo tipo carroceria */
        if (params !== undefined && params !== null) {
          let tipoCarroC = opciones.find(
            (e) => e.value === params.idtipocarroceria
          );
          if (tipoCarroC !== undefined) {
            setTipoCarroceriaSelected(tipoCarroC);
          }
          /**Cargando subtipocarroceria */
          if (params.idsubtipocarroceria !== 0) {
            setTieneSubTipoCarroceria(true);
            let objEnviar = {};
            objEnviar.idTipoCarroceria = params.idtipocarroceria;
            let subTipoCarroceria = await obtenerListaSubTipoCarroceria(
              objEnviar
            );

            if (subTipoCarroceria.length > 0) {
              const opciones = subTipoCarroceria.map((e) => {
                return {
                  value: e.id,
                  label: e.descripcion,
                  idTipoCarroceria: e.idTipoCarroceria,
                };
              });
              setListaSubTipoCarroceria(opciones);
              let subTipoC = opciones.find(
                (e) => e.value === params.idsubtipocarroceria
              );
              if (subTipoC !== undefined) {
                setSubTipoCarroceriaSelected(subTipoC);
              }
            }
          }
          /** Fin Cargando subtipocarroceria */
        }
        /**Fin Cargando el combo tipo carroceria */
      }
      let opcionesRespuestaChecklist = await obtenerOpcionesRespuestaChecklist(
        true
      );
      setTipoRespuestaChecklist(opcionesRespuestaChecklist);
      let preguntasExtra = await obtenerConcepto(INT_PREGUNTAS_EXTRA_CHECKLIST);
      setListaPreguntasExtra(preguntasExtra);
      if (params !== undefined && params === null) {
        setCargandoPage(false);
      }
      if (params !== undefined && params !== null) {
        cargarDatosModificar(data.params);
      }
    } catch (err) {
      console.log("iniciar err");
      console.log(err);
      setCargandoPage(false);
    }
  };

  const cargarDatosModificar = async (params) => {
    try {
      // console.log("cargarDatosModificar");
      // console.log(params);
      setIdChecklist(params.id);
      setNombreChecklist(params.nombre);
      const preguntas = JSON.parse(params.preguntas);
      // console.log("preguntas");
      // console.log(preguntas);
      setQuestions(preguntas);
      if (idEmpresa === EMPRESA_CBN || idEmpresa === EMPRESA_PLASTICO_USR) {
        // console.log('check si es salida para mostrar precinto dropdown');
        if (params.idSubTipoChecklist !== null && params.idSubTipoChecklist !== "") {
          const subTipo = SUBTIPO_CHECKLIST_SALIDA_CBN.find(e => e.value == params.idSubTipoChecklist);
          // console.log("subTipo");
          // console.log(subTipo);
          setSubTipoChecklistSalidaSelected(subTipo);
          setShowSelectSubTipoChecklist(true);
        }
      }
      setCargandoPage(false);
    } catch (err) {
      console.log("cargarDatosModificar err");
      console.log(err);
      setCargandoPage(false);
    }
  };

  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      {
        pregunta: "",
        opcionesrespuestas: null,
        repite: false,
        tipo: STRING_CHOICE,
      },
    ]);
  };

  const handleAddExtra = () => {
    // console.log('handleAddExtra');
    // console.log('preguntaExtraSelected');
    // console.log(preguntaExtraSelected);
    if (preguntaExtraSelected != null) {
      switch (preguntaExtraSelected.value) {
        case INT_FOTOGRAFIA:
          setQuestions([
            ...questions,
            { pregunta: "", tipo: STRING_FOTOGRAFIA },
          ]);
          break;
        case INT_COMENTARIO:
          setQuestions([
            ...questions,
            { pregunta: "", tipo: STRING_COMENTARIO },
          ]);
          break;
      }
    }
  };

  const handleQuestionChange = (event, index) => {
    // const updatedQuestions = [...questions];
    // updatedQuestions[index] = event.target.value;
    let text = event.target.value;
    // console.log('text');
    // console.log(text)
    // console.log('index');
    // console.log(index)
    const updatedQuestions = [...questions];
    // console.log('questions[index]');
    // console.log(questions[index]);
    // console.log('updatedQuestions');
    // console.log(updatedQuestions)
    updatedQuestions[index].pregunta = text;
    setQuestions(updatedQuestions);
  };

  const handleIconoComboChange = (event, index) => {
    try {
      let updatedQuestions = [...questions];
      updatedQuestions[index].iconos = event;
      setQuestions(updatedQuestions);
    } catch (err) {
      console.log("handleQuestionComboChange err");
      console.log(err);
    }
  };

  const handleQuestionComboChange = (event, index) => {
    try {
      // console.log('handleQuestionComboChange');
      // console.log('index');
      // console.log(index);
      // console.log('opcionesrespuestas');
      // console.log(event);
      let updatedQuestions = [...questions];
      updatedQuestions[index].opcionesrespuestas = event;
      setQuestions(updatedQuestions);
    } catch (err) {
      console.log("handleQuestionComboChange err");
      console.log(err);
    }
  };

  const handleTipoChecklistComboChange = (event) => {
    try {
      // console.log("handleTipoChecklistComboChange");
      // console.log(event);
      setTipoChecklistSelected(event);
      if (event !== null) {
        if ((idEmpresa === EMPRESA_CBN || idEmpresa === EMPRESA_PLASTICO_USR) && (event.value === INT_SUB_TIPO_CHECKLIST_PRECINTO_CBN || event.value == INT_SUB_TIPO_CHECKLIST_PRECINTO_PLASTICO)) {
          setShowSelectSubTipoChecklist(true);
        } else {
          setShowSelectSubTipoChecklist(false);
          setSubTipoChecklistSalidaSelected(null);
        }
      }
      if (event === null) {
        setShowSelectSubTipoChecklist(false);
        setSubTipoChecklistSalidaSelected(null);
      }
    } catch (err) {
      console.log("handleTipoChecklistComboChange err");
      console.log(err);
    }
  };

  const handleSubTipoChecklistSalidaComboChange = (event) => {
    try {
      // console.log("handleSubTipoChecklistSalidaComboChange");
      // console.log(event);
      setSubTipoChecklistSalidaSelected(event);
    } catch (err) {
      console.log("handleSubTipoChecklistSalidaComboChange err");
      console.log(err);
    }
  };

  const handleQuitarPregunta = (index) => {
    try {
      // console.log('index');
      // console.log(index);
      const updatedQuestions = [...questions];
      updatedQuestions.splice(index, 1);
      setQuestions(updatedQuestions);
    } catch (err) {
      console.log("handleQuitarPregunta err");
      console.log(err);
    }
  };

  const handleOptionTipoCarroceria = async (event) => {
    // console.log('handleTipoChecklistComboChange');
    // console.log(event);
    setTipoCarroceriaSelected(event);
    setSubTipoCarroceriaSelected(null);
    if (event !== null && event.tieneSubTipoCarroceria === TRUE) {
      setTieneSubTipoCarroceria(true);
      let objEnviar = {};
      objEnviar.idTipoCarroceria = event.value;
      let subTipoCarroceria = await obtenerListaSubTipoCarroceria(objEnviar);

      if (subTipoCarroceria.length > 0) {
        const opciones = subTipoCarroceria.map((e) => {
          return {
            value: e.id,
            label: e.descripcion,
            idTipoCarroceria: e.idTipoCarroceria,
          };
        });
        setListaSubTipoCarroceria(opciones);
      }
    } else {
      setTieneSubTipoCarroceria(false);
      setListaSubTipoCarroceria([]);
    }
  };

  const handleOptionSubTipoCarroceria = (event) => {
    // console.log('handleOptionSubTipoCarroceria');
    // console.log(event);
    setSubTipoCarroceriaSelected(event);
  };

  const handleExtraPregunda = (event) => {
    console.log("handleExtraPregunda");
    console.log(event);
    setPreguntaExtraSelected(event);
  };

  const handleNombreCheckListChange = (event) => {
    setNombreChecklist(event.target.value);
  };

  const handleCancelClick = () => {
    handleAtrasClick();
  };

  const handleSaveClick = async () => {
    try {
      // console.log("handleSaveClick");
      // console.log(questions);
      let esValido = validarChecklist();
      // console.log("esValido");
      // console.log(esValido);
      if (esValido === false) {
        return;
      }
      let objEnviar = {};
      objEnviar.idTipoCarroceria = tipoCarroceriaSelected.value;
      objEnviar.idSubTipoCarroceria = 0;
      if (subTipoCarroceriaSelected !== null) {
        objEnviar.idSubTipoCarroceria = subTipoCarroceriaSelected.value;
      }
      objEnviar.idTipoChecklist = tipoChecklistSelected.value;
      objEnviar.nombre = nombreChecklist;
      objEnviar.preguntas = questions;
      objEnviar.idSubTipoChecklist = 0;
      if (subTipoChecklistSalidaSelected !== null) {
        objEnviar.idSubTipoChecklist = subTipoChecklistSalidaSelected.value;
      }
      // console.log("objEnviar");
      // console.log(objEnviar);
      // return;
      if (idChecklist <= 0) {
        let guardo = await crearCheklist(objEnviar);
        // console.log('guardo');
        // console.log(guardo);
        if (guardo === true) {
          alert("Se creo checklist correctamente");
          handleAtrasClick();
        }
        if (guardo === false) {
          alert("No se pudo crear checklist");
        }
      }
      if (idChecklist > 0) {
        objEnviar.id = idChecklist;
        let modifico = await modificarCheklist(objEnviar);
        // console.log('guardo');
        // console.log(guardo);
        if (modifico === true) {
          alert("Se modifico checklist correctamente");
          handleAtrasClick();
        }
        if (modifico === false) {
          alert("No se pudo modificar checklist");
        }
      }
    } catch (err) {
      console.log("handleSaveClick err");
      console.log(err);
    }
  };

  const validarChecklist = () => {
    try {
      let error = "";
      if (tipoCarroceriaSelected === null) {
        error = error + "Debe seleccionar tipo carroceria\n";
      }
      if (
        tieneSubTipoCarroceria === true &&
        subTipoCarroceriaSelected === null
      ) {
        error = error + "Debe seleccionar sub tipo carroceria\n";
      }
      if (tipoChecklistSelected === null) {
        error = error + "Debe seleccionar tipo checklist\n";
      }
      if (
        nombreChecklist === undefined ||
        nombreChecklist === null ||
        String(nombreChecklist).length === 0
      ) {
        error = error + "Debe ingresar nombre checklist\n";
      }
      if (
        questions === undefined ||
        questions === null ||
        questions.length === 0
      ) {
        error = error + "Debe ingresar preguntas checklist\n";
      }
      if (questions.length > 0) {
        for (let index = 0; index < questions.length; index++) {
          const element = questions[index];
          // debugger;
          switch (element.tipo) {
            case STRING_FOTOGRAFIA:
              if (String(element.pregunta).length === 0) {
                error =
                  error + "Pregunta nro: " + (index + 1) + " no tiene titulo\n";
              }
              break;
            case STRING_COMENTARIO:
              if (String(element.pregunta).length === 0) {
                error =
                  error + "Pregunta nro: " + (index + 1) + " no tiene titulo\n";
              }
              break;
            case STRING_CHOICE:
              if (String(element.pregunta).length === 0) {
                error =
                  error + "Pregunta nro: " + (index + 1) + " no tiene titulo\n";
              }
              if (
                element.opcionesrespuestas === undefined ||
                element.opcionesrespuestas === null ||
                element.opcionesrespuestas.length === 0
              ) {
                error =
                  error +
                  "Pregunta nro: " +
                  (index + 1) +
                  " no tiene opciones respuesta seleccionadas\n";
              }
              break;
          }
        }
      }
      if (String(error).length > 0) {
        alert(error);
        return false;
      }
      return true;
    } catch (err) {
      console.log("validarChecklist err");
      console.log(err);
      return false;
    }
  };

  const handleAtrasClick = () => {
    history.goBack();
  };

  const handleDragStart = (e, item) => {
    draggingItem = item;
    e.dataTransfer.setData("text/plain", "");
  };

  const handleDragEnd = () => {
    draggingItem = null;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetItem) => {
    // console.log('handleDrop');
    // console.log('targetItem');
    // console.log(targetItem);//la pregunta donde se solto la pregunta que se esta  haciendo drag
    // console.log('draggingItem');//la pregunta que inicio el drag
    // console.log(draggingItem);
    if (!draggingItem) return;
    const currentIndex = questions.indexOf(draggingItem);
    const targetIndex = questions.indexOf(targetItem);
    // console.log('currentIndex');
    // console.log(currentIndex);
    // console.log('targetIndex');
    // console.log(targetIndex);
    if (currentIndex !== -1 && targetIndex !== -1) {
      const updatedQuestions = [...questions];
      updatedQuestions.splice(currentIndex, 1);
      updatedQuestions.splice(targetIndex, 0, draggingItem);
      setQuestions(updatedQuestions);
    }
  };

  const renderPreguntas = () => {
    try {
      // console.log('renderPreguntas');
      // console.log(questions);
      return questions.map((question, index) => {
        // console.log('question');
        // console.log(question);
        const style = {
          boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
          display: 'flex',
          flexDirection: 'row'
        };
        const arrowStyle = {
          width: "10%",
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        };
        return (
          <div
            draggable="true"
            onDragStart={(e) => handleDragStart(e, question)}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, question)}
          >
            {renderTipoPreguntas(style, arrowStyle, question, index)}
          </div>
        )
      });
    } catch (err) {
      console.log("renderPreguntas err");
      console.log(err);
      return <div>{JSON.stringify(err)}</div>;
    }
  };

  const renderTipoPreguntas = (style, arrowStyle, question, index) => {
    switch (question.tipo) {
      case STRING_CHOICE:
        return (
          <div key={index} className="mt-4 p-1 border-b-4"
            style={style}
          >
            {renderUpDownButton(arrowStyle, index)}
            <div
              style={{
                width: '90%'
              }}
            >
              <div
                style={{
                  width: "100%",
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: "29%",
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  <div style={{ paddingTop: 1 }}>
                    <Select
                      options={listaIconos}
                      isSearchable="true"
                      isClearable="true"
                      value={question.iconos}
                      onChange={(event) =>
                        handleIconoComboChange(event, index)
                      }
                      placeholder="Seleccione Icono"
                    />
                  </div>
                  {question.iconos !== null &&
                    question.iconos !== undefined && (
                      <div
                        style={{
                          paddingTop: 13,
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <img
                          src={question.iconos.url}
                          alt={question.iconos.url}
                          width="33"
                          height="33"
                        />
                      </div>
                    )}
                </div>
                <div
                  style={{
                    width: "1%",
                  }}
                ></div>
                <div
                  style={{
                    width: "70%",
                  }}
                >
                  <div className="mb-2">
                    <input
                      type="text"
                      value={question.pregunta}
                      onChange={(event) => handleQuestionChange(event, index)}
                      className="w-full px-3 py-2 border rounded"
                    />
                  </div>
                  <Select
                    options={listaOpcionesTipoRespuestaChecklist}
                    isSearchable="true"
                    isMulti
                    isClearable="true"
                    value={question.opcionesrespuestas}
                    onChange={(event) =>
                      handleQuestionComboChange(event, index)
                    }
                    placeholder="Seleccione Respuesta"
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 2,
                }}
              >
                <label>PREGUNTA NRO:</label>
                <label style={{ fontWeight: "bold" }}>{index + 1}</label>
                <button
                  type="button"
                  className={ESTILO_BOTON_GENERAL_ACEPTAR}
                  onClick={() => handleQuitarPregunta(index)}
                >
                  Quitar
                </button>
              </div>
            </div>
          </div>
        );
      case STRING_FOTOGRAFIA:
        return (
          <div key={index} className="mt-4 p-1 border-b-4"
            style={style}
          >
            {renderUpDownButton(arrowStyle, index)}
            <div
              style={{
                width: '90%'
              }}
            >
              <div>
                <div className="mb-2">
                  <input
                    type="text"
                    value={question.pregunta}
                    onChange={(event) => handleQuestionChange(event, index)}
                    className="w-full px-3 py-2 border rounded"
                  />
                </div>
                <div className="mb-2">
                  <input
                    type="text"
                    value="FOTOGRAFIA"
                    className="w-full px-3 py-2 border rounded"
                    readOnly
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 2,
                }}
              >
                <label>PREGUNTA NRO:</label>
                <label style={{ fontWeight: "bold" }}>{index + 1}</label>
                <button
                  type="button"
                  className={ESTILO_BOTON_GENERAL_ACEPTAR}
                  onClick={() => handleQuitarPregunta(index)}
                >
                  Quitar
                </button>
              </div>
            </div>
          </div>
        );
      case STRING_COMENTARIO:
        return (
          <div key={index} className="mt-4 p-1 border-b-4"
            style={style}
          >
            {renderUpDownButton(arrowStyle, index)}
            <div
              style={{
                width: '90%'
              }}
            >
              <div>
                <div className="mb-2">
                  <input
                    type="text"
                    value={question.pregunta}
                    onChange={(event) => handleQuestionChange(event, index)}
                    className="w-full px-3 py-2 border rounded"
                  />
                </div>
                <div className="mb-2">
                  <input
                    type="text"
                    value="COMENTARIO"
                    className="w-full px-3 py-2 border rounded"
                    readOnly
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 2,
                }}
              >
                <label>PREGUNTA NRO:</label>
                <label style={{ fontWeight: "bold" }}>{index + 1}</label>
                <button
                  type="button"
                  className={ESTILO_BOTON_GENERAL_ACEPTAR}
                  onClick={() => handleQuitarPregunta(index)}
                >
                  Quitar
                </button>
              </div>
            </div>
          </div>
        );
      default:
        return <div key={index}>TIPO NO VALIDO</div>;
    }
  }

  const renderUpDownButton = (arrowStyle, index) => {
    return (
      <div
        style={arrowStyle}
      >
        {index > 0 &&
          <button
            type="button"
            className={ESTILO_BOTON_UP}
            onClick={() => { movePreguntaUpClick(index); }}
            title="MOVER ARRIVA"
          >
            <ArrowUpCircle />
          </button>
        }
        <div style={{ height: 10 }}></div>
        {index < questions.length - 1 &&
          <button
            type="button"
            className={ESTILO_BOTON_DOWN}
            onClick={() => { movePreguntaDownClick(index); }}
            title="MOVER ABAJO"
          >
            <ArrowDownCircle />
          </button>}
      </div>
    );
  }

  const movePreguntaUpClick = (index) => {
    // console.log('movePreguntaUpClick');
    // console.log(index);
    if (index === 0) return; // No mover si es el primer elemento
    setQuestions((prevItems) => {
      const newItems = [...prevItems];
      [newItems[index - 1], newItems[index]] = [newItems[index], newItems[index - 1]];
      return newItems;
    });
  }

  const movePreguntaDownClick = (index) => {
    // console.log('movePreguntaDownClick');
    // console.log(index);
    if (index === questions.length - 1) return; // No mover si es el último elemento
    setQuestions((prevItems) => {
      const newItems = [...prevItems];
      [newItems[index + 1], newItems[index]] = [newItems[index], newItems[index + 1]];
      return newItems;
    });
  }

  const render = () => {
    // console.log('render checklist');
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div style={{ marginTop: 10 }}>
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      return (
        <div className="flex flex-col h-screen">
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
            className="flex-none p-4 border-b-2"
          >
            <div>
              <h1 className="text-2xl font-bold">{titulo}</h1>
            </div>
            <div>
              <button
                className={ESTILO_BOTON_LISTAR_ATRAS}
                onClick={handleAtrasClick}
              >
                ATRAS
              </button>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4 px-4 pt-2">
            <div>
              <label className="block mb-2 font-bold">TIPO CARROCERIA</label>
              <Select
                options={listaTipoCarroceria}
                isSearchable="true"
                isClearable="true"
                value={tipoCarroceriaSelected}
                onChange={(event) => handleOptionTipoCarroceria(event)}
                placeholder="Seleccione Tipo Carroceria"
              />
            </div>
            {tieneSubTipoCarroceria === true && (
              <div>
                <label className="block mb-2 font-bold">
                  SUB TIPO CARROCERIA
                </label>
                <Select
                  options={listaSubTipoCarroceria}
                  isSearchable="true"
                  isClearable="true"
                  value={subTipoCarroceriaSelected}
                  onChange={(event) => handleOptionSubTipoCarroceria(event)}
                  placeholder="Seleccione Sub Tipo Carroceria"
                />
              </div>
            )}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4 px-4 pt-2">
            <div>
              <label className="block mb-2 font-bold">TIPO CHECKLIST</label>
              <Select
                options={listaTipoChecklist}
                isSearchable="true"
                isClearable="true"
                value={tipoChecklistSelected}
                onChange={(event) => handleTipoChecklistComboChange(event)}
                placeholder="Seleccione Tipo Checklist"
              />
            </div>
            <div>
              <label className="block mb-2 font-bold">NOMBRE CHECKLIST </label>
              <input
                type="text"
                value={nombreChecklist}
                onChange={handleNombreCheckListChange}
                className="w-full px-3 py-2 border rounded"
                maxLength={128}
                placeholder="Nombre Tipo Checklist"
              />
            </div>
          </div>

          {showSelectSubTipoChecklist === true &&
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4 px-4 pt-0">
              <div>
                <label className="block mb-2 font-bold">SUB TIPO CHECKLIST SALIDA</label>
                <Select
                  options={listaSubTipoChecklistSalida}
                  isSearchable="true"
                  isClearable="true"
                  value={subTipoChecklistSalidaSelected}
                  onChange={(event) => handleSubTipoChecklistSalidaComboChange(event)}
                  placeholder="Sub Tipo Checklist Salida"
                />
              </div>
            </div>
          }

          <div className="px-4">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h2 className="mr-2 font-bold">PREGUNTAS</h2>
                <button
                  className={ESTILO_BOTON_GENERAL_ACEPTAR}
                  onClick={handleAddQuestion}
                >
                  AGREGAR
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <h2 className="mr-2 font-bold">OTRO</h2>
                  <Select
                    options={listaPreguntasExtra}
                    isSearchable="true"
                    isClearable="true"
                    onChange={(event) => handleExtraPregunda(event)}
                    placeholder="Seleccione Extra"
                  />
                </div>
                <div>
                  <button
                    className={ESTILO_BOTON_GENERAL_ACEPTAR}
                    onClick={handleAddExtra}
                  >
                    AGREGAR
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 p-4 overflow-y-auto">
            <div className="mb-4">
              {renderPreguntas()}
            </div>
          </div>
          <div className="flex justify-end px-4 mb-5">
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                width: "100%",
              }}
            >
              <button
                type="button"
                className={ESTILO_BOTON_GENERAL_CANCELAR}
                onClick={handleCancelClick}
              >
                Cancelar
              </button>
              <button
                type="button"
                className={ESTILO_BOTON_GENERAL_ACEPTAR}
                onClick={handleSaveClick}
                title="Crear nuevo Checklist?"
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      );
    } catch (err) {
      console.log("ModificarCorreos render err");
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  };

  return render();
}

import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Reportes from "./index";
import TiempoDescarga from "./tiempo-descarga";
import EstadoAlmacenesDescarga from "./estado-almacenes-descarga";
import TiempoCarga from "./tiempo-carga";
import ReporteDescarga from "./reporte-descarga";
import EntradasGeocercas from "./entradas-geocercas";
import EntradasGeocercasTiempo from "./entradas-geocercas-tiempo";
import EntradasGeocercasTiemposFechaHoraSeleccionada from "./entradas-geocercas-tiempo-actual";
import CumplimientoDescarga from "./cumplimiento-descarga";
import CumplimientoDeViaje from "./cumplimiento-de-viaje";

const RouterReportes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/cumplimiento-de-viaje`}>
        <CumplimientoDeViaje />
      </Route>
      <Route exact path={`${path}/tiempo-descarga`}>
        <TiempoDescarga />
      </Route>
      <Route exact path={`${path}/tiempo-carga`}>
        <TiempoCarga />
      </Route>
      <Route exact path={`${path}/reporte-descarga`}>
        <ReporteDescarga />
      </Route>
      <Route exact path={`${path}/cumplimiento-descarga`}>
        <CumplimientoDescarga />
      </Route>
      <Route exact path={`${path}/estado-almacenes-descarga`}>
        <EstadoAlmacenesDescarga />
      </Route>
      <Route exact path={`${path}/entradas-geocercas`}>
        <EntradasGeocercas />
      </Route>
      <Route exact path={`${path}/entradas-geocercas-tiempo`}>
        <EntradasGeocercasTiempo />
      </Route>
      <Route exact path={`${path}/entradas-geocercas-tiempo-fecha-seleccionada`}>
        <EntradasGeocercasTiemposFechaHoraSeleccionada />
      </Route>
      <Route exact path={`${path}`}>
        <Reportes />
      </Route>
    </Switch>
  );
};

export default RouterReportes;

import moment from "moment/moment";
import { SUCCESS } from "../page/const/http-const";
import { getHeaders } from "./utils";

export async function obtenerGrupoGeocercasCiudad(obj) {
    try {
        let info = null;
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/obtener-grupo-geocercas-ciudad/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            if (body[0] !== null && body[0] !== undefined) {
                info = body[0];
            }
        }
        return info;
    } catch (err) {
        console.log("obtenerGrupoGeocercasCiudad err");
        console.log(err);
        return null;
    }
}

export async function guardarGrupoGeocercasCiudad(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/guardar-grupo-geocercas-ciudad/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("guardarGrupoGeocercasCiudad err");
        console.log(err);
        return false;
    }
}

export async function modificarGrupoGeocercasCiudad(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/modificar-grupo-geocercas-ciudad/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarGrupoGeocercasCiudad err");
        console.log(err);
        return false;
    }
}

export async function obtenerGeocercasDeUnaCiudad(obj) {
    try {
        let info = null;
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/obtener-geocercas-de-una-ciudad/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            if (body[0] !== undefined && body[0] !== null) {
                info = body[0];
            }
        }
        return info;
    } catch (err) {
        console.log("obtenerGeocercasDeUnaCiudad err");
        console.log(err);
        return null;
    }
}

export async function guardarGeocercasDeUnaCiudad(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/guardar-geocercas-de-una-ciudad/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("guardarGeocercasDeUnaCiudad err");
        console.log(err);
        return false;
    }
}

export async function modificarGeocercasDeUnaCiudad(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/modificar-geocercas-de-una-ciudad/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarGeocercasDeUnaCiudad err");
        console.log(err);
        return false;
    }
}

export async function crearEstadoCarroceria(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/crear-estado-carroceria/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("crearEstadoCarroceria err");
        console.log(err);
        return false;
    }
}

export async function modificarEstadoCarroceria(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/modificar-estado-carroceria/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarEstadoCarroceria err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarEstadoCarroceria(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/deshabilitar-estado-carroceria/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarEstadoCarroceria err");
        console.log(err);
        return false;
    }
}

export async function obtenerListaEstadosCarroceria(combo = true) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/obtener-listar-estado-carroceria/",
            {
                headers: getHeaders(),
                method: "POST",
                // body: JSON.stringify({data: 'hello'}),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            if (combo === true) {
                for (let i = 0; i < body.length; i++) {
                    const element = body[i];
                    let obj = {};
                    obj.value = element.id;
                    obj.label = element.descripcion;
                    rows.push(obj);
                }
            }
            if (combo === false) {
                rows = body;
                for (let i = 0; i < rows.length; i++) {
                    const element = rows[i];
                    rows[i].fechaproceso = moment(element.fechaProceso).format(
                        "DD/MM/YYYY HH:mm"
                    );
                }
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaEstadosCarroceria err");
        console.log(err);
        return [];
    }
}

export async function crearEstadoSupervisor(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/crear-estado-supervisor/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("crearEstadoSupervisor err");
        console.log(err);
        return false;
    }
}

export async function modificarEstadoSupervisor(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/modificar-estado-supervisor/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarEstadoSupervisor err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarEstadoSupervisor(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/deshabilitar-estado-supervisor/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarEstadoSupervisor err");
        console.log(err);
        return false;
    }
}

export async function obtenerListaEstadosSupervisor(combo = true) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/obtener-listar-estado-supervisor/",
            {
                headers: getHeaders(),
                method: "POST",
                // body: JSON.stringify({data: 'hello'}),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            if (combo === true) {
                for (let i = 0; i < body.length; i++) {
                    const element = body[i];
                    let obj = {};
                    obj.value = element.id;
                    obj.label = element.descripcion;
                    rows.push(obj);
                }
            }
            if (combo === false) {
                rows = body;
                for (let i = 0; i < rows.length; i++) {
                    const element = rows[i];
                    rows[i].fechaproceso = moment(element.fechaProceso).format(
                        "DD/MM/YYYY HH:mm"
                    );
                }
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaEstadosSupervisor err");
        console.log(err);
        return [];
    }
}

export async function crearEstadoPlanificacion(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/crear-estado-planificacion/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("crearEstadoPlanificacion err");
        console.log(err);
        return false;
    }
}

export async function modificarEstadoPlanificacion(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/modificar-estado-planificacion/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarEstadoPlanificacion err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarEstadoPlanificacion(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/deshabilitar-estado-planificacion/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarEstadoPlanificacion err");
        console.log(err);
        return false;
    }
}

export async function obtenerListaEstadosPlanificacion(combo = true) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/obtener-listar-estado-planificacion/",
            {
                headers: getHeaders(),
                method: "POST",
                // body: JSON.stringify({data: 'hello'}),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            if (combo === true) {
                for (let i = 0; i < body.length; i++) {
                    const element = body[i];
                    let obj = {};
                    obj.value = element.id;
                    obj.label = element.descripcion;
                    rows.push(obj);
                }
            }
            if (combo === false) {
                rows = body;
                for (let i = 0; i < rows.length; i++) {
                    const element = rows[i];
                    rows[i].fechaproceso = moment(element.fechaProceso).format(
                        "DD/MM/YYYY HH:mm"
                    );
                }
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaEstadosPlanificacion err");
        console.log(err);
        return [];
    }
}

export async function obtenerListaDisponibilidad(obj) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/obtener-listar-disponibilidad/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            rows = body;
            for (let i = 0; i < rows.length; i++) {
                const element = rows[i];
                rows[i].fechaproceso = moment(element.fechaProceso).format(
                    "DD/MM/YYYY HH:mm"
                );
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaDisponibilidad err");
        console.log(err);
        return [];
    }
}

export async function deshabilitarDisponibilidad(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/deshabilitar-disponibilidad/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarDisponibilidad err");
        console.log(err);
        return false;
    }
}

export async function modificarDisponibilidad(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/modificar-disponibilidad/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarDisponibilidad err");
        console.log(err);
        return false;
    }
}

export async function crearEstadoDisponibilidadChofer(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/crear-estado-disponibilidad-chofer/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("crearEstadoDisponibilidadChofer err");
        console.log(err);
        return false;
    }
}

export async function modificarEstadoDisponibilidadChofer(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/modificar-estado-disponibilidad-chofer/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarEstadoDisponibilidadChofer err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarEstadoDisponibilidadChofer(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/deshabilitar-estado-disponibilidad-chofer/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarEstadoDisponibilidadChofer err");
        console.log(err);
        return false;
    }
}

export async function obtenerListaEstadoDisponibilidadChofer(combo = true) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/disponibilidad/obtener-listar-estado-disponibilidad-chofer/",
            {
                headers: getHeaders(),
                method: "POST",
                // body: JSON.stringify({data: 'hello'}),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            if (combo === true) {
                for (let i = 0; i < body.length; i++) {
                    const element = body[i];
                    let obj = {};
                    obj.value = element.id;
                    obj.label = element.descripcion;
                    rows.push(obj);
                }
            }
            if (combo === false) {
                rows = body;
                for (let i = 0; i < rows.length; i++) {
                    const element = rows[i];
                    rows[i].fechaproceso = moment(element.fechaProceso).format(
                        "DD/MM/YYYY HH:mm"
                    );
                }
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaEstadosCarroceria err");
        console.log(err);
        return [];
    }
}

export async function obtenerUbicacionLiteralDePlacas(obj) {
    try {
        let result = [];
        const data = await fetch(
            "https://gestiondeflota.boltrack.net/reportes/RP501BodyV10.rep",
            {
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        if (data.error === true) {
            alert(data.mensaje);
            return result;
        }
        result = data.data;
        return result;
    } catch (err) {
        console.log("obtenerUbicacionLiteralDePlacas err");
        console.log(err);
        return [];
    }
}
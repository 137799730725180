import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import LayoutConfiguracion from "../../components/layout-configuracion";
import ModulosDisponibilidad from "./index";
import SeleccionarGrupoGeocercasCiudad from "./seleccionar-grupo-geocerca-ciudad";
import SeleccionarGeocercaDeUnaCiudad from "./seleccionar-geocerca-de-una-ciudad";
import ListarEstadoCarroceria from "./listar-estado-carroceria";
import EstadoCarroceria from "./estado-carroceria";
import ListarEstadoSupervisor from "./listar-estado-supervisor";
import EstadoSupervisor from "./estado-supervisor";
import ListarEstadoPlanificacion from "./listar-estado-planificacion";
import EstadoPlanificacion from "./estado-planificacion";
import GestionarDisponibilidad from "./gestionar-disponibilidad";
import Disponibilidad from "./disponibilidad";
import EstadoDisponibilidadChofer from "./estado-disponibilidad-chofer";
import ListarEstadoDisponibilidadChofer from "./listar-estado-disponibilidad-chofer";

const RouterDisponibilidad = () => {
  const { path } = useRouteMatch();
  return (
    <LayoutConfiguracion>
      <Switch>
        <Route exact path={`${path}`}>
          <ModulosDisponibilidad />
        </Route>

        <Route exact path={`${path}/seleccionar-grupo-geocerca-ciudad`}>
          <SeleccionarGrupoGeocercasCiudad />
        </Route>

        <Route exact path={`${path}/seleccionar-geocerca-de-una-ciudad`}>
          <SeleccionarGeocercaDeUnaCiudad />
        </Route>

        <Route exact path={`${path}/listar-estado-disponibilidad-chofer`}>
          <ListarEstadoDisponibilidadChofer />
        </Route>
        <Route exact path={`${path}/estado-disponibilidad-chofer`}>
          <EstadoDisponibilidadChofer />
        </Route>

        <Route exact path={`${path}/listar-estado-carroceria`}>
          <ListarEstadoCarroceria />
        </Route>
        <Route exact path={`${path}/estado-carroceria`}>
          <EstadoCarroceria />
        </Route>

        <Route exact path={`${path}/listar-estado-supervisor`}>
          <ListarEstadoSupervisor />
        </Route>
        <Route exact path={`${path}/estado-supervisor`}>
          <EstadoSupervisor />
        </Route>

        <Route exact path={`${path}/listar-estado-planificacion`}>
          <ListarEstadoPlanificacion />
        </Route>
        <Route exact path={`${path}/estado-planificacion`}>
          <EstadoPlanificacion />
        </Route>
        <Route exact path={`${path}/gestionar-disponibilidad`}>
          <GestionarDisponibilidad />
        </Route>

        <Route exact path={`${path}/disponibilidad`}>
          <Disponibilidad />
        </Route>
      </Switch>
    </LayoutConfiguracion>
  );
};

export default RouterDisponibilidad;

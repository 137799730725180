import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import GeorutaMain from ".";
import Itinerario from "./itinerario";

const RouterGeoruta = () => {
  const { path } = useRouteMatch();
  return (
    // <LayoutConfiguracion>
    <Switch>

      <Route exact path={`${path}`}>
        <GeorutaMain />
      </Route>

      <Route exact path={`${path}/itinerario`}>
        <Itinerario />
      </Route>

    </Switch>
    // </LayoutConfiguracion>
  );
};

export default RouterGeoruta;

import { useState, useEffect } from "react";
import { LISTA_REPORTES_POR_MODULO } from "./modulos";
import "../../style/style-cargando.css";
import {
  INT_ID_MODULO_REPORTES,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../utils/const-int";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
import { TRUE } from "../../utils/const-bool";
import ModuloLista from "../../components/modulo-lista";
import shortid from "shortid";

const idTipoUsuario = parseInt(localStorage.getItem("tipo"));

export default function Reportes() {
  const [permisos, setPermisos] = useState(null);
  const [cargandoPage, setCargandoPage] = useState(false);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);

  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      let objPermiso = {};
      objPermiso.idMenuPrimerNivel = INT_ID_MODULO_REPORTES;
      objPermiso.idMenuSegundoNivel = 0;
      objPermiso.idMenuTercerNivel = 0;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(objPermiso);
      let permiso = obtenerPermisos[0];
      setPermisos(permiso);
      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        setTieneAccesoAlModulo(true);
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.accesoAlModulo === TRUE) {
          setTieneAccesoAlModulo(true);
        }
      }
      setCargandoPage(false);
    } catch (err) {
      console.log("iniciar err");
      console.log(err);
      setCargandoPage(false);
    }
  };

  const render = () => {
    try {
      if (cargandoPage === true) {
        return (
          <div className="flex flex-col items-center mt-8">
            <div className="loader"></div>
            <div style={{ marginTop: 10 }}>
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div className="flex flex-col items-center mt-30">
            <div style={{ marginTop: 10 }}>
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div>
          <div className="flex flex-wrap">
            {LISTA_REPORTES_POR_MODULO.map((data) => (
              <ModuloLista data={data} key={shortid.generate()} />
            ))}
          </div>
        </div>
      );
    } catch (err) {
      console.log("Reportes render");
      console.log(err);
      return (
        <div className=" w-1/2 m-auto px-[2rem] ">
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  };

  return render();
}

const ModalEditHeaderName = ({ isOpen, objData, onAccept, onCancel, gridHeaders, indexHeaderSelected, setGridHeaders }) => {

    const handleInputChange = (event) => {
        // console.log('handleInputChange');
        // console.log(gridHeaders);
        // console.log(indexHeaderSelected);
        // console.log(objData);
        const { value } = event.target;
        // console.log('name=>' + name);
        // console.log('value=>' + value);
        // Crear una copia del array gridHeaders
        const updatedGridHeaders = [...gridHeaders];

        // Actualizar el título en la copia del array
        updatedGridHeaders[indexHeaderSelected].title = value;

        // Establecer el nuevo estado con la copia actualizada
        setGridHeaders(updatedGridHeaders);
        // console.log('**************');
    }

    return (
        <>
            {isOpen && (
                <div className="fixed z-50 top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                    {/* background */}
                    <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
                    {/* end background */}
                    <div className="bg-white w-11/12 h-3/6 md:max-w-3xl lg:max-w-3xl mx-auto rounded shadow-lg z-50 overflow-y-auto flex flex-col">
                        <div className="pt-4 text-left px-6">
                            <div className="mb-4">
                                <p className="text-xl">{'EDITAR HEADER '}</p>
                                <div className="flex mt-4">
                                    <div className="w-2/2 pr-2">
                                        <label className="block mb-2 font-bold">HEADER NAME</label>
                                        <input
                                            type="text"
                                            id={objData.title}
                                            name={objData.title}
                                            value={objData.title}
                                            onChange={handleInputChange}
                                            placeholder={objData.title}
                                            className="p-2 border border-gray-300 rounded"
                                            maxLength={50}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between pt-20 pb-4 pr-4">
                            <button
                                className="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 cursor-pointer"
                                onClick={onCancel}
                            >
                                Cancel
                            </button>
                            <button
                                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 cursor-pointer"
                                onClick={onAccept}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ModalEditHeaderName;
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ESTILO_BOTON_GENERAL_ACEPTAR, ESTILO_BOTON_GENERAL_CANCELAR, ESTILO_BOTON_LISTAR_ATRAS } from "../../utils/const-estilos";
import { crearUsuarioWeb, modificarUsuarioWeb } from "../../api/usuario/usuario";
import "../../style/style-cargando.css";
import Select from "react-select";
import { obtenerListaSubTipoUsuarioWeb } from "../../api/subtipo-usuario-web";
import { EMPRESA_CBN, EMPRESA_PLASTICO_USR } from "../../utils/const-id-empresa";
import { INT_SUB_TIPO_USUARIO_AUXILIAR_CBN, INT_SUB_TIPO_USUARIO_AUXILIAR_PLASTICO } from "../../utils/const-int";
import { obtenerGrupoGeocercasCiudad } from "../../api/disponibilidad";
import { obtenerComboGeocerca, obtenerInfoGeocercaMedianteIdGrupo } from "../../api/gestion-dts/gestion";

const idEmpresa = parseInt(localStorage.getItem("idEmpresa"));

export default function UsuarioWeb() {
  const location = useLocation();
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(false);
  const [usuario, setUsuario] = useState(null);
  const [titulo, setTitulo] = useState("CREAR USUARIO WEB");
  const [showPasswordInput, setShowPasswordInput] = useState(false);

  const [listaSubTipoUsuarioWeb, setListaSubTipoUsuarioWeb] = useState([]);
  const [subTipoUsuarioWebSelected, setSubTipoUsuarioWebSelected] = useState(null);

  const [showComboSeleccionarGeocerca, setShowComboSeleccionarGeocerca] = useState(false);

  const [listaGeocercas, setListaGeocercas] = useState([]);
  const [geocercaSelected, setGeocercaSelected] = useState(null);

  useEffect(() => {
    // console.log("entro a UsuarioWeb");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      const data = location.state;
      // console.log("data");
      // console.log(data);
      if (data !== undefined && data !== null) {
        setUsuario(data.params);
        setTitulo(data.titulo);
        const listaSubTipo = await obtenerListaSubTipoUsuarioWeb();
        // console.log("listaSubTipo");
        // console.log(listaSubTipo);
        for (let i = 0; i < listaSubTipo.length; i++) {
          listaSubTipo[i].value = listaSubTipo[i].id;
          listaSubTipo[i].label = listaSubTipo[i].descripcion;
        }
        setListaSubTipoUsuarioWeb(listaSubTipo);
        const listaGeo = await obtenerComboGeocerca({});
        for (let i = 0; i < listaGeo.length; i++) {
          listaGeo[i].label = listaGeo[i].codigo + " - " + listaGeo[i].label;
        }
        // console.log("listaGeo");
        // console.log(listaGeo);
        setListaGeocercas(listaGeo);
        if (data.params.idSubTipoUsuarioWeb !== null && String(data.params.idSubTipoUsuarioWeb).length !== 0) {
          const subTipo = listaSubTipo.find(e => e.id === data.params.idSubTipoUsuarioWeb);
          if (subTipo !== undefined) {
            setSubTipoUsuarioWebSelected(subTipo);
          }
          if ((idEmpresa === EMPRESA_CBN || idEmpresa === EMPRESA_PLASTICO_USR) &&
            (data.params.idSubTipoUsuarioWeb === INT_SUB_TIPO_USUARIO_AUXILIAR_CBN || data.params.idSubTipoUsuarioWeb === INT_SUB_TIPO_USUARIO_AUXILIAR_PLASTICO)) {
            setShowComboSeleccionarGeocerca(true);
          }
        }
        if (data.params.id === 0) {
          setShowPasswordInput(true);
        }
        if (data.params.id > 0) {
          setShowPasswordInput(false);
        }

        if (data.params.idGeocerca !== null && String(data.params.idGeocerca).length !== 0) {
          const geocerca = listaGeo.find(e => e.value === data.params.idGeocerca);
          if (geocerca !== undefined) {
            setGeocercaSelected(geocerca);
          }
        }
      }
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const handleInputChange = (event) => {
    // console.log('handleInputChange');
    const { name, value } = event.target;
    // console.log(name);
    // console.log(value);
    setUsuario({ ...usuario, [name]: value });
  };

  const handleCancelClick = () => {
    history.goBack();
  };

  const handleAceptarClick = async () => {
    console.log('handleAceptarClick');
    // lógica para guardar los cambios
    console.log('usuario');
    console.log(usuario);
    const valido = await validarUsuario();
    console.log('valido');
    console.log(valido);
    if (valido === false) {
      return;
    }
    let objEnviar = {};
    objEnviar.nombre = usuario.nombre;
    objEnviar.apellido = usuario.apellido;
    objEnviar.usuario = usuario.usuario;
    objEnviar.contrasena = usuario.contrasena;
    objEnviar.nota = usuario.nota;
    objEnviar.id = usuario.id;
    objEnviar.idSubTipoUsuarioWeb = null;
    objEnviar.idGeocerca = null;
    if (subTipoUsuarioWebSelected !== null) {
      objEnviar.idSubTipoUsuarioWeb = subTipoUsuarioWebSelected.value;
    }
    if (geocercaSelected !== null) {
      objEnviar.idGeocerca = geocercaSelected.value;
    }
    if (usuario.id <= 0) {
      // console.log('crear correo');
      const creado = await crearUsuarioWeb(objEnviar);
      // console.log('creado');
      // console.log(creado);
      if (creado.creado === true) {
        // const id = creado.id;
        // console.log('id');
        // console.log(id);
        alert('Se creo correctamente');
        history.goBack();
      }
      if (creado === false) {
        alert('No se pudo crear, intente nuevamente');
      }
    }
    if (usuario.id > 0) {
      // console.log('actualizar correo');
      let modificado = await modificarUsuarioWeb(objEnviar);
      // console.log('modificado');
      // console.log(modificado);
      if (modificado === true) {
        alert('Se modifico correctamente');
        history.goBack();
      }
      if (modificado === false) {
        alert('No se pudo modificar');
      }
    }
  };

  const validarUsuario = async () => {
    try {
      let error = '';
      if (usuario === undefined || usuario === null || usuario.nombre === undefined === usuario.nombre === null || String(usuario.nombre).length === 0) {
        error = error + 'Necesita ingresar nombre\n';
      }
      if (usuario.apellido === undefined === usuario.apellido === null || String(usuario.apellido).length === 0) {
        error = error + 'Necesita ingresar apellido\n';
      }
      if (usuario.usuario === undefined === usuario.usuario === null || String(usuario.usuario).length === 0) {
        error = error + 'Necesita ingresar usuario\n';
      }
      if (usuario.contrasena === undefined === usuario.contrasena === null || String(usuario.contrasena).length === 0) {
        error = error + 'Necesita ingresar contraseña\n';
      }
      if (usuario.nota === undefined || usuario.nota === null) {
        usuario.nota = '';
      }
      if (String(error).length === 0) {
        return true;
      }
      if (String(error).length > 0) {
        alert(error);
      }
      return false;
    } catch (err) {
      console.log('validarUsuario err');
      console.log(err);
      return false;
    }
  }

  const handleAtrasClick = () => {
    history.goBack();
  }

  const handleSubTipoUsuarioSelected = (event) => {
    try {
      setSubTipoUsuarioWebSelected(event);
      if (event !== null) {
        if ((idEmpresa === EMPRESA_CBN || idEmpresa === EMPRESA_PLASTICO_USR) &&
          (event.value === INT_SUB_TIPO_USUARIO_AUXILIAR_CBN || event.value === INT_SUB_TIPO_USUARIO_AUXILIAR_PLASTICO)) {
          setShowComboSeleccionarGeocerca(true);
        } else {
          setShowComboSeleccionarGeocerca(false);
        }
      } else {
        setShowComboSeleccionarGeocerca(false);
        setGeocercaSelected(null);
      }
    } catch (err) {
      console.log('handleSubTipoUsuarioSelected err');
      console.log(err);
    }
  }

  const handleGeocercaSelected = (event) => {
    try {
      setGeocercaSelected(event);
    } catch (err) {
      console.log('handleGeocercaSelected err');
      console.log(err);
    }
  }

  const render = () => {
    // console.log('render correo');
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (cargandoPage === false && usuario === null) {
        return (
          <div>
            <label> Sin data</label>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <label
              style={{
                paddingLeft: 4,
                fontWeight: 'bold',
              }}
            >
              {titulo}
            </label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <hr />
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="unidad" className="mb-2 font-bold">
                  SUB TIPO USUARIO
                </label>
                <Select options={listaSubTipoUsuarioWeb} isSearchable='true'
                  isClearable='true'
                  value={subTipoUsuarioWebSelected}
                  onChange={(event) => handleSubTipoUsuarioSelected(event)}
                  placeholder='SELECCIONE SUB TIPO USUARIO'
                />
              </div>
            </div>

            {showComboSeleccionarGeocerca === true &&
              <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
                <div
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <label htmlFor="unidad" className="mb-2 font-bold">
                    SELECCIONAR GEOCERCA
                  </label>
                  <Select options={listaGeocercas} isSearchable='true'
                    isClearable='true'
                    value={geocercaSelected}
                    onChange={(event) => handleGeocercaSelected(event)}
                    placeholder='SELECCIONAR GEOCERCA'
                  />
                </div>
              </div>
            }

          </div>
          <br />
          <hr />
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="nombre" className="mb-2 font-bold">
                  NOMBRE
                </label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  value={usuario.nombre}
                  onChange={handleInputChange}
                  placeholder="NOMBRE"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={100}
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="apellido" className="mb-2 font-bold">
                  APELLIDO
                </label>
                <input
                  type="text"
                  id="apellido"
                  name="apellido"
                  value={usuario.apellido}
                  onChange={handleInputChange}
                  placeholder="APELLIDO"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={100}
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="usuario" className="mb-2 font-bold">
                  USUARIO
                </label>
                <input
                  type="text"
                  id="usuario"
                  name="usuario"
                  value={usuario.usuario}
                  onChange={handleInputChange}
                  placeholder="USUARIO"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={45}
                />
              </div>
            </div>

            {showPasswordInput === true &&
              <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
                <div
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <label htmlFor="contrasena" className="mb-2 font-bold">
                    CONTRASEÑA
                  </label>
                  <input
                    type="text"
                    id="contrasena"
                    name="contrasena"
                    value={usuario.contrasena}
                    onChange={handleInputChange}
                    placeholder="CONTRASEÑA"
                    className="p-2 border border-gray-300 rounded"
                    maxLength={45}
                  />
                </div>
              </div>
            }

          </div>
          <br />
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="nota" className="mb-2 font-bold">
                  NOTA
                </label>
                <textarea
                  type="text"
                  id="nota"
                  name="nota"
                  value={usuario.nota}
                  onChange={handleInputChange}
                  rows={3}
                  placeholder="NOTA"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={512}
                />
              </div>
            </div>
          </div>
          <br />
          <div className="flex justify-between p-4">
            <button className={ESTILO_BOTON_GENERAL_CANCELAR}
              onClick={handleCancelClick}
            >
              CANCELAR
            </button>
            <button className={ESTILO_BOTON_GENERAL_ACEPTAR}
              onClick={handleAceptarClick}
            >
              ACEPTAR
            </button>
          </div>
        </div>
      );
    } catch (err) {
      console.log('UsuarioWeb render err');
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  }

  return render();
}

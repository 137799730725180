import { useState, useEffect } from "react";
import { MODULOS } from "./modulos";
import { obtenerInfoGrupoSubGrupo } from "../../api/gestion-dts/gestion";
import "../../style/style-cargando.css";
import { ESTILO_BOTON_GENERAL_ACEPTAR } from "../../utils/const-estilos";
import {
  INT_TIPO_QUERY_INSERT,
  INT_TIPO_QUERY_UPDATE,
  INT_ID_MODULO_CHECKLIST,
  INT_ID_MODULO_EVENTOS,
  INT_ID_MODULO_CONFIGURACION,
  INT_ID_MODULO_DASHBOARD,
  INT_ID_MODULO_SEGUIMIENTO,
  INT_ID_MODULO_DESCARGAS,
  INT_ID_MODULO_CARGAS,
  INT_ID_MODULO_USUARIOS,
  INT_ID_MODULO_REPORTES,
  INT_ID_MODULO_GEORUTA,
  INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST,
  INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL,
  INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD,
  INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA,
  INT_ID_SEGUNDO_NIVEL_MIS_CHECKLIST,
  INT_ID_SEGUNDO_NIVEL_RESPUESTA_INGRESO_CHECKLIST,
  INT_ID_SEGUNDO_NIVEL_RESPUESTA_SALIDA_CHECKLIST,
  INT_ID_SEGUNDO_NIVEL_EVENTOS_DISPONIBILIDAD,
  INT_ID_SEGUNDO_NIVEL_EVENTOS_INCIDENTE,
  INT_ID_SEGUNDO_NIVEL_EVENTOS_BITACORA,
  INT_ID_SEGUNDO_NIVEL_USUARIOS,
  INT_ID_TERCER_NIVEL_CHECKLIST_TIPO_CARROCERIA,
  INT_ID_TERCER_NIVEL_CHECKLIST_CORREOS,
  INT_ID_TERCER_NIVEL_CHECKLIST_OPCIONES_RESPUESTA,
  INT_ID_TERCER_NIVEL_CHECKLIST_MOVIMIENTO,
  INT_ID_TERCER_NIVEL_CHECKLIST_TIPO_CHECKLIST,
  INT_ID_TERCER_NIVEL_CHECKLIST_GESTIONAR_ICONOS,
  INT_ID_TERCER_NIVEL_CHECKLIST_GEOCERCA,
  INT_ID_TERCER_NIVEL_CHECKLIST_ASIGNAR_CORREO,
  INT_ID_TERCER_NIVEL_CHECKLIST_CATEGORIA,
  INT_ID_SEGUNDO_NIVEL_REPORTE_CHECKLIST,
  INT_ID_TERCER_NIVEL_USUARIO_MOVIL,
  INT_ID_TERCER_NIVEL_USUARIO_WEB,
  INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_ASIGNAR_PLANTA_A_USUARIO,
  INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_CARGA,
  INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_DESCARGA,
  INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GRUPO_CIUDADES,
  INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GEOCERCA_DE_UNA_CIUDAD,
  INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_CARROCERIA,
  INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_SUPERVISOR,
  INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_PLANIFICACION,
  INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_DISPONIBILIDAD_CONDUCTOR,
  INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_SELECCIONAR_GRUPO_GEORUTA,
  INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_SELECCIONAR_PUNTOS_CONTROL,
  INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_TIPO_DETENCION_GEORUTA,
} from "../../utils/const-int";
import shortid from "shortid";
import { obtenerListaUsuariosWeb } from "../../api/usuario/usuario";
import Select from "react-select";
import {
  modificarAsignacionGrupoSubgrupoUsuarioWeb,
  modificarPermisosUsuarioWeb,
  obtenerAsignacionGrupoSubgrupoUsuarioWeb,
  obtenerPermisosUsuarioWeb,
} from "../../api/permisos/permisos";
import {
  INT_ID_ACCIONES_NUEVO,
  INT_ID_ACCIONES_MODIFICAR,
  INT_ID_ACCIONES_ELIMINAR,
  INT_ID_ACCIONES_LISTAR,
  INT_ID_ACCIONES_VER,
  INT_ID_ACCIONES_DESHABILITAR,
  INT_ID_ACCIONES_EXPORTAR,
  INT_ID_ACCIONES_LISTAR_SUB_TIPO_CARROCERIA,
  INT_ID_ACCIONES_CREAR_SUB_TIPO_CARROCERIA,
  INT_ID_ACCIONES_MODIFICAR_SUB_TIPO_CARROCERIA,
  INT_ID_ACCIONES_DESHABILITAR_SUB_TIPO_CARROCERIA,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
  INT_ID_ACCIONES_ITINERARIO,
  INT_ID_ACCIONES_IMPORTAR,
  INT_ID_ACCIONES_ENVIAR_SMS,
  INT_ID_ACCIONES_CARGAR,
  INT_ID_ACCIONES_GUARDAR,
  INT_ID_ACCIONES_ENVIAR_SMS_MASIVO,
} from "../../utils/const-int";
// import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
// import { TRUE } from "../../utils/const-bool";

const idTipoUsuario = parseInt(localStorage.getItem("tipo"));

export default function AdministracionPermisos() {
  const [cargandoPage, setCargandoPage] = useState(true);
  const [listaGrupoSubgrupo, setListaGrupoSubgrupo] = useState([]);
  const [listaGrupoSubgrupoPivote, setListaGrupoSubgrupoPivote] = useState([]);
  const [listaModulos, setListaModulos] = useState(MODULOS);
  const [listaUsuariosWeb, setListaUsuariosWeb] = useState([]);
  const [usuarioWebSelected, setUsuarioWebSelected] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);
  const [checkGrupoSubGrupo, setCheckGrupoSubgrupo] = useState(false);

  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        const infoGrupoSubgrupo = await obtenerInfoGrupoSubGrupo();
        setListaGrupoSubgrupo(infoGrupoSubgrupo);
        setListaGrupoSubgrupoPivote(infoGrupoSubgrupo);
        let lista = await obtenerListaUsuariosWeb();
        let arr = [];
        for (let i = 0; i < lista.length; i++) {
          const element = lista[i];
          let obj = {};
          obj.value = element.id;
          obj.label = element.usuario;
          arr.push(obj);
        }
        setListaUsuariosWeb(arr);
        setTieneAccesoAlModulo(true);
      }
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const checkPermisoGrupoSubgrupo = (lista) => {
    try {
      // console.log('checkPermisoGrupoSubgrupo');
      // console.log(lista);
      if (lista.length === 0) {
        return;
      }
      let check = false;
      // debugger;
      for (let index = 0; index < lista.length; index++) {
        const element = lista[index];
        // console.log('element.checked === true ->' + element.checked === true);
        if (element.checked === true) {
          check = true;
          // break;
        }
        for (let i = 0; i < element.listaSubGrupo.length; i++) {
          // console.log('element.listaSubGrupo[i].checked === true ->' + element.listaSubGrupo[i].checked === true);
          if (element.listaSubGrupo[i].checked === true) {
            check = true;
          }
        }
      }
      // console.log("checkPermisoGrupoSubgrupo check->" + check);
      // setCheckGrupoSubgrupo(check);
      setCheckGrupoSubgrupo(prevIncidente => check);
    } catch (err) {
      console.log('checkPermisoGrupoSubgrupo err');
      console.log(err);
    }
  }

  const renderAcciones = (acciones, indexPrimerNivel, indexSegundoNivel, indexTercerNivel) => {
    return acciones.map((accion, indexAccion) => (
      <div key={shortid.generate()}
        className="ml-60"
      >
        <label >
          <input
            type="checkbox"
            checked={accion.checked}
            onChange={() => handleAccionCheckboxChange(accion, indexAccion, indexPrimerNivel, indexSegundoNivel, indexTercerNivel)}
          />
          {accion.nombreaccion}
        </label>
      </div>
    ));
  };

  const handleAccionCheckboxChange = (accion, indexAccion, indexPrimerNivel, indexSegundoNivel, indexTercerNivel) => {
    try {
      let pivote = listaModulos;
      if (indexPrimerNivel !== null && indexSegundoNivel !== null && indexTercerNivel !== null) {
        pivote[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccion].checked = !pivote[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccion].checked;
      }
      if (indexPrimerNivel !== null && indexSegundoNivel !== null && indexTercerNivel === null) {
        pivote[indexPrimerNivel].segundonivel[indexSegundoNivel].acciones[indexAccion].checked = !pivote[indexPrimerNivel].segundonivel[indexSegundoNivel].acciones[indexAccion].checked;
      }
      if (indexPrimerNivel !== null && indexSegundoNivel === null && indexTercerNivel === null) {
        pivote[indexPrimerNivel].acciones[indexAccion].checked = !pivote[indexPrimerNivel].acciones[indexAccion].checked;
      }
      setListaModulos([
        ...pivote
      ]);
    } catch (err) {
      console.log('handleAccionCheckboxChange err');
      console.log(err);
    }
  };

  const renderTercerNivel = (tercerNivel, indexSegundoNivel, indexPrimerNivel) => {
    return tercerNivel.map((modulo, indexTercerNivel) => (
      <div key={shortid.generate()}>
        <label className="font-bold ml-20">
          <input
            type="checkbox"
            checked={modulo.checked}
            onChange={() => handleModuloTercerNivelCheckboxChange(indexTercerNivel, indexSegundoNivel, indexPrimerNivel)}
          />
          {modulo.nombresubmodulotercero}
        </label>
        {renderAcciones(modulo.acciones, indexPrimerNivel, indexSegundoNivel, indexTercerNivel)}
      </div>
    ));
  };

  const renderSegundoNivel = (segundoNivel, indexPrimerNivel) => {
    return segundoNivel.map((modulo, indexSegundoNivel) => (
      <div key={shortid.generate()}>
        <label className="font-bold ml-10">
          <span className="inline-block w-2 h-2 bg-black rounded-full mr-2"></span>
          <input
            type="checkbox"
            checked={modulo.checked}
            onChange={() => handleModuloSegundoNivelCheckboxChange(indexSegundoNivel, indexPrimerNivel)}
          />
          {" " + modulo.nombresubmodulosegundo + " "}
        </label>
        {modulo.tercernivel !== null && renderTercerNivel(modulo.tercernivel, indexSegundoNivel, indexPrimerNivel)}
        {modulo.tercernivel === null && modulo.acciones !== null && renderAcciones(modulo.acciones, indexPrimerNivel, indexSegundoNivel, null)}
      </div>
    ));
  };

  const renderSubGrupo = (listaSub, indexGrupo) => {
    return listaSub.map((subgrupo, indexSubGrupo) => (
      <div key={subgrupo.idSubgrupo}>
        <label className="font-normal ml-60">
          <input
            type="checkbox"
            checked={subgrupo.checked}
            onChange={() => handleSubGrupoCheckboxChange(indexGrupo, indexSubGrupo)}
          /> {subgrupo.nombre}
        </label>
      </div>
    ));
  };

  const handleSubGrupoCheckboxChange = (indexGrupo, indexSubGrupo) => {
    let pivote = listaGrupoSubgrupo;
    const format = pivote[indexGrupo];
    format.listaSubGrupo[indexSubGrupo].checked = !format.listaSubGrupo[indexSubGrupo].checked;
    pivote[indexGrupo] = format;
    setListaGrupoSubgrupo([
      ...pivote
    ]);
  }

  const handleModuloTercerNivelCheckboxChange = (indexTercerNivel, indexSegundoNivel, indexPrimerNivel) => {
    try {
      let pivote = listaModulos;
      const nuevoCheck = !pivote[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked;
      pivote[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = nuevoCheck;
      for (let i = 0; i < pivote[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.length; i++) {
        pivote[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[i].checked = nuevoCheck;
      }
      setListaModulos([
        ...pivote
      ]);
    } catch (err) {
      console.log('handleModuloTercerNivelCheckboxChange err');
      console.log(err);
    }
  }

  const handleModuloSegundoNivelCheckboxChange = (indexSegundoNivel, indexPrimerNivel) => {
    try {
      let pivote = listaModulos;
      const moduloPadre = pivote[indexPrimerNivel];
      const moduloSegundoNivel = moduloPadre.segundonivel[indexSegundoNivel];
      let checkSegundoNivel = !moduloSegundoNivel.checked;
      moduloSegundoNivel.checked = checkSegundoNivel;
      /**hasta ahi cambio el check del segundo nivel,
       * falta sus hijos
       */
      // console.log('moduloSegundoNivel');
      // console.log(moduloSegundoNivel);
      switch (moduloSegundoNivel.id) {
        case INT_ID_SEGUNDO_NIVEL_MIS_CHECKLIST:
          for (let i = 0; i < moduloSegundoNivel.acciones.length; i++) {
            moduloSegundoNivel.acciones[i].checked = checkSegundoNivel;
          }
          break;
        case INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST:
          for (let i = 0; i < moduloSegundoNivel.tercernivel.length; i++) {
            moduloSegundoNivel.tercernivel[i].checked = checkSegundoNivel;
            for (let j = 0; j < moduloSegundoNivel.tercernivel[i].acciones.length; j++) {
              moduloSegundoNivel.tercernivel[i].acciones[j].checked = checkSegundoNivel;
            }
          }
          break;
        case INT_ID_SEGUNDO_NIVEL_RESPUESTA_INGRESO_CHECKLIST:
          for (let i = 0; i < moduloSegundoNivel.acciones.length; i++) {
            moduloSegundoNivel.acciones[i].checked = checkSegundoNivel;
          }
          break;
        case INT_ID_SEGUNDO_NIVEL_RESPUESTA_SALIDA_CHECKLIST:
          for (let i = 0; i < moduloSegundoNivel.acciones.length; i++) {
            moduloSegundoNivel.acciones[i].checked = checkSegundoNivel;
          }
          break;
        case INT_ID_SEGUNDO_NIVEL_EVENTOS_DISPONIBILIDAD:
          for (let i = 0; i < moduloSegundoNivel.acciones.length; i++) {
            moduloSegundoNivel.acciones[i].checked = checkSegundoNivel;
          }
          break;
        case INT_ID_SEGUNDO_NIVEL_EVENTOS_INCIDENTE:
          for (let i = 0; i < moduloSegundoNivel.acciones.length; i++) {
            moduloSegundoNivel.acciones[i].checked = checkSegundoNivel;
          }
          break;
        case INT_ID_SEGUNDO_NIVEL_EVENTOS_BITACORA:
          for (let i = 0; i < moduloSegundoNivel.acciones.length; i++) {
            moduloSegundoNivel.acciones[i].checked = checkSegundoNivel;
          }
          break;
        case INT_ID_SEGUNDO_NIVEL_USUARIOS:
          for (let i = 0; i < moduloSegundoNivel.tercernivel.length; i++) {
            moduloSegundoNivel.tercernivel[i].checked = checkSegundoNivel;
            for (let j = 0; j < moduloSegundoNivel.tercernivel[i].acciones.length; j++) {
              moduloSegundoNivel.tercernivel[i].acciones[j].checked = checkSegundoNivel;
            }
          }
          break;
        case INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL:
          for (let i = 0; i < moduloSegundoNivel.tercernivel.length; i++) {
            moduloSegundoNivel.tercernivel[i].checked = checkSegundoNivel;
            for (let j = 0; j < moduloSegundoNivel.tercernivel[i].acciones.length; j++) {
              moduloSegundoNivel.tercernivel[i].acciones[j].checked = checkSegundoNivel;
            }
          }
          break;
        case INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD:
          for (let i = 0; i < moduloSegundoNivel.tercernivel.length; i++) {
            moduloSegundoNivel.tercernivel[i].checked = checkSegundoNivel;
            for (let j = 0; j < moduloSegundoNivel.tercernivel[i].acciones.length; j++) {
              moduloSegundoNivel.tercernivel[i].acciones[j].checked = checkSegundoNivel;
            }
          }
          break;
        case INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA:
          for (let i = 0; i < moduloSegundoNivel.tercernivel.length; i++) {
            moduloSegundoNivel.tercernivel[i].checked = checkSegundoNivel;
            for (let j = 0; j < moduloSegundoNivel.tercernivel[i].acciones.length; j++) {
              moduloSegundoNivel.tercernivel[i].acciones[j].checked = checkSegundoNivel;
            }
          }
          break;
        default:
          break;
      }
      moduloPadre.segundonivel[indexSegundoNivel] = moduloSegundoNivel;
      pivote[indexPrimerNivel] = moduloPadre;
      setListaModulos([
        ...pivote
      ]);
    } catch (err) {
      console.log('handleModuloSegundoNivelCheckboxChange err');
      console.log(err);
    }
  }

  const handleModuloPrimerNivelCheckboxChange = (modulo, indexModulo) => {
    try {
      switch (modulo.id) {
        case INT_ID_MODULO_DASHBOARD:
          handleCheckDashboard(indexModulo);
          break;
        case INT_ID_MODULO_CHECKLIST:
          handleCheckChecklist(indexModulo);
          break;
        case INT_ID_MODULO_SEGUIMIENTO:
          handleCheckSeguimiento(indexModulo);
          break;
        case INT_ID_MODULO_EVENTOS:
          handleCheckEventos(indexModulo);
          break;
        case INT_ID_MODULO_DESCARGAS:
          handleCheckDescargas(indexModulo);
          break;
        case INT_ID_MODULO_CARGAS:
          handleCheckCargas(indexModulo);
          break;
        case INT_ID_MODULO_USUARIOS:
          handleCheckUsuarios(indexModulo);
          break;
        case INT_ID_MODULO_CONFIGURACION:
          handleCheckConfiguracion(indexModulo);
          break;
        case INT_ID_MODULO_REPORTES:
          handleCheckReportes(indexModulo);
          break;
        case INT_ID_MODULO_GEORUTA:
          handleCheckGeoruta(indexModulo);
          break;

        default:
          break;
      }
    } catch (err) {
      console.log('handleModuloPrimerNivelCheckboxChange err');
      console.log(err);
    }
  }

  const handleCheckDashboard = (indexModulo) => {
    let pivote = listaModulos;
    const moduloPadre = pivote[indexModulo];
    moduloPadre.checked = !moduloPadre.checked;
    pivote[indexModulo] = moduloPadre;
    setListaModulos([
      ...pivote
    ]);
  }

  const handleCheckChecklist = (indexModulo) => {
    // console.log('handleCheckChecklist');
    // console.log('indexModulo->' + indexModulo);
    let pivote = listaModulos;
    let moduloPadre = pivote[indexModulo];
    // console.log('moduloPadre');
    // console.log(moduloPadre);
    const check = !moduloPadre.checked;
    moduloPadre.checked = check;
    for (let i = 0; i < moduloPadre.segundonivel.length; i++) {
      moduloPadre.segundonivel[i].checked = check;
      switch (moduloPadre.segundonivel[i].id) {
        case INT_ID_SEGUNDO_NIVEL_MIS_CHECKLIST:
          for (let j = 0; j < moduloPadre.segundonivel[i].acciones.length; j++) {
            moduloPadre.segundonivel[i].acciones[j].checked = check;
          }
          break;
        case INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST:
          for (let j = 0; j < moduloPadre.segundonivel[i].tercernivel.length; j++) {
            moduloPadre.segundonivel[i].tercernivel[j].checked = check;
            for (let k = 0; k < moduloPadre.segundonivel[i].tercernivel[j].acciones.length; k++) {
              moduloPadre.segundonivel[i].tercernivel[j].acciones[k].checked = check;
            }
          }
          break;
        case INT_ID_SEGUNDO_NIVEL_RESPUESTA_INGRESO_CHECKLIST:
          for (let j = 0; j < moduloPadre.segundonivel[i].acciones.length; j++) {
            moduloPadre.segundonivel[i].acciones[j].checked = check;
          }
          break;
        case INT_ID_SEGUNDO_NIVEL_RESPUESTA_SALIDA_CHECKLIST:
          for (let j = 0; j < moduloPadre.segundonivel[i].acciones.length; j++) {
            moduloPadre.segundonivel[i].acciones[j].checked = check;
          }
          break;
        default:
          break;
      }
    }
    pivote[indexModulo] = moduloPadre;
    setListaModulos([
      ...pivote
    ]);
  }

  const handleCheckSeguimiento = (indexModulo) => {
    let pivote = listaModulos;
    const moduloPadre = pivote[indexModulo];
    moduloPadre.checked = !moduloPadre.checked;
    pivote[indexModulo] = moduloPadre;
    setListaModulos([
      ...pivote
    ]);
  }

  const handleCheckEventos = (indexModulo) => {
    // console.log('handleCheckEventos');
    // console.log('indexModulo->' + indexModulo);
    let pivote = listaModulos;
    let moduloPadre = pivote[indexModulo];
    // console.log('moduloPadre');
    // console.log(moduloPadre);
    const check = !moduloPadre.checked;
    moduloPadre.checked = check;
    for (let i = 0; i < moduloPadre.segundonivel.length; i++) {
      moduloPadre.segundonivel[i].checked = check;
      for (let j = 0; j < moduloPadre.segundonivel[i].acciones.length; j++) {
        moduloPadre.segundonivel[i].acciones[j].checked = check;
      }
    }
    pivote[indexModulo] = moduloPadre;
    setListaModulos([
      ...pivote
    ]);
  }

  const handleCheckDescargas = (indexModulo) => {
    /*let pivote = listaModulos;
    const moduloPadre = pivote[indexModulo];
    moduloPadre.checked = !moduloPadre.checked;
    pivote[indexModulo] = moduloPadre;
    setListaModulos([
      ...pivote
    ]);*/
    let pivote = listaModulos;
    let moduloPadre = pivote[indexModulo];
    const check = !moduloPadre.checked;
    moduloPadre.checked = check;
    for (let i = 0; i < moduloPadre.acciones.length; i++) {
      moduloPadre.acciones[i].checked = check;
    }
    pivote[indexModulo] = moduloPadre;
    setListaModulos([
      ...pivote
    ]);
  }

  const handleCheckCargas = (indexModulo) => {
    /*let pivote = listaModulos;
    const moduloPadre = pivote[indexModulo];
    moduloPadre.checked = !moduloPadre.checked;
    pivote[indexModulo] = moduloPadre;
    setListaModulos([
      ...pivote
    ]);*/
    let pivote = listaModulos;
    let moduloPadre = pivote[indexModulo];
    const check = !moduloPadre.checked;
    moduloPadre.checked = check;
    for (let i = 0; i < moduloPadre.acciones.length; i++) {
      moduloPadre.acciones[i].checked = check;
    }
    pivote[indexModulo] = moduloPadre;
    setListaModulos([
      ...pivote
    ]);
  }

  const handleCheckUsuarios = (indexModulo) => {
    let pivote = listaModulos;
    let moduloPadre = pivote[indexModulo];
    const check = !moduloPadre.checked;
    moduloPadre.checked = check;
    for (let i = 0; i < moduloPadre.segundonivel.length; i++) {
      moduloPadre.segundonivel[i].checked = check;
      switch (moduloPadre.segundonivel[i].id) {
        case INT_ID_SEGUNDO_NIVEL_USUARIOS:
          for (let j = 0; j < moduloPadre.segundonivel[i].tercernivel.length; j++) {
            moduloPadre.segundonivel[i].tercernivel[j].checked = check;
            for (let k = 0; k < moduloPadre.segundonivel[i].tercernivel[j].acciones.length; k++) {
              moduloPadre.segundonivel[i].tercernivel[j].acciones[k].checked = check;
            }
          }
          break;
        default:
          break;
      }
    }
    pivote[indexModulo] = moduloPadre;
    setListaModulos([
      ...pivote
    ]);
  }

  const handleCheckConfiguracion = (indexModulo) => {
    // console.log('handleCheckConfiguracion');
    // console.log('indexModulo->' + indexModulo);
    let pivote = listaModulos;
    let moduloPadre = pivote[indexModulo];
    // console.log('moduloPadre');
    // console.log(moduloPadre);
    const check = !moduloPadre.checked;
    moduloPadre.checked = check;
    for (let i = 0; i < moduloPadre.segundonivel.length; i++) {
      moduloPadre.segundonivel[i].checked = check;
      switch (moduloPadre.segundonivel[i].id) {
        case INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL:
          for (let j = 0; j < moduloPadre.segundonivel[i].tercernivel.length; j++) {
            moduloPadre.segundonivel[i].tercernivel[j].checked = check;
            for (let k = 0; k < moduloPadre.segundonivel[i].tercernivel[j].acciones.length; k++) {
              moduloPadre.segundonivel[i].tercernivel[j].acciones[k].checked = check;
            }
          }
          break;
        case INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD:
          for (let j = 0; j < moduloPadre.segundonivel[i].tercernivel.length; j++) {
            moduloPadre.segundonivel[i].tercernivel[j].checked = check;
            for (let k = 0; k < moduloPadre.segundonivel[i].tercernivel[j].acciones.length; k++) {
              moduloPadre.segundonivel[i].tercernivel[j].acciones[k].checked = check;
            }
          }
          break;
        case INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA:
          for (let j = 0; j < moduloPadre.segundonivel[i].tercernivel.length; j++) {
            moduloPadre.segundonivel[i].tercernivel[j].checked = check;
            for (let k = 0; k < moduloPadre.segundonivel[i].tercernivel[j].acciones.length; k++) {
              moduloPadre.segundonivel[i].tercernivel[j].acciones[k].checked = check;
            }
          }
          break;
        default:
          break;
      }
    }
    pivote[indexModulo] = moduloPadre;
    setListaModulos([
      ...pivote
    ]);
  }

  const handleCheckReportes = (indexModulo) => {
    let pivote = listaModulos;
    const moduloPadre = pivote[indexModulo];
    moduloPadre.checked = !moduloPadre.checked;
    pivote[indexModulo] = moduloPadre;
    setListaModulos([
      ...pivote
    ]);
  }

  const handleCheckGeoruta = (indexModulo) => {
    let pivote = listaModulos;
    let moduloPadre = pivote[indexModulo];
    const check = !moduloPadre.checked;
    moduloPadre.checked = check;
    for (let i = 0; i < moduloPadre.acciones.length; i++) {
      moduloPadre.acciones[i].checked = check;
    }
    pivote[indexModulo] = moduloPadre;
    setListaModulos([
      ...pivote
    ]);
  }

  const handleGrupoCheckboxChange = (indexGrupo) => {
    try {
      let pivote = listaGrupoSubgrupo;
      let nuevoCheck = !pivote[indexGrupo].checked;
      pivote[indexGrupo].checked = nuevoCheck;
      if (pivote[indexGrupo].listaSubGrupo !== undefined && pivote[indexGrupo].listaSubGrupo[0] !== undefined) {
        for (let i = 0; i < pivote[indexGrupo].listaSubGrupo.length; i++) {
          pivote[indexGrupo].listaSubGrupo[i].checked = nuevoCheck;
        }
      }
      setListaGrupoSubgrupo([
        ...pivote
      ]);
    } catch (err) {
      console.log('handleGrupoCheckboxChange err');
      console.log(err);
    }
  }

  const handleGuardarClick = async () => {
    try {
      // console.log('handleGuardarClick');
      // console.log(listaModulos);
      // console.log(listaGrupoSubgrupo);
      // console.log('usuarioWebSelected');
      // console.log(usuarioWebSelected);
      if (usuarioWebSelected === undefined || usuarioWebSelected === null) {
        alert('Debe Seleccionar un Usuario');
        return;
      }
      // return;
      const modulosPermisos = crearArrayPermisos();
      let obj = {};
      obj.idUsuario = usuarioWebSelected.value;

      console.log('modulosPermisos');
      console.log(modulosPermisos);
      // return;
      obj.listaModulos = modulosPermisos;
      obj.listaGrupoSubgrupo = listaGrupoSubgrupo;
      // console.log('obj');
      // console.log(obj);
      const modificado = await modificarPermisosUsuarioWeb(obj);
      // console.log('modificado');
      // console.log(modificado);
      const modificarAsignacionGrupo = await modificarAsignacionGrupoSubgrupoUsuarioWeb(obj);
      // console.log('modificarAsignacionGrupo');
      // console.log(modificarAsignacionGrupo);
      if (modificado === true && modificarAsignacionGrupo === true) {
        alert('Se modifico correctamente');
        setListaModulos(MODULOS);
        setListaGrupoSubgrupo(listaGrupoSubgrupoPivote);
        setUsuarioWebSelected(null);
      }
      if (modificado === false || modificarAsignacionGrupo === false) {
        alert('No se pudo modificar, intente nuevamente');
      }
    } catch (err) {
      console.log('handleGuardarClick err');
      console.log(err)
    }
  }

  const handleUsuarioWebSelected = async (event) => {
    try {
      setUsuarioWebSelected(event);
      if (event === null) {
        // setListaModulos([...MODULOS]);
        // setListaGrupoSubgrupo([]);
      }
      if (event !== null) {
        //traer los permisos y los grupos asignados
        let objEnviar = {};
        objEnviar.idUsuario = event.value;
        let moduloPermisosDeLaBase = await obtenerPermisosUsuarioWeb(objEnviar);
        // console.log('moduloPermisosDeLaBase');
        // console.log(moduloPermisosDeLaBase);
        let moduloAsignacionGrupos = await obtenerAsignacionGrupoSubgrupoUsuarioWeb(objEnviar);
        // console.log('moduloAsignacionGrupos');
        // console.log(moduloAsignacionGrupos);
        /**A partir de la info de la base, si existe
         * crear la lista [] de modulos y grupos
         */
        let asignacionGrupoDelaBase = null;
        if (moduloAsignacionGrupos.lenght > 0) {
          const accesoModuloAsignacionGrupos = JSON.parse(moduloAsignacionGrupos[0].acceso);
          if (accesoModuloAsignacionGrupos.lenght > 0) {
            asignacionGrupoDelaBase = accesoModuloAsignacionGrupos;
          }
        }
        let pivoteGrupoSubGrupo = [...listaGrupoSubgrupo];
        // console.log('asignacionGrupoDelaBase');
        // console.log(asignacionGrupoDelaBase);
        /** buscar cada elemento en asignacionGrupoDelaBase
         * si un grupo de asignacionGrupoDelaBase existe en listaGrupoSubgrupo
         * ponerle los permisos que estan en asignacionGrupoDelaBase
         */
        // console.log('pivoteGrupoSubGrupo');
        // console.log(pivoteGrupoSubGrupo);
        if (asignacionGrupoDelaBase !== null) {
          for (let i = 0; i < asignacionGrupoDelaBase.length; i++) {
            const grupo = asignacionGrupoDelaBase[i];
            // console.log('grupo');
            // console.log(grupo);
            const indexGrupo = pivoteGrupoSubGrupo.findIndex(e => e.idGrupo === grupo.idGrupo);
            // console.log('indexGrupo');
            // console.log(indexGrupo);
            if (indexGrupo > -1) {
              pivoteGrupoSubGrupo[indexGrupo].checked = grupo.checked;
              for (let j = 0; j < grupo.listaSubGrupo.length; j++) {
                const subGrupo = grupo.listaSubGrupo[j];
                // console.log('subGrupo');
                // console.log(subGrupo);
                const indexSubGrupo = pivoteGrupoSubGrupo[indexGrupo].listaSubGrupo.findIndex(e => e.idSubgrupo === subGrupo.idSubgrupo);
                // console.log('indexSubGrupo');
                // console.log(indexSubGrupo);
                if (indexSubGrupo > -1) {
                  pivoteGrupoSubGrupo[indexGrupo].listaSubGrupo[indexSubGrupo].checked = subGrupo.checked;
                }
              }
            }
          }
        }
        /**AQUI SE TRABAJA LOS PERMISOS A LOS MODULOS */
        let pivoteModulos = [...MODULOS];
        // console.log('pivoteModulos');
        // console.log(pivoteModulos);
        /**DASHBOARD */
        /**PRIMER NIVEL */
        const infoMenuDashboard = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_DASHBOARD &&
          e.idMenuSegundoNivel === 0 && e.idMenuTercerNivel === 0);
        if (infoMenuDashboard !== undefined) {
          const indexEnModuloDashboard = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_DASHBOARD);
          if (indexEnModuloDashboard > -1) {
            pivoteModulos[indexEnModuloDashboard].checked = infoMenuDashboard.accesoAlModulo;
            pivoteModulos[indexEnModuloDashboard].query = INT_TIPO_QUERY_UPDATE;
          }
        }
        if (infoMenuDashboard === undefined) {
          const indexEnModuloDashboard = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_DASHBOARD);
          if (indexEnModuloDashboard > -1) {
            pivoteModulos[indexEnModuloDashboard].checked = false;
            pivoteModulos[indexEnModuloDashboard].query = INT_TIPO_QUERY_INSERT;
          }
        }
        // debugger;
        /**CHECKLIST */
        /**PRIMER NIVEL */
        const infoMenuChecklist = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CHECKLIST &&
          e.idMenuSegundoNivel === 0 && e.idMenuTercerNivel === 0);
        if (infoMenuChecklist !== undefined) {
          const indexEnModuloChecklist = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexEnModuloChecklist > -1) {
            pivoteModulos[indexEnModuloChecklist].checked = infoMenuChecklist.accesoAlModulo;
            pivoteModulos[indexEnModuloChecklist].query = INT_TIPO_QUERY_UPDATE;
          }
        }
        if (infoMenuChecklist === undefined) {
          const indexEnModuloChecklist = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexEnModuloChecklist > -1) {
            pivoteModulos[indexEnModuloChecklist].checked = false;
            pivoteModulos[indexEnModuloChecklist].query = INT_TIPO_QUERY_INSERT;
          }
        }
        /**SEGUNDO NIVEL MIS CHECKLIST*/
        const infoMenuSegundoNivelMisChecklist = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CHECKLIST &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_MIS_CHECKLIST && e.idMenuTercerNivel === 0);
        if (infoMenuSegundoNivelMisChecklist !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_MIS_CHECKLIST);
            if (indexSegundoNivel > -1) {
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].checked = infoMenuSegundoNivelMisChecklist.accesoAlModulo;
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].query = INT_TIPO_QUERY_UPDATE;
              /**ACCIONES */
              const indexAccionNuevo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
              const indexAccionListar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_LISTAR);
              const indexAccionModificar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR);
              const indexAccionDeshabilitar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_DESHABILITAR);
              const indexAccionExportar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_EXPORTAR);
              const indexAccionVer = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_VER);
              if (indexAccionNuevo > -1 &&
                indexAccionModificar > -1 &&
                indexAccionListar > -1 &&
                indexAccionDeshabilitar > -1 &&
                indexAccionExportar > -1 &&
                indexAccionVer > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].acciones[indexAccionNuevo].checked = infoMenuSegundoNivelMisChecklist.nuevo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].acciones[indexAccionModificar].checked = infoMenuSegundoNivelMisChecklist.modificar;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].acciones[indexAccionListar].checked = infoMenuSegundoNivelMisChecklist.listar;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].acciones[indexAccionDeshabilitar].checked = infoMenuSegundoNivelMisChecklist.eliminarDeshabilitar;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].acciones[indexAccionExportar].checked = infoMenuSegundoNivelMisChecklist.exportar;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].acciones[indexAccionVer].checked = infoMenuSegundoNivelMisChecklist.ver;
              }
            }
          }
        }
        if (infoMenuSegundoNivelMisChecklist === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_MIS_CHECKLIST);
            if (indexSegundoNivel > -1) {
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].checked = false;
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].query = INT_TIPO_QUERY_INSERT;
            }
          }
        }

        /**SEGUNDO NIVEL ADMINISTRACION CHECKLIST*/
        const infoMenuSegundoNivelAdmChecklist = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CHECKLIST &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST && e.idMenuTercerNivel === 0);
        if (infoMenuSegundoNivelAdmChecklist !== undefined) {
          const indexCheklist = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexCheklist > -1) {
            const indexAdmChecklist = pivoteModulos[indexCheklist].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST);
            if (indexAdmChecklist > -1) {
              pivoteModulos[indexCheklist].segundonivel[indexAdmChecklist].checked = infoMenuSegundoNivelAdmChecklist.accesoAlModulo;
              pivoteModulos[indexCheklist].segundonivel[indexAdmChecklist].query = INT_TIPO_QUERY_UPDATE;
            }
          }
        }
        if (infoMenuSegundoNivelAdmChecklist === undefined) {
          const indexCheklist = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexCheklist > -1) {
            const indexAdmChecklist = pivoteModulos[indexCheklist].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST);
            if (indexAdmChecklist > -1) {
              pivoteModulos[indexCheklist].segundonivel[indexAdmChecklist].checked = false;
              pivoteModulos[indexCheklist].segundonivel[indexAdmChecklist].query = INT_TIPO_QUERY_INSERT;
            }
          }
        }
        /**TERCER NIVEL  ADMINISTRACION CHECKLIST TIPO CARROCERIA*/
        const infoMenuTercerNivelAdministracionTipoCarroceria = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CHECKLIST &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_CHECKLIST_TIPO_CARROCERIA);
        if (infoMenuTercerNivelAdministracionTipoCarroceria !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CHECKLIST_TIPO_CARROCERIA);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelAdministracionTipoCarroceria.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
                /**ACCIONES */
                const indexAccionNuevo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
                const indexAccionListar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_LISTAR);
                const indexAccionModificar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR);
                const indexAccionDeshabilitar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_DESHABILITAR);
                const indexAccionListarSubTipo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_LISTAR_SUB_TIPO_CARROCERIA);
                const indexAccionCrearSubTipo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_CREAR_SUB_TIPO_CARROCERIA);
                const indexAccionModificarSubTipo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR_SUB_TIPO_CARROCERIA);
                const indexAccionDeshabilitarSubTipo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_DESHABILITAR_SUB_TIPO_CARROCERIA);
                const indexAccionExportar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_EXPORTAR);
                const indexAccionVer = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_VER);
                if (indexAccionNuevo > -1 &&
                  indexAccionModificar > -1 &&
                  indexAccionListar > -1 &&
                  indexAccionDeshabilitar > -1 &&
                  indexAccionListarSubTipo > -1 &&
                  indexAccionCrearSubTipo > -1 &&
                  indexAccionModificarSubTipo > -1 &&
                  indexAccionDeshabilitarSubTipo > -1 &&
                  indexAccionExportar > -1 &&
                  indexAccionVer > -1) {
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionNuevo].checked = infoMenuTercerNivelAdministracionTipoCarroceria.nuevo;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionModificar].checked = infoMenuTercerNivelAdministracionTipoCarroceria.modificar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionListar].checked = infoMenuTercerNivelAdministracionTipoCarroceria.listar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionDeshabilitar].checked = infoMenuTercerNivelAdministracionTipoCarroceria.eliminarDeshabilitar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionListarSubTipo].checked = infoMenuTercerNivelAdministracionTipoCarroceria.listarSubTipoCarroceria;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionCrearSubTipo].checked = infoMenuTercerNivelAdministracionTipoCarroceria.crearSubTipoCarroceria;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionModificarSubTipo].checked = infoMenuTercerNivelAdministracionTipoCarroceria.modificarSubTipoCarroceria;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionDeshabilitarSubTipo].checked = infoMenuTercerNivelAdministracionTipoCarroceria.eliminarDeshabilitarSubTipoCarroceria;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionExportar].checked = infoMenuTercerNivelAdministracionTipoCarroceria.exportar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionVer].checked = infoMenuTercerNivelAdministracionTipoCarroceria.ver;
                }
              }
            }
          }
        }
        if (infoMenuTercerNivelAdministracionTipoCarroceria === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CHECKLIST_TIPO_CARROCERIA);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }
        /**TERCER NIVEL  ADMINISTRACION CHECKLIST CORREO*/
        const infoMenuTercerNivelAdministracionCorreo = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CHECKLIST &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_CHECKLIST_CORREOS);
        if (infoMenuTercerNivelAdministracionCorreo !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CHECKLIST_CORREOS);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelAdministracionCorreo.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
                /**ACCIONES */
                const indexAccionNuevo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
                const indexAccionListar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_LISTAR);
                const indexAccionModificar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR);
                const indexAccionDeshabilitar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_DESHABILITAR);
                const indexAccionExportar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_EXPORTAR);
                const indexAccionVer = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_VER);
                if (indexAccionNuevo > -1 &&
                  indexAccionModificar > -1 &&
                  indexAccionListar > -1 &&
                  indexAccionDeshabilitar > -1 &&
                  indexAccionExportar > -1 &&
                  indexAccionVer > -1) {
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionNuevo].checked = infoMenuTercerNivelAdministracionCorreo.nuevo;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionModificar].checked = infoMenuTercerNivelAdministracionCorreo.modificar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionListar].checked = infoMenuTercerNivelAdministracionCorreo.listar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionDeshabilitar].checked = infoMenuTercerNivelAdministracionCorreo.eliminarDeshabilitar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionExportar].checked = infoMenuTercerNivelAdministracionCorreo.exportar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionVer].checked = infoMenuTercerNivelAdministracionCorreo.ver;
                }
              }
            }
          }
        }
        if (infoMenuTercerNivelAdministracionCorreo === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CHECKLIST_CORREOS);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }
        /**TERCER NIVEL  ADMINISTRACION CHECKLIST OPCIONES RESPUESTA*/
        const infoMenuTercerNivelAdministracionOpcionesRespuesta = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CHECKLIST &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_CHECKLIST_OPCIONES_RESPUESTA);
        if (infoMenuTercerNivelAdministracionOpcionesRespuesta !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CHECKLIST_OPCIONES_RESPUESTA);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelAdministracionOpcionesRespuesta.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
                /**ACCIONES */
                const indexAccionNuevo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
                const indexAccionListar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_LISTAR);
                const indexAccionModificar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR);
                const indexAccionDeshabilitar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_DESHABILITAR);
                const indexAccionExportar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_EXPORTAR);
                const indexAccionVer = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_VER);
                if (indexAccionNuevo > -1 &&
                  indexAccionModificar > -1 &&
                  indexAccionListar > -1 &&
                  indexAccionDeshabilitar > -1 &&
                  indexAccionExportar > -1 &&
                  indexAccionVer > -1) {
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionNuevo].checked = infoMenuTercerNivelAdministracionOpcionesRespuesta.nuevo;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionModificar].checked = infoMenuTercerNivelAdministracionOpcionesRespuesta.modificar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionListar].checked = infoMenuTercerNivelAdministracionOpcionesRespuesta.listar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionDeshabilitar].checked = infoMenuTercerNivelAdministracionOpcionesRespuesta.eliminarDeshabilitar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionExportar].checked = infoMenuTercerNivelAdministracionOpcionesRespuesta.exportar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionVer].checked = infoMenuTercerNivelAdministracionOpcionesRespuesta.ver;
                }
              }
            }
          }
        }
        if (infoMenuTercerNivelAdministracionOpcionesRespuesta === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CHECKLIST_OPCIONES_RESPUESTA);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }
        /**TERCER NIVEL  ADMINISTRACION CHECKLIST MOVIMIENTO*/
        const infoMenuTercerNivelAdministracionMovimiento = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CHECKLIST &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_CHECKLIST_MOVIMIENTO);
        if (infoMenuTercerNivelAdministracionMovimiento !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CHECKLIST_MOVIMIENTO);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelAdministracionMovimiento.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
                /**ACCIONES */
                const indexAccionNuevo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
                const indexAccionListar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_LISTAR);
                const indexAccionModificar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR);
                const indexAccionDeshabilitar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_DESHABILITAR);
                const indexAccionExportar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_EXPORTAR);
                const indexAccionVer = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_VER);
                if (indexAccionNuevo > -1 &&
                  indexAccionModificar > -1 &&
                  indexAccionListar > -1 &&
                  indexAccionDeshabilitar > -1 &&
                  indexAccionExportar > -1 &&
                  indexAccionVer > -1) {
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionNuevo].checked = infoMenuTercerNivelAdministracionMovimiento.nuevo;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionModificar].checked = infoMenuTercerNivelAdministracionMovimiento.modificar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionListar].checked = infoMenuTercerNivelAdministracionMovimiento.listar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionDeshabilitar].checked = infoMenuTercerNivelAdministracionMovimiento.eliminarDeshabilitar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionExportar].checked = infoMenuTercerNivelAdministracionMovimiento.exportar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionVer].checked = infoMenuTercerNivelAdministracionMovimiento.ver;
                }
              }
            }
          }
        }
        if (infoMenuTercerNivelAdministracionMovimiento === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CHECKLIST_MOVIMIENTO);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }
        /**TERCER NIVEL  ADMINISTRACION CHECKLIST TIPO CHECKLIST*/
        const infoMenuTercerNivelAdministracionTipoChecklist = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CHECKLIST &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_CHECKLIST_TIPO_CHECKLIST);
        if (infoMenuTercerNivelAdministracionTipoChecklist !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CHECKLIST_TIPO_CHECKLIST);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelAdministracionTipoChecklist.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
                /**ACCIONES */
                const indexAccionNuevo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
                const indexAccionListar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_LISTAR);
                const indexAccionModificar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR);
                const indexAccionDeshabilitar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_DESHABILITAR);
                const indexAccionExportar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_EXPORTAR);
                const indexAccionVer = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_VER);
                if (indexAccionNuevo > -1 &&
                  indexAccionModificar > -1 &&
                  indexAccionListar > -1 &&
                  indexAccionDeshabilitar > -1 &&
                  indexAccionExportar > -1 &&
                  indexAccionVer > -1) {
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionNuevo].checked = infoMenuTercerNivelAdministracionTipoChecklist.nuevo;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionModificar].checked = infoMenuTercerNivelAdministracionTipoChecklist.modificar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionListar].checked = infoMenuTercerNivelAdministracionTipoChecklist.listar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionDeshabilitar].checked = infoMenuTercerNivelAdministracionTipoChecklist.eliminarDeshabilitar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionExportar].checked = infoMenuTercerNivelAdministracionTipoChecklist.exportar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionVer].checked = infoMenuTercerNivelAdministracionTipoChecklist.ver;
                }
              }
            }
          }
        }
        if (infoMenuTercerNivelAdministracionTipoChecklist === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CHECKLIST_TIPO_CHECKLIST);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }
        /**TERCER NIVEL  ADMINISTRACION CHECKLIST GESTIONAR ICONOS*/
        // debugger;
        const infoMenuTercerNivelAdministracionGestionarIconos = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CHECKLIST &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_CHECKLIST_GESTIONAR_ICONOS);
        if (infoMenuTercerNivelAdministracionGestionarIconos !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CHECKLIST_GESTIONAR_ICONOS);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelAdministracionGestionarIconos.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
                /**ACCIONES */
                const indexAccionNuevo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
                const indexAccionDeshabilitar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_DESHABILITAR);
                const indexAccionVer = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_VER);
                if (indexAccionNuevo > -1 &&
                  indexAccionDeshabilitar > -1 &&
                  indexAccionVer > -1) {
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionNuevo].checked = infoMenuTercerNivelAdministracionGestionarIconos.nuevo;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionDeshabilitar].checked = infoMenuTercerNivelAdministracionGestionarIconos.eliminarDeshabilitar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionVer].checked = infoMenuTercerNivelAdministracionGestionarIconos.ver;
                }
              }
            }
          }
        }
        if (infoMenuTercerNivelAdministracionGestionarIconos === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CHECKLIST_GESTIONAR_ICONOS);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }

        /**TERCER NIVEL  ADMINISTRACION CHECKLIST GEOCERCA*/
        // debugger;
        const infoMenuTercerNivelAdministracionGeocerca = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CHECKLIST &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_CHECKLIST_GEOCERCA);
        if (infoMenuTercerNivelAdministracionGeocerca !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CHECKLIST_GEOCERCA);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelAdministracionGeocerca.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
              }
            }
          }
        }
        if (infoMenuTercerNivelAdministracionGeocerca === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CHECKLIST_GEOCERCA);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }

        /**TERCER NIVEL  ADMINISTRACION CHECKLIST ASIGNAR CORREO*/
        // debugger;
        const infoMenuTercerNivelAdministracionAsignarCorreo = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CHECKLIST &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_CHECKLIST_ASIGNAR_CORREO);
        if (infoMenuTercerNivelAdministracionAsignarCorreo !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CHECKLIST_ASIGNAR_CORREO);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelAdministracionAsignarCorreo.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
                /**ACCIONES */
                const indexAccionNuevo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
                const indexAccionDeshabilitar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_DESHABILITAR);
                const indexAccionModificar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR);
                if (indexAccionNuevo > -1 &&
                  indexAccionDeshabilitar > -1 &&
                  indexAccionModificar > -1) {
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionNuevo].checked = infoMenuTercerNivelAdministracionAsignarCorreo.nuevo;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionDeshabilitar].checked = infoMenuTercerNivelAdministracionAsignarCorreo.eliminarDeshabilitar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionModificar].checked = infoMenuTercerNivelAdministracionAsignarCorreo.modificar;
                }
              }
            }
          }
        }
        if (infoMenuTercerNivelAdministracionAsignarCorreo === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CHECKLIST_ASIGNAR_CORREO);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }

        /**TERCER NIVEL  ADMINISTRACION CHECKLIST CATEGORIA*/
        // debugger;
        const infoMenuTercerNivelAdministracionCategoria = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CHECKLIST &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_CHECKLIST_CATEGORIA);
        if (infoMenuTercerNivelAdministracionCategoria !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CHECKLIST_CATEGORIA);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelAdministracionCategoria.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
                /**ACCIONES */
                const indexAccionNuevo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
                const indexAccionDeshabilitar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_DESHABILITAR);
                const indexAccionModificar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR);
                if (indexAccionNuevo > -1 &&
                  indexAccionDeshabilitar > -1 &&
                  indexAccionModificar > -1) {
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionNuevo].checked = infoMenuTercerNivelAdministracionCategoria.nuevo;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionDeshabilitar].checked = infoMenuTercerNivelAdministracionCategoria.eliminarDeshabilitar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionModificar].checked = infoMenuTercerNivelAdministracionCategoria.modificar;
                }
              }
            }
          }
        }
        if (infoMenuTercerNivelAdministracionCategoria === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CHECKLIST_CATEGORIA);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }

        /**SEGUNDO NIVEL RESPUESTA CHECKLIST INGRESO CHECKLIST*/
        // debugger;
        const infoMenuSegundoNivelRespChecklistIngreso = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CHECKLIST &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_RESPUESTA_INGRESO_CHECKLIST && e.idMenuTercerNivel === 0);
        if (infoMenuSegundoNivelRespChecklistIngreso !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_RESPUESTA_INGRESO_CHECKLIST);
            if (indexSegundoNivel > -1) {
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].checked = infoMenuSegundoNivelRespChecklistIngreso.accesoAlModulo;
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].query = INT_TIPO_QUERY_UPDATE;
              /**ACCIONES */
              const indexAccionVer = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_VER);
              if (
                indexAccionVer > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].acciones[indexAccionVer].checked = infoMenuSegundoNivelRespChecklistIngreso.ver;
              }
            }
          }
        }
        if (infoMenuSegundoNivelRespChecklistIngreso === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_RESPUESTA_INGRESO_CHECKLIST);
            if (indexSegundoNivel > -1) {
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].checked = false;
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].query = INT_TIPO_QUERY_INSERT;
            }
          }
        }
        /**SEGUNDO NIVEL RESPUESTA CHECKLIST SALIDA CHECKLIST*/
        // debugger;
        const infoMenuSegundoNivelRespChecklistSalida = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CHECKLIST &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_RESPUESTA_SALIDA_CHECKLIST && e.idMenuTercerNivel === 0);
        if (infoMenuSegundoNivelRespChecklistSalida !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_RESPUESTA_SALIDA_CHECKLIST);
            if (indexSegundoNivel > -1) {
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].checked = infoMenuSegundoNivelRespChecklistSalida.accesoAlModulo;
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].query = INT_TIPO_QUERY_UPDATE;
              /**ACCIONES */
              const indexAccionVer = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_VER);
              if (
                indexAccionVer > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].acciones[indexAccionVer].checked = infoMenuSegundoNivelRespChecklistSalida.ver;
              }
            }
          }
        }
        if (infoMenuSegundoNivelRespChecklistSalida === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_RESPUESTA_SALIDA_CHECKLIST);
            if (indexSegundoNivel > -1) {
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].checked = false;
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].query = INT_TIPO_QUERY_INSERT;
            }
          }
        }
        /**SEGUNDO NIVEL REPORTES CHECKLIST*/
        // debugger;
        const infoMenuSegundoNivelReporteChecklist = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CHECKLIST &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_REPORTE_CHECKLIST && e.idMenuTercerNivel === 0);
        if (infoMenuSegundoNivelReporteChecklist !== undefined) {
          const indexCheklist = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexCheklist > -1) {
            const indexReporteChecklist = pivoteModulos[indexCheklist].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_REPORTE_CHECKLIST);
            if (indexReporteChecklist > -1) {
              pivoteModulos[indexCheklist].segundonivel[indexReporteChecklist].checked = infoMenuSegundoNivelAdmChecklist.accesoAlModulo;
              pivoteModulos[indexCheklist].segundonivel[indexReporteChecklist].query = INT_TIPO_QUERY_UPDATE;
            }
          }
        }
        if (infoMenuSegundoNivelReporteChecklist === undefined) {
          const indexCheklist = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CHECKLIST);
          if (indexCheklist > -1) {
            const indexReporteChecklist = pivoteModulos[indexCheklist].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_REPORTE_CHECKLIST);
            if (indexReporteChecklist > -1) {
              pivoteModulos[indexCheklist].segundonivel[indexReporteChecklist].checked = false;
              pivoteModulos[indexCheklist].segundonivel[indexReporteChecklist].query = INT_TIPO_QUERY_INSERT;
            }
          }
        }

        /**SEGUIMIENTO */
        /**PRIMER NIVEL */
        const infoMenuSeguimiento = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_SEGUIMIENTO &&
          e.idMenuSegundoNivel === 0 && e.idMenuTercerNivel === 0);
        const indexEnModuloSeguimiento = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_SEGUIMIENTO);
        if (infoMenuSeguimiento !== undefined) {
          if (indexEnModuloSeguimiento > -1) {
            pivoteModulos[indexEnModuloSeguimiento].checked = infoMenuSeguimiento.accesoAlModulo;
            pivoteModulos[indexEnModuloSeguimiento].query = INT_TIPO_QUERY_UPDATE;
          }
        }
        if (infoMenuSeguimiento === undefined) {
          if (indexEnModuloSeguimiento > -1) {
            pivoteModulos[indexEnModuloSeguimiento].checked = false;
            pivoteModulos[indexEnModuloSeguimiento].query = INT_TIPO_QUERY_INSERT;
          }
        }

        /**EVENTOS */
        /**PRIMER NIVEL */
        const infoMenuEventos = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_EVENTOS &&
          e.idMenuSegundoNivel === 0 && e.idMenuTercerNivel === 0);
        const indexEnModuloEventos = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_EVENTOS);
        if (infoMenuEventos !== undefined) {
          if (indexEnModuloEventos > -1) {
            pivoteModulos[indexEnModuloEventos].checked = infoMenuEventos.accesoAlModulo;
            pivoteModulos[indexEnModuloEventos].query = INT_TIPO_QUERY_UPDATE;
          }
        }
        if (infoMenuEventos === undefined) {
          if (indexEnModuloEventos > -1) {
            pivoteModulos[indexEnModuloEventos].checked = false;
            pivoteModulos[indexEnModuloEventos].query = INT_TIPO_QUERY_INSERT;
          }
        }

        /**SEGUNDO NIVEL EVENTOS DISPONIBILIDAD */
        const infoMenuSegundoNivelEventos = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_EVENTOS &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_EVENTOS_DISPONIBILIDAD && e.idMenuTercerNivel === 0);
        const indexEventos = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_EVENTOS);
        if (infoMenuSegundoNivelEventos !== undefined) {
          if (indexEventos > -1) {
            const indexSegundoNivelEventos = pivoteModulos[indexEventos].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_EVENTOS_DISPONIBILIDAD);
            if (indexSegundoNivelEventos > -1) {
              pivoteModulos[indexEventos].segundonivel[indexSegundoNivelEventos].checked = infoMenuSegundoNivelEventos.accesoAlModulo;
              pivoteModulos[indexEventos].segundonivel[indexSegundoNivelEventos].query = INT_TIPO_QUERY_UPDATE;
            }
          }
        }
        if (infoMenuSegundoNivelEventos === undefined) {
          if (indexEventos > -1) {
            const indexSegundoNivelEventos = pivoteModulos[indexEventos].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_EVENTOS_DISPONIBILIDAD);
            if (indexSegundoNivelEventos > -1) {
              pivoteModulos[indexEventos].segundonivel[indexSegundoNivelEventos].checked = false;
              pivoteModulos[indexEventos].segundonivel[indexSegundoNivelEventos].query = INT_TIPO_QUERY_INSERT;
            }
          }
        }

        /**SEGUNDO NIVEL EVENTOS INCIDENTE */
        const infoMenuSegundoNivelEventosIncidente = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_EVENTOS &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_EVENTOS_INCIDENTE && e.idMenuTercerNivel === 0);
        // const indexEventos = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_EVENTOS); esta declarado arriba
        if (infoMenuSegundoNivelEventosIncidente !== undefined) {
          if (indexEventos > -1) {
            const indexSegundoNivelEventosIncidente = pivoteModulos[indexEventos].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_EVENTOS_INCIDENTE);
            if (indexSegundoNivelEventosIncidente > -1) {
              pivoteModulos[indexEventos].segundonivel[indexSegundoNivelEventosIncidente].checked = infoMenuSegundoNivelEventosIncidente.accesoAlModulo;
              pivoteModulos[indexEventos].segundonivel[indexSegundoNivelEventosIncidente].query = INT_TIPO_QUERY_UPDATE;
            }
          }
        }
        if (infoMenuSegundoNivelEventosIncidente === undefined) {
          if (indexEventos > -1) {
            const indexSegundoNivelEventosIncidente = pivoteModulos[indexEventos].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_EVENTOS_INCIDENTE);
            if (indexSegundoNivelEventosIncidente > -1) {
              pivoteModulos[indexEventos].segundonivel[indexSegundoNivelEventosIncidente].checked = false;
              pivoteModulos[indexEventos].segundonivel[indexSegundoNivelEventosIncidente].query = INT_TIPO_QUERY_INSERT;
            }
          }
        }

        /**SEGUNDO NIVEL EVENTOS BITACORA */
        const infoMenuSegundoNivelEventosBitacora = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_EVENTOS &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_EVENTOS_BITACORA && e.idMenuTercerNivel === 0);
        // const indexEventos = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_EVENTOS); esta declarado arriba
        if (infoMenuSegundoNivelEventosBitacora !== undefined) {
          if (indexEventos > -1) {
            const indexSegundoNivelEventosBitacora = pivoteModulos[indexEventos].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_EVENTOS_BITACORA);
            if (indexSegundoNivelEventosBitacora > -1) {
              pivoteModulos[indexEventos].segundonivel[indexSegundoNivelEventosBitacora].checked = infoMenuSegundoNivelEventosBitacora.accesoAlModulo;
              pivoteModulos[indexEventos].segundonivel[indexSegundoNivelEventosBitacora].query = INT_TIPO_QUERY_UPDATE;
            }
          }
        }
        if (infoMenuSegundoNivelEventosBitacora === undefined) {
          if (indexEventos > -1) {
            const indexSegundoNivelEventosBitacora = pivoteModulos[indexEventos].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_EVENTOS_BITACORA);
            if (indexSegundoNivelEventosBitacora > -1) {
              pivoteModulos[indexEventos].segundonivel[indexSegundoNivelEventosBitacora].checked = false;
              pivoteModulos[indexEventos].segundonivel[indexSegundoNivelEventosBitacora].query = INT_TIPO_QUERY_INSERT;
            }
          }
        }

        /**DESCARGAS */
        /**PRIMER NIVEL */
        /*const infoMenuDescargas = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_DESCARGAS &&
          e.idMenuSegundoNivel === 0 && e.idMenuTercerNivel === 0);
        const indexEnModuloDescargas = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_DESCARGAS);
        if (infoMenuDescargas !== undefined) {
          if (indexEnModuloDescargas > -1) {
            pivoteModulos[indexEnModuloDescargas].checked = infoMenuDescargas.accesoAlModulo;
            pivoteModulos[indexEnModuloDescargas].query = INT_TIPO_QUERY_UPDATE;
          }
        }
        if (infoMenuDescargas === undefined) {
          if (indexEnModuloDescargas > -1) {
            pivoteModulos[indexEnModuloDescargas].checked = false;
            pivoteModulos[indexEnModuloDescargas].query = INT_TIPO_QUERY_INSERT;
          }
        }*/
        const infoMenuDescargas = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_DESCARGAS &&
          e.idMenuSegundoNivel === 0 && e.idMenuTercerNivel === 0);
        const indexEnModuloDescargas = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_DESCARGAS);
        if (infoMenuDescargas !== undefined) {
          if (indexEnModuloDescargas > -1) {
            pivoteModulos[indexEnModuloDescargas].checked = infoMenuDescargas.accesoAlModulo;
            pivoteModulos[indexEnModuloDescargas].query = INT_TIPO_QUERY_UPDATE;
            // const indexAccionNuevo = pivoteModulos[indexEnModuloDescargas].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
            const indexAccionModificar = pivoteModulos[indexEnModuloDescargas].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR);
            const indexAccionEliminar = pivoteModulos[indexEnModuloDescargas].acciones.findIndex(e => e.id === INT_ID_ACCIONES_ELIMINAR);
            const indexAccionItinerario = pivoteModulos[indexEnModuloDescargas].acciones.findIndex(e => e.id === INT_ID_ACCIONES_ITINERARIO);
            const indexAccionEnviarSms = pivoteModulos[indexEnModuloDescargas].acciones.findIndex(e => e.id === INT_ID_ACCIONES_ENVIAR_SMS);
            const indexAccionExportar = pivoteModulos[indexEnModuloDescargas].acciones.findIndex(e => e.id === INT_ID_ACCIONES_EXPORTAR);
            const indexAccionCargar = pivoteModulos[indexEnModuloDescargas].acciones.findIndex(e => e.id === INT_ID_ACCIONES_CARGAR);
            const indexAccionGuardar = pivoteModulos[indexEnModuloDescargas].acciones.findIndex(e => e.id === INT_ID_ACCIONES_GUARDAR);
            const indexAccionEnviarSmsMasivo = pivoteModulos[indexEnModuloDescargas].acciones.findIndex(e => e.id === INT_ID_ACCIONES_ENVIAR_SMS_MASIVO);
            if (
              indexAccionModificar > -1 &&
              indexAccionEliminar > -1 &&
              indexAccionItinerario > -1 &&
              indexAccionEnviarSms > -1 &&
              indexAccionExportar > -1 &&
              indexAccionCargar > -1 &&
              indexAccionGuardar > -1 &&
              indexAccionEnviarSmsMasivo > -1
            ) {

              pivoteModulos[indexEnModuloDescargas].acciones[indexAccionModificar].checked = infoMenuDescargas.modificar;
              pivoteModulos[indexEnModuloDescargas].acciones[indexAccionEliminar].checked = infoMenuDescargas.eliminar;
              pivoteModulos[indexEnModuloDescargas].acciones[indexAccionItinerario].checked = infoMenuDescargas.itinerario;
              pivoteModulos[indexEnModuloDescargas].acciones[indexAccionEnviarSms].checked = infoMenuDescargas.enviarSms;
              pivoteModulos[indexEnModuloDescargas].acciones[indexAccionExportar].checked = infoMenuDescargas.exportar;
              pivoteModulos[indexEnModuloDescargas].acciones[indexAccionCargar].checked = infoMenuDescargas.cargar;
              pivoteModulos[indexEnModuloDescargas].acciones[indexAccionGuardar].checked = infoMenuDescargas.guardar;
              pivoteModulos[indexEnModuloDescargas].acciones[indexAccionEnviarSmsMasivo].checked = infoMenuDescargas.enviarSmsMasivo;
            }
          }
        }
        if (infoMenuDescargas === undefined) {
          if (indexEnModuloDescargas > -1) {
            pivoteModulos[indexEnModuloDescargas].checked = false;
            pivoteModulos[indexEnModuloDescargas].query = INT_TIPO_QUERY_INSERT;
          }
        }

        /**CARGAS */
        /**PRIMER NIVEL */
        /*const infoMenuCargas = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CARGAS &&
          e.idMenuSegundoNivel === 0 && e.idMenuTercerNivel === 0);
        const indexEnModuloCargas = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CARGAS);
        if (infoMenuCargas !== undefined) {
          if (indexEnModuloCargas > -1) {
            pivoteModulos[indexEnModuloCargas].checked = infoMenuCargas.accesoAlModulo;
            pivoteModulos[indexEnModuloCargas].query = INT_TIPO_QUERY_UPDATE;
          }
        }
        if (infoMenuCargas === undefined) {
          if (indexEnModuloCargas > -1) {
            pivoteModulos[indexEnModuloCargas].checked = false;
            pivoteModulos[indexEnModuloCargas].query = INT_TIPO_QUERY_INSERT;
          }
        }*/
        const infoMenuCargas = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CARGAS &&
          e.idMenuSegundoNivel === 0 && e.idMenuTercerNivel === 0);
        const indexEnModuloCargas = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CARGAS);
        if (infoMenuCargas !== undefined) {
          if (indexEnModuloCargas > -1) {
            pivoteModulos[indexEnModuloCargas].checked = infoMenuCargas.accesoAlModulo;
            pivoteModulos[indexEnModuloCargas].query = INT_TIPO_QUERY_UPDATE;
            // const indexAccionNuevo = pivoteModulos[indexEnModuloCargas].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
            const indexAccionModificar = pivoteModulos[indexEnModuloCargas].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR);
            const indexAccionEliminar = pivoteModulos[indexEnModuloCargas].acciones.findIndex(e => e.id === INT_ID_ACCIONES_ELIMINAR);
            const indexAccionItinerario = pivoteModulos[indexEnModuloCargas].acciones.findIndex(e => e.id === INT_ID_ACCIONES_ITINERARIO);
            const indexAccionEnviarSms = pivoteModulos[indexEnModuloCargas].acciones.findIndex(e => e.id === INT_ID_ACCIONES_ENVIAR_SMS);
            const indexAccionExportar = pivoteModulos[indexEnModuloCargas].acciones.findIndex(e => e.id === INT_ID_ACCIONES_EXPORTAR);
            const indexAccionCargar = pivoteModulos[indexEnModuloCargas].acciones.findIndex(e => e.id === INT_ID_ACCIONES_CARGAR);
            const indexAccionGuardar = pivoteModulos[indexEnModuloCargas].acciones.findIndex(e => e.id === INT_ID_ACCIONES_GUARDAR);
            const indexAccionEnviarSmsMasivo = pivoteModulos[indexEnModuloCargas].acciones.findIndex(e => e.id === INT_ID_ACCIONES_ENVIAR_SMS_MASIVO);
            if (
              indexAccionModificar > -1 &&
              indexAccionEliminar > -1 &&
              indexAccionItinerario > -1 &&
              indexAccionEnviarSms > -1 &&
              indexAccionExportar > -1 &&
              indexAccionCargar > -1 &&
              indexAccionGuardar > -1 &&
              indexAccionEnviarSmsMasivo > -1
            ) {

              pivoteModulos[indexEnModuloCargas].acciones[indexAccionModificar].checked = infoMenuCargas.modificar;
              pivoteModulos[indexEnModuloCargas].acciones[indexAccionEliminar].checked = infoMenuCargas.eliminar;
              pivoteModulos[indexEnModuloCargas].acciones[indexAccionItinerario].checked = infoMenuCargas.itinerario;
              pivoteModulos[indexEnModuloCargas].acciones[indexAccionEnviarSms].checked = infoMenuCargas.enviarSms;
              pivoteModulos[indexEnModuloCargas].acciones[indexAccionExportar].checked = infoMenuCargas.exportar;
              pivoteModulos[indexEnModuloCargas].acciones[indexAccionCargar].checked = infoMenuCargas.cargar;
              pivoteModulos[indexEnModuloCargas].acciones[indexAccionGuardar].checked = infoMenuCargas.guardar;
              pivoteModulos[indexEnModuloCargas].acciones[indexAccionEnviarSmsMasivo].checked = infoMenuCargas.enviarSmsMasivo;
            }
          }
        }
        if (infoMenuCargas === undefined) {
          if (indexEnModuloCargas > -1) {
            pivoteModulos[indexEnModuloCargas].checked = false;
            pivoteModulos[indexEnModuloCargas].query = INT_TIPO_QUERY_INSERT;
          }
        }

        /**USUARIOS */
        /**PRIMER NIVEL */
        const infoMenuUsuarios = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_USUARIOS &&
          e.idMenuSegundoNivel === 0 && e.idMenuTercerNivel === 0);
        const indexEnModuloUsuarios = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_USUARIOS);
        if (infoMenuUsuarios !== undefined) {
          if (indexEnModuloUsuarios > -1) {
            pivoteModulos[indexEnModuloUsuarios].checked = infoMenuUsuarios.accesoAlModulo;
            pivoteModulos[indexEnModuloUsuarios].query = INT_TIPO_QUERY_UPDATE;
          }
        }
        if (infoMenuUsuarios === undefined) {
          if (indexEnModuloUsuarios > -1) {
            pivoteModulos[indexEnModuloUsuarios].checked = false;
            pivoteModulos[indexEnModuloUsuarios].query = INT_TIPO_QUERY_INSERT;
          }
        }
        /**SEGUNDO NIVEL USUARIOS*/
        const infoMenuSegundoNivelUsuarios = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_USUARIOS &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_USUARIOS && e.idMenuTercerNivel === 0);
        if (infoMenuSegundoNivelUsuarios !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_USUARIOS);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_USUARIOS);
            if (indexSegundoNivel > -1) {
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].checked = infoMenuSegundoNivelUsuarios.accesoAlModulo;
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].query = INT_TIPO_QUERY_UPDATE;
            }
          }
        }
        if (infoMenuSegundoNivelUsuarios === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_USUARIOS);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_USUARIOS);
            if (indexSegundoNivel > -1) {
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].checked = false;
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].query = INT_TIPO_QUERY_INSERT;
            }
          }
        }

        /**TERCER NIVEL USUARIOS WEB*/
        const infoMenuTercerNivelUsuariosWeb = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_USUARIOS &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_USUARIOS && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_USUARIO_WEB);
        if (infoMenuTercerNivelUsuariosWeb !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_USUARIOS);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_USUARIOS);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_USUARIO_WEB);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelUsuariosWeb.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
                /**ACCIONES */
                const indexAccionNuevo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
                const indexAccionModificar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR);
                const indexAccionListar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_LISTAR);
                const indexAccionDeshabilitar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_DESHABILITAR);
                const indexAccionExportar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_EXPORTAR);
                const indexAccionVer = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_VER);
                if (indexAccionNuevo > -1 &&
                  indexAccionModificar > -1 &&
                  indexAccionListar > -1 &&
                  indexAccionDeshabilitar > -1 &&
                  indexAccionExportar > -1 &&
                  indexAccionVer > -1) {
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionNuevo].checked = infoMenuTercerNivelUsuariosWeb.nuevo;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionModificar].checked = infoMenuTercerNivelUsuariosWeb.modificar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionListar].checked = infoMenuTercerNivelUsuariosWeb.listar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionDeshabilitar].checked = infoMenuTercerNivelUsuariosWeb.eliminarDeshabilitar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionExportar].checked = infoMenuTercerNivelUsuariosWeb.exportar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionVer].checked = infoMenuTercerNivelUsuariosWeb.ver;
                }
              }
            }
          }
        }
        if (infoMenuTercerNivelUsuariosWeb === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_USUARIOS);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_USUARIOS);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_USUARIO_WEB);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }

        /**TERCER NIVEL USUARIOS MOVIL*/
        const infoMenuTercerNivelUsuariosMovil = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_USUARIOS &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_USUARIOS && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_USUARIO_MOVIL);
        if (infoMenuTercerNivelUsuariosMovil !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_USUARIOS);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_USUARIOS);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_USUARIO_MOVIL);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelUsuariosMovil.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
                /**ACCIONES */
                const indexAccionNuevo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
                const indexAccionModificar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR);
                const indexAccionListar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_LISTAR);
                const indexAccionDeshabilitar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_DESHABILITAR);
                const indexAccionExportar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_EXPORTAR);
                const indexAccionVer = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_VER);
                if (indexAccionNuevo > -1 &&
                  indexAccionModificar > -1 &&
                  indexAccionListar > -1 &&
                  indexAccionDeshabilitar > -1 &&
                  indexAccionExportar > -1 &&
                  indexAccionVer > -1) {
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionNuevo].checked = infoMenuTercerNivelUsuariosMovil.nuevo;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionModificar].checked = infoMenuTercerNivelUsuariosMovil.modificar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionListar].checked = infoMenuTercerNivelUsuariosMovil.listar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionDeshabilitar].checked = infoMenuTercerNivelUsuariosMovil.eliminarDeshabilitar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionExportar].checked = infoMenuTercerNivelUsuariosMovil.exportar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionVer].checked = infoMenuTercerNivelUsuariosMovil.ver;
                }
              }
            }
          }
        }
        if (infoMenuTercerNivelUsuariosMovil === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_USUARIOS);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_USUARIOS);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_USUARIO_MOVIL);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }

        /**CONFIGURACION */
        /**PRIMER NIVEL */
        const infoMenuConfiguracion = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CONFIGURACION &&
          e.idMenuSegundoNivel === 0 && e.idMenuTercerNivel === 0);
        if (infoMenuConfiguracion !== undefined) {
          const indexEnModuloConfiguracion = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexEnModuloConfiguracion > -1) {
            pivoteModulos[indexEnModuloConfiguracion].checked = infoMenuConfiguracion.accesoAlModulo;
            pivoteModulos[indexEnModuloConfiguracion].query = INT_TIPO_QUERY_UPDATE;
          }
        }
        if (infoMenuConfiguracion === undefined) {
          const indexEnModuloConfiguracion = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexEnModuloConfiguracion > -1) {
            pivoteModulos[indexEnModuloConfiguracion].checked = false;
            pivoteModulos[indexEnModuloConfiguracion].query = INT_TIPO_QUERY_INSERT;
          }
        }

        /**SEGUNDO NIVEL CONFIGURACION GENERAL*/
        const infoMenuSegundoNivelConfigGeneral = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CONFIGURACION &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL && e.idMenuTercerNivel === 0);
        if (infoMenuSegundoNivelConfigGeneral !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL);
            if (indexSegundoNivel > -1) {
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].checked = infoMenuSegundoNivelConfigGeneral.accesoAlModulo;
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].query = INT_TIPO_QUERY_UPDATE;
            }
          }
        }
        if (infoMenuSegundoNivelConfigGeneral === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL);
            if (indexSegundoNivel > -1) {
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].checked = false;
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].query = INT_TIPO_QUERY_INSERT;
            }
          }
        }

        /**SEGUNDO NIVEL CONFIGURACION DISPONIBILIDAD*/
        const infoMenuSegundoNivelConfigDisponibilidad = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CONFIGURACION &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD && e.idMenuTercerNivel === 0);
        if (infoMenuSegundoNivelConfigDisponibilidad !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD);
            if (indexSegundoNivel > -1) {
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].checked = infoMenuSegundoNivelConfigDisponibilidad.accesoAlModulo;
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].query = INT_TIPO_QUERY_UPDATE;
            }
          }
        }
        if (infoMenuSegundoNivelConfigDisponibilidad === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD);
            if (indexSegundoNivel > -1) {
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].checked = false;
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].query = INT_TIPO_QUERY_INSERT;
            }
          }
        }

        /**SEGUNDO NIVEL CONFIGURACION GEORUTA*/
        const infoMenuSegundoNivelConfigGeoruta = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CONFIGURACION &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA && e.idMenuTercerNivel === 0);
        if (infoMenuSegundoNivelConfigGeoruta !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA);
            if (indexSegundoNivel > -1) {
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].checked = infoMenuSegundoNivelConfigGeoruta.accesoAlModulo;
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].query = INT_TIPO_QUERY_UPDATE;
            }
          }
        }
        if (infoMenuSegundoNivelConfigGeoruta === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA);
            if (indexSegundoNivel > -1) {
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].checked = false;
              pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].query = INT_TIPO_QUERY_INSERT;
            }
          }
        }

        /**TERCER NIVEL CONFIGURACION GENERAL ASIGNAR PLANTA A USUARIO */
        const infoMenuTercerNivelConfigGeneralAsignarPlantaUsuario = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CONFIGURACION &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_ASIGNAR_PLANTA_A_USUARIO);
        if (infoMenuTercerNivelConfigGeneralAsignarPlantaUsuario !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_ASIGNAR_PLANTA_A_USUARIO);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelConfigGeneralAsignarPlantaUsuario.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
                /**ACCIONES */
                const indexAccionNuevo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
                const indexAccionModificar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR);
                const indexAccionDeshabilitar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_DESHABILITAR);
                const indexAccionExportar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_EXPORTAR);
                if (indexAccionNuevo > -1 &&
                  indexAccionModificar > -1 &&
                  indexAccionDeshabilitar > -1 &&
                  indexAccionExportar > -1) {
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionNuevo].checked = infoMenuTercerNivelConfigGeneralAsignarPlantaUsuario.nuevo;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionModificar].checked = infoMenuTercerNivelConfigGeneralAsignarPlantaUsuario.modificar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionDeshabilitar].checked = infoMenuTercerNivelConfigGeneralAsignarPlantaUsuario.eliminarDeshabilitar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionExportar].checked = infoMenuTercerNivelConfigGeneralAsignarPlantaUsuario.exportar;
                }
              }
            }
          }
        }
        if (infoMenuTercerNivelConfigGeneralAsignarPlantaUsuario === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_ASIGNAR_PLANTA_A_USUARIO);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }

        /**TERCER NIVEL CONFIGURACION GENERAL CONFIG TIEMPO CARGA */
        const infoMenuTercerNivelConfigGeneralConfigTiempoCarga = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CONFIGURACION &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_CARGA);
        if (infoMenuTercerNivelConfigGeneralConfigTiempoCarga !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_CARGA);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelConfigGeneralConfigTiempoCarga.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
                /**ACCIONES */
                const indexAccionNuevo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
                const indexAccionModificar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR);
                const indexAccionDeshabilitar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_DESHABILITAR);
                const indexAccionExportar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_EXPORTAR);
                if (indexAccionNuevo > -1 &&
                  indexAccionModificar > -1 &&
                  indexAccionDeshabilitar > -1 &&
                  indexAccionExportar > -1) {
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionNuevo].checked = infoMenuTercerNivelConfigGeneralConfigTiempoCarga.nuevo;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionModificar].checked = infoMenuTercerNivelConfigGeneralConfigTiempoCarga.modificar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionDeshabilitar].checked = infoMenuTercerNivelConfigGeneralConfigTiempoCarga.eliminarDeshabilitar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionExportar].checked = infoMenuTercerNivelConfigGeneralConfigTiempoCarga.exportar;
                }
              }
            }
          }
        }
        if (infoMenuTercerNivelConfigGeneralConfigTiempoCarga === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_CARGA);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }

        /**TERCER NIVEL CONFIGURACION GENERAL CONFIG TIEMPO DESCARGA */
        const infoMenuTercerNivelConfigGeneralConfigTiempoDescarga = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CONFIGURACION &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_DESCARGA);
        if (infoMenuTercerNivelConfigGeneralConfigTiempoDescarga !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_DESCARGA);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelConfigGeneralConfigTiempoDescarga.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
                /**ACCIONES */
                const indexAccionNuevo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
                const indexAccionModificar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR);
                const indexAccionDeshabilitar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_DESHABILITAR);
                const indexAccionExportar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_EXPORTAR);
                if (indexAccionNuevo > -1 &&
                  indexAccionModificar > -1 &&
                  indexAccionDeshabilitar > -1 &&
                  indexAccionExportar > -1) {
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionNuevo].checked = infoMenuTercerNivelConfigGeneralConfigTiempoDescarga.nuevo;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionModificar].checked = infoMenuTercerNivelConfigGeneralConfigTiempoDescarga.modificar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionDeshabilitar].checked = infoMenuTercerNivelConfigGeneralConfigTiempoDescarga.eliminarDeshabilitar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionExportar].checked = infoMenuTercerNivelConfigGeneralConfigTiempoDescarga.exportar;
                }
              }
            }
          }
        }
        if (infoMenuTercerNivelConfigGeneralConfigTiempoDescarga === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_DESCARGA);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }

        /**TERCER NIVEL CONFIGURACION DISPONIBILIDAD SELECCIONAR GRUPO CIUDADES*/
        const infoMenuTercerNivelConfigDispoSeleccionarGrupoCiudades = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CONFIGURACION &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GRUPO_CIUDADES);
        if (infoMenuTercerNivelConfigDispoSeleccionarGrupoCiudades !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GRUPO_CIUDADES);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelConfigDispoSeleccionarGrupoCiudades.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
              }
            }
          }
        }
        if (infoMenuTercerNivelConfigDispoSeleccionarGrupoCiudades === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GRUPO_CIUDADES);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }

        /**TERCER NIVEL CONFIGURACION DISPONIBILIDAD SELECCIONAR GEOCERCA DE UNA CIUDAD*/
        const infoMenuTercerNivelConfigDispoSeleccionarGeoDeUnaCiudad = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CONFIGURACION &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GEOCERCA_DE_UNA_CIUDAD);
        if (infoMenuTercerNivelConfigDispoSeleccionarGeoDeUnaCiudad !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GEOCERCA_DE_UNA_CIUDAD);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelConfigDispoSeleccionarGeoDeUnaCiudad.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
              }
            }
          }
        }
        if (infoMenuTercerNivelConfigDispoSeleccionarGeoDeUnaCiudad === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GEOCERCA_DE_UNA_CIUDAD);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }

        /**TERCER NIVEL CONFIGURACION DISPONIBILIDAD SELECCIONAR ESTADOS CARROCERIA*/
        const infoMenuTercerNivelConfigDispoSeleccionarEstadosCarroceria = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CONFIGURACION &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_CARROCERIA);
        if (infoMenuTercerNivelConfigDispoSeleccionarEstadosCarroceria !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_CARROCERIA);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelConfigDispoSeleccionarEstadosCarroceria.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
                /**ACCIONES */
                const indexAccionNuevo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
                const indexAccionModificar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR);
                const indexAccionDeshabilitar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_DESHABILITAR);
                const indexAccionExportar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_EXPORTAR);
                if (indexAccionNuevo > -1 &&
                  indexAccionModificar > -1 &&
                  indexAccionDeshabilitar > -1 &&
                  indexAccionExportar > -1) {
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionNuevo].checked = infoMenuTercerNivelConfigDispoSeleccionarEstadosCarroceria.nuevo;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionModificar].checked = infoMenuTercerNivelConfigDispoSeleccionarEstadosCarroceria.modificar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionDeshabilitar].checked = infoMenuTercerNivelConfigDispoSeleccionarEstadosCarroceria.eliminarDeshabilitar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionExportar].checked = infoMenuTercerNivelConfigDispoSeleccionarEstadosCarroceria.exportar;
                }
              }
            }
          }
        }
        if (infoMenuTercerNivelConfigDispoSeleccionarEstadosCarroceria === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_CARROCERIA);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }

        /**TERCER NIVEL CONFIGURACION DISPONIBILIDAD SELECCIONAR ESTADOS SUPERVISOR*/
        const infoMenuTercerNivelConfigDispoSeleccionarEstadosSupervisor = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CONFIGURACION &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_SUPERVISOR);
        if (infoMenuTercerNivelConfigDispoSeleccionarEstadosSupervisor !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_SUPERVISOR);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelConfigDispoSeleccionarEstadosSupervisor.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
                /**ACCIONES */
                const indexAccionNuevo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
                const indexAccionModificar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR);
                const indexAccionDeshabilitar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_DESHABILITAR);
                const indexAccionExportar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_EXPORTAR);
                if (indexAccionNuevo > -1 &&
                  indexAccionModificar > -1 &&
                  indexAccionDeshabilitar > -1 &&
                  indexAccionExportar > -1) {
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionNuevo].checked = infoMenuTercerNivelConfigDispoSeleccionarEstadosSupervisor.nuevo;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionModificar].checked = infoMenuTercerNivelConfigDispoSeleccionarEstadosSupervisor.modificar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionDeshabilitar].checked = infoMenuTercerNivelConfigDispoSeleccionarEstadosSupervisor.eliminarDeshabilitar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionExportar].checked = infoMenuTercerNivelConfigDispoSeleccionarEstadosSupervisor.exportar;
                }
              }
            }
          }
        }
        if (infoMenuTercerNivelConfigDispoSeleccionarEstadosSupervisor === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_SUPERVISOR);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }

        /**TERCER NIVEL CONFIGURACION DISPONIBILIDAD SELECCIONAR ESTADOS PLANIFICACION*/
        const infoMenuTercerNivelConfigDispoSeleccionarEstadosPlanificacion = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CONFIGURACION &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_PLANIFICACION);
        if (infoMenuTercerNivelConfigDispoSeleccionarEstadosPlanificacion !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_PLANIFICACION);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelConfigDispoSeleccionarEstadosPlanificacion.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
                /**ACCIONES */
                const indexAccionNuevo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
                const indexAccionModificar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR);
                const indexAccionDeshabilitar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_DESHABILITAR);
                const indexAccionExportar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_EXPORTAR);
                if (indexAccionNuevo > -1 &&
                  indexAccionModificar > -1 &&
                  indexAccionDeshabilitar > -1 &&
                  indexAccionExportar > -1) {
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionNuevo].checked = infoMenuTercerNivelConfigDispoSeleccionarEstadosPlanificacion.nuevo;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionModificar].checked = infoMenuTercerNivelConfigDispoSeleccionarEstadosPlanificacion.modificar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionDeshabilitar].checked = infoMenuTercerNivelConfigDispoSeleccionarEstadosPlanificacion.eliminarDeshabilitar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionExportar].checked = infoMenuTercerNivelConfigDispoSeleccionarEstadosPlanificacion.exportar;
                }
              }
            }
          }
        }
        if (infoMenuTercerNivelConfigDispoSeleccionarEstadosPlanificacion === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_PLANIFICACION);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }

        /**TERCER NIVEL CONFIGURACION DISPONIBILIDAD SELECCIONAR ESTADOS DISP CONDUCTOR*/
        const infoMenuTercerNivelConfigDispoSeleccionarEstadosDispConductor = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CONFIGURACION &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_DISPONIBILIDAD_CONDUCTOR);
        if (infoMenuTercerNivelConfigDispoSeleccionarEstadosDispConductor !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_DISPONIBILIDAD_CONDUCTOR);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelConfigDispoSeleccionarEstadosDispConductor.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
                /**ACCIONES */
                const indexAccionNuevo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
                const indexAccionModificar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR);
                const indexAccionDeshabilitar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_DESHABILITAR);
                const indexAccionExportar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_EXPORTAR);
                if (indexAccionNuevo > -1 &&
                  indexAccionModificar > -1 &&
                  indexAccionDeshabilitar > -1 &&
                  indexAccionExportar > -1) {
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionNuevo].checked = infoMenuTercerNivelConfigDispoSeleccionarEstadosDispConductor.nuevo;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionModificar].checked = infoMenuTercerNivelConfigDispoSeleccionarEstadosDispConductor.modificar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionDeshabilitar].checked = infoMenuTercerNivelConfigDispoSeleccionarEstadosDispConductor.eliminarDeshabilitar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionExportar].checked = infoMenuTercerNivelConfigDispoSeleccionarEstadosDispConductor.exportar;
                }
              }
            }
          }
        }
        if (infoMenuTercerNivelConfigDispoSeleccionarEstadosDispConductor === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_DISPONIBILIDAD_CONDUCTOR);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }

        /**TERCER NIVEL CONFIGURACION GEORUTA SELECCIONAR GRUPO GEORUTA*/
        const infoMenuTercerNivelConfigGeorutaSeleccionarGrupoGeoruta = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CONFIGURACION &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_SELECCIONAR_GRUPO_GEORUTA);
        if (infoMenuTercerNivelConfigGeorutaSeleccionarGrupoGeoruta !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_SELECCIONAR_GRUPO_GEORUTA);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelConfigGeorutaSeleccionarGrupoGeoruta.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
              }
            }
          }
        }
        if (infoMenuTercerNivelConfigGeorutaSeleccionarGrupoGeoruta === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_SELECCIONAR_GRUPO_GEORUTA);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }

        /**TERCER NIVEL CONFIGURACION GEORUTA SELECCIONAR PUNTOS CONTROL*/
        const infoMenuTercerNivelConfigGeorutaSeleccionarPuntosControl = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CONFIGURACION &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_SELECCIONAR_PUNTOS_CONTROL);
        if (infoMenuTercerNivelConfigGeorutaSeleccionarPuntosControl !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_SELECCIONAR_PUNTOS_CONTROL);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelConfigGeorutaSeleccionarPuntosControl.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
              }
            }
          }
        }
        if (infoMenuTercerNivelConfigGeorutaSeleccionarPuntosControl === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_SELECCIONAR_PUNTOS_CONTROL);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }

        /**TERCER NIVEL CONFIGURACION GEORUTA TIPO DETENCION GEORUTA*/
        const infoMenuTercerNivelConfigGeorutaTipoDetencionGeoruta = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_CONFIGURACION &&
          e.idMenuSegundoNivel === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA && e.idMenuTercerNivel === INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_TIPO_DETENCION_GEORUTA);
        if (infoMenuTercerNivelConfigGeorutaTipoDetencionGeoruta !== undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_TIPO_DETENCION_GEORUTA);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = infoMenuTercerNivelConfigGeorutaTipoDetencionGeoruta.accesoAlModulo;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_UPDATE;
                /**ACCIONES */
                const indexAccionNuevo = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
                const indexAccionModificar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR);
                const indexAccionDeshabilitar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_DESHABILITAR);
                const indexAccionExportar = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones.findIndex(e => e.id === INT_ID_ACCIONES_EXPORTAR);
                if (indexAccionNuevo > -1 &&
                  indexAccionModificar > -1 &&
                  indexAccionDeshabilitar > -1 &&
                  indexAccionExportar > -1) {
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionNuevo].checked = infoMenuTercerNivelConfigGeorutaTipoDetencionGeoruta.nuevo;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionModificar].checked = infoMenuTercerNivelConfigGeorutaTipoDetencionGeoruta.modificar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionDeshabilitar].checked = infoMenuTercerNivelConfigGeorutaTipoDetencionGeoruta.eliminarDeshabilitar;
                  pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].acciones[indexAccionExportar].checked = infoMenuTercerNivelConfigGeorutaTipoDetencionGeoruta.exportar;
                }
              }
            }
          }
        }
        if (infoMenuTercerNivelConfigGeorutaTipoDetencionGeoruta === undefined) {
          const indexPrimerNivel = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_CONFIGURACION);
          if (indexPrimerNivel > -1) {
            const indexSegundoNivel = pivoteModulos[indexPrimerNivel].segundonivel.findIndex(e => e.id === INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA);
            if (indexSegundoNivel > -1) {
              const indexTercerNivel = pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel.findIndex(e => e.id === INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_TIPO_DETENCION_GEORUTA);
              if (indexTercerNivel > -1) {
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].checked = false;
                pivoteModulos[indexPrimerNivel].segundonivel[indexSegundoNivel].tercernivel[indexTercerNivel].query = INT_TIPO_QUERY_INSERT;
              }
            }
          }
        }

        /**REPORTES */
        /**PRIMER NIVEL REPORTES */
        const infoMenuReportes = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_REPORTES &&
          e.idMenuSegundoNivel === 0 && e.idMenuTercerNivel === 0);
        const indexEnModuloReportes = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_REPORTES);
        if (infoMenuReportes !== undefined) {
          if (indexEnModuloReportes > -1) {
            pivoteModulos[indexEnModuloReportes].checked = infoMenuReportes.accesoAlModulo;
            pivoteModulos[indexEnModuloReportes].query = INT_TIPO_QUERY_UPDATE;
          }
        }
        if (infoMenuReportes === undefined) {
          if (indexEnModuloReportes > -1) {
            pivoteModulos[indexEnModuloReportes].checked = false;
            pivoteModulos[indexEnModuloReportes].query = INT_TIPO_QUERY_INSERT;
          }
        }

        /**GEORUTA  */
        /**PRIMER NIVEL GEORUTA */
        const infoMenuGeoruta = moduloPermisosDeLaBase.find(e => e.idMenuPrimerNivel === INT_ID_MODULO_GEORUTA &&
          e.idMenuSegundoNivel === 0 && e.idMenuTercerNivel === 0);
        const indexEnModuloGeoruta = pivoteModulos.findIndex(e => e.id === INT_ID_MODULO_GEORUTA);
        if (infoMenuGeoruta !== undefined) {
          if (indexEnModuloGeoruta > -1) {
            pivoteModulos[indexEnModuloGeoruta].checked = infoMenuGeoruta.accesoAlModulo;
            pivoteModulos[indexEnModuloGeoruta].query = INT_TIPO_QUERY_UPDATE;
            const indexAccionNuevo = pivoteModulos[indexEnModuloGeoruta].acciones.findIndex(e => e.id === INT_ID_ACCIONES_NUEVO);
            const indexAccionModificar = pivoteModulos[indexEnModuloGeoruta].acciones.findIndex(e => e.id === INT_ID_ACCIONES_MODIFICAR);
            const indexAccionDeshabilitar = pivoteModulos[indexEnModuloGeoruta].acciones.findIndex(e => e.id === INT_ID_ACCIONES_DESHABILITAR);
            const indexAccionExportar = pivoteModulos[indexEnModuloGeoruta].acciones.findIndex(e => e.id === INT_ID_ACCIONES_EXPORTAR);
            const indexAccionItinerario = pivoteModulos[indexEnModuloGeoruta].acciones.findIndex(e => e.id === INT_ID_ACCIONES_ITINERARIO);
            if (indexAccionNuevo > -1 &&
              indexAccionModificar > -1 &&
              indexAccionDeshabilitar > -1 &&
              indexAccionExportar > -1 &&
              indexAccionItinerario > -1) {
              pivoteModulos[indexEnModuloGeoruta].acciones[indexAccionNuevo].checked = infoMenuGeoruta.nuevo;
              pivoteModulos[indexEnModuloGeoruta].acciones[indexAccionModificar].checked = infoMenuGeoruta.modificar;
              pivoteModulos[indexEnModuloGeoruta].acciones[indexAccionDeshabilitar].checked = infoMenuGeoruta.eliminarDeshabilitar;
              pivoteModulos[indexEnModuloGeoruta].acciones[indexAccionExportar].checked = infoMenuGeoruta.exportar;
              pivoteModulos[indexEnModuloGeoruta].acciones[indexAccionItinerario].checked = infoMenuGeoruta.itinerario;
            }
          }
        }
        if (infoMenuGeoruta === undefined) {
          if (indexEnModuloGeoruta > -1) {
            pivoteModulos[indexEnModuloGeoruta].checked = false;
            pivoteModulos[indexEnModuloGeoruta].query = INT_TIPO_QUERY_INSERT;
          }
        }

        console.log('///////////////************////////////');
        console.log('pivoteModulos');
        console.log(pivoteModulos);
        setListaModulos(pivoteModulos);
        setListaGrupoSubgrupo(pivoteGrupoSubGrupo);
        // console.log('pivoteGrupoSubGrupo');
        // console.log(pivoteGrupoSubGrupo);
        checkPermisoGrupoSubgrupo(pivoteGrupoSubGrupo);
      }
    } catch (err) {
      console.log('handleUsuarioWebSelected err');
      console.log(err);
    }
  }

  const crearArrayPermisos = () => {
    try {
      let modulos = [];

      const objQueryPrimerNivelDashboard = crearObjetoPermisoPrimerNivel(INT_ID_MODULO_DASHBOARD);
      modulos.push(objQueryPrimerNivelDashboard);

      const objQueryPrimerNivelChecklist = crearObjetoPermisoPrimerNivel(INT_ID_MODULO_CHECKLIST);
      modulos.push(objQueryPrimerNivelChecklist);

      const objQuerySegundoNivelChecklistMisChecklist = crearObjetoPermisoSegundoNivel(INT_ID_MODULO_CHECKLIST, INT_ID_SEGUNDO_NIVEL_MIS_CHECKLIST);
      modulos.push(objQuerySegundoNivelChecklistMisChecklist);

      const objQuerySegundoNivelChecklistAdmChecklist = crearObjetoPermisoSegundoNivel(INT_ID_MODULO_CHECKLIST, INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST);
      modulos.push(objQuerySegundoNivelChecklistAdmChecklist);

      const objQueryTercerNivelChecklistTipoCarroceria = crearObjetoPermisoTercerNivel(INT_ID_MODULO_CHECKLIST, INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST, INT_ID_TERCER_NIVEL_CHECKLIST_TIPO_CARROCERIA);
      modulos.push(objQueryTercerNivelChecklistTipoCarroceria);

      const objQueryTercerNivelChecklistCorreos = crearObjetoPermisoTercerNivel(INT_ID_MODULO_CHECKLIST, INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST, INT_ID_TERCER_NIVEL_CHECKLIST_CORREOS);
      modulos.push(objQueryTercerNivelChecklistCorreos);

      const objQueryTercerNivelChecklistOpcionesRespuesta = crearObjetoPermisoTercerNivel(INT_ID_MODULO_CHECKLIST, INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST, INT_ID_TERCER_NIVEL_CHECKLIST_OPCIONES_RESPUESTA);
      modulos.push(objQueryTercerNivelChecklistOpcionesRespuesta);

      const objQueryTercerNivelChecklistMovimiento = crearObjetoPermisoTercerNivel(INT_ID_MODULO_CHECKLIST, INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST, INT_ID_TERCER_NIVEL_CHECKLIST_MOVIMIENTO);
      modulos.push(objQueryTercerNivelChecklistMovimiento);

      const objQueryTercerNivelChecklistTipoChecklist = crearObjetoPermisoTercerNivel(INT_ID_MODULO_CHECKLIST, INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST, INT_ID_TERCER_NIVEL_CHECKLIST_TIPO_CHECKLIST);
      modulos.push(objQueryTercerNivelChecklistTipoChecklist);

      const objQueryTercerNivelChecklistGestionarIconos = crearObjetoPermisoTercerNivel(INT_ID_MODULO_CHECKLIST, INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST, INT_ID_TERCER_NIVEL_CHECKLIST_GESTIONAR_ICONOS);
      modulos.push(objQueryTercerNivelChecklistGestionarIconos);

      const objQueryTercerNivelChecklistGeocerca = crearObjetoPermisoTercerNivel(INT_ID_MODULO_CHECKLIST, INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST, INT_ID_TERCER_NIVEL_CHECKLIST_GEOCERCA);
      modulos.push(objQueryTercerNivelChecklistGeocerca);

      const objQueryTercerNivelChecklistAsignarCorreo = crearObjetoPermisoTercerNivel(INT_ID_MODULO_CHECKLIST, INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST, INT_ID_TERCER_NIVEL_CHECKLIST_ASIGNAR_CORREO);
      modulos.push(objQueryTercerNivelChecklistAsignarCorreo);

      const objQueryTercerNivelChecklistCategoria = crearObjetoPermisoTercerNivel(INT_ID_MODULO_CHECKLIST, INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST, INT_ID_TERCER_NIVEL_CHECKLIST_CATEGORIA);
      modulos.push(objQueryTercerNivelChecklistCategoria);

      const objQuerySegundoNivelChecklistRespChecklistIngreso = crearObjetoPermisoSegundoNivel(INT_ID_MODULO_CHECKLIST, INT_ID_SEGUNDO_NIVEL_RESPUESTA_INGRESO_CHECKLIST);
      modulos.push(objQuerySegundoNivelChecklistRespChecklistIngreso);

      const objQuerySegundoNivelChecklistRespChecklistSalida = crearObjetoPermisoSegundoNivel(INT_ID_MODULO_CHECKLIST, INT_ID_SEGUNDO_NIVEL_RESPUESTA_SALIDA_CHECKLIST);
      modulos.push(objQuerySegundoNivelChecklistRespChecklistSalida);

      const objQuerySegundoNivelChecklistReporteChecklist = crearObjetoPermisoSegundoNivel(INT_ID_MODULO_CHECKLIST, INT_ID_SEGUNDO_NIVEL_REPORTE_CHECKLIST);
      modulos.push(objQuerySegundoNivelChecklistReporteChecklist);

      const objQueryPrimerNivelSeguimiento = crearObjetoPermisoPrimerNivel(INT_ID_MODULO_SEGUIMIENTO);
      modulos.push(objQueryPrimerNivelSeguimiento);

      const objQueryPrimerNivelEventos = crearObjetoPermisoPrimerNivel(INT_ID_MODULO_EVENTOS);
      modulos.push(objQueryPrimerNivelEventos);

      const objQuerySegundoNivelEventosDisponibilidad = crearObjetoPermisoSegundoNivel(INT_ID_MODULO_EVENTOS, INT_ID_SEGUNDO_NIVEL_EVENTOS_DISPONIBILIDAD);
      modulos.push(objQuerySegundoNivelEventosDisponibilidad);

      const objQuerySegundoNivelEventosIncidente = crearObjetoPermisoSegundoNivel(INT_ID_MODULO_EVENTOS, INT_ID_SEGUNDO_NIVEL_EVENTOS_INCIDENTE);
      modulos.push(objQuerySegundoNivelEventosIncidente);

      const objQuerySegundoNivelEventosBitacora = crearObjetoPermisoSegundoNivel(INT_ID_MODULO_EVENTOS, INT_ID_SEGUNDO_NIVEL_EVENTOS_BITACORA);
      modulos.push(objQuerySegundoNivelEventosBitacora);

      const objQueryPrimerNivelDescargas = crearObjetoPermisoPrimerNivel(INT_ID_MODULO_DESCARGAS);
      modulos.push(objQueryPrimerNivelDescargas);

      const objQueryPrimerNivelCargas = crearObjetoPermisoPrimerNivel(INT_ID_MODULO_CARGAS);
      modulos.push(objQueryPrimerNivelCargas);

      const objQueryPrimerNivelUsuarios = crearObjetoPermisoPrimerNivel(INT_ID_MODULO_USUARIOS);
      modulos.push(objQueryPrimerNivelUsuarios);

      const objQuerySegundoNivelUsuarios = crearObjetoPermisoSegundoNivel(INT_ID_MODULO_USUARIOS, INT_ID_SEGUNDO_NIVEL_USUARIOS);
      modulos.push(objQuerySegundoNivelUsuarios);

      const objQueryTercerNivelUsuariosUsuariosWeb = crearObjetoPermisoTercerNivel(INT_ID_MODULO_USUARIOS, INT_ID_SEGUNDO_NIVEL_USUARIOS, INT_ID_TERCER_NIVEL_USUARIO_WEB);
      modulos.push(objQueryTercerNivelUsuariosUsuariosWeb);

      const objQueryTercerNivelUsuariosUsuariosMobil = crearObjetoPermisoTercerNivel(INT_ID_MODULO_USUARIOS, INT_ID_SEGUNDO_NIVEL_USUARIOS, INT_ID_TERCER_NIVEL_USUARIO_MOVIL);
      modulos.push(objQueryTercerNivelUsuariosUsuariosMobil);

      const objQueryPrimerConfiguracion = crearObjetoPermisoPrimerNivel(INT_ID_MODULO_CONFIGURACION);
      modulos.push(objQueryPrimerConfiguracion);

      const objQuerySegundoNivelConfiguracionGeneral = crearObjetoPermisoSegundoNivel(INT_ID_MODULO_CONFIGURACION, INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL);
      modulos.push(objQuerySegundoNivelConfiguracionGeneral);

      const objQuerySegundoNivelConfiguracionGeneralAsignarPlantaUsuario = crearObjetoPermisoTercerNivel(INT_ID_MODULO_CONFIGURACION, INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL, INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_ASIGNAR_PLANTA_A_USUARIO);
      modulos.push(objQuerySegundoNivelConfiguracionGeneralAsignarPlantaUsuario);

      const objQuerySegundoNivelConfiguracionGeneralConfigTiempoCarga = crearObjetoPermisoTercerNivel(INT_ID_MODULO_CONFIGURACION, INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL, INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_CARGA);
      modulos.push(objQuerySegundoNivelConfiguracionGeneralConfigTiempoCarga);

      const objQuerySegundoNivelConfiguracionGeneralConfigTiempoDescarga = crearObjetoPermisoTercerNivel(INT_ID_MODULO_CONFIGURACION, INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL, INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_DESCARGA);
      modulos.push(objQuerySegundoNivelConfiguracionGeneralConfigTiempoDescarga);

      const objQuerySegundoNivelConfiguracionDisponibilidad = crearObjetoPermisoSegundoNivel(INT_ID_MODULO_CONFIGURACION, INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD);
      modulos.push(objQuerySegundoNivelConfiguracionDisponibilidad);

      const objQuerySegundoNivelConfiguracionDisponibilidadSelecGrupoCiudades = crearObjetoPermisoTercerNivel(INT_ID_MODULO_CONFIGURACION, INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD, INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GRUPO_CIUDADES);
      modulos.push(objQuerySegundoNivelConfiguracionDisponibilidadSelecGrupoCiudades);

      const objQuerySegundoNivelConfiguracionDisponibilidadSelecGeocercaDeUnaCiudad = crearObjetoPermisoTercerNivel(INT_ID_MODULO_CONFIGURACION, INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD, INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GEOCERCA_DE_UNA_CIUDAD);
      modulos.push(objQuerySegundoNivelConfiguracionDisponibilidadSelecGeocercaDeUnaCiudad);

      const objQuerySegundoNivelConfiguracionDisponibilidadGestionarEstadoCarroceria = crearObjetoPermisoTercerNivel(INT_ID_MODULO_CONFIGURACION, INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD, INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_CARROCERIA);
      modulos.push(objQuerySegundoNivelConfiguracionDisponibilidadGestionarEstadoCarroceria);

      const objQuerySegundoNivelConfiguracionDisponibilidadGestionarEstadoSupervisor = crearObjetoPermisoTercerNivel(INT_ID_MODULO_CONFIGURACION, INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD, INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_SUPERVISOR);
      modulos.push(objQuerySegundoNivelConfiguracionDisponibilidadGestionarEstadoSupervisor);

      const objQuerySegundoNivelConfiguracionDisponibilidadGestionarEstadoPlanificacion = crearObjetoPermisoTercerNivel(INT_ID_MODULO_CONFIGURACION, INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD, INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_PLANIFICACION);
      modulos.push(objQuerySegundoNivelConfiguracionDisponibilidadGestionarEstadoPlanificacion);

      const objQuerySegundoNivelConfiguracionDisponibilidadGestionarEstadoDispConductor = crearObjetoPermisoTercerNivel(INT_ID_MODULO_CONFIGURACION, INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD, INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_DISPONIBILIDAD_CONDUCTOR);
      modulos.push(objQuerySegundoNivelConfiguracionDisponibilidadGestionarEstadoDispConductor);

      const objQuerySegundoNivelConfiguracionGeoruta = crearObjetoPermisoSegundoNivel(INT_ID_MODULO_CONFIGURACION, INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA);
      modulos.push(objQuerySegundoNivelConfiguracionGeoruta);

      const objQuerySegundoNivelConfiguracionGeorutaSelecGrupoGeoruta = crearObjetoPermisoTercerNivel(INT_ID_MODULO_CONFIGURACION, INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA, INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_SELECCIONAR_GRUPO_GEORUTA);
      modulos.push(objQuerySegundoNivelConfiguracionGeorutaSelecGrupoGeoruta);

      const objQuerySegundoNivelConfiguracionGeorutaSelecPuntosControl = crearObjetoPermisoTercerNivel(INT_ID_MODULO_CONFIGURACION, INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA, INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_SELECCIONAR_PUNTOS_CONTROL);
      modulos.push(objQuerySegundoNivelConfiguracionGeorutaSelecPuntosControl);

      const objQuerySegundoNivelConfiguracionGeorutaTipoDetencionGeoruta = crearObjetoPermisoTercerNivel(INT_ID_MODULO_CONFIGURACION, INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA, INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_TIPO_DETENCION_GEORUTA);
      modulos.push(objQuerySegundoNivelConfiguracionGeorutaTipoDetencionGeoruta);

      const objQueryPrimerNivelReportes = crearObjetoPermisoPrimerNivel(INT_ID_MODULO_REPORTES);
      modulos.push(objQueryPrimerNivelReportes);

      const objQueryPrimerNivelGeoruta = crearObjetoPermisoPrimerNivel(INT_ID_MODULO_GEORUTA);
      modulos.push(objQueryPrimerNivelGeoruta);

      return modulos;
    } catch (err) {
      console.log('crearArrayPermisos err');
      console.log(err);
      return null;
    }
  }

  const crearObjetoPermisoPrimerNivel = (idPrimerNivel) => {
    try {
      const primerNivel = listaModulos.find(e => e.id === idPrimerNivel);
      let objQuery = {};
      objQuery.query = primerNivel.query;
      objQuery.idMenuPrimerNivel = primerNivel.id;
      objQuery.idMenuSegundoNivel = 0;
      objQuery.idMenuTercerNivel = 0;
      objQuery.accesoAlModulo = primerNivel.checked;
      objQuery = setAcciones(objQuery, primerNivel.acciones);
      return objQuery;
    } catch (err) {
      console.log('crearObjetoPermisoPrimerNivel err');
      console.log(err);
      return null;
    }
  }

  const crearObjetoPermisoSegundoNivel = (idPrimerNivel, idSegundoNivel) => {
    try {
      const primerNivel = listaModulos.find(e => e.id === idPrimerNivel);
      const segundoNivel = primerNivel.segundonivel.find(e => e.id === idSegundoNivel);
      // console.log('primerNivel');
      // console.log(primerNivel);
      // console.log('segundoNivel');
      // console.log(segundoNivel);
      let objQuery = {};
      objQuery.query = segundoNivel.query;
      objQuery.idMenuPrimerNivel = primerNivel.id;
      objQuery.idMenuSegundoNivel = segundoNivel.id;
      objQuery.idMenuTercerNivel = 0;
      objQuery.accesoAlModulo = segundoNivel.checked;
      objQuery = setAcciones(objQuery, segundoNivel.acciones);
      return objQuery;
    } catch (err) {
      console.log('crearObjetoPermisoSegundoNivel err');
      console.log(err);
      return null;
    }
  }

  const crearObjetoPermisoTercerNivel = (idPrimerNivel, idSegundoNivel, idTercerNivel) => {
    try {
      const primerNivel = listaModulos.find(e => e.id === idPrimerNivel);
      const segundoNivel = primerNivel.segundonivel.find(e => e.id === idSegundoNivel);
      const tercerNivel = segundoNivel.tercernivel.find(e => e.id === idTercerNivel);
      // console.log('primerNivel');
      // console.log(primerNivel);
      // console.log('segundoNivel');
      // console.log(segundoNivel);
      let objQuery = {};
      objQuery.query = tercerNivel.query;
      objQuery.idMenuPrimerNivel = primerNivel.id;
      objQuery.idMenuSegundoNivel = segundoNivel.id;
      objQuery.idMenuTercerNivel = tercerNivel.id;
      objQuery.accesoAlModulo = tercerNivel.checked;
      objQuery = setAcciones(objQuery, tercerNivel.acciones);
      return objQuery;
    } catch (err) {
      console.log('crearObjetoPermisoTercerNivel err');
      console.log(err);
      return null;
    }
  }

  const setAcciones = (obj, acciones) => {
    if (acciones === undefined || acciones === null) {
      obj.nuevo = 0;
      obj.modificar = 0;
      obj.listar = 0;
      obj.ver = 0;
      obj.eliminarDeshabilitar = 0;
      obj.exportar = 0;
      obj.importar = 0;
      obj.itinerario = 0;
      obj.meta = null;
      obj.listarSubTipoCarroceria = 0;
      obj.crearSubTipoCarroceria = 0;
      obj.modificarSubTipoCarroceria = 0;
      obj.eliminarDeshabilitarSubTipoCarroceria = 0;
      obj.eliminar = 0; //este eliminar es para carga descarga
      obj.enviarSms = 0;
      obj.enviarSmsMasivo = 0;
      obj.cargar = 0;
      obj.guardar = 0;
    }
    // debugger;
    if (acciones !== undefined && acciones !== null) {
      const nuevo = acciones.find(e => e.id === INT_ID_ACCIONES_NUEVO);
      const listar = acciones.find(e => e.id === INT_ID_ACCIONES_LISTAR);
      const modificar = acciones.find(e => e.id === INT_ID_ACCIONES_MODIFICAR);
      const deshabilitar = acciones.find(e => e.id === INT_ID_ACCIONES_DESHABILITAR);
      const exportar = acciones.find(e => e.id === INT_ID_ACCIONES_EXPORTAR);
      const ver = acciones.find(e => e.id === INT_ID_ACCIONES_VER);
      const importar = acciones.find(e => e.id === INT_ID_ACCIONES_IMPORTAR);
      const listarSubTipoCarroceria = acciones.find(e => e.id === INT_ID_ACCIONES_LISTAR_SUB_TIPO_CARROCERIA);
      const crearSubTipoCarroceria = acciones.find(e => e.id === INT_ID_ACCIONES_CREAR_SUB_TIPO_CARROCERIA);
      const modificarSubTipoCarroceria = acciones.find(e => e.id === INT_ID_ACCIONES_MODIFICAR_SUB_TIPO_CARROCERIA);
      const deshabilitarSubTipoCarroceria = acciones.find(e => e.id === INT_ID_ACCIONES_DESHABILITAR_SUB_TIPO_CARROCERIA);
      const itinerario = acciones.find(e => e.id === INT_ID_ACCIONES_ITINERARIO);

      const eliminar = acciones.find(e => e.id === INT_ID_ACCIONES_ELIMINAR);
      const enviarSms = acciones.find(e => e.id === INT_ID_ACCIONES_ENVIAR_SMS);
      const enviarSmsMasivo = acciones.find(e => e.id === INT_ID_ACCIONES_ENVIAR_SMS_MASIVO);
      const cargar = acciones.find(e => e.id === INT_ID_ACCIONES_CARGAR);
      const guardar = acciones.find(e => e.id === INT_ID_ACCIONES_GUARDAR);

      obj.nuevo = nuevo !== undefined ? nuevo.checked : 0;
      obj.modificar = modificar !== undefined ? modificar.checked : 0;
      obj.listar = listar !== undefined ? listar.checked : 0;
      obj.ver = ver !== undefined ? ver.checked : 0;
      obj.eliminarDeshabilitar = deshabilitar !== undefined ? deshabilitar.checked : 0;
      obj.exportar = exportar !== undefined ? exportar.checked : 0;
      obj.importar = importar !== undefined ? importar.checked : 0;
      obj.meta = null;
      obj.listarSubTipoCarroceria = listarSubTipoCarroceria !== undefined ? listarSubTipoCarroceria.checked : 0;
      obj.crearSubTipoCarroceria = crearSubTipoCarroceria !== undefined ? crearSubTipoCarroceria.checked : 0;
      obj.modificarSubTipoCarroceria = modificarSubTipoCarroceria !== undefined ? modificarSubTipoCarroceria.checked : 0;
      obj.eliminarDeshabilitarSubTipoCarroceria = deshabilitarSubTipoCarroceria !== undefined ? deshabilitarSubTipoCarroceria.checked : 0;
      obj.itinerario = itinerario !== undefined ? itinerario.checked : 0;

      obj.eliminar = eliminar !== undefined ? eliminar.checked : 0;
      obj.enviarSms = enviarSms !== undefined ? enviarSms.checked : 0;
      obj.enviarSmsMasivo = enviarSmsMasivo !== undefined ? enviarSmsMasivo.checked : 0;
      obj.cargar = cargar !== undefined ? cargar.checked : 0;
      obj.guardar = guardar !== undefined ? guardar.checked : 0;
    }
    return obj;
  }

  const handleSetGrupoSubGrupo = (value) => {
    try {
      let pivote = listaGrupoSubgrupo;
      const nuevoCheck = value;
      for (let indexGrupo = 0; indexGrupo < listaGrupoSubgrupo.length; indexGrupo++) {
        pivote[indexGrupo].checked = nuevoCheck;
        if (pivote[indexGrupo].listaSubGrupo !== undefined && pivote[indexGrupo].listaSubGrupo[0] !== undefined) {
          for (let i = 0; i < pivote[indexGrupo].listaSubGrupo.length; i++) {
            pivote[indexGrupo].listaSubGrupo[i].checked = nuevoCheck;
          }
        }
      }
      setListaGrupoSubgrupo([
        ...pivote
      ]);
    } catch (err) {
      console.log('handleSetGrupoSubGrupo err');
      console.log(err);
    }
  }

  const render = () => {
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div
              style={{ marginTop: 10, }}
            >
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div
        >
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <label
              style={{
                paddingLeft: 4,
                fontWeight: 'bold',
              }}
            >
              ASIGNAR PERMISOS
            </label>
          </div>
          <hr />
          <br />
          <div className="flex flex-wrap px-5 py-5 flex-col">
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <label htmlFor="usuario" className="mb-2 font-bold">
                USUARIO
              </label>
              <Select options={listaUsuariosWeb} isSearchable='true'
                isClearable='true'
                value={usuarioWebSelected}
                onChange={(event) => {
                  handleUsuarioWebSelected(event);
                }}
                placeholder='SELECCIONE USUARIO'
              />
            </div>
          </div>
          <hr />
          <br />
          {usuarioWebSelected !== null ?
            <div>
              <div className="flex flex-wrap px-5 py-5 flex-col">
                {listaModulos.map((modulo, indexModulo) => (
                  <div key={shortid.generate()}>
                    <label className="font-bold">
                      <input
                        type="checkbox"
                        checked={modulo.checked}
                        onChange={() => handleModuloPrimerNivelCheckboxChange(modulo, indexModulo)}
                      />
                      {" MODULO " + modulo.nombre}
                    </label>
                    {modulo.acciones !== undefined && modulo.acciones !== null && renderAcciones(modulo.acciones, indexModulo, null, null)}
                    {renderSegundoNivel(modulo.segundonivel, indexModulo)}
                    <hr />
                  </div>
                ))}
              </div>
              <div className="flex flex-wrap px-5 py-5 flex-col">
                <div>
                  <label className="font-bold">
                    <input
                      type="checkbox"
                      checked={checkGrupoSubGrupo}
                      onChange={(event) => {
                        setCheckGrupoSubgrupo(event.target.checked);
                        handleSetGrupoSubGrupo(event.target.checked);
                      }}
                    />
                    {" ASIGNAR GRUPO Y SUBGRUPO"}
                  </label>
                </div>
                {listaGrupoSubgrupo.map((grupo, indexGrupo) => (
                  <div key={grupo.idGrupo}>
                    <label className="font-bold ml-10">
                      <input
                        type="checkbox"
                        checked={grupo.checked}
                        onChange={() => handleGrupoCheckboxChange(indexGrupo)}
                      /> {grupo.nombre}
                    </label>
                    {renderSubGrupo(grupo.listaSubGrupo, indexGrupo)}
                    <hr />
                  </div>
                ))}
              </div>
              <div className="flex p-5 justify-end">
                <button className={ESTILO_BOTON_GENERAL_ACEPTAR}
                  onClick={handleGuardarClick}
                >
                  GUARDAR
                </button>
              </div>
            </div>
            : null
          }

        </div>
      );
    } catch (err) {
      console.log("permisos render");
      console.log(err);
      return (
        <div className=" w-1/2 m-auto px-[2rem] ">
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  };

  return render();
}
import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Descargas from "./index";
import Administracion from "./administracion";
import Configuracion from "./configuracion";
import ConfiguracionHorarios from "./configuracion-horarios";
import ConfiguracionMensaje from "./configuracion-mensaje";
import LayoutCargas from "../../components/layout-cargas";

const RouterChecklist = () => {
  const { path } = useRouteMatch();
  return (
    <LayoutCargas>
      <Switch>
        <Route path={`${path}/configuracion`}>
          <Configuracion />
        </Route>
        <Route path={`${path}/configuracion-horarios`}>
          <ConfiguracionHorarios />
        </Route>
        <Route path={`${path}/configuracion-mensaje`}>
          <ConfiguracionMensaje />
        </Route>
        <Route path={`${path}/administracion`}>
          <Administracion />
        </Route>
        <Route path={`${path}`}>
          <Descargas />
        </Route>
      </Switch>
    </LayoutCargas>
  );
};

export default RouterChecklist;

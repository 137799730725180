export const STRING_CENTER = 'center';
export const STRING_LEFT = 'left';
export const STRING_RIGHT = 'right';

export const STRING_CHOICE = 'choice';
export const STRING_FOTOGRAFIA = 'fotografia';
export const STRING_COMENTARIO = 'comentario';

export const STRING_ALIGN_LEFT = 'left';
export const STRING_ALIGN_RIGHT = 'right';

export const STRING_BOOL_TRUE = "true";
export const STRING_BOOL_FALSE = "false";

export const STRING_NOMBRE_MODULO_SEGUNDO_MIS_CHECKLIST_PERMISOS = 'MIS CHECKLIST';
export const STRING_NOMBRE_MODULO_SEGUNDO_ADMINISTRACION_CHECKLIST_PERMISOS = 'ADMINISTRACION CHECKLIST';
export const STRING_NOMBRE_MODULO_SEGUNDO_CHECKLIST_RESPUEST_CHECKLIST_INGRESO = 'RESPUESTA CHECKLIST INGRESO';
export const STRING_NOMBRE_MODULO_SEGUNDO_CHECKLIST_RESPUEST_CHECKLIST_SALIDA = 'RESPUESTA CHECKLIST SALIDA';
export const STRING_NOMBRE_MODULO_SEGUNDO_CHECKLIST_REPORTES_CHECKLIST = 'REPORTES CHECKLIST';

export const STRING_NOMBRE_MODULO_SEGUNDO_EVENTOS_DISPONIBILIDAD = 'DISPONIBILIDAD';
export const STRING_NOMBRE_MODULO_SEGUNDO_EVENTOS_INCIDENTE = 'INCIDENTE';
export const STRING_NOMBRE_MODULO_SEGUNDO_EVENTOS_BITACORA = 'BITACORA';

export const STRING_NOMBRE_MODULO_SEGUNDO_USUARIOS_PERMISOS = 'USUARIOS';
export const STRING_NOMBRE_MODULO_SEGUNDO_PERMISOS_PERMISOS = 'PERMISOS';

export const STRING_NOMBRE_MODULO_TERCERO_TIPO_CARROCERIA_PERMISOS = 'TIPO CARROCERIA';
export const STRING_NOMBRE_MODULO_TERCERO_CORREOS_PERMISOS = 'CORREOS';
export const STRING_NOMBRE_MODULO_TERCERO_OPCIONES_RESPUESTA_PERMISOS = 'OPCIONES RESPUESTA';
export const STRING_NOMBRE_MODULO_TERCERO_MOVIMIENTO_PERMISOS = 'MOVIMIENTO';
export const STRING_NOMBRE_MODULO_TERCERO_TIPO_CHECKLIST_PERMISOS = 'TIPO CHECKLIST';
export const STRING_NOMBRE_MODULO_TERCERO_GESTIONAR_ICONOS_PERMISOS = 'GESTIONAR ICONOS';
export const STRING_NOMBRE_MODULO_TERCERO_GEOCERCA = 'GEOCERCA';
export const STRING_NOMBRE_MODULO_TERCERO_ASIGNAR_CORREO = 'ASIGNAR CORREO';
export const STRING_NOMBRE_MODULO_TERCERO_CATEGORIA = 'CATEGORIA';

export const STRING_NOMBRE_MODULO_SEGUNDO_CONFIGURACION_GENERAL = 'GENERAL';
export const STRING_NOMBRE_MODULO_SEGUNDO_CONFIGURACION_DISPONIBILIDAD = 'DISPONIBILIDAD';
export const STRING_NOMBRE_MODULO_SEGUNDO_CONFIGURACION_GEORUTA = 'GEORUTA';

export const STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_GENERAL_ASIGNAR_PLANTA_USUARIO = 'ASIGNAR PLANTA A USUARIO';
export const STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_CARGA = 'CONFIGURAR TIEMPO CARGA';
export const STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_DESCARGA = 'CONFIGURAR TIEMPO DESCARGA';

export const STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GRUPO_CIUDADES = 'SELECCIONAR GRUPO CIUDADES';
export const STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GEOCERCA_CIUDAD = 'SELECCIONAR GEOCERCA DE UNA CIUDAD';
export const STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADOS_CARROCERIA = 'GESTIONAR ESTADOS CARROCERIA';
export const STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADOS_SUPERVISOR = 'GESTIONAR ESTADOS SUPERVISOR';
export const STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADOS_PLANIFICACION = 'GESTIONAR ESTADOS PLANIFICACION';
export const STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADOS_DISPONIBILIDAD_CONDUCTOR = 'GESTIONAR ESTADOS DISPONIBILIDAD CONDUCTOR';

export const STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_GEORUTA_SELECCIONAR_GRUPO_GEORUTA = 'SELECCIONAR GRUPO GEORUTA';
export const STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_GEORUTA_SELECCIONAR_PUNTOS_CONTROL = 'SELECCIONAR PUNTOS CONTROL';
export const STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_GEORUTA_TIPO_DETENCION_GEORUTA = 'TIPO DETENCION GEORUTA';

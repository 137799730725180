import carIcon from "../assets/iconos/car.png"

export const MODULOS_CONFIGURACION_DISPONIBILIDAD = [
    {
        id: 1,
        titulo: 'Seleccionar Grupo Ciudades',
        descripcion: 'Seleccionar Grupo Ciudades',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'disponibilidad/seleccionar-grupo-geocerca-ciudad',
    },
    {
        id: 2,
        titulo: 'Seleccionar Geocerca de una Ciudad',
        descripcion: 'Seleccionar Geocerca de una Ciudad',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'disponibilidad/seleccionar-geocerca-de-una-ciudad',
    },
    {
        id: 3,
        titulo: 'Gestionar Estados Carroceria',
        descripcion: 'Estados Carroceria',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'disponibilidad/listar-estado-carroceria',
    },
    {
        id: 4,
        titulo: 'Gestionar Estados Supervisor',
        descripcion: 'Estados Supervisor',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'disponibilidad/listar-estado-supervisor',
    },
    {
        id: 5,
        titulo: 'Gestionar Estados Planificacion',
        descripcion: 'Estados Planificacion',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'disponibilidad/listar-estado-planificacion',
    },
    {
        id: 6,
        titulo: 'Gestionar Estados Disponibilidad Conductor',
        descripcion: 'Estados Estados Disponibilidad Conductor',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'disponibilidad/listar-estado-disponibilidad-chofer',
    },
];
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "../../components/grid";
import {
  Edit,
  Slash,
} from "feather-icons-react/build/IconComponents";
import {
  ESTILO_BOTON_GRILLA_NEXT,
  ESTILO_BOTON_GRILLA_PREVIOUS,
  ESTILO_BOTON_LISTAR_NUEVO,
} from "../../utils/const-estilos";
import Select from "react-select";
import {
  deshabilitarCheklist,
  habilitarCheklist,
  obtenerListaCheklist,
} from "../../api/checklist";
import ModalConfirmar from "../../components/modal-confirmar";
import "../../style/style-cargando.css";
import {
  INT_ID_MODULO_CHECKLIST,
  INT_ID_SEGUNDO_NIVEL_MIS_CHECKLIST,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../utils/const-int";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
import { TRUE } from "../../utils/const-bool";
import ModalConfiguracionGrilla from "../../components/modal-configuracion-grilla";
import { GESTIONAR_CHECKLIST_SCREEN } from "../../utils/const-screen-name";
import {
  actualizarFontSizeGrilla,
  actualizarHeadersGestionarDisponibilidadScreen,
  actualizarOrdenGestionarDisponibilidadScreen,
  guardarConfiguracionScreen,
  obtenerConfiguracionScreen,
} from "../../api/configuracion-screen/configuracion-screen";
import { Settings } from "feather-icons-react/build/IconComponents";
import { compareHeaders } from "../../share/shared-functions";
import { STRING_CENTER } from "../../utils/const-string";
import { INT_FONT_SIZE } from "../../utils/const-int";
import { SELECT_HABILITADO_DESHABILITADO } from "../../utils/const-select-habilitado-deshabilitado";

const idTipoUsuario = parseInt(localStorage.getItem("tipo"));
let accesoBotonNuevo = "none";

export default function ListarChecklist() {
  const headers = [
    { key: "acciones", title: "ACCIONES" },
    { key: "id", title: "CODIGO", visible: false },
    {
      key: "idtipocarroceria",
      title: "idTipoCarroceria",
      filter: true,
      visible: false,
    },
    {
      key: "tipocarroceria",
      title: "TIPO CARROCERIA",
      filter: true,
      visible: true,
    },
    {
      key: "idsubtipocarroceria",
      title: "idSubTipoCarroceria",
      filter: true,
      visible: false,
    },
    {
      key: "subtipocarroceria",
      title: "SUB TIPO CARROCERIA",
      filter: true,
      visible: true,
    },
    {
      key: "idtipochecklist",
      title: "idTipoChecklist",
      filter: true,
      visible: false,
    },
    {
      key: "tipochecklist",
      title: "TIPO CHECKLIST",
      filter: true,
      visible: true,
    },
    {
      key: "idSubTipoChecklist",
      title: "ID SUB TIPO CHECKLIST",
      filter: false,
      visible: false,
    },
    { key: "nombre", title: "NOMBRE", filter: true, visible: true },
    { key: "preguntas", title: "PREGUNTAS", filter: true, visible: false },
    {
      key: "fechaproceso",
      title: "FECHA PROCESO",
      filter: true,
      visible: true,
    },
  ];

  const history = useHistory();
  const [minRows, setMinRows] = useState(0);
  const [maxRows, setMaxRows] = useState(5);
  const [cargandoPage, setCargandoPage] = useState(false);
  const [listaChecklist, setListaChecklist] = useState([]);
  const [checklistSelected, setChecklistSelected] = useState(null);
  const [estadoChecklistSelected, setEstadoChecklistSelected] = useState(null);
  const [modalConfirmarDeshabilitar, setVisibleModalConfirmarDeshabilitar] =
    useState(false);
  const [modalConfirmarHabilitar, setVisibleModalConfirmarHabilitar] =
    useState(false);
  const [permisos, setPermisos] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);

  const [exportar, setExportar] = useState(true);
  const [modalConfiguracionVisible, setModalConfiguracionVisible] =
    useState(false);
  const [configuracionOrden, setConfiguracionOrden] = useState(null);
  const [ascendente, setAscendente] = useState(false);
  const [descendente, setDescendente] = useState(false);
  const [gridHeaders, setGridHeaders] = useState(headers);
  const [fontSizeValue, setFontSizeValue] = useState(INT_FONT_SIZE);
  const [alignValue, setAlignValue] = useState(STRING_CENTER);

  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      let objPermiso = {};
      objPermiso.idMenuPrimerNivel = INT_ID_MODULO_CHECKLIST;
      objPermiso.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_MIS_CHECKLIST;
      objPermiso.idMenuTercerNivel = 0;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(objPermiso);
      let permiso = null;
      if (obtenerPermisos.length > 0) {
        permiso = obtenerPermisos[0];
        setPermisos(permiso);
      }

      const configuracionScreenInfo = await obtenerConfiguracionScreen({
        nombre: GESTIONAR_CHECKLIST_SCREEN,
      });
      // console.log("configuracionScreenInfo");
      // console.log(configuracionScreenInfo);
      let objConfiguracionEnviar = {};
      objConfiguracionEnviar.descendente = true;
      objConfiguracionEnviar.ascendente = false;
      objConfiguracionEnviar.habilitado = estadoChecklistSelected !== null ? estadoChecklistSelected.value : null;
      if (configuracionScreenInfo !== null) {
        const configuracionOrden = JSON.parse(configuracionScreenInfo.orden);
        // console.log('configuracionOrden');
        // console.log(configuracionOrden);
        objConfiguracionEnviar.descendente = configuracionOrden.descendente;
        objConfiguracionEnviar.ascendente = configuracionOrden.ascendente;
        setAscendente(configuracionOrden.ascendente);
        setDescendente(configuracionOrden.descendente);
        let configuracionHeaders = null;
        if (configuracionScreenInfo.headers !== null) {
          configuracionHeaders = JSON.parse(configuracionScreenInfo.headers);
          // console.log('configuracionHeaders');
          // console.log(configuracionHeaders);
          const headerAux = compareHeaders(headers, configuracionHeaders);
          setGridHeaders(headerAux);
        }
        if (configuracionScreenInfo.fontSize !== null) {
          setFontSizeValue(configuracionScreenInfo.fontSize);
        }
      }
      setConfiguracionOrden(configuracionScreenInfo);

      let lista = [];
      // debugger;
      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        setTieneAccesoAlModulo(true);
        accesoBotonNuevo = "";
        lista = await obtenerListaCheklist(objConfiguracionEnviar);
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.accesoAlModulo === TRUE) {
          setTieneAccesoAlModulo(true);
          lista = await obtenerListaCheklist(objConfiguracionEnviar);
          setExportar(permiso.exportar);
        }
      }
      agregarAccionesALista(lista, permiso);
      setCargandoPage(false);
    } catch (err) {
      console.log("iniciar err");
      console.log(err);
      setCargandoPage(false);
    }
  };

  const agregarAccionesALista = (lista, permiso) => {
    try {
      // console.log('permiso');
      // console.log(permiso);
      if (lista.length === 0) {
        setListaChecklist(lista);
      }
      if (lista.length > 0) {
        const objModificar = {
          label: "Modificar",
          icon: Edit,
          color: "green",
          onClick: (param) => modificar(param),
        };
        const objDeshabilitar = {
          label: "Deshabilitar",
          icon: Slash,
          color: "red",
          onClick: (param) => cambiarEstadoDeshabilitar(param),
        };
        const objHabilitar = {
          label: "Habilitar",
          icon: Slash,
          color: "blue",
          onClick: (param) => cambiarEstadoHabilitar(param),
        };
        for (let i = 0; i < lista.length; i++) {
          const element = lista[i];
          // console.log('element');
          // console.log(element);
          let acciones = [];
          if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            acciones.push(objModificar);
            if (element.habilitado === 1) {
              acciones.push(objDeshabilitar);
            }
            if (element.habilitado === 0) {
              acciones.push(objHabilitar);
            }
          }
          if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            if (permiso.modificar === TRUE) {
              acciones.push(objModificar);
            }
            if (permiso.eliminarDeshabilitar === TRUE) {
              if (element.habilitado === 1) {
                acciones.push(objDeshabilitar);
              }
              if (element.habilitado === 0) {
                acciones.push(objHabilitar);
              }
            }
            if (permiso.nuevo === TRUE) {
              accesoBotonNuevo = "";
            }
          }
          lista[i].acciones = acciones;
        }
        setListaChecklist(lista);
      }
    } catch (err) {
      console.log("agregarAccionesALista err");
      console.log(err);
    }
  };

  const modificar = (data) => {
    try {
      // console.log("modificar");
      // console.log(data);
      if (data.acciones !== undefined) {
        delete data.acciones;
      }
      let obj = {};
      obj.titulo = "MODIFICAR CHECKLIST ";
      obj.params = data;
      nextPage("checklist/gestionar-checklist", obj);
    } catch (err) {
      console.log("modificar err");
      console.log(err);
    }
  };

  const cambiarEstadoDeshabilitar = async (data) => {
    try {
      // console.log("cambiarEstadoDeshabilitar");
      // console.log(data);
      setChecklistSelected(data);
      setVisibleModalConfirmarDeshabilitar(true);
    } catch (err) {
      console.log("cambiarEstadoDeshabilitar err");
      console.log(err);
    }
  };

  const cambiarEstadoHabilitar = async (data) => {
    try {
      setChecklistSelected(data);
      setVisibleModalConfirmarHabilitar(true);
    } catch (err) {
      console.log("cambiarEstadoHabilitar err");
      console.log(err);
    }
  };

  const nextPage = (page, params) => {
    try {
      if (params !== undefined && params !== null) {
        if (params.acciones !== undefined) {
          delete params.acciones;
        }
        history.push({
          pathname: `${page}`,
          state: params,
        });
      } else {
        history.push(`${page}`);
      }
    } catch (err) {
      console.log("nextPage err");
      console.log(err);
    }
  };

  const handleNextPage = () => {
    if (maxRows >= listaChecklist.length) return;
    setMinRows(maxRows);
    setMaxRows(maxRows + 5);
  };

  const handlePrevPage = () => {
    if (minRows <= 0) return;
    setMaxRows(minRows);
    setMinRows(Math.max(minRows - 5, 0));
  };

  const handleNuevoClick = () => {
    console.log("handleNuevoClick");
    console.log("permisos");
    console.log(permisos);
    let obj = {};
    let data = null;
    obj.titulo = "CREAR NUEVO CHECKLIST";
    obj.params = data;
    if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
      nextPage("checklist/gestionar-checklist", obj);
    }
    if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
      if (permisos.nuevo === TRUE) {
        nextPage("checklist/gestionar-checklist", obj);
      }
    }
  };

  const handleCancelModalDeshabilitar = () => {
    setVisibleModalConfirmarDeshabilitar(false);
  };

  const handleCancelModalHabilitar = () => {
    setVisibleModalConfirmarHabilitar(false);
  };

  const handleConfirmModalDeshabilitar = async () => {
    try {
      // console.log("Modal confirmado!");
      // console.log('tipoCarroceriaSelected');
      // console.log(tipoCarroceriaSelected);
      setVisibleModalConfirmarDeshabilitar(false);
      let obj = {};
      obj.id = checklistSelected.id;
      let habilitado = await deshabilitarCheklist(obj);
      // console.log('habilitado');
      // console.log(habilitado);
      if (habilitado === true) {
        alert("Se deshabilito correctamente");
        reset();
      }
      if (habilitado === false) {
        alert("No se pudo habilitar sub tipo carroceria");
      }
    } catch (err) {
      console.log("handleConfirmModalDeshabilitar err");
      console.log(err);
    }
  };

  const handleConfirmModalHabilitar = async () => {
    try {
      // console.log("Modal confirmado!");
      // console.log('tipoCarroceriaSelected');
      // console.log(tipoCarroceriaSelected);
      setVisibleModalConfirmarHabilitar(false);
      let obj = {};
      obj.id = checklistSelected.id;
      const habilitado = await habilitarCheklist(obj);
      // console.log('habilitado');
      // console.log(habilitado);
      if (habilitado === true) {
        alert("Se habilito correctamente");
        reset();
      }
      if (habilitado === false) {
        alert("No se pudo habilitar sub tipo carroceria");
      }
    } catch (err) {
      console.log("handleConfirmModalHabilitar err");
      console.log(err);
    }
  };

  const reset = async (permiso) => {
    setCargandoPage(true);
    const configuracionScreenInfo = await obtenerConfiguracionScreen({
      nombre: GESTIONAR_CHECKLIST_SCREEN,
    });
    // console.log('configuracionScreenInfo');
    // console.log(configuracionScreenInfo);
    let objConfiguracionEnviar = {};
    objConfiguracionEnviar.descendente = true;
    objConfiguracionEnviar.ascendente = false;
    objConfiguracionEnviar.habilitado = estadoChecklistSelected !== null ? estadoChecklistSelected.value : null;
    if (configuracionScreenInfo !== null) {
      const configuracionOrden = JSON.parse(configuracionScreenInfo.orden);
      // console.log("configuracionOrden");
      // console.log(configuracionOrden);
      objConfiguracionEnviar.descendente = configuracionOrden.descendente;
      objConfiguracionEnviar.ascendente = configuracionOrden.ascendente;
      setAscendente(configuracionOrden.ascendente);
      setDescendente(configuracionOrden.descendente);
      let configuracionHeaders = null;
      // debugger;
      if (configuracionScreenInfo.headers !== null) {
        configuracionHeaders = JSON.parse(configuracionScreenInfo.headers);
        // console.log('configuracionHeaders');
        // console.log(configuracionHeaders);
        const headerAux = compareHeaders(headers, configuracionHeaders);
        setGridHeaders(headerAux);
      }
      if (configuracionScreenInfo.fontSize !== null) {
        setFontSizeValue(configuracionScreenInfo.fontSize);
      }
      setConfiguracionOrden(configuracionScreenInfo);
    }
    let lista = await obtenerListaCheklist(objConfiguracionEnviar);
    agregarAccionesALista(lista, permiso);
    setCargandoPage(false);
  };

  const onHideModalConfiguracion = () => {
    setModalConfiguracionVisible(false);
  };

  const onClickAcceptarOrdenGrillaModalConfiguracion = async () => {
    try {
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = GESTIONAR_CHECKLIST_SCREEN;
      objConfiguracionEnviar.orden = objOrden;
      // console.log('configuracionOrden');
      // console.log(configuracionOrden);
      if (configuracionOrden === null) {
        objConfiguracionEnviar.headers = gridHeaders;
        objConfiguracionEnviar.fontSize = fontSizeValue;
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        const actualizo = await actualizarOrdenGestionarDisponibilidadScreen(
          objConfiguracionEnviar
        );
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log("onClickAcceptarOrdenGrillaModalConfiguracion err");
      console.log(err);
    }
  };

  const onClickAcceptarHeadersGrillaModalConfiguracion = async () => {
    try {
      console.log("onClickAcceptarHeadersGrillaModalConfiguracion");
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = GESTIONAR_CHECKLIST_SCREEN;
      objConfiguracionEnviar.orden = objOrden;
      console.log("configuracionOrden");
      console.log(configuracionOrden);
      if (configuracionOrden === null) {
        objConfiguracionEnviar.headers = gridHeaders;
        objConfiguracionEnviar.fontSize = fontSizeValue;
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        objConfiguracionEnviar.headers = gridHeaders;
        const actualizo = await actualizarHeadersGestionarDisponibilidadScreen(
          objConfiguracionEnviar
        );
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log("onClickAcceptarHeadersGrillaModalConfiguracion err");
      console.log(err);
    }
  };

  const onClickAcceptarFontSizeModalConfiguracion = async (fontSizeValue) => {
    try {
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = GESTIONAR_CHECKLIST_SCREEN;
      objConfiguracionEnviar.orden = objOrden;
      objConfiguracionEnviar.fontSize = fontSizeValue;
      console.log("configuracionOrden");
      console.log(configuracionOrden);
      if (configuracionOrden === null) {
        objConfiguracionEnviar.headers = gridHeaders;
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        objConfiguracionEnviar.fontSize = fontSizeValue;
        const actualizo = await actualizarFontSizeGrilla(
          objConfiguracionEnviar
        );
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log("onClickAcceptarFontSizeModalConfiguracion err");
      console.log(err);
    }
  };

  const openModalCondiguracion = () => {
    setModalConfiguracionVisible(true);
  };

  const handleEstadoChecklist = (event) => {
    setEstadoChecklistSelected(event);
  }

  const handleBuscarClick = () => {
    setCargandoPage(true);
    iniciar();
  }

  const render = () => {
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div style={{ marginTop: 10 }}>
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 30,
            }}
          >
            <div style={{ marginTop: 10 }}>
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <label
              style={{
                paddingLeft: 10,
                fontWeight: "bold",
              }}
            >
              GESTIONAR CHECKLIST
            </label>

          </div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="flex align-center" >
              <Select
                options={SELECT_HABILITADO_DESHABILITADO}
                isSearchable={true}
                isClearable={true}
                value={estadoChecklistSelected}
                onChange={(event) => handleEstadoChecklist(event)}
                placeholder="Seleccione Estado"
              />
              <div
                style={{ width: 10 }}
              ></div>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_NUEVO}
                onClick={handleBuscarClick}
                style={{
                  display: accesoBotonNuevo,
                }}
              >
                BUSCAR
              </button>
            </div>
            <div className="flex align-center" >
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_NUEVO}
                onClick={handleNuevoClick}
                style={{
                  display: accesoBotonNuevo,
                }}
              >
                NUEVO
              </button>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_NUEVO}
                onClick={openModalCondiguracion}
              >
                <Settings />
              </button>
            </div>
          </div>
          <Grid
            rows={listaChecklist}
            headers={gridHeaders}
            exportar={exportar}
            fontSize={fontSizeValue}
            align={alignValue}
          />
          <div>
            <button
              className={ESTILO_BOTON_GRILLA_PREVIOUS}
              onClick={handlePrevPage}
            >
              Previous
            </button>
            <button
              className={ESTILO_BOTON_GRILLA_NEXT}
              onClick={handleNextPage}
            >
              Next
            </button>
          </div>
          <ModalConfirmar
            isOpen={modalConfirmarDeshabilitar}
            onCancel={handleCancelModalDeshabilitar}
            onConfirm={handleConfirmModalDeshabilitar}
            message="¿Esta seguro de deshabilitar este checklist?"
          />
          <ModalConfirmar
            isOpen={modalConfirmarHabilitar}
            onCancel={handleCancelModalHabilitar}
            onConfirm={handleConfirmModalHabilitar}
            message="¿Esta seguro de habilitar este checklist?"
          />
          <ModalConfiguracionGrilla
            modalConfiguracionVisible={modalConfiguracionVisible}
            onHideModalConfiguracion={onHideModalConfiguracion}
            ascendente={ascendente}
            descendente={descendente}
            setAscendente={setAscendente}
            setDescendente={setDescendente}
            gridHeaders={gridHeaders}
            setGridHeaders={setGridHeaders}
            onClickAcceptarOrdenGrillaModalConfiguracion={
              onClickAcceptarOrdenGrillaModalConfiguracion
            }
            onClickAcceptarHeadersGrillaModalConfiguracion={
              onClickAcceptarHeadersGrillaModalConfiguracion
            }
            onClickAcceptarFontSizeModalConfiguracion={
              onClickAcceptarFontSizeModalConfiguracion
            }
            fontSize={fontSizeValue}
          />
        </div>
      );
    } catch (err) {
      console.log("index render err");
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  };

  return render();

  // return (
  //   <div>
  //     <Grid rows={data} headers={headers} />
  //     <div>
  //       <button onClick={handlePrevPage}>Previous</button>
  //       <button onClick={handleNextPage}>Next</button>
  //     </div>
  //   </div>
  // );
}

import moment from "moment/moment";
import { SUCCESS } from "../page/const/http-const";
import { getHeaders } from "./utils";

export async function obtenerListaSeguimiento(obj) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/seguimiento/obtener-lista-seguimiento/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaSeguimiento err");
        console.log(err);
        return [];
    }
}

export async function obtenerItinerariosSeguimiento(obj) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/seguimiento/obtener-itinerarios-seguimiento/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerItinerariosSeguimiento err");
        console.log(err);
        return [];
    }
}

import { useState } from "react";
import { useHistory } from "react-router-dom";
import { login as verifyLogin } from "../../api/autenticacion";
import "./Login.css";
import spinner from "./spinner4.svg";
import logo from "./logo.png";

export default function Login() {
  const [istrue, setIsTrue] = useState(false);
  const [usuario, setUsuario] = useState("");
  const [contrasenia, setContrasenia] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const nextPage = (page) => {
    try {
      history.push(`${page}`);
    } catch (err) {
      console.log("nextPage err");
      console.log(err);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    const { body, code } = await verifyLogin(usuario, contrasenia);
    // console.log(body);
    if (code === "SUCCESS") {
      // console.log("test");
      localStorage.setItem("accessToken", body.token);
      localStorage.setItem("tipo", body.idTipoUsuario);
      localStorage.setItem("idEmpresa", body.idEmpresa);
      history.push(`/`);
    }else{
      alert('Usuario o contrasena incorrecto');
    }
    // console.log(body);
  };

  return (
    <>
      <form onSubmit={submit}>
        <div className="fs f">
          <div className="login_box">
            <img id="logo" src={logo}></img>
            <span className="title">Plataforma de Despacho</span>
            <div className="login_fields">
              <div className="field_line">
                <span className="fieldname">Usuario:</span>
                <input
                  id="usuario_login"
                  value={usuario}
                  onChange={(e) => {
                    setUsuario(e.target.value);
                  }}
                  className="login_input"
                />
              </div>
              <div className="field_line">
                <span className="fieldname">Contraseña:</span>
                <input
                  id="password_login"
                  value={contrasenia}
                  onChange={(e) => {
                    setContrasenia(e.target.value);
                  }}
                  className="login_input"
                  type="password"
                />
              </div>
              {errorMsg == "" && (
                <span className="error_msg fade">{errorMsg}</span>
              )}
              <div className="field_line">
                {!loading ? (
                  <button type="onSubmit" className="login_button">
                    Ingresar
                  </button>
                ) : (
                  <button className="login_button">
                    <img className="spinner" src={spinner} />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { ChevronRightIcon, ClockIcon } from "@heroicons/react/24/outline";
const DetailsCard = ({
  showDetails,
  tiempoManejado,
  kmRecorrido,
  kmRestante,
}) => {
  return (
    <>
      {showDetails && (
        <div>
          <div className="flex justify-between">
            <p>Tiempo Manejado</p>
            <div className="flex">
              <ClockIcon className="text-green-500 h-5 w-5 m-auto mr-[5px]" />
              <p>{tiempoManejado}</p>
            </div>
          </div>
          <div className="flex  justify-between">
            <p>Tiempo Detenido</p>
            <div className="flex">
              <ClockIcon className="text-red-500 h-5 w-5 m-auto mr-[5px]" />
              <p>0:00</p>
            </div>
          </div>
          {/*<div className="flex  justify-between">
                          <p>Tiempo Restante de Operacion</p>
                          <div className="flex">
                            <ClockIcon className="text-yellow-500 h-5 w-5 m-auto mr-[5px]" />
                            <p>0:00</p>
                          </div>
                        </div>*/}
          <div className="flex  justify-between">
            <p>Kilometros Recorridos</p>
            <div>
              <p>{`${kmRecorrido} km`}</p>
            </div>
          </div>
          <div className="flex  justify-between">
            <p>Kilometros Restante</p>
            <div>
              <p>
                {`${
                  kmRestante - kmRecorrido > 0 ? kmRestante - kmRecorrido : 0
                }`}{" "}
                Km.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default DetailsCard;

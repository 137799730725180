const ModalInfoUnidadIncidente = ({ isOpen, onAccept, seguimiento }) => {

    const abrirUbicacionGoogleMaps = () => {
        const url = `https://www.google.com/maps?q=${seguimiento.ubicacionActual.LATITUD},${seguimiento.ubicacionActual.LONGITUD}&z=15`;
        window.open(url, '_blank');
    };

    return (
        <>
            {isOpen && (
                <div className="fixed z-50 top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                    {/* background */}
                    <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
                    {/* end background */}
                    <div className="bg-white w-11/12 h-3/6 md:max-w-3xl lg:max-w-3xl mx-auto rounded shadow-lg z-50 overflow-y-auto flex flex-col">
                        <div className="pt-4 text-left px-6">
                            <div className="mb-4">
                                <p className="text-xl">{'ESTADO ACTUAL ' + seguimiento.unidad}</p>
                                <div className="flex mt-4">
                                    <div className="w-1/2 pr-2">
                                        <label className="block mb-2 font-bold">GRUPO</label>
                                        <input
                                            type="text"
                                            value={seguimiento.ubicacionActual !== undefined ? seguimiento.ubicacionActual.grupo : ""}
                                            className="p-2 border border-gray-300 rounded"
                                            readOnly
                                        />
                                    </div>
                                    <div className="w-1/2 pr-2">
                                        <label className="block mb-2 font-bold">SUB GRUPO</label>
                                        <input
                                            type="text"
                                            value={seguimiento.ubicacionActual !== undefined ? seguimiento.ubicacionActual.subgrupo : ""}
                                            className="p-2 border border-gray-300 rounded"
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="flex mt-4">
                                    <div className="w-1/2 pr-2">
                                        <label className="block mb-2 font-bold">LATITUD</label>
                                        <input
                                            type="text"
                                            value={seguimiento.ubicacionActual !== undefined ? seguimiento.ubicacionActual.LATITUD : ""}
                                            className="p-2 border border-gray-300 rounded"
                                            readOnly
                                        />
                                    </div>
                                    <div className="w-1/2 pr-2">
                                        <label className="block mb-2 font-bold">LONGITUD</label>
                                        <input
                                            type="text"
                                            value={seguimiento.ubicacionActual !== undefined ? seguimiento.ubicacionActual.LONGITUD : ""}
                                            className="p-2 border border-gray-300 rounded"
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col mt-4 ml-6 mr-6">
                            <div className="w-full pr-2">
                                <label className="block mb-2 font-bold">VER EN GOOGLE MAPS</label>
                                <input
                                    type="text"
                                    value={`https://www.google.com/maps?q=${seguimiento.ubicacionActual.LATITUD},${seguimiento.ubicacionActual.LONGITUD}&z=15`}
                                    className="w-full p-2 border border-gray-300 rounded cursor-pointer"
                                    readOnly
                                    onClick={abrirUbicacionGoogleMaps}
                                />
                            </div>
                        </div>
                        <div className="flex justify-end pt-20 pb-4 pr-4">
                            <button
                                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 cursor-pointer"
                                onClick={onAccept}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ModalInfoUnidadIncidente;
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ESTILO_BOTON_GENERAL_ACEPTAR, ESTILO_BOTON_GENERAL_CANCELAR, ESTILO_BOTON_LISTAR_ATRAS } from "../../utils/const-estilos";
import { crearUsuarioMovil, modificarUsuarioMovil, obtenerListaUsuariosMovil, } from "../../api/usuario/usuario";
import Select from "react-select";
import { obtenerComboGeocerca, obtenerInfoConductorConNumeroPlaca, obtenerTodasLasUnidades } from "../../api/gestion-dts/gestion";
import { obtenerConcepto } from "../../api/checklist";
import { INT_GRUPO_TIPO_USUARIO_MOVIL, INT_TIPO_USUARIO_CHOFER_MOVIL, INT_TIPO_USUARIO_SUPERVISOR_MOVIL } from "../../utils/const-int";
import "../../style/style-cargando.css";

export default function UsuarioMovil() {
  const location = useLocation();
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(false);
  const [usuario, setUsuario] = useState(null);
  const [titulo, setTitulo] = useState("CREAR USUARIO MOVIL");
  const [listaUnidades, setListaUnidades] = useState([]);
  const [unidadesSelected, setUnidadSelected] = useState(null);
  const [listaTipoUsuarioMovil, setListaTipoUsuarioMovil] = useState([]);
  const [tipoUsuarioSelected, setTipoUsuarioSelected] = useState(null);

  const [listaGeocercas, setListaGeocercas] = useState([]);
  const [geocercaSelected, setGeocercaSelected] = useState(null);

  useEffect(() => {
    console.log("entro a UsuarioMovil");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      const data = location.state;
      console.log("data");
      console.log(data);
      if (data !== undefined && data !== null) {
        setUsuario(data.params);
        setTitulo(data.titulo);

        const listaGeo = await obtenerComboGeocerca({});
        for (let i = 0; i < listaGeo.length; i++) {
          listaGeo[i].label = listaGeo[i].codigo + " - " + listaGeo[i].label;
        }
        // console.log("listaGeo");
        // console.log(listaGeo);
        setListaGeocercas(listaGeo);

        let todasLasUnidades = await obtenerTodasLasUnidades();
        // console.log('todasLasUnidades');
        // console.log(todasLasUnidades);
        let tipoUsuario = await obtenerConcepto(INT_GRUPO_TIPO_USUARIO_MOVIL)
        // console.log('tipoUsuario');
        // console.log(tipoUsuario);
        setListaTipoUsuarioMovil(tipoUsuario);
        let listaUsuarioMovil = await obtenerListaUsuariosMovil();
        // console.log('listaUsuarioMovil');
        // console.log(listaUsuarioMovil);
        const unidadesFiltradas = todasLasUnidades.filter(objA => !listaUsuarioMovil.some(objB => objB.unidad === objA.value));
        setListaUnidades(unidadesFiltradas);
        if (data.params.idtipousuario !== null) {
          let tipoU = tipoUsuario.find(e => e.value === data.params.idtipousuario);
          if (tipoU !== undefined) {
            setTipoUsuarioSelected(tipoU);
          }
        }
        if (data.params.idtipousuario === INT_TIPO_USUARIO_CHOFER_MOVIL && data.params.unidad !== null) {
          let unidad = todasLasUnidades.find(e => String(e.value).trim() === String(data.params.unidad).trim());
          // console.log('unidad');
          // console.log(unidad);
          if (unidad !== undefined) {
            setUnidadSelected(unidad);
          }
        }
        if (data.params.idGeocerca !== null && String(data.params.idGeocerca).trim().length > 0) {
          const geocerca = listaGeo.find(e => e.id === data.params.idGeocerca);
          if (geocerca !== undefined) {
            setGeocercaSelected(geocerca);
          }
        }
      }
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const handleInputChange = (event) => {
    // console.log('handleInputChange');
    const { name, value } = event.target;
    // console.log(name);
    // console.log(value);
    setUsuario({ ...usuario, [name]: value });
  };

  const handleCancelClick = () => {
    history.goBack();
  };

  const handleAceptarClick = async () => {
    try {
      // console.log('handleAceptarClick');
      // lógica para guardar los cambios
      // console.log('usuario');
      // console.log(usuario);
      let valido = await validarUsuario();
      // console.log('valido');
      // console.log(valido);
      if (valido === false) {
        return;
      }
      let objEnviar = {};
      objEnviar.nombre = usuario.nombre;
      objEnviar.apellidoPaterno = usuario.apellidopaterno;
      objEnviar.apellidoMaterno = usuario.apellidomaterno;
      objEnviar.numeroLicenciaConducir = usuario.numerolicenciaconducir;
      objEnviar.usuario = usuario.usuario;
      objEnviar.contrasena = usuario.contrasena;
      if (tipoUsuarioSelected.value === INT_TIPO_USUARIO_CHOFER_MOVIL) {
        objEnviar.unidad = unidadesSelected.value;
        objEnviar.nombreUnidad = unidadesSelected.label;
      }
      if (tipoUsuarioSelected.value !== INT_TIPO_USUARIO_CHOFER_MOVIL) {
        objEnviar.unidad = "";
        objEnviar.nombreUnidad = "";
      }
      objEnviar.nota = usuario.nota;
      objEnviar.telefono = usuario.telefono;
      objEnviar.id = usuario.id;
      objEnviar.tipoUsuario = tipoUsuarioSelected.value;
      objEnviar.idGeocerca = null;
      objEnviar.codigoGeocerca = "";
      objEnviar.nombreGeocerca = "";
      if (geocercaSelected !== null) {
        objEnviar.idGeocerca = geocercaSelected.value;
        objEnviar.codigoGeocerca = geocercaSelected.codigo;
        objEnviar.nombreGeocerca = geocercaSelected.nombre;
      }
      if (usuario.id <= 0) {
        // console.log('crear correo');
        let creado = await crearUsuarioMovil(objEnviar);
        // console.log('creado');
        // console.log(creado);
        if (creado === true) {
          alert('Se creo correctamente');
          history.goBack();
        }
        if (creado === false) {
          alert('No se pudo crear');
        }
      }
      if (usuario.id > 0) {
        // console.log('actualizar correo');
        let modificado = await modificarUsuarioMovil(objEnviar);
        // console.log('modificado');
        // console.log(modificado);
        if (modificado === true) {
          alert('Se modifico correctamente');
          history.goBack();
        }
        if (modificado === false) {
          alert('No se pudo modificar');
        }
      }
    } catch (err) {
      console.log('handleAceptarClick err');
      console.log(err);
      alert('No se pudo continuar, por favor revise los campos');
    }
  };

  const validarUsuario = async () => {
    try {
      let error = '';
      if (tipoUsuarioSelected === undefined || tipoUsuarioSelected === null || tipoUsuarioSelected.length === 0) {
        error = error + 'Necesita seleccionar tipo usuario\n';
      }
      if (tipoUsuarioSelected.value === INT_TIPO_USUARIO_CHOFER_MOVIL) {
        if (unidadesSelected === undefined || unidadesSelected === null || unidadesSelected.length === 0) {
          error = error + 'Necesita seleccionar unidad\n';
        }
      }
      if (usuario === undefined || usuario === null || usuario.nombre === undefined === usuario.nombre === null || String(usuario.nombre).length === 0) {
        error = error + 'Necesita ingresar nombre\n';
      }
      if (usuario.apellidopaterno === undefined === usuario.apellidopaterno === null || String(usuario.apellidopaterno).length === 0) {
        error = error + 'Necesita ingresar apellido\n';
      }
      if (usuario.apellidomaterno === undefined === usuario.apellidomaterno === null) {
        usuario.apellidomaterno = '';
      }
      if (tipoUsuarioSelected.value === INT_TIPO_USUARIO_CHOFER_MOVIL) {
        if (usuario.numerolicenciaconducir === undefined || usuario.numerolicenciaconducir === null) {
          usuario.numerolicenciaconducir = '';
        }
      }
      if (tipoUsuarioSelected.value !== INT_TIPO_USUARIO_CHOFER_MOVIL) {
        usuario.numeroLicenciaConducir = "";
      }
      if (usuario.usuario === undefined === usuario.usuario === null || String(usuario.usuario).length === 0) {
        error = error + 'Necesita ingresar usuario\n';
      }
      if (usuario.contrasena === undefined === usuario.contrasena === null || String(usuario.contrasena).length === 0) {
        error = error + 'Necesita ingresar contraseña\n';
      }
      if (usuario.nota === undefined || usuario.nota === null) {
        usuario.nota = '';
      }
      if (usuario.telefono === undefined || usuario.telefono === null) {
        usuario.telefono = '';
      }
      if (String(error).length === 0) {
        return true;
      }
      if (String(error).length > 0) {
        alert(error);
      }
      return false;
    } catch (err) {
      console.log('validarUsuario err');
      console.log(err);
      return false;
    }
  }

  const handleAtrasClick = () => {
    history.goBack();
  }

  const handleUnidadSelected = async (event) => {
    try {
      if (event !== null) {
        // console.log('event');
        // console.log(event);
        let obj = {};
        obj.idActivo = event.value;
        let infoConductor = await obtenerInfoConductorConNumeroPlaca(obj);
        // console.log('infoConductor');
        // console.log(infoConductor);
        if (infoConductor !== null) {
          setUsuario(prevUsuario => ({
            ...prevUsuario,
            nombre: infoConductor.nombre || "",
            apellidopaterno: infoConductor.apellidoPaterno || "",
            apellidomaterno: infoConductor.apellidoMaterno || "",
            numerolicenciaconducir: infoConductor.nroLicConducir || "",
            telefono: infoConductor.nroCelPersonal || ""
          }));
        }
        if (infoConductor === null) {
          resetInfoUsuario();
        }

      }
      setUnidadSelected(event);
    } catch (err) {
      console.log('handleUnidadSelected err');
      console.log(err);
    }
  }

  const handleGeocercaSelected = (event) => {
    setGeocercaSelected(event);
  }

  const handleTipoUsuarioSelected = async (event) => {
    try {
      setTipoUsuarioSelected(event);
      resetInfoUsuario();
      setUnidadSelected(null);
    } catch (err) {
      console.log('handleTipoUsuarioSelected err');
      console.log(err);
    }
  }

  const resetInfoUsuario = () => {
    const camposAResetear = [
      'nombre',
      'apellidopaterno',
      'apellidomaterno',
      'numerolicenciaconducir',
      'telefono'
    ];

    setUsuario(prevUsuario =>
      camposAResetear.reduce((nuevoUsuario, campo) => {
        nuevoUsuario[campo] = '';
        return nuevoUsuario;
      }, { ...prevUsuario })
    );
  }

  const render = () => {
    // console.log('render correo');
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (cargandoPage === false && usuario === null) {
        return (
          <div>
            <label> Sin data</label>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <label
              style={{
                paddingLeft: 4,
                fontWeight: 'bold',
              }}
            >
              {titulo}
            </label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <hr />
          <br />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="unidad" className="mb-2 font-bold">
                  TIPO USUARIO
                </label>
                <Select options={listaTipoUsuarioMovil} isSearchable='true'
                  isClearable='true'
                  value={tipoUsuarioSelected}
                  onChange={(event) => handleTipoUsuarioSelected(event)}
                  placeholder='SELECCIONE TIPO USUARIO'
                />
              </div>
            </div>
            {tipoUsuarioSelected !== null && tipoUsuarioSelected.value === INT_TIPO_USUARIO_CHOFER_MOVIL &&
              <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
                <div
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <label htmlFor="unidad" className="mb-2 font-bold">
                    UNIDAD
                  </label>
                  <Select options={listaUnidades} isSearchable='true'
                    isClearable='true'
                    value={unidadesSelected}
                    onChange={(event) => handleUnidadSelected(event)}
                    placeholder='SELECCIONE UNIDAD'
                  />
                </div>
              </div>
            }
            {tipoUsuarioSelected !== null && tipoUsuarioSelected.value === INT_TIPO_USUARIO_SUPERVISOR_MOVIL &&
              <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
                <div
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <label htmlFor="geocerca" className="mb-2 font-bold">
                    GEOCERCA
                  </label>
                  <Select options={listaGeocercas} isSearchable='true'
                    isClearable='true'
                    value={geocercaSelected}
                    onChange={(event) => handleGeocercaSelected(event)}
                    placeholder='SELECCIONE GEOCERCA'
                  />
                </div>
              </div>
            }
          </div>
          <br />
          <hr />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="nombre" className="mb-2 font-bold">
                  NOMBRE
                </label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  value={usuario.nombre}
                  onChange={handleInputChange}
                  placeholder="NOMBRE"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={100}
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="apellido" className="mb-2 font-bold">
                  APELLIDO PATERNO
                </label>
                <input
                  type="text"
                  id="apellidopaterno"
                  name="apellidopaterno"
                  value={usuario.apellidopaterno}
                  onChange={handleInputChange}
                  placeholder="APELLIDO PATERNO"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={100}
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="apellido" className="mb-2 font-bold">
                  APELLIDO MATERNO
                </label>
                <input
                  type="text"
                  id="apellidomaterno"
                  name="apellidomaterno"
                  value={usuario.apellidomaterno}
                  onChange={handleInputChange}
                  placeholder="APELLIDO MATERNO"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={100}
                />
              </div>
            </div>
            {tipoUsuarioSelected !== null && tipoUsuarioSelected.value === INT_TIPO_USUARIO_CHOFER_MOVIL ?
              <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
                <div
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <label htmlFor="numerolicenciaconducir" className="mb-2 font-bold">
                    NUM. LIC. CONDUCIR
                  </label>
                  <input
                    type="text"
                    id="numerolicenciaconducir"
                    name="numerolicenciaconducir"
                    value={usuario.numerolicenciaconducir}
                    onChange={handleInputChange}
                    placeholder="LIC. CONDUCIR"
                    className="p-2 border border-gray-300 rounded"
                    maxLength={45}
                  />
                </div>
              </div>
              :
              null
            }
          </div>
          <br />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="telefono" className="mb-2 font-bold">
                  TELEFONO
                </label>
                <input
                  type="text"
                  id="telefono"
                  name="telefono"
                  value={usuario.telefono}
                  onChange={handleInputChange}
                  placeholder="TELEFONO"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={45}
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">

              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="usuario" className="mb-2 font-bold">
                  USUARIO
                </label>
                <input
                  type="text"
                  id="usuario"
                  name="usuario"
                  value={usuario.usuario}
                  onChange={handleInputChange}
                  placeholder="USUARIO"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={45}
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="contrasena" className="mb-2 font-bold">
                  CONTRASEÑA
                </label>
                <input
                  type="text"
                  id="contrasena"
                  name="contrasena"
                  value={usuario.contrasena}
                  onChange={handleInputChange}
                  placeholder="CONTRASEÑA"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={45}
                />
              </div>
            </div>

          </div>
          <br />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="nota" className="mb-2 font-bold">
                  NOTA
                </label>
                <textarea
                  type="text"
                  id="nota"
                  name="nota"
                  value={usuario.nota}
                  onChange={handleInputChange}
                  rows={3}
                  placeholder="NOTA"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={512}
                />
              </div>
            </div>

          </div>
          <br />
          <div className="flex justify-between p-4">
            <button className={ESTILO_BOTON_GENERAL_CANCELAR}
              onClick={handleCancelClick}
            >
              CANCELAR
            </button>
            <button className={ESTILO_BOTON_GENERAL_ACEPTAR}
              onClick={handleAceptarClick}
            >
              ACEPTAR
            </button>
          </div>
        </div>
      );
    } catch (err) {
      console.log('UsuarioMovil render err');
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  }

  return render();
}

import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
// import { Settings } from "feather-icons-react/build/IconComponents";
import { ESTILO_BOTON_LISTAR_ATRAS } from "../../utils/const-estilos";
import Grid from "../../components/grid";
import { INT_FONT_SIZE } from "../../utils/const-int";
import { STRING_CENTER } from "../../utils/const-string";
// import ModalConfirmar from "../../components/modal-confirmar";
// import ModalConfiguracionGrilla from "../../components/modal-configuracion-grilla";
// import ModalLoading from "../../components/modal-loading";

export default function CumplimientoDescarga() {
  let headers = [
    { key: "id", title: "ID", visible: false },
    {
      key: "llegoATiempo",
      title: "LLEGO A TIEMPO",
      visible: true,
      filter: true,
    },
    {
      key: "bultos",
      title: "BULTOS",
      visible: true,
      filter: true,
    },
    {
      key: "codigoDestino",
      title: "CODIGO PLANTA/GEOCERCA DESTINO",
      visible: true,
      filter: true,
    },
    {
      key: "codigoOrigen",
      title: "CODIGO PLANTA/GEOCERCA ORIGEN",
      visible: true,
      filter: true,
    },
    {
      key: "codigoProducto",
      title: "CODIGO PRODUCTO",
      visible: true,
      filter: true,
    },
    {
      key: "codigoUnidad",
      title: "CODIGO UNIDAD",
      visible: true,
      filter: true,
    },
    {
      key: "depositoDestino",
      title: "PLANTA/GEOCERCA DESTINO",
      visible: true,
      filter: true,
    },
    {
      key: "depositoOrigen",
      title: "PLANTA/GEOCERCA ORIGEN",
      visible: true,
      filter: true,
    },
    {
      key: "estado",
      title: "ESTADO",
      visible: true,
      filter: true,
    },
    {
      key: "eta",
      title: "ETA",
      visible: true,
      filter: true,
    },
    {
      key: "fechaEntrada",
      title: "FECHA ENTRADA",
      visible: true,
      filter: true,
    },
    {
      key: "fechaSalida",
      title: "FECHA SALIDA",
      visible: true,
      filter: true,
    },
    {
      key: "nombreFletero",
      title: "NOMBRE FLETERO",
      visible: true,
      filter: true,
    },
    {
      key: "observacionEta",
      title: "OBSERVACION ETA",
      visible: true,
      filter: true,
    },
    {
      key: "observacionPlanDescarga",
      title: "OBSERVACION PLAN DESCARGA",
      visible: true,
      filter: true,
    },
    {
      key: "placa",
      title: "PLACA",
      visible: true,
      filter: true,
    },
    {
      key: "planDescarga",
      title: "PLAN DESCARGA",
      visible: true,
      filter: true,
    },
    {
      key: "planilla",
      title: "PLANILLA",
      visible: true,
      filter: true,
    },
    {
      key: "productoNombre",
      title: "PRODUCTO NOMBRE",
      visible: true,
      filter: true,
    },
    {
      key: "referencia",
      title: "REFERENCIA",
      visible: true,
      filter: true,
    },
    {
      key: "tipoProducto",
      title: "TIPO PRODUCTO",
      visible: true,
      filter: true,
    },
    {
      key: "tipoViaje",
      title: "TIPO VIAJE",
      visible: true,
      filter: true,
    },
  ];
  //   let _counter = 0;
  const history = useHistory();
  const location = useLocation();
  //   const [routeGoBackDefault, setRouteGoBackDefault] = useState(null);
  const [listaDescarga, setListaDescarga] = useState([]);

  const [modalConfiguracionVisible, setModalConfiguracionVisible] =
    useState(false);
  const [configuracionOrden, setConfiguracionOrden] = useState(null);
  const [ascendente, setAscendente] = useState(false);
  const [descendente, setDescendente] = useState(false);
  const [gridHeaders, setGridHeaders] = useState(headers);
  const [fontSizeValue, setFontSizeValue] = useState(INT_FONT_SIZE);
  const [alignValue, setAlignValue] = useState(STRING_CENTER);

  useEffect(() => {
    const params = location.state;
    // console.log("params");
    // console.log(params);
    if (params === undefined) {
    }
    if (params !== undefined) {
      let descargas = params.descargas;
      for (let i = 0; i < descargas.length; i++) {
        if (descargas[i].llegoATiempo === "true") {
          console.log("llego a tiempo es un string");
        }
        if (
          descargas[i].llegoATiempo === "true" ||
          descargas[i].llegoATiempo === "SI"
        ) {
          descargas[i].llegoATiempo = "SI";
        } else {
          descargas[i].llegoATiempo = "NO";
        }
      }
      setListaDescarga(descargas);
    }
  }, [location.state]);

  const goBack = () => {
    history.goBack();
  };

  const openModalCondiguracion = () => {
    setModalConfiguracionVisible(true);
  };

  const onHideModalConfiguracion = () => {
    setModalConfiguracionVisible(false);
  };

  const onClickAcceptarOrdenGrillaModalConfiguracion = async () => {
    try {
      /*let objConfiguracionEnviar = {};
        let objOrden = {};
        objOrden.descendente = descendente;
        objOrden.ascendente = ascendente;
        objConfiguracionEnviar.nombre = GESTIONAR_DISPONIBILIDAD_SCREEN;
        objConfiguracionEnviar.orden = objOrden;
        objConfiguracionEnviar.headers = gridHeaders;
        console.log("objConfiguracionEnviar");
        console.log(objConfiguracionEnviar);
        if (configuracionOrden === null) {
          const guardo = await guardarConfiguracionScreen(
            objConfiguracionEnviar
          );
          if (guardo === true) {
            reset();
            alert("Se guardo correctamente");
            onHideModalConfiguracion();
          }
          if (guardo === false) {
            alert("No se pudo realizar operacion");
          }
        }
        if (configuracionOrden !== null) {
          objConfiguracionEnviar.id = configuracionOrden.id;
          const actualizo = await actualizarOrdenGestionarDisponibilidadScreen(
            objConfiguracionEnviar
          );
          if (actualizo === true) {
            reset();
            alert("Se actualizo correctamente");
            onHideModalConfiguracion();
          }
          if (actualizo === false) {
            alert("No se pudo realizar operacion");
          }
        }*/
    } catch (err) {
      console.log("onClickAcceptarOrdenGrillaModalConfiguracion err");
      console.log(err);
    }
  };

  const onClickAcceptarHeadersGrillaModalConfiguracion = async () => {
    try {
      console.log("onClickAcceptarHeadersGrillaModalConfiguracion");
      /*let objConfiguracionEnviar = {};
        let objOrden = {};
        objOrden.descendente = descendente;
        objOrden.ascendente = ascendente;
        objConfiguracionEnviar.nombre = GESTIONAR_DISPONIBILIDAD_SCREEN;
        objConfiguracionEnviar.orden = objOrden;
        console.log("gridHeaders");
        console.log(gridHeaders);
        console.log("headers");
        console.log(headers);
        objConfiguracionEnviar.headers = gridHeaders;
        console.log("objConfiguracionEnviar");
        console.log(objConfiguracionEnviar);
        if (configuracionOrden === null) {
          const guardo = await guardarConfiguracionScreen(
            objConfiguracionEnviar
          );
          if (guardo === true) {
            reset();
            alert("Se guardo correctamente");
            onHideModalConfiguracion();
          }
          if (guardo === false) {
            alert("No se pudo realizar operacion");
          }
        }
        if (configuracionOrden !== null) {
          objConfiguracionEnviar.id = configuracionOrden.id;
          const actualizo =
            await actualizarHeadersGestionarDisponibilidadScreen(
              objConfiguracionEnviar
            );
          if (actualizo === true) {
            reset();
            alert("Se actualizo correctamente");
            onHideModalConfiguracion();
          }
          if (actualizo === false) {
            alert("No se pudo realizar operacion");
          }
        }*/
    } catch (err) {
      console.log("onClickAcceptarHeadersGrillaModalConfiguracion err");
      console.log(err);
    }
  };

  const onClickAcceptarFontSizeModalConfiguracion = async (fontSizeValue) => {
    try {
      /*  let objConfiguracionEnviar = {};
        let objOrden = {};
        objOrden.descendente = descendente;
        objOrden.ascendente = ascendente;
        objConfiguracionEnviar.nombre = GESTIONAR_DISPONIBILIDAD_SCREEN;
        objConfiguracionEnviar.orden = objOrden;
        objConfiguracionEnviar.headers = gridHeaders;
        objConfiguracionEnviar.fontSize = fontSizeValue;
        // console.log('configuracionOrden');
        // console.log(configuracionOrden);
        // return;
        if (configuracionOrden === null) {
          const guardo = await guardarConfiguracionScreen(
            objConfiguracionEnviar
          );
          if (guardo === true) {
            reset();
            alert("Se guardo correctamente");
            onHideModalConfiguracion();
          }
          if (guardo === false) {
            alert("No se pudo realizar operacion");
          }
        }
        if (configuracionOrden !== null) {
          objConfiguracionEnviar.id = configuracionOrden.id;
          const actualizo = await actualizarFontSizeGrilla(
            objConfiguracionEnviar
          );
          if (actualizo === true) {
            reset();
            alert("Se actualizo correctamente");
            onHideModalConfiguracion();
          }
          if (actualizo === false) {
            alert("No se pudo realizar operacion");
          }
        }*/
    } catch (err) {
      console.log("onClickAcceptarFontSizeModalConfiguracion err");
      console.log(err);
    }
  };

  const onClickAcceptarAlignModalConfiguracion = async (align) => {
    try {
      /*let objConfiguracionEnviar = {};
        let objOrden = {};
        objOrden.descendente = descendente;
        objOrden.ascendente = ascendente;
        objConfiguracionEnviar.nombre = GESTIONAR_DISPONIBILIDAD_SCREEN;
        objConfiguracionEnviar.orden = objOrden;
        objConfiguracionEnviar.headers = gridHeaders;
        objConfiguracionEnviar.fontSize = fontSizeValue;
        objConfiguracionEnviar.align = align;
        // console.log('objConfiguracionEnviar');
        // console.log(objConfiguracionEnviar);
        // return;
        if (configuracionOrden === null) {
          const guardo = await guardarConfiguracionScreen(
            objConfiguracionEnviar
          );
          if (guardo === true) {
            reset();
            alert("Se guardo correctamente");
            onHideModalConfiguracion();
          }
          if (guardo === false) {
            alert("No se pudo realizar operacion");
          }
        }
        if (configuracionOrden !== null) {
          objConfiguracionEnviar.id = configuracionOrden.id;
          const actualizo = await actualizarAlignGrilla(objConfiguracionEnviar);
          if (actualizo === true) {
            reset();
            alert("Se actualizo correctamente");
            onHideModalConfiguracion();
          }
          if (actualizo === false) {
            alert("No se pudo realizar operacion");
          }
        }*/
    } catch (err) {
      console.log("onClickAcceptarAlignModalConfiguracion err");
      console.log(err);
    }
  };

  const render = () => {
    try {
      return (
        <div>
          <div className="pt-5 pb-5 pr-10 flex justify-between">
            <label
              style={{
                paddingLeft: 10,
                fontWeight: "bold",
              }}
            >
              CUMPLIMIENTO DESCARGA
            </label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={goBack}
            >
              ATRAS
            </button>
          </div>
          {/* <div className="pt-5 pb-5 pr-10 pl-10 flex justify-end items-center">
            <div className="pt-5 pb-5 pr-10 pl-10 flex justify-end items-center">
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_ATRAS}
                onClick={openModalCondiguracion}
              >
                <Settings />
              </button>
            </div>
          </div> */}
          <Grid
            rows={listaDescarga}
            headers={gridHeaders}
            exportar={false}
            fontSize={fontSizeValue}
            align={alignValue}
          />
          {/* <ModalConfiguracionGrilla
            modalConfiguracionVisible={modalConfiguracionVisible}
            onHideModalConfiguracion={onHideModalConfiguracion}
            ascendente={ascendente}
            descendente={descendente}
            setAscendente={setAscendente}
            setDescendente={setDescendente}
            gridHeaders={gridHeaders}
            setGridHeaders={setGridHeaders}
            onClickAcceptarOrdenGrillaModalConfiguracion={
              onClickAcceptarOrdenGrillaModalConfiguracion
            }
            onClickAcceptarHeadersGrillaModalConfiguracion={
              onClickAcceptarHeadersGrillaModalConfiguracion
            }
            onClickAcceptarFontSizeModalConfiguracion={
              onClickAcceptarFontSizeModalConfiguracion
            }
            fontSize={fontSizeValue}
            align={alignValue}
            onClickAcceptarAlignModalConfiguracion={
              onClickAcceptarAlignModalConfiguracion
            }
          /> */}
          {/* <ModalLoading isOpen={showModalLoading} /> */}
        </div>
      );
    } catch (err) {
      console.log("CumplimientoDescarga render err");
      console.log(err);
      return (
        <div className=" w-1/2 m-auto px-[2rem] ">
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  };

  return render();
}

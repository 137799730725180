import { useState, useEffect } from "react";
import Select from "react-select";
import readXlsxFile from "read-excel-file";
import {
  guardarConfiguracion,
  obtenerConfiguracion,
  modificarConfiguracion,
} from "../../api/cargas";

export default function Configuracion() {
  const [loading, setLoading] = useState(false);
  const [excelValue, setExcelValue] = useState(null);
  const [idConfiguracion, setIdConfiguracion] = useState(null);
  const [listColumnsExcel, setListColumnsExcel] = useState([]);
  const [data, setData] = useState({
    indexFecha: null,
    indexCodigoPlanta: null,
    indexPlanta: null,
    indexNroViaje: null,
    indexCodigoFletero: null,
    indexCodigoUnidad: null,
    indexOperadorLogistico: null,
    indexChofer: null,
    indexPlaca: null,
    indexTipoCamion: null,
    indexEstadoCamion: null,
    indexPrioridad: null,
    indexCodigoOrigen: null,
    indexOrigen: null,
    indexCodigoProducto: null,
    indexProducto: null,
    indexTipoProducto: null,
    indexPallets: null,
    indexCodigoDestino: null,
    indexDestino: null,
    indexBultos: null,
    indexHl: null,
    indexPesoCarga: null,
    indexPesoPallets: null,
    indexPesoTotalCarga: null,
    indexComentarios: null,
    indexFechaHoraCarga: null,
    indexDockCarga: null,
    indexCodigoViaje: null,

    valorFecha: null,
    valorCodigoPlanta: null,
    valorPlanta: null,
    valorNroViaje: null,
    valorCodigoFleteros: null,
    valorCodigoUnidad: null,
    valorOperadorLogistico: null,
    valorChofer: null,
    valorPlaca: null,
    valorTipoCamion: null,
    valorEstadoCamion: null,
    valorPrioridad: null,
    valorCodigoOrigen: null,
    valorOrigen: null,
    valorCodigoProducto: null,
    valorProducto: null,
    valorTipoProducto: null,
    valorPallets: null,
    valorCodigoDestino: null,
    valorDestino: null,
    valorBultos: null,
    valorHl: null,
    valorPesoCarga: null,
    valorPesoPallets: null,
    valorPesoTotalCarga: null,
    valorComentarios: null,
    valorFechaHoraCarga: null,
    valorDockCarga: null,
    valorCodigoViaje: null,
  });

  const importar = () => {
    try {
      document.getElementById("fileUpload").click();
    } catch (err) {
      console.log("importarExcel err");
      console.log(err);
    }
  };

  const guardar = async () => {
    let resp;
    if (idConfiguracion === null) {
      resp = await guardarConfiguracion(data);
    } else {
      resp = await modificarConfiguracion({ ...data, id: idConfiguracion });
    }
    if (resp.code === "SUCCESS") {
      alert("Se guardo correctamente la configuracion");
    } else {
      alert("Sucedio un problema porfavor vuelva a intentar");
    }
  };
  const obtener = async () => {
    try {
      const resp = await obtenerConfiguracion();
      if (resp.code === "SUCCESS") {
        setIdConfiguracion(resp.body[0].id);
        const listColumnsExcelFormat = [
          JSON.parse(resp.body[0].valorFecha),
          JSON.parse(resp.body[0].valorCodigoPlanta),
          JSON.parse(resp.body[0].valorPlanta),
          JSON.parse(resp.body[0].valorNroViaje),
          JSON.parse(resp.body[0].valorCodigoFletero),
          JSON.parse(resp.body[0].valorCodigoUnidad),
          JSON.parse(resp.body[0].valorOperadorLogistico),
          JSON.parse(resp.body[0].valorChofer),
          JSON.parse(resp.body[0].valorPlaca),
          JSON.parse(resp.body[0].valorTipoCamion),
          JSON.parse(resp.body[0].valorEstadoCamion),
          JSON.parse(resp.body[0].valorPrioridad),
          JSON.parse(resp.body[0].valorCodigoOrigen),
          JSON.parse(resp.body[0].valorOrigen),
          JSON.parse(resp.body[0].valorCodigoProducto),
          JSON.parse(resp.body[0].valorProducto),
          JSON.parse(resp.body[0].valorTipoProducto),
          JSON.parse(resp.body[0].valorPallets),
          JSON.parse(resp.body[0].valorCodigoDestino),
          JSON.parse(resp.body[0].valorDestino),
          JSON.parse(resp.body[0].valorBultos),

          JSON.parse(resp.body[0].valorHl),
          JSON.parse(resp.body[0].valorPesoCarga),
          JSON.parse(resp.body[0].valorPesoPallets),
          JSON.parse(resp.body[0].valorPesoTotalCarga),
          JSON.parse(resp.body[0].valorComentarios),
          JSON.parse(resp.body[0].valorFechaHoraCarga),
          JSON.parse(resp.body[0].valorDockCarga),
          JSON.parse(resp.body[0].valorCodigoViaje),
        ];
        setListColumnsExcel(listColumnsExcelFormat);
        const formatData = {
          ...resp.body[0],
          valorFecha: JSON.parse(resp.body[0].valorFecha),
          valorCodigoPlanta: JSON.parse(resp.body[0].valorCodigoPlanta),
          valorPlanta: JSON.parse(resp.body[0].valorPlanta),
          valorNroViaje: JSON.parse(resp.body[0].valorNroViaje),
          valorCodigoFletero: JSON.parse(resp.body[0].valorCodigoFletero),
          valorCodigoUnidad: JSON.parse(resp.body[0].valorCodigoUnidad),
          valorOperadorLogistico: JSON.parse(
            resp.body[0].valorOperadorLogistico
          ),
          valorChofer: JSON.parse(resp.body[0].valorChofer),
          valorPlaca: JSON.parse(resp.body[0].valorPlaca),
          valorTipoCamion: JSON.parse(resp.body[0].valorTipoCamion),
          valorEstadoCamion: JSON.parse(resp.body[0].valorEstadoCamion),
          valorPrioridad: JSON.parse(resp.body[0].valorPrioridad),
          valorCodigoOrigen: JSON.parse(resp.body[0].valorCodigoOrigen),
          valorOrigen: JSON.parse(resp.body[0].valorOrigen),
          valorCodigoProducto: JSON.parse(resp.body[0].valorCodigoProducto),
          valorProducto: JSON.parse(resp.body[0].valorProducto),
          valorTipoProducto: JSON.parse(resp.body[0].valorTipoProducto),
          valorPallets: JSON.parse(resp.body[0].valorPallets),
          valorCodigoDestino: JSON.parse(resp.body[0].valorCodigoDestino),
          valorDestino: JSON.parse(resp.body[0].valorDestino),
          valorBultos: JSON.parse(resp.body[0].valorBultos),

          valorHl: JSON.parse(resp.body[0].valorHl),
          valorPesoCarga: JSON.parse(resp.body[0].valorPesoCarga),
          valorPesoPallets: JSON.parse(resp.body[0].valorPesoPallets),
          valorPesoTotalCarga: JSON.parse(resp.body[0].valorPesoTotalCarga),
          valorComentarios: JSON.parse(resp.body[0].valorComentarios),
          valorFechaHoraCarga: JSON.parse(resp.body[0].valorFechaHoraCarga),
          valorDockCarga: JSON.parse(resp.body[0].valorDockCarga),
          valorCodigoViaje: JSON.parse(resp.body[0].valorCodigoViaje),

          valorFechaJson: resp.body[0].valorFecha,
          valorCodigoPlantaJson: resp.body[0].valorCodigoPlanta,
          valorPlantaJson: resp.body[0].valorPlanta,
          valorNroViajeJson: resp.body[0].valorNroViaje,
          valorCodigoFleteroJson: resp.body[0].valorCodigoFletero,
          valorCodigoUnidadJson: resp.body[0].valorCodigoUnidad,
          valorOperadorLogisticoJson: resp.body[0].valorOperadorLogistico,
          valorChoferJson: resp.body[0].valorChofer,
          valorPlacaJson: resp.body[0].valorPlaca,
          valorTipoCamionJson: resp.body[0].valorTipoCamion,
          valorEstadoCamionJson: resp.body[0].valorEstadoCamion,
          valorPrioridadJson: resp.body[0].valorPrioridad,
          valorCodigoOrigenJson: resp.body[0].valorCodigoOrigen,
          valorOrigenJson: resp.body[0].valorOrigen,
          valorCodigoProductoJson: resp.body[0].valorCodigoProducto,
          valorProductoJson: resp.body[0].valorProducto,
          valorTipoProductoJson: resp.body[0].valorTipoProducto,
          valorPalletsJson: resp.body[0].valorPallets,
          valorCodigoDestinoJson: resp.body[0].valorCodigoDestino,
          valorDestinoJson: resp.body[0].valorDestino,
          valorBultosJson: resp.body[0].valorBultos,

          valorHlJson: resp.body[0].valorHl,
          valorPesoCargaJson: resp.body[0].valorPesoCarga,
          valorPesoPalletsJson: resp.body[0].valorPesoPallets,
          valorPesoTotalCargaJson: resp.body[0].valorPesoTotalCarga,
          valorComentariosJson: resp.body[0].valorComentarios,
          valorFechaHoraCargaJson: resp.body[0].valorFechaHoraCarga,
          valorDockCargaJson: resp.body[0].valorDockCarga,
          valorCodigoViajeJson: resp.body[0].valorCodigoViaje,
        };
        setData(formatData);
      } else {
      }
    } catch (e) {
      console.error(e.message);
    }
  };
  const reset = () => {
    try {
      setListColumnsExcel([]);
    } catch (err) {
      console.log("reset err");
      console.log(err);
    }
  };

  const procesarExcel = (rows) => {
    try {
      if (rows.length == 0) {
        return;
      }
      let comboExcel = [];
      let headers = rows[0];

      for (let i = 0; i < headers.length; i++) {
        const element = headers[i];
        let objColumna = {};
        objColumna.value = i;
        objColumna.label = i + " - " + element;
        comboExcel.push(objColumna);
      }
      comboExcel.sort((a, b) => a.value - b.value);
      comboExcel.push({ value: null, label: "Sin Asignar" });
      setListColumnsExcel(comboExcel);
    } catch (err) {
      console.log("procesarExcel err");
      console.log(err);
    }
  };
  useEffect(() => {
    try {
      const fileUpload = document.getElementById("fileUpload");
      fileUpload.addEventListener("change", () => {
        readXlsxFile(fileUpload.files[0]).then((rows) => {
          reset();
          procesarExcel(rows);
        });
      });
      obtener();
    } catch (e) {
      console.error(e.message);
    }
  }, []);
  return (
    <div className="p-[2rem]">
      <div className="flex">
        <div className="w-2/3">
          <h1 className="font-bold text-gray-700 text-[24px]">Configuracion</h1>
          <h3>Importar datos desde archivo Excel</h3>
        </div>
        <div className="flex w-1/3 justify-end">
          <input
            id="fileUpload"
            type="file"
            className="my-auto text-white p-[5px] px-[10px] mr-[10px] hidden"
          />
          <button
            className="my-auto bg-[#84B044] rounded text-white p-[5px] px-[10px] mr-[10px]"
            onClick={() => {
              importar();
            }}
          >
            IMPORTAR
          </button>
          <button
            className="my-auto bg-[#113A5F] rounded text-white p-[5px] px-[10px]"
            onClick={() => {
              guardar();
            }}
          >
            GUARDAR
          </button>
        </div>
      </div>
      <div className="flex justify-around mt-[15px]">
        <div className="border border-gray rounded w-1/2 mr-[10px] p-[10px]">
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">0 - Fecha</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">1 - Codigo Planta</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">2 - Planta</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">3 - Nro. Viaje</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">4 - Cod. Fletero</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">5 - Cod. Unidad</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">6 - Operador Logistico</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">7 - Chofer</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">8 - Placa</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">9 - Tipo Camion</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">10 - Estado Camion</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">11 - Prioridad</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">12 - Cod. Origen</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">13 - Origen</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">14 - Cod. Producto</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">15 - Producto</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">16 - Tipo Producto</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">17 - Pallets</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">18 - Cod. Destino</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">19 - Destino</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">20 - Bultos</p>
          </div>

          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">21 - HL</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">22 - Peso Carga</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">23 - Peso Pallets</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">24 - Peso Total Carga</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">25 - Comentarios</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">26 - Fecha Hora Carga</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">27 - Dock Carga</p>
          </div>
          <div className="border border-gray rounded w-full  my-[5px] h-[56px] flex px-[5px]">
            <p className="my-auto">28 - Cod. Viaje</p>
          </div>
        </div>
        <div className="border border-gray rounded w-1/2 ml-[10px] p-[10px]">
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorFecha ? data.valorFecha : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexFecha: e.value,
                    valorFecha: { value: e.value, label: e.label },
                    valorFechaJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorCodigoPlanta ? data.valorCodigoPlanta : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexCodigoPlanta: e.value,
                    valorCodigoPlanta: { value: e.value, label: e.label },
                    valorCodigoPlantaJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>

          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorPlanta ? data.valorPlanta : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexPlanta: e.value,
                    valorPlanta: { value: e.value, label: e.label },
                    valorPlantaJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorNroViaje ? data.valorNroViaje : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexNroViaje: e.value,
                    valorNroViaje: { value: e.value, label: e.label },
                    valorNroViajeJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorCodigoFletero ? data.valorCodigoFletero : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexCodigoFletero: e.value,
                    valorCodigoFletero: { value: e.value, label: e.label },
                    valorCodigoFleteroJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorCodigoUnidad ? data.valorCodigoUnidad : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexCodigoUnidad: e.value,
                    valorCodigoUnidad: { value: e.value, label: e.label },
                    valorCodigoUnidadJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={
                  data.valorOperadorLogistico
                    ? data.valorOperadorLogistico
                    : null
                }
                onChange={(e) => {
                  setData({
                    ...data,
                    indexOperadorLogistico: e.value,
                    valorOperadorLogistico: { value: e.value, label: e.label },
                    valorOperadorLogisticoJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorChofer ? data.valorChofer : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexChofer: e.value,
                    valorChofer: { value: e.value, label: e.label },
                    valorChoferJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorPlaca ? data.valorPlaca : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexPlaca: e.value,
                    valorPlaca: { value: e.value, label: e.label },
                    valorPlacaJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorTipoCamion ? data.valorTipoCamion : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexTipoCamion: e.value,
                    valorTipoCamion: { value: e.value, label: e.label },
                    valorTipoCamionJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorEstadoCamion ? data.valorEstadoCamion : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexEstadoCamion: e.value,
                    valorEstadoCamion: { value: e.value, label: e.label },
                    valorEstadoCamionJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorPrioridad ? data.valorPrioridad : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexPrioridad: e.value,
                    valorPrioridad: { value: e.value, label: e.label },
                    valorPrioridadJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorCodigoOrigen ? data.valorCodigoOrigen : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexCodigoOrigen: e.value,
                    valorCodigoOrigen: { value: e.value, label: e.label },
                    valorCodigoOrigenJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorOrigen ? data.valorOrigen : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexOrigen: e.value,
                    valorOrigen: { value: e.value, label: e.label },
                    valorOrigenJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={
                  data.valorCodigoProducto ? data.valorCodigoProducto : null
                }
                onChange={(e) => {
                  setData({
                    ...data,
                    indexCodigoProducto: e.value,
                    valorCodigoProducto: { value: e.value, label: e.label },
                    valorCodigoProductoJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorProducto ? data.valorProducto : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexProducto: e.value,
                    valorProducto: { value: e.value, label: e.label },
                    valorProductoJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorTipoProducto ? data.valorTipoProducto : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexTipoProducto: e.value,
                    valorTipoProducto: { value: e.value, label: e.label },
                    valorTipoProductoJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorPallets ? data.valorPallets : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexPallets: e.value,
                    valorPallets: { value: e.value, label: e.label },
                    valorPalletsJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorCodigoDestino ? data.valorCodigoDestino : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexCodigoDestino: e.value,
                    valorCodigoDestino: { value: e.value, label: e.label },
                    valorCodigoDestinoJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorDestino ? data.valorDestino : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexDestino: e.value,
                    valorDestino: {
                      value: e.value,
                      label: e.label,
                    },
                    valorDestinoJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>

          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorBultos ? data.valorBultos : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexBultos: e.value,
                    valorBultos: {
                      value: e.value,
                      label: e.label,
                    },
                    valorBultosJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>

          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorHl ? data.valorHl : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexHl: e.value,
                    valorHl: {
                      value: e.value,
                      label: e.label,
                    },
                    valorHlJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>

          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorPesoCarga ? data.valorPesoCarga : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexPesoCarga: e.value,
                    valorPesoCarga: {
                      value: e.value,
                      label: e.label,
                    },
                    valorPesoCargaJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>

          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorPesoPallets ? data.valorPesoPallets : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexPesoPallets: e.value,
                    valorPesoPallets: {
                      value: e.value,
                      label: e.label,
                    },
                    valorPesoPalletsJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>

          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={
                  data.valorPesoTotalCarga ? data.valorPesoTotalCarga : null
                }
                onChange={(e) => {
                  setData({
                    ...data,
                    indexPesoTotalCarga: e.value,
                    valorPesoTotalCarga: {
                      value: e.value,
                      label: e.label,
                    },
                    valorPesoTotalCargaJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>

          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorComentarios ? data.valorComentarios : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexComentarios: e.value,
                    valorComentarios: {
                      value: e.value,
                      label: e.label,
                    },
                    valorComentariosJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>

          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={
                  data.valorFechaHoraCarga ? data.valorFechaHoraCarga : null
                }
                onChange={(e) => {
                  setData({
                    ...data,
                    indexFechaHoraCarga: e.value,
                    valorFechaHoraCarga: {
                      value: e.value,
                      label: e.label,
                    },
                    valorFechaHoraCargaJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>

          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorDockCarga ? data.valorDockCarga : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexDockCarga: e.value,
                    valorDockCarga: {
                      value: e.value,
                      label: e.label,
                    },
                    valorDockCargaJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>

          <div className="border border-gray rounded w-full my-[5px] flex h-[56px] px-[5px]">
            <div className="my-auto w-1/2">
              <Select
                placeholder="Seleccione columna..."
                options={listColumnsExcel}
                value={data.valorCodigoViaje ? data.valorCodigoViaje : null}
                onChange={(e) => {
                  setData({
                    ...data,
                    indexCodigoViaje: e.value,
                    valorCodigoViaje: {
                      value: e.value,
                      label: e.label,
                    },
                    valorCodigoViajeJson: JSON.stringify({
                      value: e.value,
                      label: e.label,
                    }),
                  });
                }}
              />
            </div>
            <div>.</div>
          </div>
        </div>
      </div>
    </div>
  );
}

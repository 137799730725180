import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import LayoutUsuario from "../../components/layout-usuario";
import AdministracionPermisos from "./index";

const RouterPermisos = () => {
  const { path } = useRouteMatch();
  return (
    <LayoutUsuario>
      <Switch>

        <Route exact path={`${path}`}>
          <AdministracionPermisos />
        </Route>

      </Switch>
    </LayoutUsuario>
  );
};

export default RouterPermisos;

import { useState, useEffect } from "react";
import { Edit, Eye, EyeOff, XSquare } from "feather-icons-react/build/IconComponents";
import "../style/style-cargando.css";
import {
  ESTILO_BOTON_LISTAR_ATRAS,
  ESTILO_BOTON_MODAL_CANCELAR,
} from "../utils/const-estilos";
import {
  STRING_CENTER,
  STRING_LEFT,
  STRING_RIGHT,
} from "../utils/const-string";
import { INT_FONT_SIZE } from "../utils/const-int";
import ModalEditHeaderName from "./modal-edit-header-name";

const ModalConfiguracionGrilla = ({
  modalConfiguracionVisible,
  onHideModalConfiguracion,
  onClickAcceptarOrdenGrillaModalConfiguracion,
  onClickAcceptarHeadersGrillaModalConfiguracion,
  gridHeaders,
  setGridHeaders,
  ascendente,
  descendente,
  setAscendente,
  setDescendente,
  onClickAcceptarFontSizeModalConfiguracion,
  fontSize,
  align,
  onClickAcceptarAlignModalConfiguracion,
  visibleTabOrden,
  visibleTabColumnas,
  visibleTabFuente,
  visibleTabAlineacion,
}) => {
  const heigthBody2 =
    "bg-white w-11/12 h-2/6 md:max-w-3xl lg:max-w-3xl mx-auto rounded shadow-lg z-50 overflow-y-auto flex flex-col";
  const heigthBody5 =
    "bg-white w-11/12 h-5/6 md:max-w-3xl lg:max-w-3xl mx-auto rounded shadow-lg z-50 overflow-y-auto flex flex-col";

  const [classNameBodyModal, setClassNameBodyModal] = useState(heigthBody2);
  const [activeTab, setActiveTab] = useState("tab1");
  const [draggingItem, setDraggingItem] = useState(null);
  const [fontSizeValue, setFontSizeValue] = useState(
    fontSize === undefined || fontSize === null ? INT_FONT_SIZE : fontSize
  );
  const [alignValue, setAlignValue] = useState(
    align === undefined || align === null ? STRING_CENTER : align
  );

  const [showTabOrden, setShowTabOrden] = useState(
    visibleTabOrden === undefined || visibleTabOrden === null
      ? true
      : visibleTabOrden
  );
  const [showTabColumnas, setShowTabColumnas] = useState(
    visibleTabColumnas === undefined || visibleTabColumnas === null
      ? true
      : visibleTabColumnas
  );
  const [showTabFuente, setShowTabFuente] = useState(
    visibleTabFuente === undefined || visibleTabFuente === null
      ? true
      : visibleTabFuente
  );
  const [showTabAlineacion, setShowTabAlineacion] = useState(
    visibleTabAlineacion === undefined || visibleTabAlineacion === null
      ? true
      : visibleTabAlineacion
  );

  const [editLabel, setEditLabel] = useState(false);
  const [objHeaderSelected, setObjHeaderSelected] = useState(null);
  const [indexHeaderSelected, setIndexHeaderSelected] = useState(null);

  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = () => {
    // console.log("data");
    // console.log(data);
  };

  const handleAscendenteChange = () => {
    setAscendente(!ascendente);
    setDescendente(false); // Desmarca el otro checkbox
  };

  const handleDescendenteChange = () => {
    setDescendente(!descendente);
    setAscendente(false); // Desmarca el otro checkbox
  };

  const openTab = (tabName) => {
    setActiveTab(tabName);
  };

  const handleDragStart = (e, item) => {
    setDraggingItem(item);
    e.dataTransfer.setData("text/plain", "");
  };

  const handleDragEnd = () => {
    setDraggingItem(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetItem) => {
    if (!draggingItem) return;
    const currentIndex = gridHeaders.indexOf(draggingItem);
    const targetIndex = gridHeaders.indexOf(targetItem);
    if (currentIndex !== -1 && targetIndex !== -1) {
      gridHeaders.splice(currentIndex, 1);
      gridHeaders.splice(targetIndex, 0, draggingItem);
      setGridHeaders(gridHeaders);
    }
  };

  const changeHeaderVisibility = (index) => {
    let columns = [...gridHeaders];
    columns[index].visible = !columns[index].visible;
    setGridHeaders(columns);
  };

  const handleFuenteChange = (e) => {
    // Validar que el valor sea un número entre 12 y 50
    const newValue = e.target.value;
    // if (/^\d+$/.test(newValue)) {
    //   const numericValue = parseInt(newValue, 10);
    //   if (numericValue >= 12 && numericValue <= 50) {
    setFontSizeValue(newValue);
    //   }
    // }
  };

  const handleCheckboxChange = (value) => {
    setAlignValue(value);
  };

  const changeEditLabel = () => {
    setEditLabel(!editLabel);
  }

  const changleLabelName = (item, index) => {
    try {
      console.log("changleLabelName");
      console.log("item");
      console.log(item);
      console.log("index");
      console.log(index);
      setIndexHeaderSelected(index);
      setObjHeaderSelected(item);
      changeEditLabel();
    } catch (err) {
      console.log("changleLabelName err");
      console.log(err);
    }
  }

  const render = () => {
    try {
      return (
        <>
          {modalConfiguracionVisible && (
            <div className="fixed z-50 top-0 left-0 right-0 bottom-0 flex items-center justify-center">
              {/* background */}
              <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
              {/* end background */}
              <div className={classNameBodyModal}>
                <div className="pt-4 text-left px-6">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="text-xl">{"CONFIGURAR GRILLA "}</p>
                    <button
                      type="button"
                      className={ESTILO_BOTON_LISTAR_ATRAS}
                      onClick={() => {
                        onHideModalConfiguracion();
                        setActiveTab("tab1");
                        setClassNameBodyModal(heigthBody2);
                      }}
                    >
                      <XSquare />
                    </button>
                  </div>
                  <div className="tabs">
                    {showTabOrden === true && (
                      <button
                        className={`tab ${activeTab === "tab1"
                          ? "text-white bg-green-500 rounded transition duration-300"
                          : "text-black"
                          }`}
                        onClick={() => {
                          openTab("tab1");
                          setClassNameBodyModal(heigthBody2);
                        }}
                      >
                        ORDEN
                      </button>
                    )}
                    {showTabColumnas === true && (
                      <button
                        className={`tab ${activeTab === "tab2"
                          ? "text-white bg-green-500 rounded transition duration-300"
                          : "text-black"
                          }`}
                        onClick={() => {
                          openTab("tab2");
                          setClassNameBodyModal(heigthBody5);
                        }}
                      >
                        COLUMNAS
                      </button>
                    )}
                    {showTabFuente === true && (
                      <button
                        className={`tab ${activeTab === "tab3"
                          ? "text-white bg-green-500 rounded transition duration-300"
                          : "text-black"
                          }`}
                        onClick={() => {
                          openTab("tab3");
                          setClassNameBodyModal(heigthBody2);
                        }}
                      >
                        FUENTE
                      </button>
                    )}
                    {showTabAlineacion === true && (
                      <button
                        className={`tab ${activeTab === "tab4"
                          ? "text-white bg-green-500 rounded transition duration-300"
                          : "text-black"
                          }`}
                        onClick={() => {
                          openTab("tab4");
                          setClassNameBodyModal(heigthBody2);
                        }}
                      >
                        ALINEACION
                      </button>
                    )}
                  </div>
                  {activeTab === "tab1" && (
                    <div
                      id="tab1"
                      className="sortable-list"
                      style={{
                        paddingTop: 10,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label>
                        <input
                          type="checkbox"
                          checked={ascendente}
                          onChange={handleAscendenteChange}
                        />
                        ASCENDENTE
                      </label>

                      <label>
                        <input
                          type="checkbox"
                          checked={descendente}
                          onChange={handleDescendenteChange}
                        />
                        DESCENDENTE
                      </label>
                      <div className="flex justify-between pt-20 pb-4 pr-4">
                        <button
                          className={ESTILO_BOTON_MODAL_CANCELAR}
                          onClick={onHideModalConfiguracion}
                        >
                          Cancelar
                        </button>
                        <button
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 cursor-pointer"
                          onClick={onClickAcceptarOrdenGrillaModalConfiguracion}
                          title="Guardar Orden Grilla"
                        >
                          Aceptar
                        </button>
                      </div>
                    </div>
                  )}
                  {activeTab === "tab2" && (
                    <div
                      id="tab2"
                      className="sortable-list"
                      style={{
                        paddingTop: 10,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {gridHeaders.map((item, index) => {
                        // if (item.showSettings !== undefined && item.showSettings !== false) {
                        return (
                          <div
                            key={item.key}
                            className={`item ${item === draggingItem ? "dragging" : ""
                              }`}
                            draggable="true"
                            onDragStart={(e) => handleDragStart(e, item)}
                            onDragEnd={handleDragEnd}
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, item)}
                          >
                            <div className="details">
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                }}
                              >
                                <div>
                                  <span>{item.title}</span>
                                </div>
                                <div>
                                  <button
                                    type="button"
                                    className={ESTILO_BOTON_LISTAR_ATRAS}
                                    onClick={() => changleLabelName(item, index)}
                                  >
                                    <Edit />
                                  </button>
                                  {item.key !== "acciones" &&
                                    item.visible === true ? (
                                    <button
                                      type="button"
                                      className={ESTILO_BOTON_LISTAR_ATRAS}
                                      onClick={() => changeHeaderVisibility(index)}
                                    >
                                      <Eye />
                                    </button>
                                  ) : null}
                                  {item.key !== "acciones" &&
                                    item.visible === false ? (
                                    <button
                                      type="button"
                                      className={ESTILO_BOTON_LISTAR_ATRAS}
                                      onClick={() => changeHeaderVisibility(index)}
                                    >
                                      <EyeOff />
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                        // }

                      })}
                      <div className="flex justify-between pt-20 pb-4 pr-4">
                        <button
                          className={ESTILO_BOTON_MODAL_CANCELAR}
                          onClick={onHideModalConfiguracion}
                        >
                          Cancelar
                        </button>
                        <button
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 cursor-pointer"
                          onClick={
                            onClickAcceptarHeadersGrillaModalConfiguracion
                          }
                          title="Guardar Orden Grilla"
                        >
                          Aceptar
                        </button>
                      </div>
                    </div>
                  )}
                  {activeTab === "tab3" && (
                    <div
                      id="tab3"
                      className="sortable-list"
                      style={{
                        paddingTop: 10,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div className="pt-5 flex items-center">
                        <label className="mr-2">FUENTE</label>
                        <input
                          type="number"
                          value={fontSizeValue}
                          onChange={handleFuenteChange}
                          min="5"
                          max="50"
                          // pattern="[0-9]*"
                          className="border border-gray-300 p-1"
                        />
                      </div>
                      <div className="flex justify-between pt-10 pb-4 pr-4">
                        <button
                          className={ESTILO_BOTON_MODAL_CANCELAR}
                          onClick={onHideModalConfiguracion}
                        >
                          Cancelar
                        </button>
                        <button
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 cursor-pointer"
                          onClick={() => {
                            onClickAcceptarFontSizeModalConfiguracion(
                              fontSizeValue
                            );
                          }}
                          title="Guardar Font Size Grilla"
                        >
                          Aceptar
                        </button>
                      </div>
                    </div>
                  )}
                  {activeTab === "tab4" && (
                    <div
                      id="tab4"
                      className="sortable-list"
                      style={{
                        paddingTop: 10,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        <label>
                          ESTA ALINEACION APLICA A TODAS LAS COLUMNAS
                        </label>
                      </div>
                      <div className="pt-5 flex flex-col">
                        <label>
                          <input
                            type="checkbox"
                            value={STRING_CENTER}
                            checked={alignValue === STRING_CENTER}
                            onChange={() => handleCheckboxChange(STRING_CENTER)}
                          />
                          CENTER
                        </label>

                        <label>
                          <input
                            type="checkbox"
                            value={STRING_LEFT}
                            checked={alignValue === STRING_LEFT}
                            onChange={() => handleCheckboxChange(STRING_LEFT)}
                          />
                          LEFT
                        </label>

                        <label>
                          <input
                            type="checkbox"
                            value={STRING_RIGHT}
                            checked={alignValue === STRING_RIGHT}
                            onChange={() => handleCheckboxChange(STRING_RIGHT)}
                          />
                          RIGHT
                        </label>
                      </div>
                      <div className="flex justify-between pt-10 pb-4 pr-4">
                        <button
                          className={ESTILO_BOTON_MODAL_CANCELAR}
                          onClick={onHideModalConfiguracion}
                        >
                          Cancelar
                        </button>
                        <button
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 cursor-pointer"
                          onClick={() => {
                            onClickAcceptarAlignModalConfiguracion(alignValue);
                          }}
                          title="Guardar Align Grilla"
                        >
                          Aceptar
                        </button>
                      </div>
                    </div>
                  )}
                  <ModalEditHeaderName
                    isOpen={editLabel}
                    objData={objHeaderSelected}
                    onAccept={onClickAcceptarHeadersGrillaModalConfiguracion}
                    onCancel={changeEditLabel}
                    gridHeaders={gridHeaders}
                    indexHeaderSelected={indexHeaderSelected}
                    setGridHeaders={setGridHeaders}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      );
    } catch (err) {
      console.log("ModalConfiguracionGrilla err");
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  };

  return render();
};

export default ModalConfiguracionGrilla;

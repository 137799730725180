import {
    INT_ID_ACCIONES_CREAR_SUB_TIPO_CARROCERIA,
    INT_ID_ACCIONES_DESHABILITAR,
    INT_ID_ACCIONES_DESHABILITAR_SUB_TIPO_CARROCERIA,
    INT_ID_ACCIONES_EXPORTAR,
    // INT_ID_ACCIONES_LISTAR,
    INT_ID_ACCIONES_LISTAR_SUB_TIPO_CARROCERIA,
    INT_ID_ACCIONES_MODIFICAR,
    INT_ID_ACCIONES_MODIFICAR_SUB_TIPO_CARROCERIA,
    INT_ID_ACCIONES_ELIMINAR,
    INT_ID_ACCIONES_ENVIAR_SMS,
    INT_ID_ACCIONES_ENVIAR_SMS_MASIVO,
    INT_ID_ACCIONES_CARGAR,
    INT_ID_ACCIONES_GUARDAR,
    INT_ID_ACCIONES_NUEVO,
    INT_ID_ACCIONES_VER,
    INT_ID_ACCIONES_ITINERARIO,
    INT_ID_MODULO_CHECKLIST,
    INT_ID_MODULO_CONFIGURACION,
    INT_ID_MODULO_DASHBOARD,
    INT_ID_MODULO_DESCARGAS,
    INT_ID_MODULO_CARGAS,
    INT_ID_MODULO_EVENTOS,
    INT_ID_MODULO_SEGUIMIENTO,
    INT_ID_MODULO_USUARIOS,
    INT_ID_MODULO_REPORTES,
    INT_ID_MODULO_GEORUTA,
    INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST,
    INT_ID_SEGUNDO_NIVEL_MIS_CHECKLIST,
    INT_ID_SEGUNDO_NIVEL_EVENTOS_DISPONIBILIDAD,
    INT_ID_SEGUNDO_NIVEL_EVENTOS_BITACORA,
    INT_ID_SEGUNDO_NIVEL_EVENTOS_INCIDENTE,
    INT_ID_SEGUNDO_NIVEL_RESPUESTA_INGRESO_CHECKLIST,
    INT_ID_SEGUNDO_NIVEL_RESPUESTA_SALIDA_CHECKLIST,
    INT_ID_SEGUNDO_NIVEL_REPORTE_CHECKLIST,
    INT_ID_SEGUNDO_NIVEL_USUARIOS,
    INT_ID_TERCER_NIVEL_CHECKLIST_CORREOS,
    INT_ID_TERCER_NIVEL_CHECKLIST_GESTIONAR_ICONOS,
    INT_ID_TERCER_NIVEL_CHECKLIST_MOVIMIENTO,
    INT_ID_TERCER_NIVEL_CHECKLIST_OPCIONES_RESPUESTA,
    INT_ID_TERCER_NIVEL_CHECKLIST_TIPO_CARROCERIA,
    INT_ID_TERCER_NIVEL_CHECKLIST_TIPO_CHECKLIST,
    INT_ID_TERCER_NIVEL_USUARIO_MOVIL,
    INT_ID_TERCER_NIVEL_USUARIO_WEB,
    INT_ID_TERCER_NIVEL_CHECKLIST_GEOCERCA,
    INT_ID_TERCER_NIVEL_CHECKLIST_ASIGNAR_CORREO,
    INT_ID_TERCER_NIVEL_CHECKLIST_CATEGORIA,
    INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL,
    INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD,
    INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA,
    INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_ASIGNAR_PLANTA_A_USUARIO,
    INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_CARGA,
    INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_DESCARGA,

    INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GRUPO_CIUDADES,
    INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GEOCERCA_DE_UNA_CIUDAD,
    INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_CARROCERIA,
    INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_SUPERVISOR,
    INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_PLANIFICACION,
    INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_DISPONIBILIDAD_CONDUCTOR,

    INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_SELECCIONAR_GRUPO_GEORUTA,
    INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_SELECCIONAR_PUNTOS_CONTROL,
    INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_TIPO_DETENCION_GEORUTA,
} from "../../utils/const-int";
import {
    STRING_NOMBRE_MODULO_SEGUNDO_ADMINISTRACION_CHECKLIST_PERMISOS,
    STRING_NOMBRE_MODULO_SEGUNDO_MIS_CHECKLIST_PERMISOS,
    STRING_NOMBRE_MODULO_SEGUNDO_CHECKLIST_RESPUEST_CHECKLIST_INGRESO,
    STRING_NOMBRE_MODULO_SEGUNDO_CHECKLIST_RESPUEST_CHECKLIST_SALIDA,
    STRING_NOMBRE_MODULO_SEGUNDO_CHECKLIST_REPORTES_CHECKLIST,
    STRING_NOMBRE_MODULO_SEGUNDO_EVENTOS_DISPONIBILIDAD,
    STRING_NOMBRE_MODULO_SEGUNDO_EVENTOS_BITACORA,
    STRING_NOMBRE_MODULO_SEGUNDO_EVENTOS_INCIDENTE,
    STRING_NOMBRE_MODULO_SEGUNDO_USUARIOS_PERMISOS,
    STRING_NOMBRE_MODULO_TERCERO_CORREOS_PERMISOS,
    STRING_NOMBRE_MODULO_TERCERO_GESTIONAR_ICONOS_PERMISOS,
    STRING_NOMBRE_MODULO_TERCERO_MOVIMIENTO_PERMISOS,
    STRING_NOMBRE_MODULO_TERCERO_OPCIONES_RESPUESTA_PERMISOS,
    STRING_NOMBRE_MODULO_TERCERO_TIPO_CARROCERIA_PERMISOS,
    STRING_NOMBRE_MODULO_TERCERO_TIPO_CHECKLIST_PERMISOS,
    STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_GENERAL_ASIGNAR_PLANTA_USUARIO,
    STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_CARGA,
    STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_DESCARGA,

    STRING_NOMBRE_MODULO_SEGUNDO_CONFIGURACION_GENERAL,
    STRING_NOMBRE_MODULO_SEGUNDO_CONFIGURACION_DISPONIBILIDAD,
    STRING_NOMBRE_MODULO_SEGUNDO_CONFIGURACION_GEORUTA,

    STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GRUPO_CIUDADES,
    STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GEOCERCA_CIUDAD,
    STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADOS_CARROCERIA,
    STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADOS_SUPERVISOR,
    STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADOS_PLANIFICACION,
    STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADOS_DISPONIBILIDAD_CONDUCTOR,

    STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_GEORUTA_SELECCIONAR_GRUPO_GEORUTA,
    STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_GEORUTA_SELECCIONAR_PUNTOS_CONTROL,
    STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_GEORUTA_TIPO_DETENCION_GEORUTA,
    STRING_NOMBRE_MODULO_TERCERO_GEOCERCA,
    STRING_NOMBRE_MODULO_TERCERO_ASIGNAR_CORREO,
    STRING_NOMBRE_MODULO_TERCERO_CATEGORIA,
} from "../../utils/const-string";

/**los id salen de la bd 
*/
export const MODULOS = [
    {
        id: INT_ID_MODULO_DASHBOARD,
        nombre: 'DASHBOARD',
        checked: false,
        segundonivel: [
        ],
    },
    {
        id: INT_ID_MODULO_CHECKLIST,
        nombre: 'CHECKLIST',
        checked: false,
        segundonivel: [
            {
                id: INT_ID_SEGUNDO_NIVEL_MIS_CHECKLIST,
                nombresubmodulosegundo: STRING_NOMBRE_MODULO_SEGUNDO_MIS_CHECKLIST_PERMISOS,
                tercernivel: null,
                checked: false,
                acciones: [
                    {
                        id: INT_ID_ACCIONES_NUEVO,
                        nombreaccion: 'NUEVO',
                        esmeta: false,
                        checked: false,
                    },
                    {
                        id: INT_ID_ACCIONES_MODIFICAR,
                        nombreaccion: 'MODIFICAR',
                        esmeta: false,
                        checked: false,
                    },
                    // {
                    //     id: INT_ID_ACCIONES_LISTAR,
                    //     nombreaccion: 'LISTAR',
                    //     esmeta: false,
                    //     checked: false,
                    // },
                    {
                        id: INT_ID_ACCIONES_DESHABILITAR,
                        nombreaccion: 'DESHABILITAR',
                        esmeta: false,
                        checked: false,
                    },
                    {
                        id: INT_ID_ACCIONES_EXPORTAR,
                        nombreaccion: "EXPORTAR",
                        esmeta: false,
                        checked: false,
                    },
                    {
                        id: INT_ID_ACCIONES_VER,
                        nombreaccion: "VER",
                        esmeta: false,
                        checked: false,
                    }
                ],
            },
            {
                id: INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST,
                nombresubmodulosegundo: STRING_NOMBRE_MODULO_SEGUNDO_ADMINISTRACION_CHECKLIST_PERMISOS,
                checked: false,
                tercernivel: [
                    {
                        id: INT_ID_TERCER_NIVEL_CHECKLIST_TIPO_CARROCERIA,
                        nombresubmodulotercero: STRING_NOMBRE_MODULO_TERCERO_TIPO_CARROCERIA_PERMISOS,
                        checked: false,
                        acciones: [
                            {
                                id: INT_ID_ACCIONES_NUEVO,
                                nombreaccion: 'NUEVO',
                                esmeta: false,
                                checked: false,
                            },
                            // {
                            //     id: INT_ID_ACCIONES_LISTAR,
                            //     nombreaccion: 'LISTAR',
                            //     esmeta: false,
                            //     checked: false,
                            // },
                            {
                                id: INT_ID_ACCIONES_MODIFICAR,
                                nombreaccion: 'MODIFICAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_DESHABILITAR,
                                nombreaccion: 'DESHABILITAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_LISTAR_SUB_TIPO_CARROCERIA,
                                nombreaccion: "LISTAR SUB TIPO CARROCERIA",
                                esmeta: true,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_CREAR_SUB_TIPO_CARROCERIA,
                                nombreaccion: "CREAR SUB TIPO CARROCERIA",
                                esmeta: true,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_MODIFICAR_SUB_TIPO_CARROCERIA,
                                nombreaccion: "MODIFICAR SUB TIPO CARROCERIA",
                                esmeta: true,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_DESHABILITAR_SUB_TIPO_CARROCERIA,
                                nombreaccion: "DESHABILITAR SUB TIPO CARROCERIA",
                                esmeta: true,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_EXPORTAR,
                                nombreaccion: "EXPORTAR",
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_VER,
                                nombreaccion: "VER",
                                esmeta: false,
                                checked: false,
                            }
                        ],
                    },
                    {
                        id: INT_ID_TERCER_NIVEL_CHECKLIST_CORREOS,
                        nombresubmodulotercero: STRING_NOMBRE_MODULO_TERCERO_CORREOS_PERMISOS,
                        checked: false,
                        acciones: [
                            {
                                id: INT_ID_ACCIONES_NUEVO,
                                nombreaccion: 'NUEVO',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_MODIFICAR,
                                nombreaccion: 'MODIFICAR',
                                esmeta: false,
                                checked: false,
                            },
                            // {
                            //     id: INT_ID_ACCIONES_LISTAR,
                            //     nombreaccion: 'LISTAR',
                            //     esmeta: false,
                            //     checked: false,
                            // },
                            {
                                id: INT_ID_ACCIONES_DESHABILITAR,
                                nombreaccion: 'DESHABILITAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_EXPORTAR,
                                nombreaccion: "EXPORTAR",
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_VER,
                                nombreaccion: "VER",
                                esmeta: false,
                                checked: false,
                            }
                        ],
                    },
                    {
                        id: INT_ID_TERCER_NIVEL_CHECKLIST_OPCIONES_RESPUESTA,
                        nombresubmodulotercero: STRING_NOMBRE_MODULO_TERCERO_OPCIONES_RESPUESTA_PERMISOS,
                        checked: false,
                        acciones: [
                            {
                                id: INT_ID_ACCIONES_NUEVO,
                                nombreaccion: 'NUEVO',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_MODIFICAR,
                                nombreaccion: 'MODIFICAR',
                                esmeta: false,
                                checked: false,
                            },
                            // {
                            //     id: INT_ID_ACCIONES_LISTAR,
                            //     nombreaccion: 'LISTAR',
                            //     esmeta: false,
                            //     checked: false,
                            // },
                            {
                                id: INT_ID_ACCIONES_DESHABILITAR,
                                nombreaccion: 'DESHABILITAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_EXPORTAR,
                                nombreaccion: "EXPORTAR",
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_VER,
                                nombreaccion: "VER",
                                esmeta: false,
                                checked: false,
                            },
                        ],
                    },
                    {
                        id: INT_ID_TERCER_NIVEL_CHECKLIST_MOVIMIENTO,
                        nombresubmodulotercero: STRING_NOMBRE_MODULO_TERCERO_MOVIMIENTO_PERMISOS,
                        checked: false,
                        acciones: [
                            {
                                id: INT_ID_ACCIONES_NUEVO,
                                nombreaccion: 'NUEVO',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_MODIFICAR,
                                nombreaccion: 'MODIFICAR',
                                esmeta: false,
                                checked: false,
                            },
                            // {
                            //     id: INT_ID_ACCIONES_LISTAR,
                            //     nombreaccion: 'LISTAR',
                            //     esmeta: false,
                            //     checked: false,
                            // },
                            {
                                id: INT_ID_ACCIONES_DESHABILITAR,
                                nombreaccion: 'DESHABILITAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_EXPORTAR,
                                nombreaccion: "EXPORTAR",
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_VER,
                                nombreaccion: "VER",
                                esmeta: false,
                                checked: false,
                            }
                        ],
                    },
                    {
                        id: INT_ID_TERCER_NIVEL_CHECKLIST_TIPO_CHECKLIST,
                        nombresubmodulotercero: STRING_NOMBRE_MODULO_TERCERO_TIPO_CHECKLIST_PERMISOS,
                        checked: false,
                        acciones: [
                            {
                                id: INT_ID_ACCIONES_NUEVO,
                                nombreaccion: 'NUEVO',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_MODIFICAR,
                                nombreaccion: 'MODIFICAR',
                                esmeta: false,
                                checked: false,
                            },
                            // {
                            //     id: INT_ID_ACCIONES_LISTAR,
                            //     nombreaccion: 'LISTAR',
                            //     esmeta: false,
                            //     checked: false,
                            // },
                            {
                                id: INT_ID_ACCIONES_DESHABILITAR,
                                nombreaccion: 'DESHABILITAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_EXPORTAR,
                                nombreaccion: "EXPORTAR",
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_VER,
                                nombreaccion: "VER",
                                esmeta: false,
                                checked: false,
                            }
                        ],
                    },
                    {
                        id: INT_ID_TERCER_NIVEL_CHECKLIST_GESTIONAR_ICONOS,
                        nombresubmodulotercero: STRING_NOMBRE_MODULO_TERCERO_GESTIONAR_ICONOS_PERMISOS,
                        checked: false,
                        acciones: [
                            {
                                id: INT_ID_ACCIONES_NUEVO,
                                nombreaccion: 'NUEVO',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_DESHABILITAR,
                                nombreaccion: 'DESHABILITAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_VER,
                                nombreaccion: "VER",
                                esmeta: false,
                                checked: false,
                            }
                        ],
                    },
                    {
                        id: INT_ID_TERCER_NIVEL_CHECKLIST_GEOCERCA,
                        nombresubmodulotercero: STRING_NOMBRE_MODULO_TERCERO_GEOCERCA,
                        checked: false,
                        acciones: [],
                    },
                    {
                        id: INT_ID_TERCER_NIVEL_CHECKLIST_ASIGNAR_CORREO,
                        nombresubmodulotercero: STRING_NOMBRE_MODULO_TERCERO_ASIGNAR_CORREO,
                        checked: false,
                        acciones: [
                            {
                                id: INT_ID_ACCIONES_NUEVO,
                                nombreaccion: 'NUEVO',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_DESHABILITAR,
                                nombreaccion: 'DESHABILITAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_MODIFICAR,
                                nombreaccion: "MODIFICAR",
                                esmeta: false,
                                checked: false,
                            }
                        ],
                    },
                    {
                        id: INT_ID_TERCER_NIVEL_CHECKLIST_CATEGORIA,
                        nombresubmodulotercero: STRING_NOMBRE_MODULO_TERCERO_CATEGORIA,
                        checked: false,
                        acciones: [
                            {
                                id: INT_ID_ACCIONES_NUEVO,
                                nombreaccion: 'NUEVO',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_DESHABILITAR,
                                nombreaccion: 'DESHABILITAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_MODIFICAR,
                                nombreaccion: "MODIFICAR",
                                esmeta: false,
                                checked: false,
                            }
                        ],
                    },
                ],
            },
            {
                id: INT_ID_SEGUNDO_NIVEL_RESPUESTA_INGRESO_CHECKLIST,
                nombresubmodulosegundo: STRING_NOMBRE_MODULO_SEGUNDO_CHECKLIST_RESPUEST_CHECKLIST_INGRESO,
                tercernivel: null,
                checked: false,
                acciones: [
                    {
                        id: INT_ID_ACCIONES_VER,
                        nombreaccion: "VER",
                        esmeta: false,
                        checked: false,
                    }
                ],
            },
            {
                id: INT_ID_SEGUNDO_NIVEL_RESPUESTA_SALIDA_CHECKLIST,
                nombresubmodulosegundo: STRING_NOMBRE_MODULO_SEGUNDO_CHECKLIST_RESPUEST_CHECKLIST_SALIDA,
                tercernivel: null,
                checked: false,
                acciones: [
                    {
                        id: INT_ID_ACCIONES_VER,
                        nombreaccion: "VER",
                        esmeta: false,
                        checked: false,
                    }
                ],
            },
            {
                id: INT_ID_SEGUNDO_NIVEL_REPORTE_CHECKLIST,
                nombresubmodulosegundo: STRING_NOMBRE_MODULO_SEGUNDO_CHECKLIST_REPORTES_CHECKLIST,
                tercernivel: null,
                checked: false,
                acciones: [
                ],
            }
        ],
    },
    {
        id: INT_ID_MODULO_SEGUIMIENTO,
        nombre: 'SEGUIMIENTO',
        checked: false,
        segundonivel: [
        ],
    },
    {
        id: INT_ID_MODULO_EVENTOS,
        nombre: 'EVENTOS',
        checked: false,
        segundonivel: [
            {
                id: INT_ID_SEGUNDO_NIVEL_EVENTOS_DISPONIBILIDAD,
                nombresubmodulosegundo: STRING_NOMBRE_MODULO_SEGUNDO_EVENTOS_DISPONIBILIDAD,
                tercernivel: null,
                checked: false,
                acciones: [
                    {
                        id: INT_ID_ACCIONES_MODIFICAR,
                        nombreaccion: 'MODIFICAR',
                        esmeta: false,
                        checked: false,
                    },
                    {
                        id: INT_ID_ACCIONES_DESHABILITAR,
                        nombreaccion: 'DESHABILITAR',
                        esmeta: false,
                        checked: false,
                    },
                ],
            },
            {
                id: INT_ID_SEGUNDO_NIVEL_EVENTOS_INCIDENTE,
                nombresubmodulosegundo: STRING_NOMBRE_MODULO_SEGUNDO_EVENTOS_INCIDENTE,
                tercernivel: null,
                checked: false,
                acciones: [
                    {
                        id: INT_ID_ACCIONES_VER,
                        nombreaccion: 'VER',
                        esmeta: false,
                        checked: false,
                    },
                ],
            },
            {
                id: INT_ID_SEGUNDO_NIVEL_EVENTOS_BITACORA,
                nombresubmodulosegundo: STRING_NOMBRE_MODULO_SEGUNDO_EVENTOS_BITACORA,
                tercernivel: null,
                checked: false,
                acciones: [
                    {
                        id: INT_ID_ACCIONES_VER,
                        nombreaccion: 'VER',
                        esmeta: false,
                        checked: false,
                    },
                ],
            },
        ],
    },
    {
        id: INT_ID_MODULO_DESCARGAS,
        nombre: 'DESCARGAS',
        checked: false,
        segundonivel: [
        ],
        acciones: [
            {
                id: INT_ID_ACCIONES_MODIFICAR,
                nombreaccion: 'MODIFICAR',
                esmeta: false,
                checked: false,
            },
            {
                id: INT_ID_ACCIONES_ELIMINAR,
                nombreaccion: 'ELIMINAR',
                esmeta: false,
                checked: false,
            },
            {
                id: INT_ID_ACCIONES_ITINERARIO,
                nombreaccion: "ITINERARIO",
                esmeta: false,
                checked: false,
            },
            {
                id: INT_ID_ACCIONES_ENVIAR_SMS,
                nombreaccion: "ENVIAR SMS",
                esmeta: false,
                checked: false,
            },
            {
                id: INT_ID_ACCIONES_EXPORTAR,
                nombreaccion: "EXPORTAR",
                esmeta: false,
                checked: false,
            },
            {
                id: INT_ID_ACCIONES_CARGAR,
                nombreaccion: "CARGAR",
                esmeta: false,
                checked: false,
            },
            {
                id: INT_ID_ACCIONES_GUARDAR,
                nombreaccion: "GUARDAR",
                esmeta: false,
                checked: false,
            },
            {
                id: INT_ID_ACCIONES_ENVIAR_SMS_MASIVO,
                nombreaccion: "ENVIAR SMS MASIVO",
                esmeta: false,
                checked: false,
            },
        ],
    },
    {
        id: INT_ID_MODULO_CARGAS,
        nombre: 'CARGAS',
        checked: false,
        segundonivel: [
        ],
        acciones: [
            {
                id: INT_ID_ACCIONES_MODIFICAR,
                nombreaccion: 'MODIFICAR',
                esmeta: false,
                checked: false,
            },
            {
                id: INT_ID_ACCIONES_ELIMINAR,
                nombreaccion: 'ELIMINAR',
                esmeta: false,
                checked: false,
            },
            {
                id: INT_ID_ACCIONES_ITINERARIO,
                nombreaccion: "ITINERARIO",
                esmeta: false,
                checked: false,
            },
            {
                id: INT_ID_ACCIONES_ENVIAR_SMS,
                nombreaccion: "ENVIAR SMS",
                esmeta: false,
                checked: false,
            },
            {
                id: INT_ID_ACCIONES_EXPORTAR,
                nombreaccion: "EXPORTAR",
                esmeta: false,
                checked: false,
            },
            {
                id: INT_ID_ACCIONES_CARGAR,
                nombreaccion: "CARGAR",
                esmeta: false,
                checked: false,
            },
            {
                id: INT_ID_ACCIONES_GUARDAR,
                nombreaccion: "GUARDAR",
                esmeta: false,
                checked: false,
            },
            {
                id: INT_ID_ACCIONES_ENVIAR_SMS_MASIVO,
                nombreaccion: "ENVIAR SMS MASIVO",
                esmeta: false,
                checked: false,
            },
        ],
    },
    {
        id: INT_ID_MODULO_USUARIOS,
        nombre: "USUARIOS",
        checked: false,
        segundonivel: [
            {
                id: INT_ID_SEGUNDO_NIVEL_USUARIOS,
                nombresubmodulosegundo: STRING_NOMBRE_MODULO_SEGUNDO_USUARIOS_PERMISOS,
                checked: false,
                tercernivel: [
                    {
                        id: INT_ID_TERCER_NIVEL_USUARIO_WEB,
                        nombresubmodulotercero: "USUARIO WEB",
                        acciones: [
                            {
                                id: INT_ID_ACCIONES_NUEVO,
                                nombreaccion: 'NUEVO',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_MODIFICAR,
                                nombreaccion: 'MODIFICAR',
                                esmeta: false,
                                checked: false,
                            },
                            // {
                            //     id: INT_ID_ACCIONES_LISTAR,
                            //     nombreaccion: 'LISTAR',
                            //     esmeta: false,
                            //     checked: false,
                            // },
                            {
                                id: INT_ID_ACCIONES_DESHABILITAR,
                                nombreaccion: 'DESHABILITAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_EXPORTAR,
                                nombreaccion: "EXPORTAR",
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_VER,
                                nombreaccion: "VER",
                                esmeta: false,
                                checked: false,
                            }
                        ],
                    },
                    {
                        id: INT_ID_TERCER_NIVEL_USUARIO_MOVIL,
                        nombresubmodulotercero: "USUARIO MOVIL",
                        checked: false,
                        acciones: [
                            {
                                id: INT_ID_ACCIONES_NUEVO,
                                nombreaccion: 'NUEVO',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_MODIFICAR,
                                nombreaccion: 'MODIFICAR',
                                esmeta: false,
                                checked: false,
                            },
                            // {
                            //     id: INT_ID_ACCIONES_LISTAR,
                            //     nombreaccion: 'LISTAR',
                            //     esmeta: false,
                            //     checked: false,
                            // },
                            {
                                id: INT_ID_ACCIONES_DESHABILITAR,
                                nombreaccion: 'DESHABILITAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_EXPORTAR,
                                nombreaccion: "EXPORTAR",
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_VER,
                                nombreaccion: "VER",
                                esmeta: false,
                                checked: false,
                            }
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: INT_ID_MODULO_CONFIGURACION,
        nombre: 'CONFIGURACION',
        checked: false,
        segundonivel: [
            {
                id: INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL,
                nombresubmodulosegundo: STRING_NOMBRE_MODULO_SEGUNDO_CONFIGURACION_GENERAL,
                checked: false,
                tercernivel: [
                    {
                        id: INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_ASIGNAR_PLANTA_A_USUARIO,
                        nombresubmodulotercero: STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_GENERAL_ASIGNAR_PLANTA_USUARIO,
                        acciones: [
                            {
                                id: INT_ID_ACCIONES_NUEVO,
                                nombreaccion: 'NUEVO',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_MODIFICAR,
                                nombreaccion: 'MODIFICAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_DESHABILITAR,
                                nombreaccion: 'DESHABILITAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_EXPORTAR,
                                nombreaccion: "EXPORTAR",
                                esmeta: false,
                                checked: false,
                            },
                        ],
                    },
                    {
                        id: INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_CARGA,
                        nombresubmodulotercero: STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_CARGA,
                        checked: false,
                        acciones: [
                            {
                                id: INT_ID_ACCIONES_NUEVO,
                                nombreaccion: 'NUEVO',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_MODIFICAR,
                                nombreaccion: 'MODIFICAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_DESHABILITAR,
                                nombreaccion: 'DESHABILITAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_EXPORTAR,
                                nombreaccion: "EXPORTAR",
                                esmeta: false,
                                checked: false,
                            },
                        ],
                    },
                    {
                        id: INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_DESCARGA,
                        nombresubmodulotercero: STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_GENERAL_CONFIGURAR_TIEMPO_DESCARGA,
                        checked: false,
                        acciones: [
                            {
                                id: INT_ID_ACCIONES_NUEVO,
                                nombreaccion: 'NUEVO',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_MODIFICAR,
                                nombreaccion: 'MODIFICAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_DESHABILITAR,
                                nombreaccion: 'DESHABILITAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_EXPORTAR,
                                nombreaccion: "EXPORTAR",
                                esmeta: false,
                                checked: false,
                            },
                        ],
                    },
                ],
            },
            {
                id: INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD,
                nombresubmodulosegundo: STRING_NOMBRE_MODULO_SEGUNDO_CONFIGURACION_DISPONIBILIDAD,
                checked: false,
                tercernivel: [
                    {
                        id: INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GRUPO_CIUDADES,
                        nombresubmodulotercero: STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GRUPO_CIUDADES,
                        acciones: [],
                    },
                    {
                        id: INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GEOCERCA_DE_UNA_CIUDAD,
                        nombresubmodulotercero: STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GEOCERCA_CIUDAD,
                        checked: false,
                        acciones: [],
                    },
                    {
                        id: INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_CARROCERIA,
                        nombresubmodulotercero: STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADOS_CARROCERIA,
                        checked: false,
                        acciones: [
                            {
                                id: INT_ID_ACCIONES_NUEVO,
                                nombreaccion: 'NUEVO',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_MODIFICAR,
                                nombreaccion: 'MODIFICAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_DESHABILITAR,
                                nombreaccion: 'DESHABILITAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_EXPORTAR,
                                nombreaccion: "EXPORTAR",
                                esmeta: false,
                                checked: false,
                            },
                        ],
                    },
                    {
                        id: INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_SUPERVISOR,
                        nombresubmodulotercero: STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADOS_SUPERVISOR,
                        checked: false,
                        acciones: [
                            {
                                id: INT_ID_ACCIONES_NUEVO,
                                nombreaccion: 'NUEVO',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_MODIFICAR,
                                nombreaccion: 'MODIFICAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_DESHABILITAR,
                                nombreaccion: 'DESHABILITAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_EXPORTAR,
                                nombreaccion: "EXPORTAR",
                                esmeta: false,
                                checked: false,
                            },
                        ],
                    },
                    {
                        id: INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_PLANIFICACION,
                        nombresubmodulotercero: STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADOS_PLANIFICACION,
                        checked: false,
                        acciones: [
                            {
                                id: INT_ID_ACCIONES_NUEVO,
                                nombreaccion: 'NUEVO',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_MODIFICAR,
                                nombreaccion: 'MODIFICAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_DESHABILITAR,
                                nombreaccion: 'DESHABILITAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_EXPORTAR,
                                nombreaccion: "EXPORTAR",
                                esmeta: false,
                                checked: false,
                            },
                        ],
                    },
                    {
                        id: INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADO_DISPONIBILIDAD_CONDUCTOR,
                        nombresubmodulotercero: STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_DISPONIBILIDAD_GESTIONAR_ESTADOS_DISPONIBILIDAD_CONDUCTOR,
                        checked: false,
                        acciones: [
                            {
                                id: INT_ID_ACCIONES_NUEVO,
                                nombreaccion: 'NUEVO',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_MODIFICAR,
                                nombreaccion: 'MODIFICAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_DESHABILITAR,
                                nombreaccion: 'DESHABILITAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_EXPORTAR,
                                nombreaccion: "EXPORTAR",
                                esmeta: false,
                                checked: false,
                            },
                        ],
                    },
                ],
            },
            {
                id: INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA,
                nombresubmodulosegundo: STRING_NOMBRE_MODULO_SEGUNDO_CONFIGURACION_GEORUTA,
                checked: false,
                tercernivel: [
                    {
                        id: INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_SELECCIONAR_GRUPO_GEORUTA,
                        nombresubmodulotercero: STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_GEORUTA_SELECCIONAR_GRUPO_GEORUTA,
                        acciones: [],
                    },
                    {
                        id: INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_SELECCIONAR_PUNTOS_CONTROL,
                        nombresubmodulotercero: STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_GEORUTA_SELECCIONAR_PUNTOS_CONTROL,
                        checked: false,
                        acciones: [],
                    },
                    {
                        id: INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_TIPO_DETENCION_GEORUTA,
                        nombresubmodulotercero: STRING_NOMBRE_MODULO_TERCERO_CONFIGURACION_GEORUTA_TIPO_DETENCION_GEORUTA,
                        checked: false,
                        acciones: [
                            {
                                id: INT_ID_ACCIONES_NUEVO,
                                nombreaccion: 'NUEVO',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_MODIFICAR,
                                nombreaccion: 'MODIFICAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_DESHABILITAR,
                                nombreaccion: 'DESHABILITAR',
                                esmeta: false,
                                checked: false,
                            },
                            {
                                id: INT_ID_ACCIONES_EXPORTAR,
                                nombreaccion: "EXPORTAR",
                                esmeta: false,
                                checked: false,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: INT_ID_MODULO_REPORTES,
        nombre: 'REPORTES',
        checked: false,
        segundonivel: [
        ],
    },
    {
        id: INT_ID_MODULO_GEORUTA,
        nombre: 'GEORUTA',
        checked: false,
        segundonivel: [],
        acciones: [
            {
                id: INT_ID_ACCIONES_NUEVO,
                nombreaccion: 'NUEVO',
                esmeta: false,
                checked: false,
            },
            {
                id: INT_ID_ACCIONES_MODIFICAR,
                nombreaccion: 'MODIFICAR',
                esmeta: false,
                checked: false,
            },
            {
                id: INT_ID_ACCIONES_DESHABILITAR,
                nombreaccion: 'DESHABILITAR',
                esmeta: false,
                checked: false,
            },
            {
                id: INT_ID_ACCIONES_EXPORTAR,
                nombreaccion: "EXPORTAR",
                esmeta: false,
                checked: false,
            },
            {
                id: INT_ID_ACCIONES_ITINERARIO,
                nombreaccion: "ITINERARIO",
                esmeta: false,
                checked: false,
            },
        ],
    },
];
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { obtenerGrupoGeocerca } from "../../api/gestion-dts/gestion";
import "../../style/style-cargando.css";
import {
  ESTILO_BOTON_GENERAL_ACEPTAR,
  ESTILO_BOTON_LISTAR_ATRAS,
} from "../../utils/const-estilos";
import {
  INT_ID_MODULO_CONFIGURACION,
  INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA,
  INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_SELECCIONAR_GRUPO_GEORUTA,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB
} from "../../utils/const-int";
import {
  guardarGrupoGeoruta,
  modificarGrupoGeoruta,
  obtenerGrupoGeoruta,
} from "../../api/georuta";
import { TRUE } from "../../utils/const-bool";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";

const idTipoUsuario = parseInt(localStorage.getItem("tipo"));

export default function SeleccionarGrupoGeoRuta() {
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(true);
  const [listaGrupoSubgrupo, setListaGrupoSubgrupo] = useState([]);
  const [infoGeocercaEnBase, setInfoGeocercaEnBase] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);
  const [permisos, setPermisos] = useState(null);

  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      let objPermiso = {};
      objPermiso.idMenuPrimerNivel = INT_ID_MODULO_CONFIGURACION;
      objPermiso.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA;
      objPermiso.idMenuTercerNivel = INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_SELECCIONAR_GRUPO_GEORUTA;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(objPermiso);
      let permiso = obtenerPermisos[0];
      setPermisos(permiso);

      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        procesarSiTienePermiso();
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.accesoAlModulo === TRUE) {
          procesarSiTienePermiso();
        }
      }
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const procesarSiTienePermiso = async () => {
    try {
      let listaInfoGrupoSubgrupo = [];
      let infoGeocerca = null;
      infoGeocerca = await obtenerGrupoGeoruta();
      // console.log('infoGeocerca');
      // console.log(infoGeocerca);
      setInfoGeocercaEnBase(infoGeocerca);
      listaInfoGrupoSubgrupo = await obtenerGrupoGeocerca();
      // console.log('listaInfoGrupoSubgrupo');
      // console.log(listaInfoGrupoSubgrupo);
      const procesado = procesarLista(listaInfoGrupoSubgrupo, infoGeocerca);
      setListaGrupoSubgrupo(procesado);

      setTieneAccesoAlModulo(true);
    } catch (err) {
      console.log('procesarSiTienePermiso err');
      console.log(err);
    }
  }

  const procesarLista = (lista, infoGeocerca) => {
    try {
      let listaInfoGeocercaEnBase = [];
      if (infoGeocerca !== null) {
        listaInfoGeocercaEnBase = JSON.parse(infoGeocerca.meta);
      }
      let listaGrupo = [];
      for (let i = 0; i < lista.length; i++) {
        const element = lista[i];
        const existe = listaGrupo.find(e => e.idGrupoGeoCerca === element.idGrupoGeoCerca);
        if (existe === undefined) {
          const indexEnBase = listaInfoGeocercaEnBase.find(e => e === element.idGrupoGeoCerca);
          let objGrupo = {};
          objGrupo.idGrupoGeoCerca = element.idGrupoGeoCerca;
          objGrupo.nombre = element.nombreGrupoGeocerca;
          objGrupo.checked = indexEnBase === undefined ? false : true;
          objGrupo.geocercas = [];

          let objGeocerca = {};
          objGeocerca.nombre = element.nombreGeocerca;
          objGeocerca.codigo = element.codigoGeocerca;
          objGeocerca.id = element.idGeoCerca;
          objGrupo.geocercas.push(objGeocerca);
          listaGrupo.push(objGrupo);
        } else {
          const index = listaGrupo.findIndex(e => e.idGrupoGeoCerca === element.idGrupoGeoCerca);
          let objGeocerca = {};
          objGeocerca.nombre = element.nombreGeocerca;
          objGeocerca.codigo = element.codigoGeocerca;
          objGeocerca.id = element.idGeoCerca;
          listaGrupo[index].geocercas.push(objGeocerca);
        }
      }
      // console.log('listaGrupo');
      // console.log(listaGrupo);
      return listaGrupo;
    } catch (err) {
      console.log('procesarLista err');
      console.log(err);
      return [];
    }
  }

  const nextPage = (page) => {
    try {

      history.push(`${page}`);

    } catch (err) {
      console.log('nextPage err');
      console.log(err);
    }
  };

  const renderGeocercas = (listaGeo) => {
    // console.log('listaGeo');
    // console.log(listaGeo);
    return listaGeo.map((geocerca) => (
      <div key={geocerca.id}>
        <label className="font-normal ml-60">
          {geocerca.codigo !== undefined && geocerca.codigo !== null ? geocerca.codigo + " - " : ""} {geocerca.nombre}
        </label>
      </div>
    ));
  };


  const handleGrupoCheckboxChange = (indexGrupo) => {
    try {
      let pivote = listaGrupoSubgrupo;
      const nuevoCheck = !pivote[indexGrupo].checked;
      pivote[indexGrupo].checked = nuevoCheck;
      setListaGrupoSubgrupo([
        ...pivote
      ]);
    } catch (err) {
      console.log('handleGrupoCheckboxChange err');
      console.log(err);
    }
  }

  const handleGuardarClick = async () => {
    try {
      // console.log('handleGuardarClick');
      // console.log('listaGrupoSubgrupo');
      // console.log(listaGrupoSubgrupo);
      let arr = [];
      for (let i = 0; i < listaGrupoSubgrupo.length; i++) {
        const element = listaGrupoSubgrupo[i];
        if (element.checked === true) {
          arr.push(element.idGrupoGeoCerca);
        }
      }
      let objEnviar = {};
      objEnviar.meta = arr;
      if (infoGeocercaEnBase === null) {
        const guardado = await guardarGrupoGeoruta(objEnviar);
        // console.log('guardado');
        // console.log(guardado);
        if (guardado === true) {
          alert('Se guardo correctamente');
          // nextPage("disponibilidad");
          handleAtrasClick();
        } else {
          alert('Ocurrio un error, intente nuevamente');
        }
      }
      if (infoGeocercaEnBase !== null) {
        objEnviar.id = infoGeocercaEnBase.id;
        const modificado = await modificarGrupoGeoruta(objEnviar);
        // console.log('modificado');
        // console.log(modificado);
        if (modificado === true) {
          alert('Se actualizo correctamente');
          // nextPage("disponibilidad");
          handleAtrasClick();
        } else {
          alert('Ocurrio un error, intente nuevamente');
        }
      }
    } catch (err) {
      console.log('handleGuardarClick err');
      console.log(err)
    }
  }

  const handleAtrasClick = () => {
    history.goBack();
  }

  const render = () => {
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div
              style={{ marginTop: 10, }}
            >
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div
        >
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <label
              style={{
                paddingLeft: 4,
                fontWeight: 'bold',
              }}
            >
              SELECCIONAR GRUPO GEO-RUTA
            </label>
            <button className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <hr />
          <br />
          <div className="flex flex-wrap px-5 py-5 flex-col">
            {listaGrupoSubgrupo.map((grupo, indexGrupo) => (
              <div key={grupo.idGrupoGeoCerca}>
                <label className="font-bold ml-10">
                  <input
                    type="checkbox"
                    checked={grupo.checked}
                    onChange={() => handleGrupoCheckboxChange(indexGrupo)}
                  /> {grupo.nombre}
                </label>
                {renderGeocercas(grupo.geocercas)}
                <hr />
              </div>
            ))}
          </div>
          <div className="flex p-5 justify-end">
            <button className={ESTILO_BOTON_GENERAL_ACEPTAR}
              onClick={handleGuardarClick}
            >
              GUARDAR
            </button>
          </div>
        </div>
      );
    } catch (err) {
      console.log("permisos render");
      console.log(err);
      return (
        <div className=" w-1/2 m-auto px-[2rem] ">
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  };

  return render();
}
import { AZUL_BOLTRACK1, AZUL_BOLTRACK2 } from "../share/shared-colors";
import { ESTILO_BOTON_LISTAR_NUEVO } from "../utils/const-estilos";
import TimeLine from "./time-line";

const CardSeguimiento = ({ seguimiento, onButtonDetalles }) => {

    return (
        <>
            <div className="flex flex-row md:flex-col mb-5"
                key={seguimiento.planilla}
            >
                <div
                    style={{
                        borderLeft: '2px solid green',
                        paddingLeft: '10px',
                        borderRadius: 5,
                        boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #d9d4d4',
                            marginRight: 2,
                        }}
                    >
                        <label
                            style={{
                                color: AZUL_BOLTRACK2,
                                fontWeight: 'bold',
                            }}
                        >{seguimiento.planilla}</label>
                        <label>ESTADO</label>
                        <button
                            className={ESTILO_BOTON_LISTAR_NUEVO}
                            onClick={onButtonDetalles}
                        >
                            Detalles
                        </button>
                    </div>
                    <div className="flex flex-row">
                        <div className="w-full sm:w-4/4 md:w-4/4 lg:w-1/4 md:mb-0 mr-4">
                            <div className="flex flex-col ">
                                <label
                                    style={{
                                        color: AZUL_BOLTRACK1,
                                        fontWeight: 'bold',
                                    }}
                                >{seguimiento.unidad}</label>
                                <label>{seguimiento.codigoOrigen + " - " + seguimiento.depositoOrigen + " -> " + seguimiento.codigoDestino + " - " + seguimiento.depositoDestino}</label>
                            </div>
                        </div>
                        <div className="w-full sm:w-4/4 md:w-4/4 lg:w-3/4 md:mb-0 mr-4">
                            <TimeLine
                                key={seguimiento.planilla}
                                seguimiento={seguimiento}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardSeguimiento;
import "../style/style-cargando.css";
import { ESTILO_BOTON_LISTAR_ATRAS, ESTILO_BOTON_MODAL_CANCELAR } from "../utils/const-estilos";
import { XSquare } from "feather-icons-react/build/IconComponents";
import Select from "react-select";

const ModalDashboardOptions = ({
    show,
    hideModalNuevoDashboardCard,
    guardarNuevoDashboardCard,
    options,
    optionSelected,
    setDashboardCardSelected,
}) => {

    const handleOptionCardSelected = async (event) => {
        setDashboardCardSelected(event);
    };

    const onClickGuardar = () => {
        guardarNuevoDashboardCard(optionSelected);
    }

    return (
        <>
            {show && (
                <div className="fixed z-50 top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                    <div className="bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
                        {/* TITLE */}
                        <div className="flex justify-between items-center pr-5 pl-5 pt-5">
                            <p className="text-xl">{'NUEVO DASHBOARD CARD'}</p>
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_ATRAS}
                                onClick={() => {
                                    hideModalNuevoDashboardCard();
                                }}
                            >
                                <XSquare />
                            </button>
                        </div>
                        {/* BODY */}
                        <div className="pr-5 pl-5 pt-5">
                            <Select
                                options={options}
                                isSearchable="true"
                                isClearable="true"
                                value={optionSelected}
                                onChange={(event) => handleOptionCardSelected(event)}
                                placeholder="Seleccione Card"
                            />
                        </div>
                        {/* FOOTER */}
                        <div className="flex justify-between pt-20 pb-4 pr-4">
                            <button
                                className={ESTILO_BOTON_MODAL_CANCELAR}
                                onClick={hideModalNuevoDashboardCard}
                                title="Cerrar"
                            >
                                Cancelar
                            </button>
                            <button
                                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 cursor-pointer"
                                onClick={onClickGuardar}
                                title="Guardar Card"
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ModalDashboardOptions;
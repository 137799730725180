import { SUCCESS } from "../../page/const/http-const";
import { getHeaders } from "../utils";

export async function modificarPermisosUsuarioWeb(obj) {
  try {
    let modificado = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/permisos/modificar-permisos-usuario-web/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    if (code === SUCCESS) {
      modificado = true;
    }
    return modificado;
  } catch (err) {
    console.log("modificarPermisosUsuarioWeb err");
    console.log(err);
    return false;
  }
}

export async function modificarAsignacionGrupoSubgrupoUsuarioWeb(obj) {
  try {
    let modificado = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/permisos/modificar-asignacion-grupo-subgrupo-usuario-web/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    if (code === SUCCESS) {
      modificado = true;
    }
    return modificado;
  } catch (err) {
    console.log("modificarAsignacionGrupoSubgrupoUsuarioWeb err");
    console.log(err);
    return false;
  }
}

export async function obtenerPermisosUsuarioWeb(obj) {
  try {
    let rows = [];
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/permisos/obtener-permisos-usuario-web/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    if (code === SUCCESS) {
      rows = body;
    }
    return rows;
  } catch (err) {
    console.log("obtenerPermisosUsuarioWeb err");
    console.log(err);
    return [];
  }
}

export async function obtenerAsignacionGrupoSubgrupoUsuarioWeb(obj) {
  try {
    let rows = [];
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/permisos/obtener-asignacion-grupo-subgrupo-usuario-web/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    if (code === SUCCESS) {
      rows = body;
    }
    return rows;
  } catch (err) {
    console.log("obtenerAsignacionGrupoSubgrupoUsuarioWeb err");
    console.log(err);
    return [];
  }
}

export async function obtenerPermisosPorNivelUsuarioWeb(obj) {
  try {
    let rows = [];
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/despachoapi/api/permisos/obtener-permisos-por-nivel-usuario-web/",
      {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    if (code === SUCCESS) {
      rows = body;
    }
    return rows;
  } catch (err) {
    console.log("obtenerPermisosPorNivelUsuarioWeb err");
    console.log(err);
    return [];
  }
}
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "../../components/grid";
import {
  CHECK,
  ESTILO_BOTON_GRILLA_NEXT, ESTILO_BOTON_GRILLA_PREVIOUS,
  ESTILO_BOTON_LISTAR_ATRAS,
} from "../../utils/const-estilos";
import ModalConfirmar from "../../components/modal-confirmar";
import "../../style/style-cargando.css";
import {
  INT_ID_MODULO_EVENTOS,
  INT_ID_SEGUNDO_NIVEL_EVENTOS_DISPONIBILIDAD,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../utils/const-int";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
import { TRUE } from "../../utils/const-bool";
import { deshabilitarDisponibilidad, obtenerListaDisponibilidad, obtenerUbicacionLiteralDePlacas } from "../../api/disponibilidad";
import { Settings } from "feather-icons-react/build/IconComponents";
import "./modal-style.css";
import {
  actualizarAlignGrilla,
  actualizarFontSizeGrilla,
  actualizarHeadersGestionarDisponibilidadScreen,
  actualizarOrdenGestionarDisponibilidadScreen,
  guardarConfiguracionScreen, obtenerConfiguracionScreen,
} from "../../api/configuracion-screen/configuracion-screen";
import { GESTIONAR_DISPONIBILIDAD_SCREEN } from "../../utils/const-screen-name";
import { obtenerPuntoActualVehiculoXGrupoPlaca } from "../../api/gestion-dts/gestion";
import ModalConfiguracionGrilla from "../../components/modal-configuracion-grilla";
import { addAttribute, addAttributeById, compareHeaders, sharedGetIdEmpresa, sleepFunction } from "../../share/shared-functions";
import { STRING_CENTER, } from "../../utils/const-string";
import { INT_FONT_SIZE } from "../../utils/const-int";
import ModalLoading from "../../components/modal-loading";

const idTipoUsuario = parseInt(localStorage.getItem("tipo"));

export default function GestionarDisponibilidad() {
  let headers = [
    { key: "acciones", title: "ACCIONES", visible: true, },
    { key: "id", title: "ID", visible: false, },
    { key: "idplantageocercaciudad", title: "ID PLANTA GEOCERCA CIUDAD", visible: false, filter: true, },
    { key: "codigoplantageocercaciudad", title: "CODIGO PLANTA/GEOCERCA CIUDAD", visible: true, filter: true, },
    { key: "nombreplantageocercaciudad", title: "PLANTA/GEOCERCA CIUDAD", visible: true, filter: true, },
    { key: "idplantageocercadestino", title: "ID PLANTA GEOCERCA", visible: false, filter: true, },
    { key: "codigoplantageocercadestino", title: "CODIGO PLANTA/GEOCERCA", visible: true, filter: true, },
    { key: "nombreplantageocercadestino", title: "PLANTA/GEOCERCA", visible: true, filter: true, },
    { key: "unidad", title: "UNIDAD", visible: true, filter: true, },
    { key: "nombrechofer", title: "NOMBRE CHOFER", visible: true, filter: true, },
    { key: "idestadocarroceria", title: "IDESTADO CARROCERIA", visible: false, filter: true, },
    { key: "estadocarroceria", title: "ESTADO CARROCERIA", visible: true, filter: true, },
    { key: "idestadodisponibilidadchofer", title: "ID ESTADO DISPONIBILIDAD CHOFER", visible: false, filter: true, },
    { key: "estadodisponibilidadchofer", title: "ESTADO DISPONIBILIDAD CHOFER", visible: true, filter: true, },
    { key: "idestadosupervisor", title: "ID ESTADO SUPERVISOR", visible: false, filter: true, },
    { key: "estadosupervisor", title: "ESTADO SUPERVISOR", visible: true, filter: true, },
    { key: "idestadoplanificacion", title: "ID ESTADO PLANIFICACION", visible: false, filter: true, },
    { key: "estadoplanificacion", title: "ESTADO PLANIFICACION", visible: true, filter: true, },
    { key: "ubicaciongps", title: "COORDENADAS", visible: true, filter: true, },
    { key: "ubicacionliteral", title: "UBICACION", visible: true, filter: true, },
    { key: "fechaproceso", title: "FECHA PROCESO", visible: true, filter: true, },
    { key: "check", title: "SELECCIONAR", visible: true, },
    { key: "idPlantaGeocercaOrigen", title: "ID PLANTA GEOCERCA ORIGEN", visible: false, filter: true, },
    { key: "codigoPlantaGeocercaOrigen", title: "CODIGO PLANTA/GEOCERCA ORIGEN", visible: true, filter: true, },
    { key: "nombrePlantaGeocercaOrigen", title: "PLANTA/GEOCERCA ORIGEN", visible: true, filter: true, },
  ];

  const history = useHistory();
  const [minRows, setMinRows] = useState(0);
  const [maxRows, setMaxRows] = useState(5);
  const [cargandoPage, setCargandoPage] = useState(true);
  const [listaDisponibilidad, setListaDisponibilidad] = useState([]);
  const [modalConfirmarDeshabilitarVisible, setModalConfirmarDeshabilitarVisible] = useState(false);
  const [disponibilidadSelected, setDisponibilidadSelected] = useState(null);
  const [permisos, setPermisos] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);
  const [showDeshabilitarAll, setShowDeshabilitarAll] = useState(false);
  const [showModalLoading, setShowModalLoading] = useState(false);

  const [modalConfiguracionVisible, setModalConfiguracionVisible] = useState(false);
  const [configuracionOrden, setConfiguracionOrden] = useState(null);
  const [ascendente, setAscendente] = useState(false);
  const [descendente, setDescendente] = useState(false);
  const [gridHeaders, setGridHeaders] = useState(headers);
  const [exportar, setExportar] = useState(true);
  const [fontSizeValue, setFontSizeValue] = useState(INT_FONT_SIZE);
  const [alignValue, setAlignValue] = useState(STRING_CENTER);

  useEffect(() => {
    iniciar();
  }, []);

  const updateAttributeInRow = (rows, id, label, value) => {
    const newListaDisponibilidad = addAttributeById(rows, id, label, value);
    setListaDisponibilidad(newListaDisponibilidad);
    displayDeshabilitarAll(newListaDisponibilidad);
  }

  const displayDeshabilitarAll = (lista) => {
    let count = 0;
    lista.forEach((item) => {
      if (item.checked !== undefined && item.checked === true) {
        count = count + 1;
      }
    });
    setShowDeshabilitarAll(count >= 2);
  }

  const iniciar = async () => {
    try {
      let objPermisos = {};
      objPermisos.idMenuPrimerNivel = INT_ID_MODULO_EVENTOS;
      objPermisos.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_EVENTOS_DISPONIBILIDAD;
      objPermisos.idMenuTercerNivel = 0;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(objPermisos);
      let permiso = obtenerPermisos[0];
      setPermisos(permiso);

      const configuracionScreenInfo = await obtenerConfiguracionScreen({ nombre: GESTIONAR_DISPONIBILIDAD_SCREEN });
      // console.log('configuracionScreenInfo');
      // console.log(configuracionScreenInfo);
      let objConfiguracionEnviar = {};
      objConfiguracionEnviar.descendente = true;
      objConfiguracionEnviar.ascendente = false;
      if (configuracionScreenInfo !== null) {
        const configuracionOrden = JSON.parse(configuracionScreenInfo.orden);
        // console.log('configuracionOrden');
        // console.log(configuracionOrden);
        objConfiguracionEnviar.descendente = configuracionOrden.descendente;
        objConfiguracionEnviar.ascendente = configuracionOrden.ascendente;
        setAscendente(configuracionOrden.ascendente);
        setDescendente(configuracionOrden.descendente);
        let configuracionHeaders = null;
        if (configuracionScreenInfo.headers !== null) {
          configuracionHeaders = JSON.parse(configuracionScreenInfo.headers);
          // console.log('configuracionHeaders');
          // console.log(configuracionHeaders);
          let headerAux = compareHeaders(headers, configuracionHeaders);
          headerAux = addFunctionToHeaders(headerAux);
          setGridHeaders(headerAux);
        }
        if (configuracionScreenInfo.fontSize !== null) {
          setFontSizeValue(configuracionScreenInfo.fontSize);
        }
        if (configuracionScreenInfo.align !== null) {
          setAlignValue(configuracionScreenInfo.align);
        }
      }
      setConfiguracionOrden(configuracionScreenInfo);

      let listaDisp = [];
      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        setTieneAccesoAlModulo(true);
        listaDisp = await obtenerListaDisponibilidad(objConfiguracionEnviar);
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.accesoAlModulo === TRUE) {
          setTieneAccesoAlModulo(true);
          listaDisp = await obtenerListaDisponibilidad(objConfiguracionEnviar);
          setExportar(permiso.exportar);
        }
      }
      // console.log('listaDisp');
      // console.log(listaDisp);
      if (listaDisp.length > 0) {
        const unidades = listaDisp.map(item => `'${item.unidad}'`).join(',');
        // console.log('unidades');
        // console.log(unidades);
        let objUbicacion = {};
        objUbicacion.placas = unidades;
        const ubicacionArr = await obtenerPuntoActualVehiculoXGrupoPlaca(objUbicacion);
        // console.log('ubicacionArr');
        // console.log(ubicacionArr);
        for (let i = 0; i < ubicacionArr.length; i++) {
          const element = ubicacionArr[i];
          const index = listaDisp.findIndex(e => e.unidad === element.placa);
          // console.log('index');
          // console.log(index);
          if (index > -1) {
            listaDisp[index].ubicaciongps = element.LATITUD + "," + element.LONGITUD;
          }
        }
        listaDisp = await setUbicacionLiteral(listaDisp);
      }
      agregarAccionesALista(listaDisp, permiso);
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const addFunctionToHeaders = (headerAux) => {
    for (let i = 0; i < headerAux.length; i++) {
      const element = headerAux[i];
      if (element.key === CHECK) {
        headerAux[i].funcion = (rows, id, label, value) => { updateAttributeInRow(rows, id, label, value); };
      }
    }
    return headerAux;
  }

  const agregarAccionesALista = (lista, permiso) => {
    try {
      if (lista.length === 0) {
        setListaDisponibilidad(lista);
      }
      if (lista.length > 0) {
        let objModificar = {
          label: "Modificar",
          color: "green",
          onClick: (param) => editarDisponibilidad(param),
        };
        let objDeshabilitar = {
          label: "Deshabilitar",
          color: "red",
          onClick: (param) => cambiarEstado(param),
        };
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            acciones.push(objModificar);
            acciones.push(objDeshabilitar);
          }
          if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            if (permiso.modificar === TRUE) {
              acciones.push(objModificar);
            }
            if (permiso.eliminarDeshabilitar === TRUE) {
              acciones.push(objDeshabilitar);
            }
          }
          lista[i].acciones = acciones;
        }
        setListaDisponibilidad(lista);
      }
    } catch (err) {
      console.log('agregarAccionesALista err');
      console.log(err);
    }
  }

  const setUbicacionLiteral = async (lista) => {
    try {
      // console.log('setUbicacionLiteral');
      // console.log(lista);
      let objEnviar = {};
      objEnviar.empresa = sharedGetIdEmpresa();
      objEnviar.unidades = lista.map(data => ({
        placa: data.unidad.toUpperCase()
      }));
      // console.log(objEnviar);
      const direcciones = await obtenerUbicacionLiteralDePlacas(objEnviar);
      console.log('direcciones');
      console.log(direcciones);
      for (let i = 0; i < lista.length; i++) {
        const element = lista[i];
        let ubicacion = direcciones.find(e => e.placa === element.unidad);
        let geocerca = ubicacion.geocerca;
        if (geocerca !== undefined && geocerca !== null && geocerca.length > 0) {
          geocerca = geocerca + " - ";
        }
        lista[i].ubicacionliteral = geocerca + ubicacion.direccion;
      }
      return lista;
    } catch (err) {
      console.log('setUbicacionLiteral err');
      console.log(err);
      return lista;
    }
  }

  const nextPage = (page, params) => {
    try {
      // history.push(`${page}`);
      // console.log('params');
      // console.log(params);
      if (params !== undefined && params !== null) {
        if (params.acciones !== undefined) {
          delete params.acciones;
        }
        // console.log('params sin acciones');
        // console.log(params);
        history.push({
          pathname: `${page}`,
          state: params
        });
      } else {
        history.push(`${page}`);
      }
    } catch (err) {
      console.log('nextPage err');
      console.log(err);
    }
  };

  const handleNextPage = () => {
    if (maxRows >= listaDisponibilidad.length) return;
    setMinRows(maxRows);
    setMaxRows(maxRows + 5);
  };

  const handlePrevPage = () => {
    if (minRows <= 0) return;
    setMaxRows(minRows);
    setMinRows(Math.max(minRows - 5, 0));
  };

  const editarDisponibilidad = (data) => {
    try {
      // console.log("editarDisponibilidad");
      // console.log(data);
      if (data.acciones !== undefined) {
        delete data.acciones;
      }
      let obj = {};
      obj.titulo = "MODIFICAR DISPONIBILIDAD";
      obj.params = data;
      // console.log('obj');
      // console.log(obj);
      nextPage("disponibilidad/disponibilidad", obj);
    } catch (err) {
      console.log("editarDisponibilidad err");
      console.log(err);
    }
  };

  const cambiarEstado = async (data) => {
    try {
      console.log("cambiarEstado");
      console.log(data);
      setDisponibilidadSelected(data);
      setModalConfirmarDeshabilitarVisible(true);
    } catch (err) {
      console.log("cambiarEstado err");
      console.log(err);
    }
  };

  const handleAtrasClick = () => {
    history.goBack();
  }

  const reset = async () => {
    console.log('reset');
    setCargandoPage(true);
    const configuracionScreenInfo = await obtenerConfiguracionScreen({ nombre: GESTIONAR_DISPONIBILIDAD_SCREEN });
    console.log('configuracionScreenInfo');
    console.log(configuracionScreenInfo);
    let objConfiguracionEnviar = {};
    objConfiguracionEnviar.descendente = true;
    objConfiguracionEnviar.ascendente = false;
    if (configuracionScreenInfo !== null) {
      const configuracionOrden = JSON.parse(configuracionScreenInfo.orden);
      // console.log('configuracionOrden');
      // console.log(configuracionOrden);
      objConfiguracionEnviar.descendente = configuracionOrden.descendente;
      objConfiguracionEnviar.ascendente = configuracionOrden.ascendente;
      setAscendente(configuracionOrden.ascendente);
      setDescendente(configuracionOrden.descendente);
      let configuracionHeaders = null;
      // debugger;
      if (configuracionScreenInfo.headers !== null) {
        configuracionHeaders = JSON.parse(configuracionScreenInfo.headers);
        console.log('configuracionHeaders');
        console.log(configuracionHeaders);
        let headerAux = compareHeaders(headers, configuracionHeaders);
        headerAux = addFunctionToHeaders(headerAux);
        setGridHeaders(headerAux);
      }
      if (configuracionScreenInfo.fontSize !== null) {
        setFontSizeValue(configuracionScreenInfo.fontSize);
      }
      if (configuracionScreenInfo.align !== null) {
        setAlignValue(configuracionScreenInfo.align);
        const newHeader = addAttribute(gridHeaders, 'align', configuracionScreenInfo.align);
        setGridHeaders(newHeader);
      }
      setConfiguracionOrden(configuracionScreenInfo);
    }
    let lista = await obtenerListaDisponibilidad(objConfiguracionEnviar);
    agregarAccionesALista(lista);
    setCargandoPage(false);
  }

  const handleCancelModal = () => {
    setModalConfirmarDeshabilitarVisible(false);
  }

  const handleConfirmModal = async () => {
    setModalConfirmarDeshabilitarVisible(false);
    if (disponibilidadSelected == null) {
      return;
    }
    let objEnviar = {};
    objEnviar.id = disponibilidadSelected.id;
    if (disponibilidadSelected.id > 0) {
      // console.log('crear correo');
      let deshabilitado = await deshabilitarDisponibilidad(objEnviar);
      // console.log('creado');
      // console.log(creado);
      if (deshabilitado === true) {
        alert('Se deshabilito correctamente');
        reset();
      }
      if (deshabilitado === false) {
        alert('No se pudo deshabilitar');
      }
    }
  }

  const openModalCondiguracion = () => {
    setModalConfiguracionVisible(true);
  }

  const onHideModalConfiguracion = () => {
    setModalConfiguracionVisible(false);
  }

  const onClickAcceptarOrdenGrillaModalConfiguracion = async () => {
    try {
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = GESTIONAR_DISPONIBILIDAD_SCREEN;
      objConfiguracionEnviar.orden = objOrden;
      objConfiguracionEnviar.headers = gridHeaders;
      console.log('objConfiguracionEnviar');
      console.log(objConfiguracionEnviar);
      if (configuracionOrden === null) {
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        const actualizo = await actualizarOrdenGestionarDisponibilidadScreen(objConfiguracionEnviar);
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log('onClickAcceptarOrdenGrillaModalConfiguracion err');
      console.log(err);
    }
  }

  const onClickAcceptarHeadersGrillaModalConfiguracion = async () => {
    try {
      console.log('onClickAcceptarHeadersGrillaModalConfiguracion');
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = GESTIONAR_DISPONIBILIDAD_SCREEN;
      objConfiguracionEnviar.orden = objOrden;
      console.log('gridHeaders');
      console.log(gridHeaders);
      console.log('headers');
      console.log(headers);
      objConfiguracionEnviar.headers = gridHeaders;
      console.log('objConfiguracionEnviar');
      console.log(objConfiguracionEnviar);
      if (configuracionOrden === null) {
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        const actualizo = await actualizarHeadersGestionarDisponibilidadScreen(objConfiguracionEnviar);
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log('onClickAcceptarHeadersGrillaModalConfiguracion err');
      console.log(err);
    }
  }

  const onClickAcceptarFontSizeModalConfiguracion = async (fontSizeValue) => {
    try {
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = GESTIONAR_DISPONIBILIDAD_SCREEN;
      objConfiguracionEnviar.orden = objOrden;
      objConfiguracionEnviar.headers = gridHeaders;
      objConfiguracionEnviar.fontSize = fontSizeValue;
      // console.log('configuracionOrden');
      // console.log(configuracionOrden);
      // return;
      if (configuracionOrden === null) {
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        const actualizo = await actualizarFontSizeGrilla(objConfiguracionEnviar);
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log('onClickAcceptarFontSizeModalConfiguracion err');
      console.log(err);
    }
  }

  const onClickAcceptarAlignModalConfiguracion = async (align) => {
    try {
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = GESTIONAR_DISPONIBILIDAD_SCREEN;
      objConfiguracionEnviar.orden = objOrden;
      objConfiguracionEnviar.headers = gridHeaders;
      objConfiguracionEnviar.fontSize = fontSizeValue;
      objConfiguracionEnviar.align = align;
      // console.log('objConfiguracionEnviar');
      // console.log(objConfiguracionEnviar);
      // return;
      if (configuracionOrden === null) {
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        const actualizo = await actualizarAlignGrilla(objConfiguracionEnviar);
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log('onClickAcceptarAlignModalConfiguracion err');
      console.log(err);
    }
  }

  const deshablitarTodoSeleccionado = async () => {
    try {
      // console.log('deshablitarTodoSeleccionado');
      // console.log(listaDisponibilidad);
      const listaParaDeshabilitar = listaDisponibilidad.filter(e => e.checked !== undefined && e.checked === true);
      // console.log(listaParaDeshabilitar);
      setShowModalLoading(true);
      for (let i = 0; i < listaParaDeshabilitar.length; i++) {
        const element = listaParaDeshabilitar[i];
        let objEnviar = {};
        objEnviar.id = element.id;
        let deshabilitado = await deshabilitarDisponibilidad(objEnviar);
        if (deshabilitado === false) {
          alert('No se pudo deshabilitar');
          break;
        }
        await sleepFunction(200);
      }
      // await sleepFunction(3000);
      setShowModalLoading(false);
      setShowDeshabilitarAll(false);
      reset();
    } catch (err) {
      console.log('deshablitarTodoSeleccionado err');
      console.log(err);
      setShowModalLoading(false);
      reset();
    }
  }

  const render = () => {
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div
              style={{ marginTop: 10, }}
            >
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <label
              style={{
                paddingLeft: 10,
                fontWeight: 'bold',
              }}
            >
              GESTIONAR DISPONIBILIDAD
            </label>
            <div
              style={{
                paddingTop: 5,
                paddingBottom: 5,
                paddingRight: 10,
                paddingLeft: 10,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_ATRAS}
                onClick={openModalCondiguracion}
              >
                <Settings />
              </button>
            </div>
          </div>
          {showDeshabilitarAll &&
            <div
              style={{
                paddingTop: 5,
                paddingBottom: 5,
                paddingRight: 10,
                paddingLeft: 10,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  backgroundColor: 'red',
                  color: "white",
                  borderRadius: "15px",
                  cursor: "pointer",
                  marginLeft: 10,
                  marginTop: 5,
                }}
                onClick={() => {
                  deshablitarTodoSeleccionado();
                }}
                title={"DESHABILITAR TODO"}
              >
                DESHABILITAR TODO
              </span>
            </div>
          }
          <Grid
            rows={listaDisponibilidad}
            headers={gridHeaders}
            exportar={exportar}
            fontSize={fontSizeValue}
            align={alignValue}
          />
          <div>
            <button className={ESTILO_BOTON_GRILLA_PREVIOUS} onClick={handlePrevPage}>Previous</button>
            <button className={ESTILO_BOTON_GRILLA_NEXT} onClick={handleNextPage}>Next</button>
          </div>
          <ModalConfirmar
            isOpen={modalConfirmarDeshabilitarVisible}
            onCancel={handleCancelModal}
            onConfirm={handleConfirmModal}
            message="¿Estás de deshabilitar este registro?"
          />
          <ModalConfiguracionGrilla
            modalConfiguracionVisible={modalConfiguracionVisible}
            onHideModalConfiguracion={onHideModalConfiguracion}
            ascendente={ascendente}
            descendente={descendente}
            setAscendente={setAscendente}
            setDescendente={setDescendente}
            gridHeaders={gridHeaders}
            setGridHeaders={setGridHeaders}
            onClickAcceptarOrdenGrillaModalConfiguracion={onClickAcceptarOrdenGrillaModalConfiguracion}
            onClickAcceptarHeadersGrillaModalConfiguracion={onClickAcceptarHeadersGrillaModalConfiguracion}
            onClickAcceptarFontSizeModalConfiguracion={onClickAcceptarFontSizeModalConfiguracion}
            fontSize={fontSizeValue}
            align={alignValue}
            onClickAcceptarAlignModalConfiguracion={onClickAcceptarAlignModalConfiguracion}
          />
          <ModalLoading
            isOpen={showModalLoading}
          />
        </div >
      );
    } catch (err) {
      console.log('GestionarDisponibilidad render err');
      console.log(err);
      return (
        <div><label>{JSON.stringify(err)}</label></div>
      );
    }
  }

  return render();
}


//comment
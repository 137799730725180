import { useState, useEffect } from "react";
import Modulo from "../../../components/modulo";
import "../../../style/style-cargando.css";
import {
  INT_ID_MODULO_CHECKLIST,
  INT_ID_SEGUNDO_NIVEL_REPORTE_CHECKLIST,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../../utils/const-int";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../../api/permisos/permisos";
import { TRUE } from "../../../utils/const-bool";
import { LISTA_REPORTES_CHECKLIST } from "./lista-reportes-checklist";
import { EMPRESA_CBN, EMPRESA_PLASTICO_USR } from "../../../utils/const-id-empresa";
import shortid from "shortid";
import carIcon from "../../../assets/iconos/car.png"

const idTipoUsuario = parseInt(localStorage.getItem("tipo"));
const idEmpresa = parseInt(localStorage.getItem("idEmpresa"));

export default function ReportesChecklist() {

  const [permisos, setPermisos] = useState(null);
  const [cargandoPage, setCargandoPage] = useState(false);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);
  const [listaReportes, setListaReportes] = useState(LISTA_REPORTES_CHECKLIST);

  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      let objPermiso = {};
      objPermiso.idMenuPrimerNivel = INT_ID_MODULO_CHECKLIST;
      objPermiso.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_REPORTE_CHECKLIST;
      objPermiso.idMenuTercerNivel = 0;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(objPermiso);
      // console.log('obtenerPermisos');
      // console.log(obtenerPermisos);
      let permiso = obtenerPermisos[0];
      setPermisos(permiso);
      // console.log('idTipoUsuario');
      // console.log(idTipoUsuario);
      // debugger;
      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        setTieneAccesoAlModulo(true);
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.accesoAlModulo === TRUE) {
          setTieneAccesoAlModulo(true);
        }
      }
      console.log('idEmpresa');
      console.log(idEmpresa === EMPRESA_CBN || idEmpresa === EMPRESA_PLASTICO_USR);
      if (idEmpresa === EMPRESA_CBN || idEmpresa === EMPRESA_PLASTICO_USR) {
        let lista = [...listaReportes];
        lista.push({

          id: shortid.generate(),
          titulo: 'CONSOLIDADO CHECKLIST PRECINTO',
          descripcion: 'Reporte excel',
          icono: carIcon,
          color: 'rgba(0, 128, 0, 0.5)',
          href: 'reporte-consolidado-precinto',

        });
        setListaReportes(lista);
      }
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const render = () => {
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div
              style={{ marginTop: 10, }}
            >
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div>
          <div className="flex flex-wrap">
            {listaReportes.map((data) => Modulo(data))}
          </div>
        </div>
      );
    } catch (err) {
      console.log("ReportesChecklist render");
      console.log(err);
      return (
        <div className=" w-1/2 m-auto px-[2rem] ">
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  };

  return render();
}
